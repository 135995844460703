import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CallWithAuth } from "../action/apiActions";
import { GET_CREW } from "../action/apiPath";
import FilterModal from "./FilterModal";
import FilterModalInputComponent from "./FilterModalInputComponent";
import Search from "../pages/ShiftPlanning/components/GeneralFields/Search";
import "./styles.css";
import filterUrl from "../assets/images/filter.png";
import ActionButton from "./Button";
import DynamicSelectField from "../pages/ShiftPlanning/components/DynamicSelectField/DynamicSelectField";
import moment from "moment";
import DatePickerField from "../pages/ShiftPlanning/components/GeneralFields/DateTimeSelect/DateTimeSelect"

const FilterBoard = ({ headerClassName, className, openModal, optimizedVersion, date, setDate, searchCrew, setSearchCrew, selectedEquipment, setSelectedEquipment, selectedUserType, setSelectedUserType, userType, onApplyFilter, onClear, filter, departments, statusList, selectedDepartmentType, setSelectedDepartmentType, selectedStatus, setSelectedStatus }) => {
  const [crewTypesList, setCrewTypesList] = useState([]);
  const [isFilterModalOpen, setisFilterModalOpen] = useState(false);
  const equipmentLicenseList = [
    { label: "Excavator", value: "Excavator" },
    { label: "Truck", value: "Truck" }
  ]

  // useEffect(() => {
  //   const today = moment().format('YYYYMMDD');
  //   if (!date) setDate(today);
  // }, [date]);
  const departmentKeyValuePairs = departments.map((department) => ({
    label: department?.DepartmentType,
    value: department?.DepartmentType
  }));


  const getCrew = async () => {
    const response = await CallWithAuth("GET", GET_CREW);
    if (response.res.data.status === 200) {
      setCrewTypesList(response.res.data.data.map((Crew) => ({
        label: Crew?.CrewType,
        value: Crew?.CrewType
      })));
    }
  };

  useEffect(() => {
    getCrew();
  }, []);

  function convertToMoment(arr) {
    if (arr === null) {
      return null;
    }
    const date = arr.map(dateStr => moment(dateStr, 'YYYYMMDD')); // Parse using the YYYYMMDD format
    console.log(date);
    return date;
  }

  const DateFooter = () => {
    return (
      <div className="d-flex justify-content-end my-2">
        <ActionButton
          actionType="discard"
          className="manage-user-action-btn mr-5"
          onClick={() => {setisFilterModalOpen(false);setisFilterModalOpen(false);}}
        >
          Cancel
        </ActionButton>
        <ActionButton
          className="manage-user-action-btn"
          onClick={() => { onApplyFilter(); setisFilterModalOpen(false);}}
        >
          Apply
        </ActionButton>
      </div>
    )
  }

  return (
    <>
      <ActionButton
        className="manage-user-action-btn mt-3"
        onClick={() => openModal()}
      >
        Add User
      </ActionButton>
      <div className="top_middle_right mt-3">
        <Search className="" onChange={(value) => optimizedVersion(value)} />
        <div className="manage-user-filter-btn" onClick={() => setisFilterModalOpen((prev) => !prev)} style={{backgroundColor:isFilterModalOpen?"#e2ecfc":"#FFFFFF"}}>
          <img src={filterUrl} />
          <span>Filter</span>
        </div>
      </div>
      {isFilterModalOpen && (
        <FilterModal
          onCancel={() => setisFilterModalOpen(false)}
          onFilterApply={()=> {onApplyFilter(); setisFilterModalOpen(false);}}
          onClear={()=>{onClear(); setisFilterModalOpen(false);}}
          filter={filter}
          className={className}
          headerClassName={headerClassName}
          clearFontColor={"#707070"}
        >
          {/* <DateSelect
            label="Added On"
            // renderExtraFooter={() => <DateFooter/>}
            defaultValue={[date, endDate]}
            disabled={[false, false]}
            value={[date, endDate]}
            onChange={(dates) => {
              if (dates === null) {
                setdate(null);
                setEndDate(null);
              } else {
                setdate(dates[0]);
                setEndDate(dates[1]);
              }
            }}
          /> */}
          <>{console.log("date====",date,1)}</>
          <DatePickerField
            label={"Added On"}
            containerClass="align-items-center"
            dateOnly={true}
            onChange={(val) => setDate(new Date(val).toISOString())}
            value={date ? new Date(date) : ""}
          />
          <DynamicSelectField
            label={"User Type"}
            placeholder={""}
            options={userType}
            value={selectedUserType}
            onChange={(v) => setSelectedUserType(v)}
            isSearchable
            isMulti
          />
          <DynamicSelectField
            label={"Department"}
            placeholder={""}
            options={departmentKeyValuePairs}
            value={selectedDepartmentType}
            onChange={(value) => setSelectedDepartmentType(value)}
            isMulti
            isSearchable
          />
          <DynamicSelectField
            label={"Crew"}
            placeholder={""}
            options={crewTypesList}
            value={searchCrew}
            onChange={(value) => setSearchCrew(value)}
            isMulti
            isSearchable
          />
          <DynamicSelectField
            label={"License"}
            placeholder={""}
            options={equipmentLicenseList}
            value={selectedEquipment}
            onChange={(value) => setSelectedEquipment(value)}
            isMulti
            isSearchable
          />
          <DynamicSelectField
            label={"Status"}
            placeholder={""}
            options={statusList}
            value={selectedStatus}
            onChange={(value) => setSelectedStatus(value)}
            isMulti
          />
        </FilterModal>
      )}
    </>
  )
};

export default FilterBoard;
