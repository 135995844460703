import React, { useEffect } from 'react'

export const ConfirmSingleStatusPopup = ({
    onClose,
    statusChange,
    confirmOnClick,
    type,
    userName,
    setUsers,
    users
  }) => {
    useEffect(()=>{
        setUsers((prev) => ({
            ...prev,
            checkedUserList: [...prev.checkedUserList, users?.selectedUser?._id]
          }));
    },[])
  const submitStatusHandler = () => {
    onClose(false);
    confirmOnClick();
  };
  return (
    <div
      className={
        statusChange
          ? "full_modal generate_new_pin_active"
          : "full_modal"
      }
      id="generate_new_pin"
    >
      <div className="generate_new_pin_box" style={{width: "345px"}}>
        <div
          className="close_icon"
          onClick={() => onClose(false)}
        >
          <img src="assets/images/close.png" />
        </div>
        <h4 style={{font: "normal normal normal 16px/22px Open Sans"}}>Are you sure you want to <span style={{font: "normal normal bold 16px/22px Open Sans"}}>{type==="ACTIVATE"?"activate":(type==="delete"?"delete":"deactivate")}</span> {userName}?</h4>
        <div className="generate_new_pin_form">
          <div className="add_new_user_box_top_right">
            {/* <button
                  className="btn discard"
                  onClick={() => setStatusModal(false)}
                >
                  Cancel
                </button> */}
            <button className="btn save" onClick={submitStatusHandler} style={{height:"40px",paddingTop:"0.6rem"}}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
