import React from 'react';

const NoDataMessage = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center',
            color: '#666'
        }}>
            <div style={{
                fontSize: '4rem',
                marginBottom: '1rem'
            }}>
                📄
            </div>
            <h2 style={{
                fontSize: '1.5rem',
                margin: '0'
            }}>
                No Data to Show
            </h2>
            <p style={{
                fontSize: '1rem',
                color: '#999'
            }}>
                Truck Assigned to Excavator, but Not Assigned in Any Excavator Shift Plan
            </p>
        </div>
    );
};

export default NoDataMessage;
