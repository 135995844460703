import React from 'react'
import ActionButton from '../../../components/Button';
import './style.css';

const BulkAction = ({ users, setBulkActionType, setModals, removeAllSelected }) => {
  const getActions = () => {
    const statusSet = new Set(users?.checkedUserList?.map(id => 
      users?.userData?.find(user => user._id === id)?.status
    ));

    if (statusSet.size === 1) {
      switch (statusSet.values().next().value) {
        case 'A': return [
          { value: 'I', type: 'deactivate' },
          { value: 'D', type: 'delete' },
        ];
        case 'I': return [
          { value: 'A', type: 'activate' },
          { value: 'D', type: 'delete' },
        ];
        case 'Invited':
        default: return [
          { value: 'D', type: 'delete' },
        ];
      }
    }

    return [{ value: 'D', type: 'delete' }];
  };

  const actions = getActions();

  const handleAction = (type) => {
    setBulkActionType(type);
    setModals(prev => ({ ...prev, MultipleStatusChange: true }));
  };

  return (
    <div className="select_check_user mt-1">
      <div className="select_check_user_left">
        {actions.map((action, index) => (
          <ActionButton
            key={index}
            className="manage-user-action-btn"
            onClick={() => handleAction(action)}
          >
            {action?.type.charAt(0).toUpperCase() + action?.type.slice(1).toLowerCase()}
          </ActionButton>
        ))}
      </div>
      <div className="select_check_user_right">
        <text>
          {users.checkedUserList?.length} {users.checkedUserList?.length > 1 ? "Users" : "User"} Selected
        </text>
        <span
          onClick={removeAllSelected}
          style={{ cursor: "pointer" }}
        >
          <img src="assets/images/close.png" alt="Close" />
        </span>
      </div>
    </div>
  )
}

export default BulkAction