import { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { CgSearch, CgClose } from 'react-icons/cg';

import VehicleDetailModal from './VehicleDetailModal';
import { DumpPointsContext } from '../DumpPointsContext';
import { CSVLink } from 'react-csv';
import { FiDownload } from 'react-icons/fi';
import { formatEpochTimeToNZ } from '../../../utils/helper';


export default function VehiclesModal(props) {
  const {
    setIsDetailPageOpen,
    shiftChoice,
    setShiftChoice,
    vehicleEvents,
    selectedVehicle,
    setSelectedVehicle,
    selectedDate,
    setSelectedDate
  } = useContext(DumpPointsContext);
  const [searchQuery, setSearchQuery] = useState();

  const [filteredVehicleEvents, setFilteredVehicleEvents] =
    useState(vehicleEvents);
  // useEffect(() => {
  //   setSelectedVehicle(undefined)
  // }, [shiftChoice])
  useEffect(() => {
    if (!searchQuery) {
      setFilteredVehicleEvents(vehicleEvents);
    } else {
      setFilteredVehicleEvents(
        vehicleEvents.filter((vehicleEvent) =>
          vehicleEvent.name.toLowerCase()?.includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [vehicleEvents, searchQuery]);

  useEffect(() => {
    // setSelectedVehicle({})
    setSearchQuery('')
  }, [shiftChoice])

  const [showDetailModal, setShowDetailModal] = useState(false);

  if (showDetailModal) {
    setIsDetailPageOpen(true);
    return <VehicleDetailModal close={() => setShowDetailModal(false)} />;
  } else {
    setIsDetailPageOpen(false);
  }
  const getCSVData = () => {
    const allLoads = [];
    for (let i = 0; i < vehicleEvents.length; i++) {
      const selectedVehicle = vehicleEvents[i];
      const laodsForOneTruck = selectedVehicle?.allLoads
        .reduce((prev, dumpPoint, j) => [...prev, ...dumpPoint?.loads.map((load, k) => ({
          Truck: j == 0 && k == 0 ? selectedVehicle?.name : '',
          DumpPoint: dumpPoint?.name,
          Material: load.material,
          Location: load.lat + " , " + load.lng,
          Operator: load.operator || '-',
          Timestamp: load.timestamp
          ,
        }))], []);
      allLoads.push(...laodsForOneTruck.sort((a, b) => a.Timestamp - b.Timestamp).map((x) => ({
        ...x, Timestamp: x?.Timestamp !== 0
          ? formatEpochTimeToNZ(x?.Timestamp)
          : '-'
      })));
    }
    console.log(allLoads);
    return allLoads;
  }
  const vehicleEventsList = () => {
    return filteredVehicleEvents.map((vehicleEvent, index) => {
      const isUnknown = vehicleEvent.totalUnknowns > 0;
      return (
        <tr
          className="d-flex my-2 align-items-stretch"
          key={index}
          style={{
            background:
              selectedVehicle?._id === vehicleEvent._id
                ? '#D9E5FC66 0% 0% no-repeat padding-box'
                : '#FFFFFF66 0% 0% no-repeat padding-box',
            border:
              selectedVehicle?._id === vehicleEvent._id
                ? '2px solid #346FD2'
                : '2px solid #DDE5F3',
            borderRadius: 5,
            fontSize: '13px',
            width: '100%',
          }}
          onClick={() => setSelectedVehicle(vehicleEvent)}
        >
          <td
            style={{
              backgroundColor: isUnknown ? '#FF6E6E' : 'transparent',
              width: '6%',
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          />
          <td style={{ width: '4%' }} />
          <td className="py-2" style={{ width: '30%', lineHeight: '150%' }}>
            {vehicleEvent.name}
          </td>
          <td style={{ height: 'min-content', margin: 'auto', width: '30%' }}>
            {vehicleEvent.totalLoads}
          </td>
          <td style={{ height: 'min-content', margin: 'auto', width: '30%' }}>
            {vehicleEvent.totalUnknowns}
          </td>
        </tr>
      );
    });
  };

  return (
    <div
      style={{
        top: '50px',
        left: '20px',
        position: 'absolute',
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "space-between",
        width: 350,
        height: 'calc(100% - 140px)',
        background: ' #FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: ' 0px 3px 6px #00000029',
        borderRadius: '10px',
        zIndex: 2,
      }}
    >
      <div
        style={{
          background: '#F5F6F9 0% 0% no-repeat padding-box',
          padding: '15px 28px',
          borderRadius: '10px 10px 0px 0px',
        }}
      >
        <div className="d-flex justify-content-between ">
          <div className="d-flex align-items-center">
            <Image
              src={require('../../../assets/images/truck.png')}
              height={30}
              width={45}
              style={{ marginRight: '5px' }}
            />
            <span
              style={{
                font: 'normal normal 600 18px/24px Open Sans',
                marginLeft: '5px',
              }}
            >
              VEHICLES
            </span>
          </div>
          <CgClose
            onClick={props.close}
            style={{ cursor: 'pointer' }}
            size={20}
            className="px-1 mb-3"
          />
        </div>
        <div className='d-flex align-items-center'>
          <div style={{ width: '70%', }}><input type="date" style={{ height: 35 }} className='form-control' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} /></div>

          <div
            className="m-3 d-flex"
            style={{ backgroundColor: 'rgb(240,244,251)', borderRadius: '15px', boxShadow: '0px 0px 1px grey' }}
          >

            <div
              style={{
                backgroundColor:
                  shiftChoice == "0" ? 'rgb(203,220,248)' : 'rgb(240,244,251)',
                color: shiftChoice == "0" ? 'black' : '#96a4bb',

                borderWidth: '0px',
                cursor: 'pointer',
                fontSize: '1rem',
                fontWeight: '600',
                padding: '0px 1.3rem',
                borderRadius: '15px'
              }}
              key={0}
              name="radio"
              value={0}
              onClick={(e) => setShiftChoice("0")}
            >
              Day
            </div>
            <div
              style={{
                backgroundColor:
                  shiftChoice == "1" ? 'rgb(20,36,62)' : 'rgb(241,244,251)',

                color: shiftChoice == "1" ? 'white' : '#96a4bb',

                borderWidth: '0px',
                cursor: 'pointer',
                fontSize: '1rem',
                fontWeight: '600',
                padding: '0px 1rem',
                borderRadius: '15px',
              }}
              key={1}
              name="radio"
              value={1}
              onClick={(e) => setShiftChoice("1")}
            >
              Night
            </div>

          </div>
        </div>
      </div>
      <div
        style={{
          padding: '15px 28px',
          height: 'calc(100% - 130px)',
        }}
      >
        <div className="d-flex justify-content-between align-items-end">
          <div
            className="px-2 my-2 d-flex align-items-center"
            style={{
              height: 35,
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              border: '1px solid #DDE5F3',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '3px',
              width: '100%',
              padding: 8,
            }}
          >
            <CgSearch color="gray" size={12} style={{ margin: '0 7px' }} />
            <input
              className="shadow-none"
              style={{
                border: 'none',
                font: 'normal normal normal 14px/19px Open Sans',
              }}
              placeholder="Search for a vehicle"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div style={{ flexDirection: 'column' }} className='d-flex align-items-center justify-content-center'>
            <CSVLink
              data={getCSVData()}
              filename={"Shift-Dump-Point-Data"}
              target="_blank"
            >
              <FiDownload
                style={{
                  backgroundColor: '#F2F7FF',
                  color: 'black',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  padding: '.4rem',
                  width: '2.3rem',
                  height: '2.3rem',
                  border: '1.5px solid rgb(225,232,244)',
                  marginBottom: 3,
                  marginLeft: 5
                }}
              />
            </CSVLink>
            <span
              style={{
                textDecoration: 'underline',
                font: 'normal normal normal 14px/19px Open Sans',
                color: ' #346FD2',
                cursor: vehicleEvents.length > 0 && vehicleEvents.findIndex(v => v.totalLoads > 0) != -1 ? 'pointer' : 'not-allowed',
              }}

              onClick={() => vehicleEvents.length > 0 && vehicleEvents.findIndex(v => v.totalLoads > 0) != -1 && setShowDetailModal(true)}
              className="d-flex align-items-center justify-content-center  mb-2 ml-3"
            >
              Details
            </span>
          </div>
        </div>

        <div
          className="mt-3"
          style={{
            height: 'calc(100% - 50px)',
            border: ' 1px solid #DDE5F3',
            borderRadius: 5,
            width: '100%',
          }}
        >
          <tr
            className="d-flex py-3"
            style={{
              background: ' #F2F7FF 0% 0% no-repeat padding-box',
              borderRadius: '4px 4px 0px 0px',
              font: 'normal normal 600 13px/17px Open Sans',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <td style={{ width: '10%' }} />
            <td style={{ width: '30%' }}>Truck</td>
            <td style={{ width: '30%' }}>Total Loads</td>
            <td style={{ width: '30%' }}>Unknown</td>
          </tr>
          <div
            style={{
              backgroundColor: '#FAFAFA',
              overflowY: 'auto',
              height: 'calc(100% - 40px)',
              padding: '0 8px 0',
            }}
          >
            <div
              style={{
                paddingTop: '15px',
                paddingBottom: '50px',
              }}
              className="d-flex flex-column"
            >
              {vehicleEventsList()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
