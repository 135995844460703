import moment from 'moment';
import { DateTimeFormats, ShiftStatus } from '../../../utils/contants';

// Validate function
export const validate = (data) => {
    const {
        reportNo,
        mine,
        shiftType,
        department,
        crew,
        startTime,
        endTime,
        productiveHours,
        shiftSupervisor,
        sickLeave,
        annualLeave,
        absent
    } = data;

    // Check for required fields
    if (
        !reportNo ||
        !mine ||
        !shiftType ||
        !department ||
        !crew ||
        !startTime ||
        !endTime ||
        !productiveHours ||
        !shiftSupervisor ||
        moment(endTime, DateTimeFormats.DATE_TIME_U).diff(moment(startTime, DateTimeFormats.DATE_TIME_U), 'minutes') <= 0 ||
        moment(endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 0
    ) {
        return false;
    }

    // Check if endTime is after startTime and the difference in minutes is less than productive hours
    if (
        moment(endTime, DateTimeFormats.DATE_TIME_U).diff(moment(startTime, DateTimeFormats.DATE_TIME_U), 'hour') < productiveHours.value
    ) {
        return false;
    }

    // Check mutual exclusivity of leave types
    if (
        [...sickLeave, ...absent].some(v => annualLeave?.find(iv => iv.value === v.value)) ||
        [...annualLeave, ...absent].some(v => sickLeave?.find(iv => iv.value === v.value)) ||
        [...sickLeave, ...annualLeave].some(v => absent?.find(iv => iv.value === v.value))
    ) {
        return false;
    }

    return true;
};

// Calculate targetBCMs and productiveHours
export const calculateTargetBCMsAndProductiveHours = (startTime, endTime, bcmPerHour, productiveHours) => {
    const diffInHours = moment(endTime, DateTimeFormats.DATE_TIME_U).diff(moment(startTime, DateTimeFormats.DATE_TIME_U), 'hours');
    const effectiveProductiveHours = Math.min(diffInHours, productiveHours.value);

    return {
        targetBCMs: effectiveProductiveHours * parseInt(bcmPerHour),
        productiveHours: { value: effectiveProductiveHours, label: effectiveProductiveHours.toString() }
    };
};

// Update excavator details function
export const updateExcavatorDetails = (prev, data, shiftStatus, getTargetLoads) => {
    return prev.map(ex => {
        const firstShiftPlan = ex?.shiftPlans[0];
        const lastShiftPlan = ex?.shiftPlans[ex?.shiftPlans.length - 1];

        const startTime = firstShiftPlan?.plan === ex.plan ? data?.startTime : ex.startTime;
        const endTime = lastShiftPlan?.plan === ex.plan ? data?.endTime : ex.endTime;

        const { targetBCMs, productiveHours } = calculateTargetBCMsAndProductiveHours(
            startTime,
            endTime,
            ex.bcmPerHour,
            data.productiveHours
        );

        return {
            ...ex,
            startTime,
            endTime,
            targetBCMs,
            productiveHours,
            haulTrucks: ex.haulTrucks?.map(ht => ({
                ...ht,
                targetLoads: getTargetLoads({ targetBCMs, actualBCMs: ex?.actualBCMs }, ex.haulTrucks),
                productiveHours,
                startTime,
                endTime,
            })),
            shiftPlans: ex?.shiftPlans?.map((shiftPlan, spIdx) => {
                const isFirstShiftPlan = spIdx === 0;
                const isLastShiftPlan = spIdx === ex?.shiftPlans?.length - 1;
                const isActivePlan = shiftStatus === ShiftStatus.NEW || shiftStatus === ShiftStatus.DRAFT || (shiftPlan?.status !== ShiftStatus.ENDED && moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') > 0);

                if ((isFirstShiftPlan || isLastShiftPlan) && isActivePlan) {
                    const newStartTime = isFirstShiftPlan ? data?.startTime : shiftPlan.startTime;
                    const newEndTime = isLastShiftPlan ? data?.endTime : shiftPlan.endTime;

                    const { targetBCMs, productiveHours } = calculateTargetBCMsAndProductiveHours(
                        newStartTime,
                        newEndTime,
                        ex.bcmPerHour,
                        data.productiveHours
                    );

                    return {
                        ...shiftPlan,
                        startTime: newStartTime,
                        endTime: newEndTime,
                        targetBCMs,
                        productiveHours,
                        haulTrucks: shiftPlan.haulTrucks?.map(ht => ({
                            ...ht,
                            targetLoads: getTargetLoads({ targetBCMs, actualBCMs: shiftPlan?.actualBCMs }, shiftPlan.haulTrucks),
                            productiveHours,
                            startTime: newStartTime,
                            endTime: newEndTime,
                        })),
                    };
                }

                return shiftPlan;
            }),
        };
    });
};
