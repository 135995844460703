import React, { useState } from "react";

import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ActionMenu from "../pages/ShiftPlanning/components/ActionMenu/ActionMenu";

const Header = ({ pageName }) => {
  const { auth } = useAuth();
  const { logout } = useAuth();
  const [ShowAccountPopUp, setShowAccountPopUp] = useState(false);
  const [ChatPopUp, setShowChatPopUp] = useState(false);

  // console.log(auth)
  return (
    <>
      <div className="top_heading">
        <h2 style={{ marginBottom: 0 }}>{pageName}</h2>
      </div>
      <div className="top_right">
        <ul>
          <li className="header_dropdown_li">
            <a href="#">
              <i
                style={{ color: "#FFF", fontSize: "1rem" }}
                className="fa-regular fa-bell"
              ></i>
            </a>
            {/* <div className="notifiDropdown">
                <div className="notifiDropdownTop">
                  <h2>Notifications</h2>
                  <div className="right">
                    <div className="top_middle_search">
                      <input type="search" id="searchuser" name="searchuser" placeholder="Search" />
                      <button type="search" name="" id=""><i className="fa-regular fa-magnifying-glass"></i></button>
                    </div>
                    <div className="top_middle_filter">
                      <img src="assets/images/filter.png" style={{cursor: "pointer"}} />
                    </div>
                    <div className="top_dot_filter">
                      <div className="dropdown">
                        <button className="btn btn-light btn-lg dropdown-toggle" type="button" data-toggle="dropdown">
                          <img src="assets/images/dot.png" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a className="dropdown-item" href="#">Mark as Read</a>
                          <a className="dropdown-item" href="#">Remind Later (15 min)</a>
                          <a className="dropdown-item" href="#">Remind Later (1 hour)</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="notifiDropdownTab">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Inbox</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Archive</a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="tabs-1" role="tabpanel">
                      <div className="notifiRow">
                        <div className="notifiCol unreadNotifi">
                          <div className="left">
                            <h3>Breakdown - HT227</h3>
                            <p>Description - Lorem ipsum dolor sit amet, timeam aliquid inciderint duo eu. Et vitae assueverit quaerendum eam, ex sit case nemore probatus, ad est harum homero.</p>
                          </div>
                          <div className="right">
                            <div className="btnGroup">
                              <button className="flagactive">
                                <img className="forUnactive" src="assets/images/flag.png" />
                                <img className="foractive" src="assets/images/flag-blue.svg" />
                              </button>
                              <button><img src="assets/images/box.png" /></button>
                              <div className="dropdown">
                                <button className="btn btn-light btn-lg dropdown-toggle" type="button" data-toggle="dropdown">
                                 <img src="assets/images/dot2.png" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <a className="dropdown-item" href="#">Mark as Read</a>
                                  <a className="dropdown-item" href="#">Remind Later (15 min)</a>
                                  <a className="dropdown-item" href="#">Remind Later (1 hour)</a>
                                </div>
                              </div>
                            </div>
                            <p>1 hour ago</p>
                          </div>
                        </div> 
                        <div className="notifiCol unreadNotifi">
                          <div className="left">
                            <h3>Delay Log - HT340</h3>
                            <p>Description - Lorem ipsum dolor sit amet, timeam aliquid inciderint duo eu. Et vitae assueverit quaerendum eam, ex sit case nemore probatus - Ref No: <a href="#">DL09823</a> pduo eu.</p>
                          </div>
                          <div className="right">
                          <div className="btnGroup">
                              <button className="flagactive">
                                <img className="forUnactive" src="assets/images/flag.png" />
                                <img className="foractive" src="assets/images/flag-blue.svg" />
                              </button>
                              <button><img src="assets/images/box.png" /></button>
                              <div className="dropdown">
                                <button className="btn btn-light btn-lg dropdown-toggle" type="button" data-toggle="dropdown">
                                 <img src="assets/images/dot2.png" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <a className="dropdown-item" href="#">Mark as Read</a>
                                  <a className="dropdown-item" href="#">Remind Later (15 min)</a>
                                  <a className="dropdown-item" href="#">Remind Later (1 hour)</a>
                                </div>
                              </div>
                            </div>
                            <p>1 hour ago</p>
                          </div>
                        </div>
                        <div className="notifiCol flagactived">
                          <div className="left">
                            <h3>Breakdown - HT227</h3>
                            <p>Description - Lorem ipsum dolor sit amet, timeam aliquid inciderint duo eu. Et vitae assueverit quaerendum eam, ex sit case nemore probatus, ad est harum homero.</p>
                          </div>
                          <div className="right">
                          <div className="btnGroup">
                              <button className="flagactive active">
                                <img className="forUnactive" src="assets/images/flag.png" />
                                <img className="foractive" src="assets/images/flag-blue.svg" />
                              </button>
                              <button className="archiveBtn"><img src="assets/images/box.png" /></button>
                              <div className="dropdown actionBtn">
                                <button className="btn btn-light btn-lg dropdown-toggle" type="button" data-toggle="dropdown">
                                 <img src="assets/images/dot2.png" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <a className="dropdown-item" href="#">Mark as Read</a>
                                  <a className="dropdown-item" href="#">Remind Later (15 min)</a>
                                  <a className="dropdown-item" href="#">Remind Later (1 hour)</a>
                                </div>
                              </div>
                            </div>
                            <p>1 hour ago</p>
                          </div>
                        </div>
                        <div className="notifiCol">
                          <div className="left">
                            <h3>Breakdown - HT227</h3>
                            <p>Description - Lorem ipsum dolor sit amet, timeam aliquid inciderint duo eu. Et vitae assueverit quaerendum eam, ex sit case nemore probatus, ad est harum homero.</p>
                          </div>
                          <div className="right">
                          <div className="btnGroup">
                              <button className="flagactive">
                                <img className="forUnactive" src="assets/images/flag.png" />
                                <img className="foractive" src="assets/images/flag-blue.svg" />
                              </button>
                              <button><img src="assets/images/box.png" /></button>
                              <div className="dropdown">
                                <button className="btn btn-light btn-lg dropdown-toggle" type="button" data-toggle="dropdown">
                                 <img src="assets/images/dot2.png" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <a className="dropdown-item" href="#">Mark as Read</a>
                                  <a className="dropdown-item" href="#">Remind Later (15 min)</a>
                                  <a className="dropdown-item" href="#">Remind Later (1 hour)</a>
                                </div>
                              </div>
                            </div>
                            <p>1 hour ago</p>
                          </div>
                        </div> 
                      </div>
                    </div>
                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                      <p>Second Panel</p>
                    </div>                    
                  </div>
                </div>
            </div> */}
          </li>
          <li>
            <ActionMenu
              isOpen={ShowAccountPopUp}
              onClick={(e) => {
                setShowAccountPopUp(!ShowAccountPopUp);
              }}
              icon={
                <div className="account-icon">{auth?.userData?.firstName?.charAt(0)}
                  {auth?.userData?.lastName?.charAt(0)}
                </div>
              }
              options={[
                { name: 'My Account', onClick: () => window.location.href = '/myaccount' },
                { name: 'LogOut', onClick: () => logout() }]}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
