export const whichUserType = (userType) => {
    const operatorCodes = [200, 700];
    const bothCodes = [300, 400, 600];
    const supervisorCodes = [500]

    const isBothExist = bothCodes?.some(value => userType?.includes(value)) || false;
    const isSupervisorExist = supervisorCodes?.some(value => userType?.includes(value)) || false;
    const isOperator = operatorCodes?.some(value => userType?.includes(value)) || false;

    return [isBothExist, isSupervisorExist, isOperator]
}
export const userTypeName = (currentUserType, userType) => {
    const code = currentUserType[0]
    const userTypeNameValue = userType?.find((ele => ele?.code == code));
    return userTypeNameValue?.name
}

export const sortExData = (exData) => {

    const sortHaulTrucks = (haulTrucks) => {
        return haulTrucks?.sort((a, b) => a?.plantId?.localeCompare(b?.plantId));
    };

    const sortShiftPlans = (shiftPlans) => {
        return shiftPlans?.map(shiftPlan => ({
            ...shiftPlan,
            haulTrucks: sortHaulTrucks(shiftPlan?.haulTrucks)
        })).sort((a, b) => a?.plan?.localeCompare(b?.plan));
    };

    return exData?.map(ex => ({
        ...ex,
        shiftPlans: sortShiftPlans(ex?.shiftPlans)
    })).sort((a, b) => a?.plantId?.localeCompare(b?.plantId));
};