import React, { useEffect, useState } from "react";
import { CallWithAuth } from "../action/apiActions";
import { CHANGE_PASSWORD } from "../action/apiPath";
import usePasswordToggle from "../hooks/usePasswordToggle";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";

const ChangePasswordModal = ({ openChangeModal, closeModal }) => {
  const { logout } = useAuth();
  const [CurrentpasswordInputType, CurrentToggleIcon] = usePasswordToggle();
  const [NewpasswordInputType, NewPasswordToggleIcon] = usePasswordToggle();
  const [ConfirmpasswordInputType, ConfirmToggleIcon] = usePasswordToggle();
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    isError: false,
  });

  useEffect(() => {
    return () => {
      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  }, []);


  const handelOnchange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      isError: false,
    });
  };

  const handelValidateForm = () => {
    if (
      formData.currentPassword !== "" &&
      formData.newPassword !== "" &&
      formData.confirmPassword !== "" &&
      formData.newPassword === formData.confirmPassword
    )
      return Promise.resolve(true);
    return Promise.resolve(false);
  };

  const submitChangePasswordForm = async () => {
    console.log("submitChangePasswordForm");
    let validationStatus = await handelValidateForm();
    if (validationStatus) {
      let payload = {
        oldpassword: formData.currentPassword,
        newpassword: formData.newPassword,
      };
      const response = await CallWithAuth("POST", CHANGE_PASSWORD, payload);
      if (response?.res?.data?.status === 200) {
        toast.success("Password Changed Successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        closeModal((prevState) => !prevState);
        // setFormData({
        //   currentPassword: "",
        //   newPassword: "",
        //   confirmPassword: "",
        // });
        logout();
      } else {
        toast.error("Password has not been changed.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      setFormData({ ...formData, isError: true });
    }
  };

  return (
    <div
      className={
        openChangeModal ? "full_modal" : "full_modal generate_new_pin_active"
      }
      id="change_password"
    >
      <div className="generate_new_pin_box my_account_box">
        <h4 style={{font:"normal normal bold 20px/27px Open Sans"}}>Change Password</h4>
        <div className="generate_new_pin_form">
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className=" my_account_imput_wrap align-items-center">
                    <input
                      type={CurrentpasswordInputType}
                      className="form-control pl-4 pt-4"
                      id="pwd"
                      name="currentPassword"
                      value={formData?.currentPassword}
                      style={{
                        border:
                          formData.isError && formData.currentPassword === ""
                            ? "1px solid red"
                            : "",                          
                            height:"55px",
                            font: formData?.currentPassword && CurrentpasswordInputType === "password"
                            ? "bold 22px/22px monospace"
                            : "normal normal normal 14px/22px Open Sans",
                            color:"#092344"
                      }}
                      onChange={handelOnchange}
                      placeholder=""
                    />
                    <label className={`${formData?.currentPassword==="" ? "empty-passwords-label ":"non-empty-label"}`}>Current Password*</label>
                    {formData?.currentPassword &&  <div className="onpwd" style={{top:"1.3rem"}}>{CurrentToggleIcon}</div>}
                  </div>
                  <span className="form-control-message">
                    {formData.isError &&
                      formData.currentPassword === "" &&
                      "Please enter Current Password"}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="my_account_imput_wrap">
                    <input
                      type={NewpasswordInputType}
                      className="form-control pl-4 pt-4"
                      id="pwd"
                      name="newPassword"
                      value={formData?.newPassword}
                      style={{
                        border:
                          formData.isError && (formData.newPassword === "" || formData.newPassword !== formData.confirmPassword)
                            ? "1px solid red"
                            : "",                          
                            height:"55px",
                            font: formData?.newPassword && NewpasswordInputType === "password"
                            ? "bold 22px/22px monospace"
                            : "normal normal normal 14px/22px Open Sans",
                            color:"#092344"
                      }}
                      onChange={handelOnchange}
                      placeholder=""
                    />
                    <label className={`${formData?.newPassword==="" ? "empty-passwords-label ":"non-empty-label"}`}>New Password*</label>
                    {formData?.newPassword &&  <div className="onpwd" style={{top:"1.3rem"}}>{NewPasswordToggleIcon}</div>}
                  </div>
                  <span className="form-control-message">
                    {formData.isError &&
                      formData.newPassword === "" &&
                      "Please enter New Password"}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="my_account_imput_wrap">
                    <input
                      type={ConfirmpasswordInputType}
                      className="form-control pl-4 pt-4"
                      id="pwd"
                      name="confirmPassword"
                      value={formData?.confirmPassword}
                      style={{
                        border:
                          formData.isError && (formData.confirmPassword === "" || formData.newPassword !== formData.confirmPassword)
                            ? "1px solid red"
                            : "",                          
                            height:"55px",
                            font: formData?.confirmPassword && ConfirmpasswordInputType === "password"
                            ? "bold 22px/22px monospace"
                            : "normal normal normal 14px/22px Open Sans",
                            color:"#092344"
                      }}
                      onChange={handelOnchange}
                      placeholder=""
                    />
                    <label className={`${formData?.confirmPassword==="" ? "empty-passwords-label ":"non-empty-label"}`}>Confirm New Password*</label>
                   {formData?.confirmPassword &&  <div className="onpwd" style={{top:"1.3rem"}}>{ConfirmToggleIcon}</div>}
                  </div>
                  <span className="form-control-message">
                    {formData.isError &&
                      formData.confirmPassword === "" &&
                      "Please enter Confirm Password"}
                  </span>
                  <span className="form-control-message">
                    {formData.isError && formData.newPassword !== formData.confirmPassword && "New Password and Confirm Password Doesnot match"}
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="add_new_user_box_top_right justify-content-end">
            <button
              style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#186FD9",border:"1.4px solid #a3c6ff", width:"100px",}}
              className="btn"
              onClick={() => {
                closeModal((prevState) => !prevState);
                setFormData((prev) => ({
                  ...prev,
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: ""
                }));
              }}              
            >
              Cancel
            </button>
            <button
            style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:(formData?.newPassword && formData?.confirmPassword && formData?.currentPassword && (formData?.newPassword===formData?.confirmPassword))?"#FFFFFF":"#728BB5",backgroundColor:(formData?.newPassword && formData?.confirmPassword && formData?.currentPassword && (formData?.newPassword===formData?.confirmPassword))?"#186FD9" :"#DEE5F2", width:"140px", border:"none"}} 
            className="btn ml-2" onClick={(formData?.newPassword && formData?.confirmPassword && formData?.currentPassword && (formData?.newPassword===formData?.confirmPassword)) && submitChangePasswordForm}>
              {" "}
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
