import { GET_CREW, GET_DEPARTMENTS, GET_MINES, GET_EQUIMENTS, GET_SOURCE_DUMPS, GET_AREA, GET_MATERIALS, GEt_ALL_USER_WITH_FILTER, GET_ACTIVITY_DESCRIPTION } from '../../../action/apiPath';
import { CallWithAuth } from '../../../action/apiActions';
import { SelectAssetTypes } from './contants';

// Cache object to store data and timestamps
const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getFromCache = (key) => {
    const cachedData = cache[key];
    if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
        return cachedData.data;
    }
    return null;
};

const setCache = (key, data) => {
    cache[key] = {
        data,
        timestamp: Date.now(),
    };
};

export const fetchCrewTypes = async () => {
    const cacheKey = 'crewTypes';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_CREW);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching crew: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching crew: ${error.message}`);
    }
};

export const fetchDepartments = async () => {
    const cacheKey = 'departments';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_DEPARTMENTS);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching departments: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching departments: ${error.message}`);
    }
};

export const fetchMines = async () => {
    const cacheKey = 'mines';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_MINES);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching mines: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching mines: ${error.message}`);
    }
};

export const fetchEquipments = async (departmentName) => {
    const cacheKey = `equipments_${departmentName}`;
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const payload = { department: departmentName };
        const response = await CallWithAuth("POST", GET_EQUIMENTS, payload);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching equipments: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching equipments: ${error.message}`);
    }
};

export const fetchSourceDumps = async () => {
    const cacheKey = 'sourceDumps';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_SOURCE_DUMPS);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching dumps: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching dumps: ${error.message}`);
    }
};

export const fetchAreas = async () => {
    const cacheKey = 'areas';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_AREA);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching areas: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching areas: ${error.message}`);
    }
};

export const fetchMaterials = async () => {
    const cacheKey = 'materials';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_MATERIALS);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching materials: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching materials: ${error.message}`);
    }
};

export const fetchActivityDescriptions = async () => {
    const cacheKey = 'activityDescription';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_ACTIVITY_DESCRIPTION);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching activity Description: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching activity Description: ${error.message}`);
    }
};

export const fetchUserList = async (userType) => {
    const cacheKey = `userList_${userType}`;
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    let payload = {
        key: "",
        userType: userType,
        // equipmentLicense: selectedEquiment,
        PAGE_NUMBER: -1,
    };

    try {
        const response = await CallWithAuth("POST", GEt_ALL_USER_WITH_FILTER, payload);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data.result);
            return data.result;
        } else {
            throw new Error(`Error fetching user list: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching user list: ${error.message}`);
    }
};

export const fetchEquipmentList = async (type = 'all') => {
    const endpointMap = {
        [SelectAssetTypes.EXCAVATORS]: "api/equipments?equipmentType=Excavator",
        [SelectAssetTypes.TRUCKS]: "api/equipments?equipmentType=Truck",
    };

    const endpoint = endpointMap[type] || "api/equipments";
    if (!endpoint) {
        throw new Error(`Invalid equipment type: ${type}`);
    }

    try {
        const { res } = await CallWithAuth("GET", endpoint);
        const { status, data } = res.data;

        if (status === 200) {
            const filteredData =
                type === SelectAssetTypes.EXCAVATORS
                    ? (data.excavators || [])
                    : type === SelectAssetTypes.TRUCKS ? (data.trucks || [])
                        : data;

            return filteredData;
        } else {
            throw new Error(`Error fetching equipment list: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching equipment list: ${error.message}`);
    }
};

export const fetchCheckIsGettingEdited = async (sid) => {
    try {
        const response = await CallWithAuth("GET", "/api/shift-planning/session/" + sid);
        const { status, data } = response.res.data;
        return data;
    } catch (error) {
        throw new Error(`Error fetching activity Description: ${error.message}`);
    }
};