import React, { useEffect, useState } from 'react'
import Flyer from '../../components/Flyers/Flyer'
import TruckPlan from './Sections/TruckPlan'
import { AssetCategories } from '../../utils/contants';
import ConfirmAlert from '../../components/ConfirmAlert/ConfirmAlert';
import HistoryWithPlans from '../HistoryTab/HistoryWithPlans';
import { useAddDailyPlan } from '../../Context/context';
import { fetchOperatorShiftAssignments, findOperatorInAssignments, getShiftPlanData, getShiftPlans, validate } from './utils/helper';


export default function TruckShiftDetails({
    isOpen,
    assignedTruckDetailsIdx,
    onClose,
    assignedTruckDetailsId,
    singleAssignedExcavatorDetailsFormData,
    onSaveTruckDetails,
    assignedTruckDetailsFormData,
    notEditable,
}) {
    const {
        canEditShift,
        generalShiftPlanDetailsFormData,
        assignedExcavatorDetailsFormData,
        activityDescriptions
    } = useAddDailyPlan();

    const getIdx = (planIndex, shiftPlan) => {
        return shiftPlan ? shiftPlan?.haulTrucks?.findIndex((ht) => ht.equipment == assignedTruckDetailsId) :
            singleAssignedExcavatorDetailsFormData?.shiftPlans?.[planIndex]?.haulTrucks?.findIndex((ht) => ht.equipment == assignedTruckDetailsId)
    }

    const initialPlanData = (planIndex) => {
        const truckIdx = getIdx(planIndex);
        return getShiftPlanData(planIndex, truckIdx, singleAssignedExcavatorDetailsFormData, { activityDescriptions });
    };

    const [formData, setFormData] = useState(notEditable ? assignedTruckDetailsFormData : initialPlanData(0));
    const [showError, setShowError] = useState(false);
    const [viewOnly, setViewOnly] = useState(notEditable || singleAssignedExcavatorDetailsFormData?.shiftPlans[0]?.haulTrucks[getIdx(0)]?.operator);
    const [currPlan, setCurrPlan] = useState(singleAssignedExcavatorDetailsFormData?.shiftPlans?.length - 1);
    const [isExtraFlyerOpen, setIsExtraFlyerOpen] = useState(false);
    const [expandAll, setExpandAll] = useState(false); // set as 0 to keep the 1st plan expanded on first load
    const [operatorChangeConfirmationPopup, setOperatorChangeConfirmationPopup] = useState(false);
    const [overlappingDetails, setOverlappingDetails] = useState({
        operator: {
            locations: [],
            found: false
        },
        assistantOperator: {
            locations: [],
            found: false
        },
        trainee: {
            locations: [],
            found: false
        }
    })
    const [search, setSearch] = useState({
        query: "",
        count: 0,
    });

    const handleOnClear = () => {
        setSearch({
            query: "",
            count: 0,
        });
    };

    useEffect(() => {
        setFormData(notEditable ? assignedTruckDetailsFormData : initialPlanData(0));
        setIsExtraFlyerOpen(false)
    }, [assignedTruckDetailsIdx, assignedTruckDetailsFormData])

    if (!isOpen) return null;

    const handleEditMultiPlanTruck = (plan) => {
        const planIndex = singleAssignedExcavatorDetailsFormData?.shiftPlans?.findIndex((shiftPlan) => shiftPlan.plan == plan)
        setFormData(initialPlanData(planIndex));
        setCurrPlan(planIndex);
        setIsExtraFlyerOpen(true);
    }


    const getFormData = (isSinglePlan) => {
        if (notEditable) {
            return isSinglePlan ? formData?.shiftPlans?.[0] : formData?.shiftPlans;
        }

        if (isExtraFlyerOpen && isSinglePlan) {
            return initialPlanData(0);
        }

        if (!isSinglePlan) {
            return getShiftPlans(singleAssignedExcavatorDetailsFormData, singleAssignedExcavatorDetailsFormData?.excavatorHaulTrucks[assignedTruckDetailsIdx]?.value);
        }

        return formData;
    };

    const handleSave = async () => {
        if (!validate(formData)) {
            setShowError(true);
            return;
        }
        setShowError(false);

        if (await fetchOperatorShiftAssignments(formData, generalShiftPlanDetailsFormData)) {
            const newLocation = { exIdx: assignedExcavatorDetailsFormData?.findIndex((ex => ex.equipment == singleAssignedExcavatorDetailsFormData?.equipment)), truckIdx: getIdx(currPlan) }

            const operatorOverlapDetails = findOperatorInAssignments(assignedExcavatorDetailsFormData, formData?.operator?.value, formData?.startTime, formData?.endTime, newLocation, 'operator');

            const assistantOperatorOverlapDetails = findOperatorInAssignments(assignedExcavatorDetailsFormData, formData?.assistantOperator?.value, formData?.startTime, formData?.endTime, newLocation, 'assistantOperator');

            const traineeOverlapDetails = findOperatorInAssignments(assignedExcavatorDetailsFormData, formData?.trainee?.value, formData?.startTime, formData?.endTime, newLocation, 'trainee');

            if (operatorOverlapDetails.found || assistantOperatorOverlapDetails.found || traineeOverlapDetails.found) {
                setOverlappingDetails({
                    operator: operatorOverlapDetails,
                    assistantOperator: assistantOperatorOverlapDetails,
                    trainee: traineeOverlapDetails,
                })
                setOperatorChangeConfirmationPopup(true)
            } else {
                onSaveTruckDetails(formData, currPlan, isExtraFlyerOpen);
                setIsExtraFlyerOpen(false)
            }

        }
    };

    const generateTabs = () => {
        const isSinglePlan = notEditable ? (assignedTruckDetailsFormData?.shiftPlans.length == 1) : (singleAssignedExcavatorDetailsFormData?.shiftPlans.length
            === 1);

        return [
            {
                title: "Shift Plan",
                leftActionButtons: [
                    !notEditable && viewOnly && isSinglePlan && canEditShift && (formData?.length || formData?.plantId) && {
                        label: "Edit",
                        style: { border: "1px solid #186FD9" },
                        onClick: () => setViewOnly(false),
                    }
                ].filter(Boolean),
                rightActionButtons: (formData?.length || formData?.plantId) ? [
                    !viewOnly && isSinglePlan && canEditShift && !notEditable && {
                        label: "Save",
                        onClick: handleSave
                    },
                    !isSinglePlan && viewOnly && {
                        label: expandAll ? "Collapse All" : "Expand All",
                        style: {
                            backgroundColor: "transparent",
                            color: "#186FD9",
                            border: "1px solid #E1E8F4",
                        },
                        onClick: () => setExpandAll(prev => prev === 0 ? true : !prev)
                    },
                ].filter(Boolean) : [],
                component: (
                    <TruckPlan
                        multi={!isSinglePlan}
                        excavatorAssignedPlan={singleAssignedExcavatorDetailsFormData?.shiftPlans[currPlan]}
                        setFormData={setFormData}
                        canEditShift={canEditShift}
                        formData={getFormData(isSinglePlan)}
                        showError={showError && !isExtraFlyerOpen}
                        viewOnly={viewOnly || isExtraFlyerOpen || !canEditShift}
                        expandAll={expandAll}
                        handleEditMultiPlanTruck={handleEditMultiPlanTruck}
                    />
                ),
            },
            { title: "History", component: <HistoryWithPlans exId={singleAssignedExcavatorDetailsFormData?.equipment} search={search} setSearch={setSearch} shiftId={generalShiftPlanDetailsFormData.newShiftId} equipType={AssetCategories.TRUCKS} equipId={assignedTruckDetailsId ?? assignedTruckDetailsFormData.equipment} /> },
        ];
    };

    const extraFlyerTabs = [
        {
            title: "Shift Plan",
            rightActionButtons: !notEditable && (formData?.length || formData?.plantId) ? [
                {
                    label: "Save",
                    onClick: handleSave
                },
            ] : [],
            component: (
                <TruckPlan
                    excavatorAssignedPlan={singleAssignedExcavatorDetailsFormData?.shiftPlans[currPlan]}
                    setFormData={setFormData}
                    formData={formData}
                    showError={showError}
                    canEditShift={canEditShift}
                />
            ),
        },
    ];

    const operatorAssignmentWarning = () => {

        const getCommaSeparatedLocations = (locations) => locations.map(location => location.type === 'excavator' ? location?.ExPlantId : location?.truckPlantId).join(', ');

        const commaSeparatedLocationsOperator = getCommaSeparatedLocations(overlappingDetails.operator.locations);
        const commaSeparatedLocationsAssistantOperator = getCommaSeparatedLocations(overlappingDetails.assistantOperator.locations);
        const commaSeparatedLocationsTrainee = getCommaSeparatedLocations(overlappingDetails.trainee.locations);

        return (
            <div>
                {overlappingDetails.operator.found && <div>
                    Operator <span style={{ fontWeight: 'bold' }}>{formData?.operator?.label}</span> has already been assigned to <span style={{ fontWeight: 'bold' }}>{commaSeparatedLocationsOperator}</span>
                </div>}

                {overlappingDetails.assistantOperator.found && <div>
                    Assistant Operator <span style={{ fontWeight: 'bold' }}>{formData?.assistantOperator?.label}</span> has already been assigned to <span style={{ fontWeight: 'bold' }}>{commaSeparatedLocationsAssistantOperator}</span>
                </div>}

                {overlappingDetails.trainee.found && <div>
                    Trainee <span style={{ fontWeight: 'bold' }}>{formData?.trainee?.label}</span> has already been assigned to <span style={{ fontWeight: 'bold' }}>{commaSeparatedLocationsTrainee}</span>.
                </div>}

                Are you sure you want to reassign them to <span style={{ fontWeight: 'bold' }}>{formData?.plantId}</span>?

                <div className="my-2">
                    <span style={{ fontWeight: 'bold' }}>Note:</span> Please make sure you assign a new operator/Trainee to <span style={{ fontWeight: 'bold' }}>
                        {Array.from(new Set([
                            ...(commaSeparatedLocationsOperator ? commaSeparatedLocationsOperator?.split(', ') : []),
                            ...(commaSeparatedLocationsAssistantOperator ? commaSeparatedLocationsAssistantOperator?.split(', ') : []),
                            ...(commaSeparatedLocationsOperator ? commaSeparatedLocationsOperator?.split(', ') : [])
                        ])).join(', ')}
                    </span> before you publish the plan.
                </div>
            </div>
        );
    }

    return (
        <>
            {operatorChangeConfirmationPopup && canEditShift && (
                <ConfirmAlert
                    title={<div style={{ fontSize: 18, fontWeight: "bold", color: "#c13e3d" }}>Attention!</div>}
                    message={operatorAssignmentWarning()}
                    onClose={() => setOperatorChangeConfirmationPopup(false)}
                    onConfirm={() => {
                        onSaveTruckDetails(formData, currPlan, singleAssignedExcavatorDetailsFormData?.shiftPlans.length > 1, overlappingDetails);
                        setOperatorChangeConfirmationPopup(false)
                        setIsExtraFlyerOpen(false)
                    }}
                />
            )}
            <Flyer
                isOpen={isOpen}
                onClose={onClose}
                style={{ backgroundColor: isExtraFlyerOpen ? "#F8FAFD" : "white" }}
                title={`${notEditable ? assignedTruckDetailsFormData?.plantId : singleAssignedExcavatorDetailsFormData?.excavatorHaulTrucks[assignedTruckDetailsIdx]?.label} - Shift Plan`}
                tabs={generateTabs()}
                search={true}
                searchString={search.query}
                searchCount={search.count}
                onSearchInput={(val) => {
                    setSearch({ ...search, query: val });
                }}
                handleOnClear={handleOnClear}
            />
            <Flyer
                style={{ position: "relative", right: 620 }}
                isOpen={isExtraFlyerOpen && canEditShift}
                removeBorderRadius={true}
                onClose={() => {
                    if (singleAssignedExcavatorDetailsFormData?.shiftPlans.length === 1) {
                        setFormData(initialPlanData(0))
                    }
                    setIsExtraFlyerOpen(false)
                }}
                title={`${singleAssignedExcavatorDetailsFormData?.excavatorHaulTrucks[assignedTruckDetailsIdx]?.label} - Add Plan ${formData?.plan}`}
                tabs={extraFlyerTabs}
            />
        </>
    );
}
