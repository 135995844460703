import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingScreen from '../../../components/LoadingScreen';
import UserRow from './UserRow';
import "./style.css";
import ToggleArrow from '../../../components/ToggleDropDownArrow/ToggleArrow';
import { useSearchParams } from 'react-router-dom';

const UserTable = ({
  users,
  userType,
  pagination,
  setUsers,
  setModals,
  getUserDetails,
  setPagination,
  totalUsers
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedSort, setSelectedSort] = useState({
    sortName: -1,
    sortAddedBy: -1,
    sortStatus: null,
  });

  const toggleArrow = (index) => {
    setActiveDropdown((prev) => (prev === index ? null : index));
  };

  const updateSearchParams = (e, param, value) => {
    e.stopPropagation(); 
    
    const newSearchParams = new URLSearchParams(searchParams); // Start with existing search params
    
    // Update the specific parameter
    if (value !== null) {
      newSearchParams.set(param, value);
      setSelectedSort((prev) => ({ ...prev, [param]: value }));
    } else {
      newSearchParams.delete(param);
      setSelectedSort((prev) => ({ ...prev, [param]: null }));
    }
    
    setSearchParams(newSearchParams);
    setActiveDropdown(null);
  };
  

  const fetchMoreData = () => {
    if (users?.userData?.length >= pagination?.userCount) {
      setUsers((prev) => ({ ...prev, hasMore: false }));
      return;
    }
    setPagination((prev) => ({ ...prev, page: prev.page + 1, loading: true }));
  };

  const handleUserSelection = (e, data, type = "") => {
    const { checked } = e.target;
    let list = [...users?.checkedUserList];

    if (type) {
      // Handle bulk selection
      list = checked ? users?.userData?.map(user => user._id) || [] : [];
      document.querySelectorAll('input').forEach(input => input.checked = checked);
    } else {
      // Handle individual selection
      if (checked) {
        if (!list.includes(data)) list.push(data);
      } else {
        list = list.filter(item => item !== data);
      }
    }
    setUsers((prev) => ({ ...prev, checkedUserList: list }));
  };

  const tableHeaders = [
    { fieldName: "", filterDropdown: false },
    { 
      fieldName: "User Name", 
      filterDropdown: true,
      options: [
        { 
          name: 'Sort by ascending alphabetical order', 
          icon: (
            <span style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
              <span style={{ fontSize: '0.8em' }}>A</span>
              <span style={{ fontSize: '0.8em' }}>Z</span>
            </span>
          ),
          arrowIcon: '↑',
          onClick: (e) => updateSearchParams(e,'sortName', '1')
        },
        { 
          name: 'Sort by descending alphabetical order', 
          icon: (
            <span style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
              <span style={{ fontSize: '0.8em' }}>Z</span>
              <span style={{ fontSize: '0.8em' }}>A</span>
            </span>
          ),
          arrowIcon: '↓',
          onClick: (e) => updateSearchParams(e,'sortName', '-1'),
        },
      ]
    },
    { 
      fieldName: "Status", 
      filterDropdown: true,
      options: [
        { 
          name: 'All status', 
          icon: <span className="status-icon all"></span>, 
          onClick: (e) => updateSearchParams(e,'sortStatus', null)
        },
        { 
          name: 'Sort by active', 
          icon: <span className="status-icon active"></span>, 
          onClick: (e) => updateSearchParams(e,'sortStatus', 'A')
        },
        { 
          name: 'Sort by inactive', 
          icon: <span className="status-icon inactive"></span>, 
          onClick: (e) => updateSearchParams(e,'sortStatus', 'I')
        },
        { 
          name: 'Sort by invited', 
          icon: <span className="status-icon invited"></span>, 
          onClick: (e) => updateSearchParams(e,'sortStatus', 'P')
        },
      ]
    },
    { fieldName: "User Type", filterDropdown: false, options: [] },
    { fieldName: "Email", filterDropdown: false },
    { fieldName: "Department", filterDropdown: false, options: [] },
    { fieldName: "Crew", filterDropdown: false, options: [] },
    { fieldName: "Equipment License", filterDropdown: false },
    { fieldName: "PIN No", filterDropdown: false },
    { 
      fieldName: "Added By", 
      filterDropdown: true,
      options: [
        { 
          name: 'Sort by ascending chronological order',
          arrowIcon: '↑',
          onClick: (e) => updateSearchParams(e,'sortAddedBy', '1')
        },
        { 
          name: 'Sort by descending chronological order', 
          arrowIcon: '↓', 
          onClick: (e) => updateSearchParams(e,'sortAddedBy', '-1')
        },
      ]
    },
    { fieldName: "Actions", filterDropdown: false }
  ];

  return (
    <>
        <div
    className=""
    id=""
    style={{
      borderLeft: "none",
      borderRadius: "0px",
      borderRight: "none",
      overflow: 'hidden',
    }}
  >
    <InfiniteScroll
      className=""
      dataLength={users?.userData?.length}
      next={fetchMoreData}
      hasMore={true}
      loader={
        pagination.loading && (
          <div className="loadingScreen">
            <LoadingScreen />
          </div>
        )
      }
      height={'100%'}
    >
      <table cellPadding="0" cellSpacing="0">
      <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} align="left" valign="top">
                  <div style={{ display: 'flex', alignItems: 'center', }}>
                    {index === 0 ? (
                      <input 
                        style={{
                          border: "1px solid black",
                          margin: 0,
                          verticalAlign: 'middle'
                        }} 
                        type="checkbox" 
                        className='user-checkbox' 
                        name="required" 
                        onChange={(e) => handleUserSelection(e, "", "all")} 
                      />
                    ) : (
                      <div className='d-flex' style={{ cursor: header?.filterDropdown ? "pointer" : "default",position:"relative" }} onClick={() =>{toggleArrow(index)}}>
                        <span>{header.fieldName}</span>
                        {header?.filterDropdown && (
                          <ToggleArrow 
                            headerType={header?.fieldName}
                            options={header?.options}
                            showDropdown={activeDropdown === index}
                            selectedSort={selectedSort} 
                          />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='user-table-body'>
            {users?.userData?.length ? (
              users.userData.map((user, index) => (
                <UserRow
                  checkedList={users?.checkedUserList}
                  key={index}
                  user={user}
                  index={index}
                  users={users}
                  getUserDetails={getUserDetails}
                  userType={userType}
                  handleUserSelection={handleUserSelection}
                  setUsers={setUsers}
                  setModals={setModals}
                />
              ))
            ) : (
              <tr><td colSpan="10">No Data found</td></tr>
            )}
          </tbody>
      </table>
    </InfiniteScroll>
  </div>
    <div className="shiftlisting-footer">
          Displaying {users?.userData.length} / {totalUsers} Users 
        </div>
        </>
  )
};

export default UserTable;
