import { RiRadioButtonLine } from 'react-icons/ri';
import { formatDate, formatDate24, getTimeDiff } from './helper';

const DOT_HEIGHT = 20;
const MIN_LINE_HEIGHT = 52;



export const Dot = ({ active = false, type, status, Dotstyles }) => {
  const getColor = () => {
    if (active && type == 'basic')
      return '#1b499f';
    else if (type == "breakdown")
      return '#BF1A1A'
    else if (type == "delay")
      return '#C65400'
    else
      return '#939292';
  }
  const getBgColor = () => {
    if (active && type == 'basic')
      return '#72a2fd';
    else if (type == "breakdown")
      return '#FC6262'
    else if (type == "delay")
      return '#FAA138'
    else
      return '#e2e2e2';
  }
  return (
    <RiRadioButtonLine
      color={getColor()}
      min={status ? DOT_HEIGHT + 8 : DOT_HEIGHT}
      size={status ? DOT_HEIGHT + 8 : DOT_HEIGHT}
      style={{
        backgroundColor: getBgColor(),
        borderRadius: '50%',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        // padding: '0',
        margin: '0 auto',
        ...(Dotstyles ? Dotstyles : {})
      }}
    />
  );
};

export const DotDescription = ({ label, detail }) => (
  <hgroup style={{ fontSize: '12px', height: DOT_HEIGHT }}>
    <h4 style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: 0 }}>
      {label}
    </h4>
    <p
      style={{
        color: 'gray',
        fontSize: '11px',
        fontWeight: 600,
        height: 'max-content',
        marginBottom: 0,
      }}
    >
      <em>{detail}</em>
    </p>
  </hgroup>
);

const Line = ({ active = false, height }) => {
  const color = active ? '#1b499f' : '#939292';
  const width = active ? '5px' : '3px';
  return (
    <div
      style={{
        backgroundColor: color,
        height: height,
        minHeight: height,
        margin: '0 auto',
        width: width,
      }}
    />
  );
};

// const LineDescription = ({ timestampA, timestampB }) => (
//   <p
//     style={{
//       color: 'gray',
//       fontSize: '11px',
//       fontWeight: 600,
//       marginBottom: 'auto',
//       marginTop: 'auto',
//       width: 'max-content',
//     }}
//   >
//     <em>{getTimeDiff(timestampA, timestampB)}</em>
//   </p>
// );

export const ShiftHistoryTimeline = ({ operatorLoad, }) => {
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        // height: 'calc(100% - 40px)',
        paddingBottom: 20,
        minHeight: 'max-content',
        marginLeft: 75,
        marginTop: 40,
        width: 350,
      }}
    >
      {/* <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: DOT_HEIGHT * 4 + LINE_HEIGHT * 3,
          gap: DOT_HEIGHT,
          paddingTop: DOT_HEIGHT,
          paddingBottom: DOT_HEIGHT,
        }}
      >
        <LineDescription
          timestampA={operatorLoad.loginTime}
          timestampB={operatorLoad.firstLoad}
        />
        <LineDescription
          timestampA={operatorLoad.firstLoad}
          timestampB={operatorLoad.lastLoad}
        />
        <LineDescription
          timestampA={operatorLoad.lastLoad}
          timestampB={operatorLoad.logoutTime}
        />
      </section> */}

      < section style={{ marginLeft: 10, width: 30, marginRight: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {
          operatorLoad.events.map((event, i) => {
            if (i == 0)
              return <Dot active={event.timestamp} type={event.type} status={event.status} />
            return <>
              <Line active={event.timestamp && (event.label != "First Load" || operatorLoad.events.find((e) => e.label == "Login (Shift Start)")?.timestamp) && (event.label != "Logout (Shift End)" || operatorLoad.events.find((e) => e.label == "Last Load")?.timestamp)} height={Math.max(MIN_LINE_HEIGHT, Math.floor(360 / operatorLoad?.events?.length)) + 'px'} />
              <Dot active={event.timestamp} type={event.type} status={event.status} />
            </>
          })
        }

      </section >

      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: Math.max(MIN_LINE_HEIGHT, Math.floor(360 / operatorLoad?.events?.length)) + 'px',
          width: 'fit-content',
        }}
      >
        {operatorLoad.events.map((event) =>
          <DotDescription label={event.label} detail={event.detail} />
        )}
      </section>
    </section >
  );
};
