import React from 'react';
import ReactSwitch from "react-switch";
import "./style.css"

export const ToggleSwitch = ({
    containerClassName,
    label,
    labelClassName,
    required,
    checked,
    onChange,
    offSymbol,
    onSymbol,
    viewOnly,
    labelStyle
}) => {
    const DefaultOffSymbol = () => (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 8,
                fontWeight: "bold",
            }}
        >
            Inactive
        </div>
    );

    const DefaultOnSymbol = () => (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingLeft: 8,
                fontWeight: "bold",
            }}
        >
            Active
        </div>
    );

    const OffSymbol = offSymbol || DefaultOffSymbol;
    const OnSymbol = onSymbol || DefaultOnSymbol;

    return (
        <div className={`row ${containerClassName} align-items-center`}>
            {label && (
                <div className={`col-md-4 ${labelClassName}`}>
                    <label  style={{ margin: 0, color: "#4b515a)",...labelStyle }}>
                        {label} {required && <span style={{ color: "#6A7C9A" }}>*</span>}
                    </label>
                </div>
            )}
            {viewOnly ?
                <div className={`col-md-${label ? "8" : "12"}`}
                    style={{ font: "normal normal bold 1.3rem/19px Open Sans" }}>
                        <span className={`status ${checked ? "active" : "inactive"} ml-1`}></span>
                        {checked ? "Active" :  "Inactive" }
                </div>
                :
                <div className={`col-md-${label ? "8" : "12"}`}>
                    <ReactSwitch
                        uncheckedIcon={<OffSymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#4CAF50"
                        offColor="#f44336"
                        height={30}
                        width={90}
                        handleDiameter={26}
                        onChange={onChange}
                        checked={checked}
                    />
                </div>
            }
        </div>
    );
};
