import React, { useContext, useEffect, useState } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { DumpPointsContext } from '../DumpPointsContext';

function FilterModal({ onApply, onCancel, selectFilter }) {
  const { optionsForDumpPoints, optionsForMaterials, optionsForOperators, shiftChoice } =
    useContext(DumpPointsContext);

  const [filterVariable, setFilterVariable] = useState(selectFilter);
  const [filterCount, setFilterCount] = useState(0);
  useEffect(() => {
    setFilterCount(
      Object.values(filterVariable).reduce(
        (accumulator, currentValue) =>
          currentValue.length !== 0 ? accumulator + 1 : accumulator,
        0
      )
    );
  }, [filterVariable]);

  const onSelect = (option, selected) => {
    setFilterVariable((prev) => {
      return { ...prev, [option]: selected };
    });
  };

  return (
    <div
      className="dashboard_filter"
      style={{
        borderRadius: '5px',
        height: '90%',
        width: '500px',
        right: '20px',
        backgroundColor: 'white',
        position: 'absolute',
        bottom: '12px',
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 10px 14px #0000001a',
      }}
    >
      <div
        className="dashboard_filter_heading"
        style={{
          display: 'flex',
          border: '0px solid black',
          justifyContent: 'space-between',
          margin: '0px 30px',
          marginTop: '30px',
        }}
      >
        <span
          style={{
            font: 'normal normal bold 15px/20px Open Sans',
            color: ' #1A1A1A',
          }}
        >
          Filter By
        </span>
        <div className="d-flex" style={{ width: 'max-content' }}>
          <button
            style={{
              width: 80,
              height: 25,
              font: 'normal normal bold 12px/17px Open Sans',
              color: '#186FD9',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              border: ' 1px solid #186FD9',
              borderRadius: 4,
            }}
            className="btn discard"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="btn save ml-3"
            onClick={() => {
              onApply(filterVariable);
            }}
            style={{
              width: 80,
              height: 25,
              font: 'normal normal bold 12px/17px Open Sans',
              border: ' 1px solid #186FD9',
              background: '#186FD9 0% 0% no-repeat padding-box',
              borderRadius: 4,
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            Apply
          </button>
        </div>
      </div>
      <div
        style={{
          border: '0px solid black',
          marginLeft: '20px',
          marginRight: '20px',
          marginTop: '60px',
        }}
      >
        <div className="col-md-12 form-group">
          <div className="row d-flex align-items-center">
            <div className="col-md-3">
              <label
                style={{ font: 'normal normal normal 14px/19px Open Sans' }}
              >
                Dump Point{' '}
              </label>
            </div>
            <div className="col-md-9 custom_select">
              <ReactMultiSelectCheckboxes
                maxHeight={50}
                options={optionsForDumpPoints}
                onChange={(selected) => onSelect('dumpPoints', selected)}
                value={filterVariable.dumpPoints}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    // maxHeight: '256px',
                    fontSize: '1.1rem'
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: '256px',
                    overflowY: 'auto',
                    fontSize: '1.1rem'
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 form-group">
          <div className="row d-flex align-items-center">
            <div className="col-md-3">
              <label
                style={{ font: 'normal normal normal 14px/19px Open Sans' }}
              >
                Material
              </label>
            </div>
            <div className="col-md-9 custom_select">
              <ReactMultiSelectCheckboxes
                onChange={(selected) => onSelect('materials', selected)}
                options={optionsForMaterials}
                value={filterVariable.materials}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    // maxHeight: '256px',
                    fontSize: '1.1rem'
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: '256px',
                    overflowY: 'auto',
                    fontSize: '1.1rem'
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 form-group">
          <div className="row d-flex align-items-center">
            <div className="col-md-3">
              <label
                style={{ font: 'normal normal normal 14px/19px Open Sans' }}
              >
                Operator
              </label>
            </div>
            <div className="col-md-9 custom_select" style={{ height: 'px' }}>
              <ReactMultiSelectCheckboxes
                maxHeight={50}
                onChange={(selected) => onSelect('operators', selected)}
                options={optionsForOperators[parseInt(shiftChoice)]}
                value={filterVariable.operators}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    // maxHeight: '256px',
                    fontSize: '1.1rem'
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: '256px',
                    overflowY: 'auto',
                    fontSize: '1.1rem'
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          paddingLeft: '14px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '7%',
          background: '#FAFBFD 0% 0% no-repeat padding-box',
          border: '1px solid #E1E8F4',
        }}
      >
        <span
          onClick={() => {
            if (filterCount) {
              let tempFilterVariable = {};
              Object.keys(filterVariable).forEach((variable) => {
                tempFilterVariable[variable] = [];
              });
              setFilterVariable(tempFilterVariable);
            }
          }}
          style={{
            color: !filterCount ? '#ADD8E6' : 'black',
            cursor: filterCount ? 'pointer' : 'not-allowed',
            textDecoration: 'underline',
            font: 'normal normal 600 13px/17px Open Sans',
          }}
        >
          Clear all ({filterCount})
        </span>
      </div>
    </div>
  );
}
export default FilterModal;
