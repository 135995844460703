export const weatherDataInitialState = {
    todayData: {
        temperature: '',
        max_temp: '',
        min_temp: '',
        rain_so_far: '',
        feels_like: '',
    },
    tomorrowData: {
        cloud_cover: '',
        humidity: '',
        max_temp: '',
        min_temp: '',
        rain_so_far: '',
        uv: '',
        wind_direction: '',
        wind_gusts: '',
        wind_speed: '',
        pressure: '',
    },
    webhookData: {
        temp: '',
        wind: '',
        windDirection: '',
        pressure: '',
        rain: '',
        dust: '',
    },
    forecast: {
        temp: [],
        cloudCover: [],
    },
};