import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { CallWithAuth } from '../../action/apiActions';
import { GET_USER_DETAILS, REACT_APP_API_ENDPOINT } from '../../action/apiPath';
import GuestLayout from '../../components/GuestLayout';
import usePasswordToggle from '../../hooks/usePasswordToggle';

const Register = (props) => {
  const useParamValue = useParams();
  const [alreadyExist, setAlreadyExist] = useState('');
  const [passwordInputType, PasswordToggleIcon] = usePasswordToggle();
  const [ConfirmpasswordInputType, ConfirmPasswordToggleIcon] =
    usePasswordToggle();

  const [userData, setUserData] = useState('');
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    isAdmin: false,
    getProfileId: '',
    firstName: '',
    lastName: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
    isError: false,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sid = queryParams.get('id');
    // console.log(sid);
    if (sid) {
      sid && getUserList(sid);
    } else {
    }
  }, []);

  const getUserList = (sid) => {
    setLoading(true);
    // setFormData({
    //   ...formData,
    //   [name]:value,
    //   isError:false
    // })
    CallWithAuth('GET', GET_USER_DETAILS(sid)).then((response) => {
      // console.log(response);
      if (response.res.data.status === 200) {
        if (response.res.data.data && response.res.data.data.length > 0)
          setUserData(response.res.data.data[0]);
        setFormData({
          ...formData,
          getProfileId: sid,
          isAdmin: response.res.data.data[0].isAdmin,
          firstName: response.res.data.data[0].firstName,
          lastName: response.res.data.data[0].lastName,
          email: response.res.data.data[0].email,
          isError: false,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handelOnchange = (e) => {
    setAlreadyExist('');
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      isError: false,
    });
  };

  const handelValidateForm = () => {
    if (
      formData.firstName !== '' &&
      formData.lastName !== '' &&
      formData.email !== '' &&
      formData.newPassword !== '' &&
      formData.confirmPassword !== '' &&
      formData.newPassword === formData.confirmPassword
    )
      return Promise.resolve(true);
    return Promise.resolve(false);
  };

  const submitRegisterHandler = async (e) => {
    e.preventDefault();

    let response = await handelValidateForm();
    console.log(
      'first Name --->' +
        formData.firstName +
        'last Name --->' +
        formData.lastName +
        'email --->' +
        formData.email +
        'New Password --->' +
        formData.newPassword +
        'confirm password --->' +
        formData.confirmPassword
    );

    if (response) {
      axios
        .post(`${REACT_APP_API_ENDPOINT}/signup`, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.newPassword,
          isAdmin: true,
        })
        .then((response) => {
          // console.log(response);
          if (response.data.message === 'EmailId Already Exists') {
            setAlreadyExist('Given Email Id Already Exists');
          } else {
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      setFormData({ ...formData, isError: true });
      // alert('Not registered')
    }
  };
  return (
    <GuestLayout>
      <section className="before_login_screen">
        <Helmet>
          <title>Register | iMOS</title>
        </Helmet>
        <div className="before_main_login_box">
          <div className="before_logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="login_box">
            <hgroup className="before_login_heading">
              <h1>New User Registration</h1>
            </hgroup>
            <form onSubmit={submitRegisterHandler}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div
                      className="input_field_wrap has-float-label"
                      style={{
                        border:
                          formData.isError && formData.firstName === ''
                            ? '1px solid red'
                            : '',
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="fname"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handelOnchange}
                        placeholder="First Name"
                      />
                      <label>First Name*</label>
                    </div>
                    <span className="form-control-message">
                      {formData.isError &&
                        formData.firstName === '' &&
                        'Please enter First Name'}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div
                      className="input_field_wrap has-float-label"
                      style={{
                        border:
                          formData.isError && formData.lastName === ''
                            ? '1px solid red'
                            : '',
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="lname"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handelOnchange}
                        placeholder="Last Name"
                      />
                      <label>Last Name*</label>
                    </div>
                    <span className="form-control-message">
                      {formData.isError &&
                        formData.lastName === '' &&
                        'Please enter Last Name'}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div
                      className="input_field_wrap has-float-label"
                      style={{
                        border:
                          formData.isError && formData.email === ''
                            ? '1px solid red'
                            : '',
                      }}
                    >
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handelOnchange}
                        disabled={
                          formData.getProfileId && formData.isAdmin
                            ? true
                            : false
                        }
                        placeholder="Email"
                      />
                      <label>Email*</label>
                    </div>
                    <span className="form-control-message">
                      {formData.isError &&
                        formData.email === '' &&
                        'Please enter Email'}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div
                      className="input_field_wrap has-float-label"
                      style={{
                        border:
                          formData.isError && formData.newPassword === ''
                            ? '1px solid red'
                            : '',
                      }}
                    >
                      <input
                        type={passwordInputType}
                        className="form-control"
                        id="pwd"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handelOnchange}
                        placeholder="New Password"
                      />
                      <label>New Password*</label>
                      <div className="onpwd">{PasswordToggleIcon}</div>
                      <span className="form-control-message">
                        {formData.isError &&
                          formData.newPassword === '' &&
                          'Please enter Password'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div
                      className="input_field_wrap has-float-label"
                      style={{
                        border:
                          formData.isError && formData.confirmPassword === ''
                            ? '1px solid red'
                            : '',
                      }}
                    >
                      <input
                        type={ConfirmpasswordInputType}
                        className="form-control"
                        id="pwd"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handelOnchange}
                        placeholder="Confirm New Password"
                      />
                      <label>Confirm New Password*</label>
                      <div className="onpwd">{ConfirmPasswordToggleIcon}</div>
                      <span className="form-control-message">
                        {formData.isError &&
                          formData.confirmPassword === '' &&
                          'Please enter Confirm Password'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      className="form-control login_button"
                      value="Register"
                      id="login-btn"
                    />
                  </div>
                </div>
                <span className="form-control-message">
                  {alreadyExist && 'Given Email Is Already Exist'}
                </span>
              </div>
            </form>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default Register;
