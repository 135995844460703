import React from "react";


const ViewPinModal = ({closeModal,pin,openChangePinModal}) => {
    return (
        <div
            className="full_modal generate_new_pin_active"
            id="change_password"
        >
            <div className="generate_new_pin_box my_account_box" style={{
                width: '70rem'
            }}>
                <h4 style={{font:" normal normal bold 20px/27px Open Sans"}}>Your PIN Number</h4>
                <div className="generate_new_pin_form">
                    <form>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <div className="my_account_imput_wrap" style={{backgroundColor:"#F5F5F5",borderRadius:"10px",  }}>
                                    <input
                                        type="number"
                                        className="form-control pl-4 pt-4"
                                        id="pin"
                                        name="pin"
                                        disabled
                                        value={pin}
                                        style={{
                                            backgroundColor:"#F5F5F5",
                                            borderRadius:"10px",                          
                                            height:"55px",
                                            font: "normal normal normal 14px/22px Open Sans",
                                            color:"#092344"
                                        }}
                                    />
                                    <label className={`${!pin ? "empty-label":"non-empty-label ml-3 pl-1"}`}>Current PIN</label>
                                </div>
                            </div>
                            <div className="d-flex col-sm-12 justify-content-end">
                                <button
                                    style={{ height: "44px", font: "normal normal bold 14px/19px Open Sans", color: "#186FD9", border: "1.4px solid #a3c6ff", width: "100px" }}
                                    className="btn"
                                    onClick={(e)=>{ e.stopPropagation(); closeModal((prevstate)=>!prevstate);}}
                                >
                                    Cancel
                                </button>
                                <button
                                 style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#FFFFFF",backgroundColor:"#186FD9",border:"1.4px solid #a3c6ff", width:"140px"}} 
                                 className="btn ml-2" onClick={(e)=>{
                                    e.stopPropagation(); 
                                    openChangePinModal((prevState)=>!prevState);
                                    closeModal((prevstate)=>!prevstate)
                                 }}>
                                    {" "}
                                    Edit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default ViewPinModal;
