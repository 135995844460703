import { useContext, useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';

import { ShiftHistoryContext } from '../../context/history';
import { generateShiftHistoryPDF } from './download';
import { formatDate, formatDate24 } from './helper';
import ShiftHistoryModal from './shift-history-modal';
import { CSVLink } from 'react-csv';
import DynamicSelectField from '../ShiftPlanning/components/DynamicSelectField/DynamicSelectField';

const getSingleCSVData = (unit) =>
  unit.operatorLoads.map((operatorLoad) => ({
    name: unit.name,
    firstLoad: formatDate(operatorLoad.firstLoad),
    lastLoad: formatDate(operatorLoad.lastLoad, '00:00'),
    operatorName: operatorLoad.operatorName,
    loginTime: formatDate(operatorLoad.loginTime),
    logoutTime: formatDate(operatorLoad.logoutTime, '00:00'),
  }));

const getCSVData = (shift) =>
  shift?.flatMap((unit) =>
    unit.operatorLoads.map((operatorLoad) => ({
      name: unit.name,
      firstLoad: formatDate(operatorLoad.firstLoad),
      lastLoad: formatDate(operatorLoad.lastLoad, '00:00'),
      operatorName: operatorLoad.operatorName,
      loginTime: formatDate(operatorLoad.loginTime),
      logoutTime: formatDate(operatorLoad.logoutTime, '00:00'),
    }))
  );

const searchFilter = (shift, searchQuery) => {
  if (!searchQuery) return shift;

  return shift.filter((shift) => {
    const { name, operatorLoads } = shift;
    const operatorNames = operatorLoads.map(
      (operatorLoad) => operatorLoad.name
    );

    return (
      name.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      operatorNames.some((operatorName) =>
        operatorName.toLowerCase()?.includes(searchQuery.toLowerCase())
      )
    );
  });
};

const getLoadTime = (date, shiftChoice) => {
  // '2023-10-21T18:10:00.000Z' 
  // const d= new Date(date).toISOString();
  // const formatTime=d?.substring(0,11)+"05:10"
  // return shiftChoice===1? 

  const d = new Date(date);
  // if (shiftChoice == 1) {
  // ((d.getUTCHours() >= 17 && d.getUTCMinutes() >= 45) && (d.getUTCMinutes() <= 18 && d.getUTCMinutes() < 10) )  &&
  const fromDate = new Date(date)
  fromDate.setUTCHours(17)
  fromDate.setUTCMinutes(45)

  const tillDate = new Date(date)
  tillDate.setUTCHours(18)
  tillDate.setUTCMinutes(10)

  if (d.getTime() < tillDate.getTime() && d.getTime() > fromDate.getTime()) {
    d.setUTCHours(18)
    d.setUTCMinutes(10);
  }
  // }
  return d.toISOString();
}

const getLoadDate = (date, shiftChoice) => {
  if (!date) {
    return "00:00 PM"
  }
  if (isNaN(Date.parse(date))) {
    return "00:00 PM"
  }
  if (shiftChoice == 1) {
    if (formatDate24(date) < "17:10" && formatDate24(date) > "13:00") {
      return "05:10 PM";
    }
  }
  return formatDate(date, "00:00 PM")
}

export default function ShiftHistory() {
  const { shift, shiftChoice, setShiftChoice, setSelectedDate, selectedShift,
    setSelectedShift,
    shiftsOptions,
    selectedShiftRef, } =
    useContext(ShiftHistoryContext);

  const [searchQuery, setSearchQuery] = useState();
  const [filteredShift, setFilteredShift] = useState(shift);


  useEffect(() => {
    if (!shift) return;

    setFilteredShift(searchFilter(shift, searchQuery));
  }, [shift, searchQuery]);

  useEffect(() => {
    // console.log(shift, selectedUnit);
    if (selectedUnit)
      setSelectedUnit(shift?.find((s) => s?.id == selectedUnit?.id))
  }, [shift])


  const [temporaryShift, setTemporaryShift] = useState([]);
  useEffect(() => {
    if (temporaryShift?.length === 0) return;

    generateShiftHistoryPDF('Shift-History', 'shift-history-table');
    setFilteredShift(temporaryShift);
    setTemporaryShift([]);
  }, [temporaryShift]);

  const [selectedUnit, setSelectedUnit] = useState();

  const downloadSingleUnit = (unit) => {
    setTemporaryShift(filteredShift);
    setFilteredShift(
      unit?.operatorLoads?.map((operatorLoad) => ({
        ...unit,
        operatorLoads: [operatorLoad],
      }))
    );
  };

  return (
    <>
      <section style={{ padding: '0 2rem 0 2rem' }}>
        <ul className="my-2 shift-history-toolbar">
          <li>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #dde5f3',
                padding: '4px 12px',
                borderRadius: '15px',
                width: '160px',
                backgroundColor: 'white',
              }}
            >
              <input
                type="search"
                className="shadow-none"
                value={searchQuery}
                placeholder="Search"
                style={{
                  border: 'none',
                  font: 'normal normal normal 1rem Open Sans',
                  width: '125px',
                }}
                onChange={(e) => setSearchQuery(e?.target?.value)}
              />
              <i
                style={{ fontSize: '13px' }}
                className="fa-regular fa-magnifying-glass"
              ></i>
            </div>
          </li>
          <li>
            <div style={{ width: '180px' }}><input type="date" className='form-control' onChange={(e) => setSelectedDate(e?.target?.value)} /></div>
          </li>
          <li>
            <div className="d-flex" style={{ height: '100%' }}>

              <div
                className="m-3 d-flex"
                style={{ backgroundColor: 'rgb(240,244,251)', borderRadius: '45%' }}
              >

                <div
                  style={{
                    backgroundColor:
                      shiftChoice == "0" ? 'rgb(203,220,248)' : 'rgb(240,244,251)',
                    color: shiftChoice == "0" ? 'black' : '#96a4bb',

                    borderWidth: '0px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: '600',
                    padding: '0px 1.3rem',
                    borderRadius: '15px'
                  }}
                  key={0}
                  name="radio"
                  value={0}
                  onClick={(e) => setShiftChoice('0')}
                >
                  Day
                </div>
                <div
                  style={{
                    backgroundColor:
                      shiftChoice == "1" ? 'rgb(20,36,62)' : 'rgb(241,244,251)',

                    color: shiftChoice == "1" ? 'white' : '#96a4bb',

                    borderWidth: '0px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: '600',
                    padding: '0px 1rem',
                    borderRadius: '15px',
                  }}
                  key={1}
                  name="radio"
                  value={1}
                  onClick={(e) => setShiftChoice("1")}
                >
                  Night
                </div>

              </div>
            </div>
          </li>
          <li>
            <DynamicSelectField
              placeholder={"Select ReportNo."}
              wrapperStyle={{ marginBottom: 0, marginRight: '5px' }}
              value={selectedShift[shiftChoice == "0" ? "day" : "night"]}
              options={shiftsOptions[shiftChoice == "0" ? "day" : "night"]}
              onChange={(v) => {
                setSelectedShift((prev) => ({
                  ...prev,
                  [shiftChoice == "0" ? "day" : "night"]: v
                }))
                selectedShiftRef.current[shiftChoice == "0" ? "day" : "night"] = v
              }}
            />
          </li>
          <li style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
            <CSVLink
              data={getCSVData(shift)}
              filename="Shift-History"
              target="_blank"
            >
              <FiDownload
                onClick={() => {
                  let newShiftList = [];
                  shift?.forEach(({ operatorLoads, ...shift
                  }) => {
                    newShiftList?.push(
                      ...operatorLoads?.map((operatorLoad) => ({
                        ...shift,
                        operatorLoads: [operatorLoad],
                      }))
                    );
                  });
                  setTemporaryShift(filteredShift);
                  setFilteredShift(newShiftList);
                }}
                style={{
                  color: 'black',
                  cursor: 'pointer',
                  backgroundColor: '#E2ECFC',
                  borderRadius: '10%',
                  padding: '.1rem',
                  width: '2.1rem',
                  height: '2.1rem',
                  border: '1.5px solid rgb(225,232,244)',
                }}
              />
            </CSVLink>
          </li>
        </ul>

        <hr />
      </section>

      <main
        style={{
          minHeight: 'calc(100% - 180px)',
          position: 'relative',
        }}
      >
        <table className="shift-history-table">
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Plant ID</th>
              <th style={{ width: '8%' }}>First Load</th>
              <th style={{ width: '8%' }}>Last Load</th>
              <th style={{ width: '11%' }}>Current Operator</th>
              <th style={{ width: '9%' }}>Login (Shift Start)</th>
              <th style={{ width: '9%' }}>Logout (Shift End)</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {filteredShift?.filter(({ type }) => type === 'Excavator')?.length >
              0 && (
                <tr style={{ borderBottom: 'none' }}>
                  <th style={{ padding: '1.75rem 0 1.25rem 4rem' }}>
                    <span
                      className="px-4 py-2"
                      style={{
                        background: '#707070',
                        width: 'max-content',
                        padding: '3px',
                        borderRadius: '15px',
                        backgroundColor: '#00214d',
                        color: 'white',
                        font: 'normal normal bold 12px/17px Open Sans',
                      }}
                    >
                      Excavators
                    </span>
                  </th>
                </tr>
              )}
            {filteredShift
              ?.filter(({ type }) => type === 'Excavator')
              ?.map((excavator) => (
                <tr
                  style={excavator?.removedEquipment ? { backgroundColor: "rgb(240, 240, 240)" } : {}}
                  hover={1}
                  key={`${excavator?.name}-${excavator?.operatorLoads[0]?.operatorName}`}
                  onClick={() => setSelectedUnit({ ...excavator, shiftChoice })}
                >
                  <th>
                    {excavator?.name}
                    {excavator?.breakdown && (
                      <Image
                        src={require('../../assets/images/not-available.png')}
                        height={20}
                        width={20}
                        style={{ margin: '0 5px' }}
                      />
                    )}
                  </th>
                  <td>{formatDate(excavator?.operatorLoads[0]?.firstLoad)}</td>
                  <td>
                    {formatDate(
                      excavator?.operatorLoads[0]?.lastLoad, '00:00 PM'
                    )}
                  </td>
                  <td>{excavator?.operatorLoads[0]?.operatorName}</td>
                  <td>{formatDate(excavator?.operatorLoads[0]?.loginTime)}</td>
                  <td>
                    {formatDate(
                      excavator?.operatorLoads[0]?.logoutTime,
                      '00:00 PM'
                    )}
                  </td>
                  <td
                    onClick={(event) => {
                      event?.stopPropagation();
                      downloadSingleUnit(excavator);
                    }}
                    style={{ float: 'right' }}
                  >
                    <CSVLink
                      data={getSingleCSVData(excavator)}
                      filename={excavator?.name}
                      target="_blank"
                    >
                      <FiDownload
                        className="shift-history-hover"
                        style={{
                          color: 'black',
                          width: '1.8rem',
                          height: '1.8rem',
                        }}
                      />
                    </CSVLink>
                  </td>
                </tr>
              ))}
            {filteredShift?.filter(({ type }) => type === 'Truck')?.length >
              0 && (
                <tr style={{ borderBottom: 'none' }}>
                  <th style={{ padding: '1.75rem 0 1.25rem 4rem' }}>
                    <span
                      className="px-4 py-2"
                      style={{
                        background: '#707070',
                        width: 'max-content',
                        padding: '3px',
                        borderRadius: '15px',
                        backgroundColor: '#00214d',
                        color: 'white',
                        font: 'normal normal bold 12px/17px Open Sans',
                      }}
                    >
                      Trucks
                    </span>
                  </th>
                </tr>
              )}
            {filteredShift
              .filter(({ type }) => type === 'Truck')
              .map((truck) => (
                <tr
                  style={truck?.removedEquipment ? { backgroundColor: "rgb(240, 240, 240)" } : {}}
                  hover={1}
                  key={`${truck?.name}-${truck?.operatorLoads[0]?.operatorName}`}
                  onClick={() => setSelectedUnit({ ...truck, shiftChoice })}
                >
                  <th>
                    {truck?.name}
                    {truck?.breakdown && (
                      <Image
                        src={require('../../assets/images/not-available.png')}
                        height={27}
                        width={27}
                        style={{ margin: '0 5px' }}
                      />
                    )}
                    {truck?.delay && (
                      <Image
                        src={require('../../assets/images/delay.png')}
                        height={15}
                        width={15}
                        style={{ marginLeft: '6px', marginBottom: '3px' }}
                      />
                    )}
                  </th>
                  <td>{formatDate(truck?.operatorLoads[0]?.firstLoad)}</td>
                  <td>
                    {formatDate(truck?.operatorLoads[0]?.lastLoad, '00:00 PM')}
                  </td>
                  <td>{truck?.operatorLoads[0]?.operatorName}</td>
                  <td>{formatDate(truck?.operatorLoads[0]?.loginTime)}</td>
                  <td>
                    {formatDate(truck?.operatorLoads[0]?.logoutTime, '00:00 PM')}
                  </td>
                  <td
                    onClick={(event) => {
                      event.stopPropagation();
                      downloadSingleUnit(truck);
                    }}
                    style={{ float: 'right' }}
                  >
                    <CSVLink
                      data={getSingleCSVData(truck)}
                      filename={truck?.name}
                      target="_blank"
                    >
                      <FiDownload
                        className="shift-history-hover"
                        style={{
                          color: 'black',
                          width: '1.8rem',
                          height: '1.8rem',
                        }}
                      />
                    </CSVLink>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {selectedUnit && (
          <ShiftHistoryModal
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
          />
        )}
      </main >
    </>
  );
}
