import clsx from 'clsx';
import { useState, useContext } from 'react';

import {
  FetchShiftHistoryData,
  ShiftHistoryContext,
} from '../../context/history';
import Other from './other';
import ShiftHistory from './shift-history';
import './style.css';
import AuthContext from '../../context/AuthProvider';

export default function History({...props}) {
  const options = ['Shift History', 'Other'];
  const [activeOption, setActiveOption] = useState('Shift History');

  const historyData = FetchShiftHistoryData();
  const { myRoles } = useContext(AuthContext)


  return (
    <ShiftHistoryContext.Provider value={historyData}>,
      <>
        <nav style={{ padding: '2rem 2rem 0 2rem' }}>
          <ul className="shift-history-menu">
            {options.map((option, index) => (
              <li
                key={index}
                className={clsx(
                  'shift-history-item',
                  option === activeOption ? 'active' : ''
                )}
                onClick={() => setActiveOption(option)}
                style={{ cursor: 'pointer' }}
              >
                {option}
              </li>
            ))}
          </ul>
        </nav>
        <hr style={{ marginTop: 0 }} />

        {activeOption === 'Shift History' && <ShiftHistory />}
        {activeOption === 'Other' && <Other />}
      </>

    </ShiftHistoryContext.Provider>
  );
}
