import {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { isValidNumber } from '../../utils/helper';

const OTPInput = (
  {
    className,
    otpLength = 4,
    style,

    onChange,

    ...props
  },
  ref
) => {
  const [otp, setOTP] = useState([...Array(otpLength).map(() => undefined)]);
  const inputs = [...Array(otpLength)].map((_) => createRef());

  useEffect(() => {
    if (otp.every((digit) => digit === undefined)) {
      inputs.forEach((input) => {
        if (input.current) {
          input.current.value = '';
        }
      });
    }
  }, [inputs, otp]);

  useImperativeHandle(ref, () => ({
    triggerClear() {
      setOTP([...Array(otpLength).map(() => undefined)]);
    },
  }));

  const handleChange = (event, index, otpValue) => {
    const newOTP = otpValue ? otpValue : [...otp];
    let digit = '';

    digit = event.target.value;
    newOTP[index] = digit === '' ? undefined : Number(digit);
    setOTP(newOTP);

    if (newOTP.every((digit) => digit !== undefined)) {
      event.target.dataset.otp = newOTP.join('');
    } else {
      delete event?.target?.dataset?.otp;
    }
    onChange(event);

    if (digit !== '') {
      inputs[index].current?.blur();
      if (index !== inputs.length - 1) {
        inputs[index + 1].current?.focus();
      }
    }
  };


  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && otp[index] === undefined) {
      if (index === 0) {
        return;
      }
      inputs[index].current?.blur();
      inputs[index - 1].current?.focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData('Text');
    if (pastedValue.length === otpLength && isValidNumber(pastedValue)) {
      setOTP(pastedValue.split('').map((digit) => Number(digit)));
      handleChange(event, undefined, pastedValue.split('').map((digit) => Number(digit)))
    }
  };

  return (
    <section
      className={className}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        ...style,
      }}
    >
      {[...Array(otpLength)].map((_, index) => {
        return (
          <input
            key={index}
            maxLength={1}
            onBlur={(event) => (event.target.style.borderBottomColor = '#000')}
            onChange={(event) => handleChange(event, index)}
            onFocus={(event) =>
              (event.target.style.borderBottomColor = '#186fd9')
            }
            onKeyDown={(event) => handleKeyDown(event, index)}
            onPaste={handlePaste}
            pattern="\d{1}"
            ref={inputs[index]}
            style={{
              aspectRatio: '1 / 1',
              border: 'none',
              borderBottom: '2px solid #000',
              height: '100%',
              textAlign: 'center',
              width: `calc(100% / ${otpLength + 1})`,
            }}
            type="text"
            value={otp[index]}
            {...props}
          />
        );
      })}
    </section>
  );
};

export default forwardRef(OTPInput);
