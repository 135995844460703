import { Box, Typography, Modal } from "@mui/material";
import { CallWithAuth } from "../../action/apiActions";
import React, { useRef, useState, useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import {
  GET_PRESENT_TOMMOROW_DATA,
  GET_PRESENT_FORECAST_DATA,
  GET_WEBHOOK_DATA,
} from "../../action/apiPath";
import useWindowDimensions from "../../hooks/useDimensions";
import maxTemp from "../../assets/svgs/maxTemp.svg";
import minTemp from "../../assets/svgs/minTemp.svg";
import dust from "../../assets/svgs/dust.svg";
import cloudyDay from "../../assets/svgs/cloudyDay.svg";
import cloudyNight from "../../assets/svgs/cloudyNight.svg";
import fullCloudy from "../../assets/svgs/fullCloudy.svg";
import moon from "../../assets/svgs/moon.svg";
import sun from "../../assets/svgs/sun.svg";
import pressure from "../../assets/svgs/pressure.svg";
import rainfall from "../../assets/svgs/rainfall.svg";
import wind from "../../assets/svgs/wind.svg";
import sunnyCloudy from "../../assets/svgs/sunnyCloudy.svg";
import nightCloudy from "../../assets/svgs/nightCloudy.svg";

export default function WeatherReport(props) {
  const { forecast, webhookData, todayData, tomorrowData } = props;
  const { height, width } = useWindowDimensions();

  function convert24to12(time) {
    var hours = parseInt(time?.substr(0, 2));
    var minutes = time?.substr(3, 2);
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + " " + ampm;
  }

  const getIconForData = (t) => {
    const timeC = new Date();
    timeC?.setHours(new Date()?.getHours() + t);
    const time = timeC?.getHours();
    console?.log(time, forecast?.cloudCover?.length);
    if (time >= forecast?.cloudCover?.length || !forecast?.cloudCover)
      return <img src={fullCloudy} alt="full Cloudy" />;
    else if (forecast?.cloudCover[time] <= 20) {
      if (time > 7 && time < 19) return <img src={sun} alt="sun" />;
      else return <img src={moon} alt="moon" />;
    } else if (forecast?.cloudCover[time] <= 60) {
      if (time > 7 && time < 19)
        return <img src={sunnyCloudy} alt="sunny Cloudy" />;
      else return <img src={nightCloudy} alt="night Cloudy" />;
    } else if (forecast?.cloudCover[time] <= 90) {
      if (time > 7 && time < 19)
        return <img src={cloudyDay} alt="cloudy Day" />;
      else return <img src={cloudyNight} alt="cloudy Night" />;
    } else return <img src={fullCloudy} alt="fully Cloudy" />;
  };

  const getNextDayDate = () => {
    const tomorrow = new Date(new Date());
    tomorrow?.setDate(tomorrow?.getDate() + 1);
    return tomorrow?.toDateString();
  };

  return (
    <div
      style={{
        height: "100vh",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: 200,
        display: "flex",
        backgroundColor: "#5f5c5c96",

        width: "100%",

        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          padding: "50px 50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#284a83",
          border: "1px solid #000",
          boxShadow: 24,

          borderRadius: 4,
          flexDirection: "column",
          alignSelf: "center",
          paddingBottom: "30px",
        }}
      >
        <span
          onClick={() => props?.setModalShow(false)}
          style={{
            float: "right",
            // width: "30px",
            alignSelf: "flex-end",

            position: "absolute",
            top: "5px",
            right: "15px",
          }}
        >
          <MdOutlineCancel
            size={30}
            style={{
              cursor: "pointer",
              color: "white",
              margin: "7px 0",
              marginLeft: "30px",
            }}
          />
        </span>
        <div
          style={{
            width: "max-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#284a83",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "max-content",
              width: "50vw",
              display: "flex",
              backgroundColor: "white",

              border: "1px solid #000",
              backgroundColor: "white",
              boxShadow: 24,
              padding: "40px",
              paddingTop: "20px",
              borderRadius: 2,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: 20,
                height: "30px",
                marginBottom: "30px",
              }}
            >
              Today Current Conditions
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                height: "35%",
              }}
            >
              <div
                id="weather"
                className="d-flex "
                style={{
                  height: 100,
                  width: "45%",
                  backgroundColor: "#c7d9f7",
                  display: "flex",
                  boxShadow: " 0px 3px 6px #00000029",
                  borderRadius: "20px",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: 700,
                    }}
                  >
                    {webhookData?.temp}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    Feels like{" "}
                    {todayData?.feels_like
                      ? `${(todayData?.feels_like - 273.15).toFixed(1)} °C`
                      : webhookData?.temp}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={maxTemp} alt="max Temp" />

                  <span
                    style={{
                      fontSize: "19px",
                      fontWeight: 600,
                    }}
                  >
                    {todayData?.max_temp
                      ? todayData?.max_temp
                      : todayData?.temperature}
                    °C
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={minTemp} alt="min Temp" />

                  <span
                    style={{
                      fontSize: "19px",
                      fontWeight: 600,
                    }}
                  >
                    {todayData?.min_temp
                      ? todayData?.min_temp
                      : todayData?.temperature}
                    °C
                  </span>
                </div>
              </div>
              <div
                id="weather"
                className="d-flex "
                style={{
                  lineHeight: "2?.5rem",
                  height: 100,
                  width: width * 0.25,
                  display: "flex",
                  borderRadius: 10,
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span>Rainfall</span>
                  <img src={rainfall} alt="rain" />

                  <span
                    style={{
                      color: "",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {webhookData?.rain}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span>Wind</span>
                  <img src={wind} alt="wind" />

                  <span
                    style={{
                      color: "",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {webhookData?.windDirection} {webhookData?.wind}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span>Pressure</span>
                  <img src={pressure} alt="pressure" />
                  <span
                    style={{
                      color: "",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {webhookData?.pressure}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span>Dust</span>
                  <img src={dust} alt="max Temp" />

                  <span
                    style={{
                      color: "",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {webhookData?.dust} &mu;g/m<sup>3</sup>
                  </span>
                </div>
              </div>
            </div>
            <div
              id="weather__hourly__forecast"
              style={{
                width: "100%",
                backgroundColor: "#f9fcfe",
                display: "flex",
                borderRadius: 10,
                // flexWrap: 'wrap',
                alignItems: "center",
                overflowX: "scroll",
                overflowY: "hidden",

                padding: "20px",
                marginTop: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: "100px",
                  // backgroundColor: 'green'                                 // marginInline: 20,
                }}
              >
                <span
                  style={{
                    fontSize: "17px",
                    fontWeight: 600,
                    color: "#1A1A1A",
                  }}
                >
                  Now
                </span>
                {getIconForData(0)}
                <span
                  style={{
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  {webhookData?.temp}{" "}
                </span>
              </div>

              {new Array(23)?.fill(undefined)?.map((_, i) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // marginInline: 20,
                    minWidth: "100px",
                    // height:'100px',
                    // backgroundColor: 'green'
                  }}
                >
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: 600,
                      color: "#1A1A1A",
                    }}
                  >
                    {convert24to12(
                      (() => {
                        const time = new Date();
                        time?.setHours(new Date()?.getHours() + i + 1);
                        return time?.getHours();
                      })() + ":00"
                    )}
                  </span>
                  {getIconForData(i + 1)}
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: 600,
                    }}
                  >
                    {forecast?.temp
                      ? forecast?.temp[i]
                        ? (forecast?.temp[i] - 273.15).toFixed(1)
                        : ""
                      : "-"}{" "}
                    °C{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <Box
            sx={{
              width: width * 0.5,
              height: height * 0.25,
              display: "flex",
              bgcolor: "white",
              border: "1px solid #000",
              boxShadow: 24,
              marginTop: 2,
              borderRadius: 2,
              flexDirection: "column",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#6A7C9A",
                fontSize: "15px",
                height: "30px",
                marginLeft: "20px",
                marginTop: "17px",
              }}
            >
              Tomorrow:{getNextDayDate()}
            </Typography>

            <div
              id="weather"
              className="d-flex "
              style={{
                width: width * 0.45,
                display: "flex",
                borderRadius: 10,
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>High</span>
                <img src={maxTemp} alt="max Temp" />
                <span
                  style={{
                    font: "normal normal normal 20px/30px Open Sans",
                  }}
                >
                  {tomorrowData?.max_temp}°C
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Low</span>
                <img src={minTemp} alt="min Temp" />
                <span
                  style={{
                    font: "normal normal normal 20px/30px Open Sans",
                  }}
                >
                  {tomorrowData?.min_temp}°C
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Rainfall</span>
                <img src={rainfall} alt="rain" />
                <span
                  style={{
                    font: "normal normal normal 20px/30px Open Sans",
                  }}
                >
                  {tomorrowData?.rain_so_far} mm
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Wind</span>
                <img src={wind} alt="wind" />
                <span
                  style={{
                    font: "normal normal normal 20px/30px Open Sans",
                  }}
                >
                  {tomorrowData?.wind_direction} {tomorrowData?.wind_speed}{" "}
                  km/hr
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Pressure</span>
                <img src={pressure} alt="pressure" />
                <span
                  style={{
                    font: "normal normal normal 20px/30px Open Sans",
                  }}
                >
                  {tomorrowData?.pressure} hpa
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Dust</span>
                <img src={dust} alt="dust" />

                <span
                  style={{
                    font: "normal normal normal 20px/30px Open Sans",
                  }}
                >
                  {webhookData?.dust} &mu;g/m<sup>3</sup>
                </span>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}
