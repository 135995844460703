import React from "react";
import ProgressBar from "../ProgressBar";
import { Image } from "react-bootstrap";
import TruckRow from "./TruckRow";
import { utcToNZTime } from "../../utils/helper";
import { getHourlyTarget } from "../../utils/helper";

export default function ExcavatorsCard(excavator) {
  const {
    trucks,
    behindTargetExcavators,
    behindTargetTrucks,
    notOperatingTrucks,
    notOperatingExcavators,
    breakdownExcavatorsOldData,
    shiftStartingAndEndingTime,
    productive_hours
  } = excavator;

  const isNotOperating = notOperatingExcavators?.includes(excavator.name.Unit)

  const getAverageBCM = () => {
    const curr = utcToNZTime()?.date;
    const endTime = new Date(shiftStartingAndEndingTime.end);
    const startTime = new Date(shiftStartingAndEndingTime.start);

    if (endTime.getTime() < curr.getTime()) {
      const totalHours = productive_hours;
      const totalBCM = Object.values(excavator?.hourlyData)?.reduce((prev, curr) => prev + curr, 0);
      return Math.floor(totalBCM / totalHours);
    } else {
      const elapsedTime = curr.getTime() - startTime.getTime();
      const totalHours = elapsedTime / 3600000;
      const totalBCM = Object.values(excavator?.hourlyData)?.reduce((prev, curr) => prev + curr, 0);
      return Math.floor(totalBCM / totalHours);
    }
  };


  const checkForCatching = (equipment) => {
    const curr = utcToNZTime()?.date;
    let ext;
    let finalTime;
    if (new Date(shiftStartingAndEndingTime.end)?.getTime() > curr.getTime()) {
      finalTime = new Date(curr?.getTime() - 3600000)?.toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true
      })
      ext = new Date(curr?.getTime())?.toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true
      })
    }
    else
      finalTime = new Date(new Date(shiftStartingAndEndingTime.end).getTime() - 10000).toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true
      })
    const data = equipment?.hourlyData[finalTime];

    const target = Math.round(getHourlyTarget(
      equipment?.productive_hours,
      parseInt(equipment?.targetLoads, 10)
    ))
    // console.log(finalTime, equipment, target, equipment.name.Unit, (!data || data < target) && equipment.actualLoads < equipment.targetLoads);

    return (!data || (data < target && (equipment?.hourlyData[ext] ? equipment?.hourlyData[ext] : 0) < target)) && equipment.actualLoads < equipment.targetLoads;
  }
  //  delete excavator.trucks

  //   console.log(excavator, "esc");
  return (
    <div
      className="excavator_card_container"
      style={{
        // height: 'max-content',
        opacity: isNotOperating
          ? .5 : 1,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border:
          "1px solid " +
          (isNotOperating
            ? "#ED7C7F"
            : behindTargetExcavators?.includes(excavator.name.Unit)
              ? "#EADE5B"
              : "#DEE5F2"),
        borderRadius: "7px",
        width: 370,
        margin: "15px 10px",
      }}
    >
      {/* {console.log(trucks)} */}
      <div
        style={{
          borderBottom: "1px solid #E1E8F4",
          display: "flex",
          flexDirection: 'column',
          alignItems: "start",
          padding: "20px 20px 20px 20px",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex" style={{ alignItems: "flex-start" }}>
          {isNotOperating ? (
            <Image
              src={require("../../assets/images/not-available.png")}
              height={28}
              width={28}
              style={{ marginTop: 2.5, marginRight: -5 }}
            />
          ) : behindTargetExcavators?.includes(excavator.name.Unit) ? (
            <Image
              src={require("../../assets/images/alert.png")}
              height={18}
              width={18}
              style={{ marginTop: 5 }}
            />
          ) : (
            <div
              style={{
                height: 18,
                width: 18,
              }}
            ></div>
          )}
          <div
            style={{ marginLeft: "3px" }}
            className="d-flex flex-column align-items-start"
          >
            <span
              style={{
                font: " normal normal 600 20px/27px Open Sans",
                marginBottom: "10px",
              }}
            >
              {excavator?.name?.Unit}
            </span>
            {/* <span
              style={{
                font: "normal normal normal 12px/17px Open Sans",
                color: "#606060",
              }}
            >
              ({excavator?.operator?.firstName})
            </span> */}
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between w-100">
          <div className="d-flex flex-column align-items-start">
            <span
              style={{
                font: "normal normal 600 20px/27px Open Sans",
                marginBottom: "2px",
              }}
            >
              {" "}
              {excavator?.actualLoads}
              <span
                style={{
                  font: "normal normal normal 20px/27px Open Sans",
                  color: "#707070",
                }}
              >
                {" "}
                / {excavator?.targetLoads} BCM
              </span>
            </span>

            <div>
              <ProgressBar
                progress={(excavator?.actualLoads / excavator?.targetLoads) * 100}
                height={20}
                width={160}
                notCatching={checkForCatching(excavator)}
                fallingBehind={behindTargetExcavators?.includes(
                  excavator.name.Unit
                )}
                isNotOperating={isNotOperating}
              />
            </div>

          </div>
          <div style={{ marginTop: -5, display: 'flex', flexDirection: 'column', }}>
            <span className="d-flex justify-content-between"><span style={{ fontSize: '13px' }}>Average/hr: </span><span style={{ minWidth: '80px', textAlign: 'right', fontSize: '13px' }}>{getAverageBCM()} BCM</span></span>
            <span className="d-flex justify-content-between"><span style={{ fontSize: '13px' }}>Operator: </span><span style={{ minWidth: '80px', textAlign: 'right', fontSize: '13px' }}>{excavator?.operator?.firstName}</span></span>
          </div>
        </div>
      </div>

      <div style={{ padding: "15px 0" }}>
        <div style={{ marginTop: "20px", marginBottom: "35px", padding: "0 30px" }}>
          <Image
            src={require("../../assets/images/truck_light.png")}
            height={30}
            width={40}
          />{" "}
          <span
            style={{
              font: "normal normal normal 20px/27px Open Sans",
              color: "#707070",
              marginLeft: "10px",
              fontSize: "1.3rem",
            }}
          >
            TRUCKS (
            {excavator?.haulTrucks?.length ?? 0})
          </span>
        </div>

        <div style={{ width: "100%" }}>
          {(!isNotOperating || !breakdownExcavatorsOldData?.find((ex) => ex._id.toString() == excavator.equipment?.toString())?.trucks?.length) && excavator?.haulTrucks?.map((tt, i) => {
            const truck = trucks?.filter(function (t) {
              return t?.name?._id == tt._id || t?.name?._id == tt.id;
            })[0];
            return <TruckRow key={i} truck={truck} behindTargetTrucks={behindTargetTrucks} notOperatingTrucks={notOperatingTrucks} shiftStartingAndEndingTime={shiftStartingAndEndingTime} />

          })}

          {excavator?.removedTruckInfo?.map((tt, i) => {
            return <TruckRow externalLoads={tt?.loads} unScheduled={true} key={i} truck={tt} behindTargetTrucks={[]} notOperatingTrucks={[]} shiftStartingAndEndingTime={shiftStartingAndEndingTime} />
          })}

          {isNotOperating && breakdownExcavatorsOldData?.find((ex) => ex._id.toString() == excavator.equipment?.toString())?.trucks?.map((truck, o) => <TruckRow key={o} truck={truck} behindTargetTrucks={[]} notOperatingTrucks={notOperatingTrucks} shiftStartingAndEndingTime={shiftStartingAndEndingTime} />
          )}

          {/* {excavator?.allLoads?.filter(tt =>
            !excavator?.haulTrucks?.some((ht) => ht.id == tt.id)
          )?.map((tt, o) => {
            const truck = trucks?.filter(function (t) {
              return t?.name?._id == tt.id;
            })[0];
            return <TruckRow key={o} shiftStartingAndEndingTime={shiftStartingAndEndingTime} externalLoads={tt?.loads} unScheduled={true} truck={truck} behindTargetTrucks={behindTargetTrucks} notOperatingTrucks={notOperatingTrucks} />
          })}  */}
        </div>
      </div>
    </div>
  );
}


