import ReactDOM from 'react-dom';

export const DumpPointPopup = (coordinate, { title, data }) => {
  const div = document.createElement('div');
  const dumpEvent = data.dumpEvent;

  const popup = (
    <>
      <table
        className="dumpPointPopup"
        style={{ width: '100%' }}
        key={`popup-${coordinate.lat}-${coordinate.lng}`}
      >
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <strong>{title}</strong>
            </th>
          </tr>
        </thead>

        <tbody>
          {dumpEvent ? (
            <>
              <tr style={{ backgroundColor: '#e1e8f4' }}>
                <td
                  style={{
                    paddingLeft: '1rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    marginRight: '0.5rem',
                  }}
                >
                  Trucks: <strong>{dumpEvent.totalTrucks}</strong>
                </td>
                <td
                  style={{
                    paddingRight: '1rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                >
                  Loads: <strong>{dumpEvent.totalLoads}</strong>
                </td>
              </tr>
              {dumpEvent.allLoads.map((truck, index) => {
                return (index % 2 === 0) && (
                  <tr key={truck.id}>
                    <td
                      style={{
                        paddingLeft: '1rem',
                      }}
                    >
                      <strong>{truck.name}</strong> ({truck.loads.length})
                    </td>
                    {index === dumpEvent.allLoads.length - 1 ? (
                      <td
                        style={{
                          paddingRight: '1rem',
                        }}
                      />
                    ) : (
                      <td
                        style={{
                          paddingRight: '1rem',
                        }}
                      >
                        <strong>{dumpEvent.allLoads[index + 1].name}</strong> (
                        {dumpEvent.allLoads[index + 1].loads.length})
                      </td>
                    )}
                  </tr>
                );
              })}
              <tr>
                <td style={{ paddingBottom: '1rem' }} />
              </tr>
            </>
          ) : (
            <>
              <tr style={{ backgroundColor: '#e1e8f4' }}>
                <td
                  style={{
                    paddingLeft: '1rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    textAlign: 'center',
                  }}
                >
                  Loading data...
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '1rem' }} />
              </tr>
            </>
          )}
        </tbody>
      </table>
      <div className="popup-tip-container"></div>
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
