import React, { useEffect, useState } from "react";

import { CallWithAuth } from "../action/apiActions";
import { EDIT_ADMIN_USER } from "../action/apiPath";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import validationUtility from "../utils/validation";

const UserEditModal = ({ openEditModal, closeModal, userData }) => {
  const { setAuth, auth } = useAuth();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo:"",
    isError: false,
  });

  useEffect(() => {
    // console.log("useEffect");
    setFormData({
      ...formData,
      firstName: auth?.userData?.firstName,
      lastName: auth?.userData?.lastName,
      email: auth?.userData?.email,
      phoneNo: auth?.userData?.phoneNo,
    });

    return () => {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo:"",
      });
    };
  }, []);

  const handelOnchange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      isError: false,
    });
  };

  const handelValidateForm = () => {
    if (
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      validationUtility.email(formData.email)
    )
      return Promise.resolve(true);
    return Promise.resolve(false);
  };

  const submitEditForm = async () => {
    console.log("submitEditForm");
    let validationStatus = await handelValidateForm();
    if (validationStatus) {
      let payload = {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNo: formData.phoneNo,
      };
      const response = await CallWithAuth("PUT", EDIT_ADMIN_USER, payload);
      // console.log(response);
      if (response?.res?.data?.status === 200) {
        let obj = {
          ...auth.userData,
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNo: formData.phoneNo,
        };
        setAuth({
          ...auth,
          userData: obj,
        });
        toast.success("User Edited successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        closeModal((prevState) => !prevState);
      }
    } else {
      // console.log("else section");
      setFormData({ ...formData, isError: true });
    }
  };

  // console.log(userData);

  return (
    <div
      className={
        openEditModal ? "full_modal" : "full_modal generate_new_pin_active"
      }
      id="account_edit_popup"
    >
      <div className="generate_new_pin_box my_account_box">
        <h4 style={{font:"normal normal bold 20px/27px Open Sans"}}>Edit Account Details</h4>
        <div className="generate_new_pin_form">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="my_account_imput_wrap ">
                  <input
                    type="text"
                    className="form-control pl-4 pt-4"
                    id="fname"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handelOnchange} 
                    style={{
                      border:
                        formData.isError && formData.firstName === ""
                          ? "1px solid red"
                          : "",
                          height:"55px",
                          font: "normal normal normal 14px/22px Open Sans",
                          color:"#092344"
                    }}
                    placeholder="" 
                  />
                  <label className={`${formData?.firstName==="" ? "empty-label":"non-empty-label"}`}>First Name*</label>
                </div>
                <span className="form-control-message">
                  {formData.isError &&
                    formData.firstName === "" &&
                    "Please enter First Name"}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="my_account_imput_wrap ">
                  <input
                    type="text"
                    className="form-control pl-4 pt-4"
                    id="lname"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handelOnchange}
                    style={{
                      border:
                        formData.isError && formData.lastName === ""
                          ? "1px solid red"
                          : "",
                           height:"55px",
                           font: "normal normal normal 14px/22px Open Sans",
                           color:"#092344"
                    }}
                    placeholder="" 
                  />
                  <label className={`${formData?.lastName==="" ? "empty-label":"non-empty-label"}`}>Last Name*</label>
                </div>
                <span className="form-control-message">
                  {formData.isError &&
                    formData.lastName === "" &&
                    "Please enter Last Name"}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <div className="my_account_imput_wrap ">
                  <input
                    type="email"
                    className="form-control pl-4 pt-4"
                    id="email"
                    name="email"
                    disabled
                    value={formData.email}
                    onChange={handelOnchange}
                    style={{
                      border:
                        formData.isError &&
                        !validationUtility.email(formData.email)
                          ? "1px solid red"
                          : "",
                           height:"55px",
                           font: "normal normal normal 14px/22px Open Sans",
                           color:"#092344"
                    }}
                    placeholder="" 
                  />
                  <label className={`${formData?.email==="" ? "empty-label":"non-empty-label"}`}>Email*</label>
                </div>
                <span className="form-control-message">
                  {formData.isError &&
                    !validationUtility.email(formData.email) &&
                    "Please enter correct email"}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <div className="my_account_imput_wrap ">
                  <input
                    type="text"
                    className="form-control pl-4 pt-4"
                    id="phone"
                    name="phoneNo"
                    value={formData?.phoneNo}
                    onChange={handelOnchange}
                    placeholder="" 
                    style={{
                      border:
                        formData?.isError
                          ? "1px solid red"
                          : "",
                           height:"55px", 
                           font: "normal normal normal 14px/22px Open Sans",
                           color:"#092344"
                    }}
                  />
                  <label className={`${formData?.phoneNo==="" ? "empty-label":"non-empty-label"}`}>Phone No.*</label>
                </div>
              </div>
            </div>
          </div>
          <div className="add_new_user_box_top_right d-flex justify-content-end">
            <button
              style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#186FD9",border:"1.4px solid #a3c6ff", width:"100px"}}
              className="btn"
              onClick={() => closeModal((prevState) => !prevState)}
            >
              Discard
            </button>
            <button 
            style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#FFFFFF",backgroundColor:"#186FD9",border:"1.4px solid #a3c6ff", width:"140px"}}
            className="btn ml-2" onClick={submitEditForm}>
              {" "}
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditModal;
