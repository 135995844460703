const nonEmptyString = { type: "string", minLength: 1 };
const nullableString = { type: ["string", "null"] };
const arrayOfStrings = { type: "array", items: nonEmptyString };
const arrayOfStringsWithMinOneValue = { type: "array", items: nonEmptyString, minItems: 1 };
const numberType = { type: "number" };

// Haul Truck Schema
const haulTruckSchema = {
    type: "object",
    required: [
        "equipment",
        "plantId",
        "category",
        "operator",
        "material",
        "sourceArea",
        "dumpArea",
        "bcmPerLoad",
        "targetLoads",
        "startTime",
        "endTime",
        "productiveHours"
    ],
    properties: {
        assignmentId: nonEmptyString,
        equipment: nonEmptyString,
        plantId: nonEmptyString,
        category: nonEmptyString,
        operator: nonEmptyString,
        assistantOperator: nullableString,
        trainee: nullableString,
        material: nonEmptyString,
        sourceArea: nonEmptyString,
        dumpArea: arrayOfStringsWithMinOneValue,
        bcmPerLoad: numberType,
        loadingMachine: nonEmptyString,
        targetLoads: numberType,
        startTime: nonEmptyString,
        endTime: nonEmptyString,
        productiveHours: numberType,
        activityDescription: nullableString,
        knownHazards: nullableString,
        startOfShiftNoteToOperator: nullableString
    }
};

// Shift Plans Schema
const shiftPlanSchema = {
    type: "object",
    required: [
        "plan",
        "operator",
        "material",
        "sourceArea",
        "dumpArea",
        "targetBCMs",
        "bcmPerHour",
        "startTime",
        "endTime",
        "productiveHours"
    ],
    properties: {
        assignmentId: nonEmptyString,
        plan: nonEmptyString,
        operator: nonEmptyString,
        assistantOperator: nullableString,
        trainee: nullableString,
        material: nonEmptyString,
        sourceArea: nonEmptyString,
        dumpArea: arrayOfStringsWithMinOneValue,
        targetBCMs: numberType,
        bcmPerHour: numberType,
        startTime: nonEmptyString,
        endTime: nonEmptyString,
        productiveHours: numberType,
        activityDescription: nullableString,
        knownHazards: nullableString,
        startOfShiftNoteToOperator: nullableString,
        haulTrucks: {
            type: "array",
            items: haulTruckSchema,
            minItems: 1
        },
        removedHaulTrucks: {
            type: "array",
            items: haulTruckSchema
        }
    }
};

// Excavator Schema
const excavatorSchema = {
    type: "object",
    required: [
        "equipment",
        "plantId",
        "category",
        "excavatorHaulTrucks",
        "plan",
        "operator",
        "material",
        "sourceArea",
        "haulTrucks",
        "removedHaulTrucks",
        "dumpArea",
        "targetBCMs",
        "bcmPerHour",
        "startTime",
        "endTime",
        "productiveHours"
    ],
    properties: {
        assignmentId: nonEmptyString,
        equipment: nonEmptyString,
        plantId: nonEmptyString,
        category: nonEmptyString,
        plan: nonEmptyString,
        operator: nonEmptyString,
        assistantOperator: nullableString,
        trainee: nullableString,
        material: nonEmptyString,
        sourceArea: nonEmptyString,
        dumpArea: arrayOfStringsWithMinOneValue,
        targetBCMs: numberType,
        bcmPerHour: numberType,
        startTime: nonEmptyString,
        endTime: nonEmptyString,
        productiveHours: numberType,
        activityDescription: nullableString,
        knownHazards: nullableString,
        startOfShiftNoteToOperator: nullableString,
        excavatorHaulTrucks: arrayOfStringsWithMinOneValue,
        haulTrucks: {
            type: "array",
            items: haulTruckSchema,
            minItems: 1
        },
        removedHaulTrucks: {
            type: "array",
            items: haulTruckSchema
        },
        shiftPlans: {
            type: "array",
            items: shiftPlanSchema,
            minItems: 1
        }
    }
};

// Shift Schema
export const AJVSchema = {
    type: "object",
    required: [
        "reportNo",
        "status",
        "mine",
        "shiftType",
        "department",
        "crew",
        "startTime",
        "endTime",
        "productiveHours",
        "shiftSupervisor",
        "annualLeave",
        "assignedExcavators",
        "sickLeave",
        "absent",
        "createdBy"
    ],
    properties: {
        _id: nonEmptyString,
        newShiftId: nullableString,
        reportNo: { type: "number" },
        status: nonEmptyString,
        mine: nonEmptyString,
        shiftType: nonEmptyString,
        department: nonEmptyString,
        crew: nonEmptyString,
        startTime: nonEmptyString,
        endTime: nonEmptyString,
        productiveHours: numberType,
        shiftSupervisor: nonEmptyString,
        leadingHand: nullableString,
        annualLeave: arrayOfStrings,
        sickLeave: arrayOfStrings,
        absent: arrayOfStrings,
        createdBy: nonEmptyString,
        generalShiftNotes: nullableString,
        assignedExcavators: {
            type: "array",
            items: excavatorSchema,
        }
    }
};
