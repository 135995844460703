// import Header from "../Header";

import Dashboard from "../../assets/svgs/dashboard.svg";
import ShiftPlanning from "../../assets/svgs/shiftPlanning.svg";
import Dump from "../../assets/svgs/dump.png";
import KPI from "../../assets/svgs/kpi.svg";
import Asset from "../../assets/svgs/AssetMonitoring.svg";
import Maintainence from "../../assets/svgs/maintainence.svg";
import PreStart from "../../assets/svgs/preStart.svg";
import History from "../../assets/svgs/history.svg";
import ManageUser from "../../assets/svgs/manageUser.svg";
import Notifications from "../../assets/svgs/noti.svg";
import Settings from "../../assets/svgs/settings.svg";
import Logo from "../../assets/svgs/favicon.png";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "./landingPage.css";
import AuthLayout from "../../components/AuthLayout";

const departments = (notPriviliged) => [
  { name: "Dashboard", code: 100, allowedRoles: [300, 400, 500] },
  { disabled: notPriviliged, name: "KPI Management", code: 1100, allowedRoles: [300, 400, 500] },
  { name: "Shift Planning", code: 200, allowedRoles: [300, 400, 500] },
  { name: "Dump Management", code: `300`, allowedRoles: [300, 400, 500] },
  { disabled: notPriviliged, name: "Asset Monitoring", code: 400, allowedRoles: [300, 400, 500] },
  { disabled: notPriviliged, name: "Maintainence", code: 500, allowedRoles: [300, 400, 500, 600] },
  { disabled: notPriviliged, name: "Pre-Start Checks", code: 600, allowedRoles: [300, 400, 500] },
  { name: "History", code: 700, allowedRoles: [300, 400, 500] },
  { name: "User Management", code: 800, allowedRoles: [300, 400, 500] },
  { disabled: true, name: "Notifications", code: 900, allowedRoles: [300, 400, 500] },
  { disabled: true, name: "Settings", code: 1000, allowedRoles: [300, 400, 500] },
];

const imosDepartments = (notPriviliged) => [
  { name: "Dashboard", code: 100, allowedRoles: [300, 400, 500] },
  { disabled: true, name: "KPI Management", code: 1100, allowedRoles: [300, 400, 500] },
  { name: "Shift Planning", code: 200, allowedRoles: [300, 400, 500] },
  { name: "Dump Management", code: 300, allowedRoles: [300, 400, 500] },
  { disabled: notPriviliged, name: "Maintainence", code: 500, allowedRoles: [300, 400, 500, 600] },
  { disabled: true, name: "Pre-Start Checks", code: 600, allowedRoles: [300, 400, 500] },
  { name: "History", code: 700, allowedRoles: [300, 400, 500] },
  { name: "User Management", code: 800, allowedRoles: [300, 400, 500] },
  { disabled: true, name: "Notifications", code: 900, allowedRoles: [300, 400, 500] },
  { disabled: true, name: "Settings", code: 1000, allowedRoles: [300, 400, 500] },
];

const icons = {
  100: [Dashboard],
  200: [ShiftPlanning],
  300: [Dump],
  400: [Asset],
  500: [Maintainence],
  600: [PreStart],
  700: [History],
  900: [Notifications],
  800: [ManageUser],
  1000: [Settings],
  1100: [KPI],
};

const links = {
  100: "/dashboard",
  200: "/shift-list",
  300: "/dump-points",
  400: "/asset-monitoring",
  500: "/maintainance",
  600: "/coming-soon",
  700: "/history",
  800: "/manageuser",
  900: "/coming-soon",
  1000: "/coming-soon",
  1100: "/management-kpis",
};

const LandingPage = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const isPriviliged = [
    "6340ba6a85fb2e16f77d5f55",
    "6342d9e56363e50bf1dcbd2f",
    "63436d336363e50bf1dcc50a",
    "646164f1b1a1a1216c7b7ff4",
    "64ab461b2ea82c770718b84e",
  ].includes(auth?.userData?._id.toString())

  const routes = process.env.REACT_APP_ENV == 'stage'
    ? departments(!isPriviliged)
    : imosDepartments(!isPriviliged);
  const onDepartmentClick = (code) => {
    return navigate(links[code]);
  };
  return (
    <AuthLayout noSideBar pageName="Landing ">
      <div className="h-100 mb-4 pt-3 pe-3">
        <div className="d-flex flex-column align-items-center gap-4">
          <h2 className="font-bold">Welcome to </h2>
          <img height={30} src={Logo} />

          <div className="d-flex row align-items-center justify-content-center flex-wrap max-width">
            {routes.map(({ name, code, allowedRoles, disabled }, index) => (
              <div
                key={code}
                className={
                  index === 0 ? "d-flex justify-content-center w-100" : "basis"
                }
              >
                <button
                  value={code}
                  onClick={() => onDepartmentClick(code)}
                  key={name}
                  className={`
                 ${disabled ||
                      !allowedRoles?.find((role) => auth.roles.includes(role))
                      ? "department-disabled"
                      : "department-container"
                    }
                rounded-4 d-flex align-items-end justify-content-between border-0 px-4
                ${index === 0 ? "kpi-container" : "dpt-container"}`}
                >
                  <p style={{ fontSize: "1.6rem" }} className="w-50 text-start">
                    {name}
                  </p>
                  <img
                    className="mb-4"
                    style={{
                      width: index === 0 ? "3.5rem" : "5rem",
                      height: index === 0 ? "3.5rem" : "5rem",
                      objectFit: "contain",
                      opacity:
                        disabled ||
                          !allowedRoles?.find((role) => auth.roles.includes(role))
                          ? "50%"
                          : "100%",
                    }}
                    alt={name}
                    src={icons[code]}
                    key={code}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AuthLayout >
  );
};

export default LandingPage;
