import React from 'react';
import MaintainancePageImg from './assets/MaintainancePage.png';

export default function Maintainance() {
    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(180deg, #7FAEFF 0%, #328FFF 100%)' // Adjust this as per the exact gradient.
        }}>
            <img style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }} src={MaintainancePageImg} alt="In Maintenance" />
        </div>
    );
}
