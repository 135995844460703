import React, { useState } from 'react'
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FiEye, FiEyeOff } from "react-icons/fi";

const usePasswordToggle = () => {
    const[visible, setVisibility] = useState(false);

    const icon = ( visible ? 
    <FiEyeOff style={{
              color: 'rgb(222, 217, 217)',
              cursor: 'pointer',
              marginBottom: '20px',
              fontSize: '20px'
    }} onClick={() =>setVisibility(visibility => !visibility)}/>: 
    <FiEye style={{
            color: 'rgb(222, 217, 217)',
            cursor: 'pointer',
            marginBottom: '20px',
            fontSize: '20px'
    }} onClick={() =>setVisibility(visibility => !visibility)}/>)

    const inputType = visible ? 'text' : 'password'
  return [inputType,icon];
}

export default usePasswordToggle;