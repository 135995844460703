const convertNumberToHours = (number) => {
  number = Math.abs(number);
  const hours = Math.floor(number);
  let decimal = number - hours;

  let min = 1 / 60;
  decimal = min * Math.round(decimal / min);
  const minutes = Math.floor(decimal * 60);

  const paddedHours = String(hours).padStart(2, '0');
  const paddedMinutes = String(minutes).padStart(2, '0');
  return `${paddedHours}:${paddedMinutes} hrs`;
};

export const formatDate = (timestamp, defaultValue = '00:00 AM') => {
  if (isNaN(Date.parse(timestamp))) return defaultValue;

  const date = new Date(timestamp);
  return date.toLocaleTimeString('en-US', {
    timeZone: 'Pacific/Auckland',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

export const formatDate24 = (timestamp, defaultValue = '00:00 AM') => {
  if (isNaN(Date.parse(timestamp))) return defaultValue;

  const date = new Date(timestamp);
  return date.toLocaleTimeString('en-US', {
    timeZone: 'Pacific/Auckland',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};

export const getElementDimensions = (element) => {
  return [
    element.getBoundingClientRect().width,
    element.getBoundingClientRect().height,
  ];
};

export const getTimeDiff = (a, b) => {
  const _MS_PER_HOUR = 1000 * 60 * 60;

  if (isNaN(Date.parse(a)) || isNaN(Date.parse(b))) return '00:00 hrs';

  const aDate = new Date(a);
  const bDate = new Date(b);

  return convertNumberToHours((aDate - bDate) / _MS_PER_HOUR);
};
