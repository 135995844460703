import React from "react";
import styles from "./ErrorAlert.module.css";

const ErrorAlert = ({ onClose, message, title }) => {
  document.body.style.height = "100vh";
  document.body.style.overflow = "hidden";
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.mid}>
          {title && <div className={styles.title}>{title}</div>}
          {message && <div className={styles.message}>{message}</div>}
        </div>
        <div className={styles.bottom}>
          <div
            className={styles.btn}
            onClick={() => {
              document.body.style.height = "100%";
              document.body.style.overflow = "auto";
              onClose();
            }}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorAlert;
