import React, { useState, useEffect } from 'react';
import styles from './style.css';
import DynamicSelectField from '../../pages/ShiftPlanning/components/DynamicSelectField/DynamicSelectField';

const CountryCode = ({ value, style, onChange, error, errorMessage, className, label, labelClassName, selectClassName }) => {
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    fetchCountryCodes();
  }, []);

  const fetchCountryCodes = async () => {
    try {
      const response = await fetch('api/utils/countries'); // Adjust the API endpoint if necessary
      const data = await response.json();

      const formattedCountryCodes = data.data.map(country => ({
        value: country?.isd,
        label: `+${country.isd}`,
        icon: (
          <div
            className="flag-icon"
            style={{
              width: '24px',
              height: '18px',
              backgroundImage: `url(${country.flag})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '2px',
              marginRight: '8px'
            }}
          />
        ),
        countryCode: country.iso_code
      }));
      
      setCountryCodes(formattedCountryCodes);
    } catch (error) {
      console.error('Error fetching country codes:', error);
    }
  };

  const selectedOption = countryCodes.find(option => option?.value === Number(value));

  return (
    <div className={className ? className : "d-flex align-items-ceter"} style={style}>
      {label && <label className={labelClassName}>{label}</label>}
      <DynamicSelectField
        valueStyle={{height:"100%"}}
        isMulti={false}
        value={selectedOption}
        onChange={(v) => onChange(v.value)}
        options={countryCodes}
        isSearchable={true}
        placeholder=" "
        error={error}
        errorMessage={errorMessage}
        className="pr-0"
        labelIconClass="mr-2"
      />
    </div>
  );
};

export default CountryCode;
