import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { HiDownload } from "react-icons/hi";
import Search from "../../components/GeneralFields/Search";
import { ShiftStatus } from "../../utils/contants";
import { useAddDailyPlan } from "../../Context/context";

const getShiftStatus = (status) => {
  if (status == ShiftStatus.NEW) return "New";
  else if (status == ShiftStatus.DRAFT) return "Draft";
  else if (status == ShiftStatus.PUSBLISHED) return "Published";
  else if (status == ShiftStatus.ONGOING) return "Ongoing";
  else if (status == ShiftStatus.ENDED) return "Ended";
};

export default function ShiftPlanSubHeaderBar({
  handleOnSearch,
  handleOnClear,
  onClickPublish,
  onClickSaveDraft,
  isSavingDisabled,
}) {
  const {
    search,
    shiftStatus,
    generalShiftPlanDetailsFormData,
    editSession,
    seconds,
  } = useAddDailyPlan();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="font-weight-bold pb-3 ">
          {generalShiftPlanDetailsFormData.reportNo} ({getShiftStatus(shiftStatus)})
        </div>

        <div className="d-flex align-items-center">
          <span>
            {editSession.by
              ? `${editSession.by} is currently editing the shift.` :
              seconds > 0 ? "Save to apply your changes." : ""
            }
          </span>
        </div>
      </div >
      <div className="d-flex justify-content-between align-items-center pb-3">
        <div className="d-flex align-items-center">
          {(shiftStatus == ShiftStatus.NEW ||
            shiftStatus == ShiftStatus.DRAFT) && (
              <Button
                onClick={onClickSaveDraft}
                className={"mr-3"}
                style={{
                  backgroundColor: "transparent",
                  color: isSavingDisabled ? "#8190AD" : "#186FD9",
                  borderColor: " #E1E8F4",
                }}
                label={"Save Draft"}
                isDisabled={isSavingDisabled}
              />
            )}
          {shiftStatus != ShiftStatus.ENDED && (
            <Button
              onClick={onClickPublish}
              label={
                (shiftStatus == ShiftStatus.ONGOING ||
                  shiftStatus == ShiftStatus.PUSBLISHED
                  ? "Save Changes & Republish"
                  : "Save & Publish")
              }
              isDisabled={isSavingDisabled}
            />
          )}
        </div>
        <div className="d-flex align-items-center ">
          <Search
            query={search.query}
            onChange={handleOnSearch}
            count={search.count + search?.count2 ?? 0}
            onClear={handleOnClear}
            showCount={true}
          />
          <div
            style={{
              width: 30,
              height: 30,
              backgroundColor: "#E1E4EA",
              cursor: "not-allowed",
            }}
            className="ml-2 d-flex align-items-center justify-content-center rounded-circle"
          >
            <HiDownload size={17.5} color="#8190AD" />
          </div>
        </div>
      </div>
    </>
  );
}
