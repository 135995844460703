import { useEffect, useState, useRef } from 'react';

import { CallWithAuth } from '../../action/apiActions';
import {
  GET_ALL_OPERATOR_FOR_SHIFTS,
  GET_DUMP_EVENTS,
  GET_MATERIALS,
  GET_SOURCE_DUMPS,
  GET_VEHICLE_EVENTS,
  SHIFT_DASHBOARD_LISTING_DETAILS,
} from '../../action/apiPath';
import { useIntervalFetch } from '../../hooks/useIntervalFetch';

const initialState = {
  dumpEvents: [],
  dayDumpEvents: [],
  nightDumpEvents: [],

  vehicleEvents: [],
  dayVehicleEvents: [],
  nightVehicleEvents: [],

  dumpPoints: [],
  optionsForDumpPoints: [],

  materials: [],
  optionsForMaterials: [],

  operators: [],
  optionsForOperators: [],

  trucks: [],
  optionsForTrucks: [],
};

export const FetchDumpPointsData = () => {
  const [selectedDate, setSelectedDate] = useState('')
  const selectedDateRef = useRef('')
  const fetch = (dispatch) => {
    const fetchDumpEvents = async () => {
      const response = await CallWithAuth('POST', GET_DUMP_EVENTS, { date: selectedDateRef.current });
      if (response?.res?.data?.status === 200) {
        dispatch({
          type: 'dayDumpEvents',
          payload: response?.res?.data?.data[0],
        });
        dispatch({
          type: 'nightDumpEvents',
          payload: response?.res?.data?.data[1],
        });
      } else throw response?.res;
    };

    const fetchDumpPoints = async () => {
      const response = await CallWithAuth('GET', GET_SOURCE_DUMPS);
      if (response?.res?.data?.status === 200) {
        const dumpPoints = response?.res?.data?.data;
        const optionsForDumpPoints = dumpPoints?.map((dumpPoint) => {
          return { label: dumpPoint?.name, value: dumpPoint?._id };
        });
        dispatch({ type: 'dumpPoints', payload: dumpPoints });
        dispatch({
          type: 'optionsForDumpPoints',
          payload: optionsForDumpPoints,
        });
      } else throw response?.res;
    };

    const fetchMaterials = async () => {
      const response = await CallWithAuth('GET', GET_MATERIALS);
      if (response?.res?.data?.status === 200) {
        const materials = response?.res?.data?.data;
        const optionsForMaterials = materials?.map((material) => {
          return { label: material?.MaterialType, value: material?._id };
        });
        dispatch({ type: 'materials', payload: materials });
        dispatch({
          type: 'optionsForMaterials',
          payload: optionsForMaterials,
        });
      } else throw response?.res;
    };

    const fetchTrucks = async () => {
      const response = await CallWithAuth(
        'POST',
        SHIFT_DASHBOARD_LISTING_DETAILS
      );
      if (response?.res?.data?.status === 200) {
        const trucks = response?.res?.data?.data?.trucks?.map((truck) => {
          return truck?.truck;
        });
        const optionsForTrucks = response?.res?.data?.data?.trucks?.map((truck) => {
          return { label: truck?.truck?.equipments, value: truck?.truck?._id };
        });
        dispatch({ type: 'trucks', payload: trucks });
        dispatch({ type: 'optionsForTrucks', payload: optionsForTrucks });
      } else throw response?.res;
    };

    const fetchVehicleEvents = async () => {
      const response = await CallWithAuth('POST', GET_VEHICLE_EVENTS, { date: selectedDateRef.current });
      if (response?.res?.data?.status === 200) {
        dispatch({
          type: 'dayVehicleEvents',
          payload: response?.res?.data?.data[0],
        });
        dispatch({
          type: 'nightVehicleEvents',
          payload: response?.res?.data?.data[1],
        });
      } else throw response?.res;
    };

    const fetchOperators = async () => {
      const response = await CallWithAuth('GET', GET_ALL_OPERATOR_FOR_SHIFTS);
      if (response?.res?.data?.status === 200) {
        const operators = response?.res?.data?.data;
        const optionsForOperators = [];
        optionsForOperators?.push(
          operators[0]?.map((operator) => {
            return {
              label: operator?.firstName + ' ' + operator?.lastName,
              value: operator?._id,
            };
          })
        );
        optionsForOperators?.push(
          operators[1]?.map((operator) => {
            return {
              label: operator?.firstName + ' ' + operator?.lastName,
              value: operator?._id,
            };
          })
        );
        dispatch({ type: 'operators', payload: optionsForOperators });
        dispatch({
          type: 'optionsForOperators',
          payload: optionsForOperators,
        });
      } else throw response?.res;
    };

    fetchDumpEvents();
    fetchDumpPoints();
    fetchMaterials();
    fetchTrucks();
    fetchOperators();
    fetchVehicleEvents();
  };

  const [state, dispatch] = useIntervalFetch(
    'Dump Points',
    fetch,
    initialState,
    60000
  );

  useEffect(() => {
    const fetchData = () => {
      const fetchDumpEvents = async () => {
        const response = await CallWithAuth('POST', GET_DUMP_EVENTS, { date: selectedDateRef.current });
        if (response?.res?.data?.status === 200) {
          dispatch({
            type: 'dayDumpEvents',
            payload: response?.res?.data?.data[0],
          });
          dispatch({
            type: 'nightDumpEvents',
            payload: response?.res?.data?.data[1],
          });
        } else throw response?.res;
      };

      const fetchDumpPoints = async () => {
        const response = await CallWithAuth('GET', GET_SOURCE_DUMPS);
        if (response?.res?.data?.status === 200) {
          const dumpPoints = response?.res?.data?.data;
          const optionsForDumpPoints = dumpPoints?.map((dumpPoint) => {
            return { label: dumpPoint?.name, value: dumpPoint?._id };
          });
          dispatch({ type: 'dumpPoints', payload: dumpPoints });
          dispatch({
            type: 'optionsForDumpPoints',
            payload: optionsForDumpPoints,
          });
        } else throw response?.res;
      };

      const fetchMaterials = async () => {
        const response = await CallWithAuth('GET', GET_MATERIALS);
        if (response?.res?.data?.status === 200) {
          const materials = response?.res?.data?.data;
          const optionsForMaterials = materials?.map((material) => {
            return { label: material?.MaterialType, value: material?._id };
          });
          dispatch({ type: 'materials', payload: materials });
          dispatch({
            type: 'optionsForMaterials',
            payload: optionsForMaterials,
          });
        } else throw response?.res;
      };

      const fetchTrucks = async () => {
        const response = await CallWithAuth(
          'POST',
          SHIFT_DASHBOARD_LISTING_DETAILS
        );
        if (response?.res?.data?.status === 200) {
          const trucks = response?.res?.data?.data?.trucks?.map((truck) => {
            return truck?.truck;
          });
          const optionsForTrucks = response?.res?.data?.data?.trucks?.map((truck) => {
            return { label: truck?.truck?.equipments, value: truck?.truck?._id };
          });
          dispatch({ type: 'trucks', payload: trucks });
          dispatch({ type: 'optionsForTrucks', payload: optionsForTrucks });
        } else throw response?.res;
      };

      const fetchVehicleEvents = async () => {
        const response = await CallWithAuth('POST', GET_VEHICLE_EVENTS, { date: selectedDateRef.current });
        if (response?.res?.data?.status === 200) {
          dispatch({
            type: 'dayVehicleEvents',
            payload: response?.res?.data?.data[0],
          });
          dispatch({
            type: 'nightVehicleEvents',
            payload: response?.res?.data?.data[1],
          });
        } else throw response?.res;
      };

      const fetchOperators = async () => {
        const response = await CallWithAuth('GET', GET_ALL_OPERATOR_FOR_SHIFTS);
        if (response?.res?.data?.status === 200) {
          const operators = response?.res?.data?.data;
          const optionsForOperators = [];
          optionsForOperators?.push(
            operators[0]?.map((operator) => {
              return {
                label: operator?.firstName + ' ' + operator?.lastName,
                value: operator?._id,
              };
            })
          );
          optionsForOperators?.push(
            operators[1]?.map((operator) => {
              return {
                label: operator?.firstName + ' ' + operator?.lastName,
                value: operator?._id,
              };
            })
          );
          dispatch({ type: 'operators', payload: optionsForOperators });
          dispatch({
            type: 'optionsForOperators',
            payload: optionsForOperators,
          });
        } else throw response?.res;
      };

      fetchDumpEvents();
      fetchDumpPoints();
      fetchMaterials();
      fetchTrucks();
      fetchOperators();
      fetchVehicleEvents();
    };
    if (selectedDate) { selectedDateRef.current = selectedDate; fetchData(); }
  }, [selectedDate])


  // 0 = day Shift
  // 1 = night Shift
  const [shiftChoice, setShiftChoice] = useState('0');
  useEffect(() => {
    switch (shiftChoice) {
      case '0':
        dispatch({ type: 'dumpEvents', payload: state?.dayDumpEvents });
        dispatch({
          type: 'vehicleEvents',
          payload: state?.dayVehicleEvents,
        });
        break;

      case '1':
        dispatch({ type: 'dumpEvents', payload: state?.nightDumpEvents });
        dispatch({
          type: 'vehicleEvents',
          payload: state?.nightVehicleEvents,
        });
        break;
    }
  }, [
    state?.dayDumpEvents,
    state?.nightDumpEvents,
    state?.dayVehicleEvents,
    state?.nightVehicleEvents,
    shiftChoice,
  ]);

  const [isDetailPageOpen, setIsDetailPageOpen] = useState(false);
  const [selectedDumpPoint, setSelectedDumpPoint] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState({});

  useEffect(() => {
    if (selectedDumpPoint?._id) {
      setSelectedDumpPoint(
        state?.dumpEvents?.find(
          (dumpEvent) => dumpEvent?._id === selectedDumpPoint?._id
        )
      );
    } else if (selectedVehicle?._id) {
      setSelectedVehicle(
        state?.vehicleEvents?.find(
          (vehicleEvent) => vehicleEvent?._id === selectedVehicle?._id
        )
      );
    }
  }, [state?.dumpEvents, state?.vehicleEvents]);

  return {
    isDetailPageOpen,
    setIsDetailPageOpen,
    selectedDumpPoint,
    setSelectedDumpPoint,
    selectedVehicle,
    setSelectedVehicle,
    shiftChoice,
    setShiftChoice,
    setSelectedDate,
    selectedDate,
    ...state,
  };
};


