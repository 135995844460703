export const exOptions = [
    {
        label: "EX075",
        value: "EX075",
        device_id: null,
        anchor_sn: "0x0016E0"
    },
    {
        label: "EX076",
        value: "EX076",
        device_id: null,
        anchor_sn: "0x0016E4"
    },
    {
        label: "EX077",
        value: "EX077",
        device_id: null,
        anchor_sn: "0x0016C3"
    },
    {
        label: "EX078",
        value: "EX078",
        device_id: null,
        anchor_sn: "0x0012F8"
    },
    {
        label: "EX079",
        value: "EX079",
        device_id: null,
        anchor_sn: "0x0012CF"
    }
];


export const truckOptions = [
    {
        label: "HT 212",
        value: "HT 212",
        device_id: "008748",
        tag_sn: "0x001887"
    },
    {
        label: "HT 213",
        value: "HT 213",
        device_id: "082117",
        tag_sn: "0x00188A"
    },
    {
        label: "HT 214",
        value: "HT 214",
        device_id: "00871A",
        tag_sn: "0x001892"
    },
    {
        label: "HT 215",
        value: "HT 215",
        device_id: "0820A8",
        tag_sn: "0x001893"
    },
    {
        label: "HT 216",
        value: "HT 216",
        device_id: "008727",
        tag_sn: "0x001873"
    },
    {
        label: "HT 217",
        value: "HT 217",
        device_id: "008744",
        tag_sn: "0x001889"
    },
    {
        label: "HT 218",
        value: "HT 218",
        device_id: "00872A",
        tag_sn: "0x001870"
    },
    {
        label: "HT 219",
        value: "HT 219",
        device_id: "008739",
        tag_sn: "0x001875"
    },
    {
        label: "HT 220",
        value: "HT 220",
        device_id: "00873B",
        tag_sn: "0x00188D"
    },
    {
        label: "HT 223",
        value: "HT 223",
        device_id: "008742",
        tag_sn: "0x00187C"
    },
    {
        label: "HT 224",
        value: "HT 224",
        device_id: "0086F4",
        tag_sn: "0x001878"
    },
    {
        label: "HT 230",
        value: "HT 230",
        device_id: "00872D",
        tag_sn: "0x001877"
    },
    {
        label: "HT 231",
        value: "HT 231",
        device_id: "00872F",
        tag_sn: "0x001874"
    }
];
