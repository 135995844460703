import React from "react";
import styles from "./SelectAsset.module.css";
import { SelectAssetTypes } from "../../utils/contants";
import Tooltip from "../../components/Tooltip/Tooltip";
import { clipString } from "../../utils/helper";

const SelectAssetTable = ({
  data,
  selected,
  setSelected,
  allAssets = false,
}) => {

  const handleCheckboxChange = (item) => {
    if (item.completelyUnavailable)
      return
    if (selected.find((e) => item._id === e._id)) {
      setSelected(selected.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  const handleAllCheckboxChange = () => {
    if (selected.length === data.length) {
      setSelected([]);
    } else {
      setSelected(data.filter((d) => !d?.completelyUnavailable));
    }
  };

  const renderRow = (item) => {
    const isChecked = data.length !== 0 && selected.find((e) => item._id?.toString() == e._id?.toString());

    if (allAssets && isChecked) return null;

    return (
      <tr
        key={item._id}
        onClick={() => handleCheckboxChange(item)}
        className={styles.tableRow}
      >
        <td className={styles.tableCell} align="center">
          <input
            type="checkbox"
            disabled={item?.completelyUnavailable ? true : false}
            checked={isChecked}
            onChange={() => handleCheckboxChange(item)}
          />
        </td>
        <td className={styles.tableCell} style={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
          <div style={{ height: 9, width: 9, borderRadius: "50%", backgroundColor: item.completelyUnavailable ? "#6A7C9A" : item.unavailable ? "#C13E3D" : "#26A071" }}>
          </div>
          <span className="pl-1">
            {item.Unit}
          </span>
        </td>
        <td className={styles.tableCell}>
          {item.unavailable ? <Tooltip tooltipContent={item.unavailable} child={clipString(item.unavailable, 19)} /> : "Available"}
        </td>
        <td className={styles.tableCell}>{item.reason ? <Tooltip tooltipContent={"Assigned - " + item.reason} child={clipString("Assigned - " + item.reason, 27)} /> : "N/A"}</td>
        <td className={styles.tableCell}>{item.model || "N/A"}</td>
      </tr>
    );
  };

  return (
    <table style={{ width: "100%" }}>
      <thead style={{ width: "100%" }}>
        <tr style={{ width: "100%", cursor: "pointer" }} onClick={handleAllCheckboxChange}>
          <th className={styles.tableHead} style={{ width: "10%", textAlign: "center" }}>
            <input
              type="checkbox"
              checked={data.length !== 0 && selected.length === (data.length - data.filter(d => d?.completelyUnavailable).length)}
              onChange={handleAllCheckboxChange}
            />
          </th>
          <th className={styles.tableHead} style={{ width: "15%" }}>Plant ID</th>
          <th className={styles.tableHead} style={{ width: "25%" }}>Unavailability Period</th>
          <th className={styles.tableHead} style={{ width: "35%" }}>Reason</th>
          <th className={styles.tableHead} style={{ width: "15%" }}>Subcategory</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => renderRow(item))}
      </tbody>
    </table>
  );
};

export default SelectAssetTable;
