import { AES, enc } from 'crypto-js';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import GuestLayout from '../../components/GuestLayout';
import LoginForm from './login-form';
import OTP from './otp';

let login_time;

const openDocument = async (documentName, type) => {
  try {
    // Replace 'document.pdf' with the actual name of your document file
    const response = await fetch(`/api/documents/${documentName}/${type}`, {
      method: 'GET',
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // Open the document in a new tab
      window.open(url, '_blank');
    } else {
      console.log('Error loading document');
    }
  } catch (error) {
    console.log('Error:', error);
  }
};

const KEY =
  'zYnKiNgarFJabKTVoqy4egfR8LYDNW4jsyqbvDvPRcZZ3zhIxRtLC7RcfEykxujx4jJgyS0ICQoUlWaf';

const Login = () => {
  const [state, setState] = useState('login'); // ['login', 'otp', 'forgot-password']

  // TODO: use `useSmartReducer` once that branch is merged to main
  const [email, setEmail] = useState(Cookies.get('login') || '');
  const [password, setPassword] = useState(
    Cookies.get('password')
      ? AES.decrypt(Cookies.get('password'), KEY).toString(enc.Utf8)
      : ''
  );
  const [rememberMe, setRememberMe] = useState(
    Cookies.get('login') ? true : false
  );

  return (
    <GuestLayout>
      <section className="before_login_screen">
        <Helmet>
          <title>Login | iMOS</title>
        </Helmet>
        <div className="before_main_login_box">
          <div className="before_logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="login_box">
            {state === 'login' ? (
              <LoginForm
                KEY={KEY}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                rememberMe={rememberMe}
                login_time={login_time}
                setRememberMe={setRememberMe}
                onClickContinue={() => setState('otp')}
              />
            ) : state === 'otp' ? (
              <OTP
                KEY={KEY}
                login_time={login_time}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                rememberMe={rememberMe}
                onClickPrevious={() => setState('login')}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="before_login_footer_menu">
            <ul>
              <li>
                <Link to='/' onClick={() => openDocument('Privacy-Policy', 'pdf')}>Privacy Policy</Link>
              </li>
              <li>
                <Link to='/' onClick={() => openDocument('iMOS Terms and Conditions', 'pdf')} > Terms of Service</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};
export { login_time };
export default Login;
