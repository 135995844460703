import React, { useState } from 'react'
import TruckShiftDetails from '../../../TruckShiftDetails/TruckShiftDetails';
import ExcavatorShiftDetails from '../../../ExcavatorShiftDetails/ExcavatorShiftDetails';
import styles from '../EquimentPlan.module.css'
import RemovedAssetTableRow from '../../RemovedAsset/RemovedAssetTablRow';
import { useAddDailyPlan } from '../../../../Context/context';

export default function RemovedEquipments({
    handleReassignRemovedAsset,
    searchQuery,
    removedSectionRef
}) {
    const {
        removedEquipments,
        removedEquipmentFlyerOpen,
        canEditShift,
        shiftStatus,
        openFlyer,
        closeFlyer,
        assignedExcavatorDetailsFormData
    } = useAddDailyPlan();
    return (
        <div ref={removedSectionRef}>
            <div className={styles.heading} style={{ background: "#F0F0F0" }}>
                <span>Removed Equipment</span>
            </div>
            {removedEquipments.map((e, idx) => {
                return (
                    <RemovedAssetTableRow
                        key={idx}
                        canEditShift={canEditShift}
                        searchQuery={searchQuery}
                        onViewDetails={() => openFlyer("removedEquipment", idx)}
                        data={e}
                        handleReassignRemovedAsset={handleReassignRemovedAsset}
                        status={shiftStatus}
                        totalAssignedExcavators={assignedExcavatorDetailsFormData?.length}
                    />
                );
            })}
            {
                removedEquipmentFlyerOpen != -1 && (removedEquipments[removedEquipmentFlyerOpen]?.category == "Excavator" ? (
                    <ExcavatorShiftDetails
                        notEditable={true}
                        singleAssignedExcavatorDetailsFormData={removedEquipments[removedEquipmentFlyerOpen]}
                        isOpen={removedEquipmentFlyerOpen + 1}
                        onClose={() => closeFlyer("removedEquipment")}
                    />
                )
                    :
                    (<TruckShiftDetails
                        notEditable={true}
                        assignedTruckDetailsFormData={removedEquipments[removedEquipmentFlyerOpen]}
                        isOpen={removedEquipmentFlyerOpen + 1}
                        onClose={() => closeFlyer("removedEquipment")}
                    />
                    ))
            }

        </div>
    )
}
