import { GoAlert } from "react-icons/go";
import { Image } from "react-bootstrap";
import { IoMdRemoveCircle } from "react-icons/io";
import React from "react";

export default function Button({
  selected,
  alert,
  hold,
  onClick,
  label,
  disabled,
  isOperating,
  refComp,
  idRef,
  style,
  className
}) {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        background: " #F3F7FE 0% 0% no-repeat padding-box",
        borderRadius: "5px",
        boxShadow: "none",
        width: "10rem",
        height: "3rem",
        cursor: "pointer",
        font: "normal normal 600 17px/24px Open Sans",
        fontSize: "1rem",
        margin: "0 1rem",
        overflow: "hidden",
        border: "1px solid " + (selected ? "#186FD9" : "transparent"),
        ...style,
      }}
    >
      {(alert || hold) && (
        <>
          {hold ? (
            <Image
              src={require("../assets/images/not-available.png")}
              height={28}
              width={28}
              style={{ marginRight: -1, marginTop: 2 }}
            // style={{ marginLeft: -10 }}
            />
          ) : (
            <Image
              src={require("../assets/images/alert.png")}
              height={18}
              width={18}
              style={{ margin: "0 5px" }}
            // style={{ marginLeft: -10 }}
            />
          )}
        </>
      )}
      <span className="mx-1">{label}</span>
    </div>
  );
}
