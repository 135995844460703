import React, { useEffect, useState } from "react";
import styles from "../../EquimentPlan.module.css";
import { formateDataForView, getTargetLoads } from "../../../../../utils/helper";
import { DateTimeFormats, SelectAssetTypes, ShiftStatus } from "../../../../../utils/contants";
import EquipmentRow from "./components/EquipmentDataRow";
import ErrorAlert from "../../../../../components/ErrorAlert/ErrorAlert";
import ConfirmAlert from "../../../../../components/ConfirmAlert/ConfirmAlert";
import ReassignTrucks from "../../../../ReassignTrucks/ReassignTrucks";
import { createHaulTruckDetailFromAssignment } from "../../utils/helper";
import moment from "moment";
import { useAddDailyPlan } from "../../../../../Context/context";

const EquipmentPlanTableRow = ({
  excavatorViewOnly,
  index,
  searchQuery,
  onTruckAddClick,
}) => {
  const {
    originalData,
    shiftStatus,
    assignedExcavatorDetailsFormData,
    setAssignedExcavatorDetailsFormData,
    canEditShift,
    startSession,
    stopFetching,
    setRemovedEquipments,
    generalShiftPlanDetailsFormData,
    originalShiftData

  } = useAddDailyPlan()

  const [expanded, setExpanded] = useState(excavatorViewOnly?.plantId);
  const [showConfirmRemove, setShowConfirmRemove] = useState({ show: false, title: "", message: "", truckID: '' });
  const [showConfirmReassignTrucks, setShowConfirmReassignTrucks] = useState({ show: false, title: "", message: "", truck: '' });
  const [showError, setShowError] = useState({ show: false, title: '', message: '' });
  const [showReassignTrucks, setShowReassignTrucks] = useState([])

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpanded(prev => prev === excavatorViewOnly?.plantId ? null : excavatorViewOnly?.plantId);
  };

  const handleRemoveTruck = async (truckIdx) => {
    if (shiftStatus != ShiftStatus.NEW) {
      try {
        await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
      } catch (error) {
        return
      }
    }
    const truckEquipmentId = assignedExcavatorDetailsFormData[index]?.excavatorHaulTrucks[truckIdx]?.value;
    let removedData;
    setAssignedExcavatorDetailsFormData((prev) =>
      prev.map((ex, idx) => {
        if (idx !== index) return ex;

        const { shiftPlans, excavatorHaulTrucks } = ex;

        const originalAssignedEx = originalShiftData?.assignedExcavators?.find(exAssign =>
          exAssign?.shiftPlans?.some(shiftPlan =>
            shiftPlan?.haulTrucks?.some(ht => ht.equipment === truckEquipmentId)
          )
        );

        if (originalAssignedEx) {
          const targetShiftPlans = originalAssignedEx.shiftPlans
            .map(plan => {
              const haulTruck = plan?.haulTrucks?.find(ht => ht.equipment === truckEquipmentId) || {};
              return { ...haulTruck, targetLoads: haulTruck?.actualLoads };
            })
            .filter(plan => plan.assignmentId);

          if (targetShiftPlans.length) {
            removedData = {
              ...targetShiftPlans[0],
              targetLoads: targetShiftPlans[0]?.actualLoads || 0,
              shiftPlans: targetShiftPlans
            };
          }
        }
        else {
          const removedEquipment = originalShiftData?.removedEquipments?.find(eqAssign =>
            eqAssign.equipment === truckEquipmentId
          );
          if (removedEquipment) {
            removedData = removedEquipment;
          }
        }

        const filterTrucks = (trucks) => trucks?.filter((ht) => ht.equipment !== truckEquipmentId);
        const updateTargetLoads = (trucks, exData) => trucks.map((ht) => ({
          ...ht,
          targetLoads: (ht?.actualLoads || 0) + getTargetLoads(exData, trucks),
        }));

        const updatedShiftPlans = (shiftPlans || []).map((plan) => (((shiftStatus == ShiftStatus.ONGOING || shiftStatus == ShiftStatus.PUSBLISHED) && (plan?.status == ShiftStatus.ENDED || moment(plan.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 10)) ? { ...plan } : {
          ...plan,
          haulTrucks: updateTargetLoads(filterTrucks(plan.haulTrucks), plan),
        }));

        return {
          ...ex,
          excavatorHaulTrucks: excavatorHaulTrucks.filter((ht) => ht.value !== truckEquipmentId),
          haulTrucks: updatedShiftPlans?.find((plan) => plan.plan == ex.plan)?.haulTrucks,
          shiftPlans: updatedShiftPlans,
        };
      })
    );

    if (removedData)
      setRemovedEquipments((prev) => [...prev, removedData]);
  };

  const handleRemoveExcavator = async () => {
    if (shiftStatus != ShiftStatus.NEW) {
      try {
        await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
      } catch (error) {
        return
      }
    }
    let removedData;
    setAssignedExcavatorDetailsFormData(prev => prev.filter((ex, idx) => {
      if (idx !== index) {
        return true;
      }

      if (shiftStatus === ShiftStatus.NEW) {
        return false;
      }

      const unTouchedExData = originalData.find(od => od.plantId === ex.plantId);

      if (!unTouchedExData) {
        return false;
      }
      const removedEquipment = originalShiftData?.removedEquipments?.find(eqAssign =>
        eqAssign.equipment === ex.equipment
      );

      if (removedEquipment) {
        removedData = removedEquipment;
        return false;
      }

      const calculateProductiveHours = (startTime, endTime, referenceTime, defaultProductiveHours = 0) => {
        const startMoment = moment(startTime, DateTimeFormats.DATE_TIME_U);
        const endMoment = moment(endTime, DateTimeFormats.DATE_TIME_U);

        const totalDuration = endMoment.diff(startMoment, 'hours', true);
        const elapsedDuration = referenceTime.diff(startMoment, 'hours', true);

        const productiveHours = unTouchedExData?.productiveHours?.value ?? defaultProductiveHours;

        // Ensure the result is non-negative
        if (elapsedDuration < 0) {
          return 0;
        }

        return Math.floor((elapsedDuration / totalDuration) * productiveHours);
      };

      const productiveHours = calculateProductiveHours(unTouchedExData.startTime, unTouchedExData.endTime, moment());
      const targetBCMs = (unTouchedExData.bcmPerHour ?? 0) * productiveHours;

      const updatedShiftPlans = unTouchedExData?.shiftPlans?.map(shiftPlan => {
        //!Some Scenerios Possible
        const planProductiveHours = calculateProductiveHours(shiftPlan.startTime, shiftPlan.endTime, moment());
        const plantTargetBCMs = (unTouchedExData.bcmPerHour ?? 0) * planProductiveHours;

        return { ...shiftPlan, targetBCMs: plantTargetBCMs };
      });
      removedData = {
        ...unTouchedExData,
        targetBCMs,
        shiftPlans: updatedShiftPlans
      }

      return false;
    }));
    if (removedData)
      setRemovedEquipments((prev) => [...prev, removedData])
  };

  const handleReassignTrucks = async (selectedExcavatorPlantId, selectedTrucks) => {
    if (shiftStatus !== ShiftStatus.NEW) {
      try {
        await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus);
      } catch (error) {
        return;
      }
    }

    const selectedExcavatorIndex = assignedExcavatorDetailsFormData.findIndex(
      (ex) => ex.plantId === selectedExcavatorPlantId
    );

    const isPastPlan = (plan) => {
      const isEndedOrEndingSoon = (plan?.status === ShiftStatus.ENDED || moment(plan.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 10);
      const isOngoingOrPublished = (shiftStatus === ShiftStatus.ONGOING || shiftStatus === ShiftStatus.PUSBLISHED);
      return isOngoingOrPublished && isEndedOrEndingSoon;
    };

    setAssignedExcavatorDetailsFormData((prev) => {
      const ExcavtorsDataUpdated = prev.map((ex) => {
        if (ex?.plantId === selectedExcavatorPlantId) {
          // Update haulTrucks in each shift plan
          const updatedShiftPlans = ex.shiftPlans.map((plan) => !isPastPlan(plan) ? ({
            ...plan,
            haulTrucks: [
              ...plan.haulTrucks,
              ...selectedTrucks.map((haulTruck) => createHaulTruckDetailFromAssignment({ plantId: haulTruck?.label, equipment: haulTruck?.value, bcmPerLoad: haulTruck?.bcmPerLoad }, ex)),
            ],
          }) : plan);

          return {
            ...ex,
            haulTrucks: updatedShiftPlans?.find((plan) => plan.plan === ex.plan)?.haulTrucks,
            excavatorHaulTrucks: [...ex?.excavatorHaulTrucks, ...selectedTrucks],
            shiftPlans: updatedShiftPlans,
          };
        } else if (ex?.plantId === excavatorViewOnly.plantId) {
          // Remove selected trucks from both haulTrucks and shiftPlans
          return {
            ...ex,
            haulTrucks: isPastPlan(ex) ? ex.haulTrucks : ex.haulTrucks.filter((ht) => {
              return selectedTrucks.findIndex((selectedHT) => selectedHT.label === ht.plantId) === -1;
            }),
            removedHaulTrucks: isPastPlan(ex) ? ex.removedHaulTrucks : ex.removedHaulTrucks.filter((ht) => {
              return selectedTrucks.findIndex((selectedHT) => selectedHT.label === ht.plantId) === -1;
            }),

            excavatorHaulTrucks: ex.excavatorHaulTrucks.filter((ht) => {
              return selectedTrucks.findIndex((selectedHT) => selectedHT.label === ht.label) === -1;
            }),
            shiftPlans: ex.shiftPlans.map((plan) => isPastPlan(plan) ? plan : ({
              ...plan,
              haulTrucks: plan.haulTrucks.filter((ht) => {
                return selectedTrucks.findIndex((selectedHT) => selectedHT.label === ht.plantId) === -1;
              }),
              removedHaulTrucks: plan.removedHaulTrucks.filter((ht) => {
                return selectedTrucks.findIndex((selectedHT) => selectedHT.label === ht.plantId) === -1;
              }),
            })),
          };
        } else {
          return ex;
        }
      });
      console.log(ExcavtorsDataUpdated);

      return ExcavtorsDataUpdated.map((ex, i) =>
        i !== selectedExcavatorIndex
          ? ex
          : {
            ...ex,
            haulTrucks: ex?.haulTrucks?.map((ht) => ({
              ...ht, targetLoads: (ht?.actualLoads ?? 0) + getTargetLoads(
                ex,
                ex?.haulTrucks
              )
            })),
            shiftPlans: ex.shiftPlans.map((plan) => isPastPlan(plan) ? plan : ({
              ...plan,
              haulTrucks: plan?.haulTrucks?.map((ht) => ({
                ...ht, targetLoads: (ht?.actualLoads ?? 0) + getTargetLoads(
                  plan,
                  plan?.haulTrucks
                )
              })),
            })),
          }
      );
    });

    setShowReassignTrucks({
      show: false,
      selectedTruck: '',
    });
  };

  const handleReassignTruck = async (selectedExcavatorPlantId, selectedTruck) => {
    if (shiftStatus !== ShiftStatus.NEW) {
      if (!stopFetching) {
        try {
          await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus);
        } catch (error) {
          return;
        }
      }
    }

    const isPastShift = (shiftPlanOrExcavator) =>
      (shiftStatus === ShiftStatus.ONGOING || shiftStatus === ShiftStatus.PUSBLISHED) &&
      (shiftPlanOrExcavator.status === ShiftStatus.ENDED || moment(shiftPlanOrExcavator.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 10);

    const updateHaulTrucks = (excavator, haulTruck) => {

      const updatedShiftPlans = excavator.shiftPlans.map((plan) => {
        if (isPastShift(plan)) return plan;

        const updatedPlanHaulTrucks = [...plan.haulTrucks, createHaulTruckDetailFromAssignment({ plantId: haulTruck?.label, equipment: haulTruck?.value, bcmPerLoad: haulTruck?.bcmPerLoad }, plan)];
        const planTargetLoads = getTargetLoads(plan, updatedPlanHaulTrucks);

        return {
          ...plan,
          haulTrucks: updatedPlanHaulTrucks.map((ht) => ({ ...ht, targetLoads: (ht?.actualLoads || 0) + planTargetLoads }))
        };
      });

      return {
        ...excavator,
        haulTrucks: updatedShiftPlans?.find((plan) => plan.plan == excavator.plan)?.haulTrucks,
        excavatorHaulTrucks: [...excavator?.excavatorHaulTrucks, { label: haulTruck?.label, value: haulTruck?.value, bcmPerLoad: haulTruck?.bcmPerLoad }],
        shiftPlans: updatedShiftPlans
      };
    };

    setAssignedExcavatorDetailsFormData((prev) => {
      const updatedData = prev.map((ex) => {
        if (ex.plantId === selectedExcavatorPlantId) {
          return updateHaulTrucks(ex, selectedTruck);
        } else if (ex.plantId === excavatorViewOnly.plantId) {

          const updatedShiftPlans = ex.shiftPlans.map((plan) => {
            if (isPastShift(plan)) return plan; // Skip updating if it's a past plan

            const updatedPlanHaulTrucks = plan.haulTrucks.filter((ht) => ht.plantId !== selectedTruck.label);
            const removedPlanHaulTrucks = plan.removedHaulTrucks.filter((ht) => ht.plantId !== selectedTruck.label);
            const planTargetLoads = getTargetLoads(plan, updatedPlanHaulTrucks);
            return {
              ...plan,
              haulTrucks: updatedPlanHaulTrucks.map((ht) => ({ ...ht, targetLoads: (ht?.actualLoads || 0) + planTargetLoads })),
              removedHaulTrucks: removedPlanHaulTrucks
            };
          });

          return {
            ...ex,
            excavatorHaulTrucks: ex?.excavatorHaulTrucks?.filter((ht) => ht?.value !== selectedTruck.value),
            haulTrucks: updatedShiftPlans?.find((plan) => plan.plan == ex.plan)?.haulTrucks,
            removedHaulTrucks: updatedShiftPlans?.find((plan) => plan.plan == ex.plan)?.removedHaulTrucks,
            shiftPlans: updatedShiftPlans
          };
        }
        return ex;
      });

      return updatedData;
    });

    setShowReassignTrucks({
      show: false,
      selectedTruck: '',
    });
  };


  useEffect(() => {
    const checkQueryToExpand = (query) => {
      let count = 0;
      query = query.toLowerCase();
      const checkMatch = (value) => {
        if (value && query) {
          value = value.toLowerCase();
          const regex = new RegExp(query, 'g');
          const matches = value.match(regex);
          if (matches) {
            count = count + matches.length;
          }
        }
      };
      assignedExcavatorDetailsFormData[index]?.haulTrucks.forEach(truck => {
        const formatedTruckData = formateDataForView(truck);
        checkMatch(formatedTruckData.plantId);
        checkMatch(formatedTruckData.category);
        checkMatch(formatedTruckData.material.label);
        checkMatch(formatedTruckData.operator);
        checkMatch(formatedTruckData.assistantOperator);
        checkMatch(formatedTruckData.trainee);
        checkMatch(formatedTruckData.sourceArea.label);
        checkMatch(formatedTruckData.dumpArea);
      });
      return count > 0;
    };

    if (checkQueryToExpand(searchQuery) && searchQuery !== "") {
      setExpanded(excavatorViewOnly?.plantId);
    }
  }, [searchQuery]);

  return (
    <>
      {showConfirmRemove.show && canEditShift && (
        <ConfirmAlert
          title={showConfirmRemove.title}
          message={showConfirmRemove.message}
          onClose={() => {
            setShowConfirmRemove({
              show: false,
              title: "",
              message: "",
              truckID: '',
            });
          }}
          onConfirm={() => {
            if (showConfirmRemove.truckID !== '') {
              handleRemoveTruck(showConfirmRemove.truckID);
            } else {
              handleRemoveExcavator(index);
            }
            setShowConfirmRemove({
              show: false,
              title: "",
              message: "",
              truckID: '',
            });
          }}
        />
      )}

      {showConfirmReassignTrucks.show && canEditShift && assignedExcavatorDetailsFormData.length > 1 && (
        <ConfirmAlert
          title={showConfirmReassignTrucks.title}
          message={showConfirmReassignTrucks.message}
          onClose={() => {
            setShowConfirmReassignTrucks({
              show: false,
              title: "",
              message: "",
              truck: '',
            });
          }}
          onConfirm={() => {
            if (showConfirmReassignTrucks.truck !== '') {
              if (assignedExcavatorDetailsFormData.length == 2) {
                handleReassignTruck(assignedExcavatorDetailsFormData[index == 0 ? 1 : 0]?.plantId, showConfirmReassignTrucks.truck)
              }
              else
                setShowReassignTrucks({ show: true, selectedTruck: showConfirmReassignTrucks.truck })
            } else {
              setShowReassignTrucks({ show: true, selectedTruck: '' })
            }
            setShowConfirmReassignTrucks({
              show: false,
              title: "",
              message: "",
              truck: '',
            });
          }}
        />
      )}

      {
        showConfirmReassignTrucks.show &&
        canEditShift &&
        assignedExcavatorDetailsFormData.length <= 1 && (
          <ErrorAlert
            title={"Error Reassigning Trucks!"}
            message={
              <div style={{ lineHeight: 1.25 }} className="d-flex flex-column px-5 mx-5 ">
                <span> There are no excavators to reassign trucks{" "}
                  <span style={{ fontWeight: '700' }}>
                    {showConfirmReassignTrucks?.truck?.label ||
                      assignedExcavatorDetailsFormData[index]?.excavatorHaulTrucks
                        ?.map((v) => v?.label)
                        .join(" , ")}
                  </span>
                  .</span>
                <span> Please add a new excavator before you attempt to reassign these
                  trucks again.</span>
              </div>
            }
            onClose={() => {
              setShowConfirmReassignTrucks({
                show: false,
                title: "",
                message: "",
                truck: "",
              });
            }}
          />
        )
      }

      {
        showReassignTrucks.show && canEditShift && (
          <ReassignTrucks
            onClose={() => setShowReassignTrucks({ show: false, selectedTruck: "" })}
            truck={
              showReassignTrucks.selectedTruck === ""
                ? false
                : showReassignTrucks.selectedTruck
            }
            trucks={assignedExcavatorDetailsFormData[index].excavatorHaulTrucks}
            excavators={assignedExcavatorDetailsFormData.filter(
              (ex) => ex.plantId !== excavatorViewOnly.plantId
            )}
            handleReassignTrucks={handleReassignTrucks}
            handleReassignTruck={handleReassignTruck}
          />
        )
      }

      {showError.show && <ErrorAlert title={showError.title} message={showError.message} onClose={() => {
        setShowError({ show: false, title: '', message: '' })
      }} />}

      <EquipmentRow
        showOperatorWarning={assignedExcavatorDetailsFormData[index]?.shiftPlans?.some((shiftPlan) => !shiftPlan.operator && shiftPlan.isOperatorSwapped)}
        equipmentViewOnlyData={formateDataForView(excavatorViewOnly)}
        type={SelectAssetTypes.EXCAVATORS}
        onExpand={handleExpand}
        index={index}
        rowIndex={index}
        handleRemoveAsset={handleRemoveExcavator}
        setShowError={setShowError}
        setShowConfirmRemove={setShowConfirmRemove}
        setShowConfirmReassignTrucks={setShowConfirmReassignTrucks}
        onTruckAddClick={(data) => {
          onTruckAddClick(data)
          setExpanded(excavatorViewOnly?.plantId)
        }}
        searchQuery={searchQuery}
        expanded={expanded}
      />
      {expanded && (
        <div className={styles.truckList}>
          {assignedExcavatorDetailsFormData[index].excavatorHaulTrucks.map((exHaulTruck, idx) => {
            const haulTruck = assignedExcavatorDetailsFormData[index]?.haulTrucks?.find((ht) => ht.equipment == exHaulTruck.value)
            return <EquipmentRow
              showOperatorWarning={assignedExcavatorDetailsFormData[index]?.shiftPlans?.some((shiftPlan) => shiftPlan?.haulTrucks?.some((ht) => ht?.equipment == exHaulTruck?.value && !ht.operator && ht.isOperatorSwapped))}
              key={haulTruck?.plantId || exHaulTruck?.label}
              equipmentViewOnlyData={formateDataForView(haulTruck || { category: "Truck", plantId: exHaulTruck?.label, equipment: exHaulTruck.value })}
              type={SelectAssetTypes.TRUCKS}
              index={index}
              rowIndex={idx}
              isDisabled={!haulTruck}
              handleRemoveAsset={handleRemoveTruck}
              setShowError={setShowError}
              setShowConfirmRemove={setShowConfirmRemove}
              setShowConfirmReassignTrucks={setShowConfirmReassignTrucks}
              searchQuery={searchQuery}
              expanded={expanded}
            />
          })}
        </div>
      )}
    </>
  );
};

export default EquipmentPlanTableRow;
