import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import AssetPerformance from './AssetPerformance/AssetPerformance';
// import AssetPerformance from '../Dashboard/Dashboard';
import './style.css';
import DumpCycleTracker from './DumpCycleTracker/DumpCycleTracker';
import LoadingScreen from '../../components/LoadingScreen';
import DashboardContext from './Context/DashboardContext';

export default function DashboardComponent() {
    const { loading, } = useContext(DashboardContext)
    const options = ['Asset Performance', 'Dump Cycle Tracker'];
    const [activeOption, setActiveOption] = useState('Asset Performance');

    return loading ? <LoadingScreen />
        : <div style={{ backgroundColor: "#FFF" }} className="right_sidebar_body" id="main">
            <nav style={{ padding: '2rem 2rem 0 2rem', borderBottom: '1px solid #E1E8F4' }}>
                <ul className="shift-history-menu">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className={clsx(
                                'shift-history-item',
                                option === activeOption ? 'active' : ''
                            )}
                            onClick={() => setActiveOption(option)}
                            style={{ cursor: 'pointer' }}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            </nav>
            {activeOption === 'Asset Performance' && <AssetPerformance />}
            {activeOption === 'Dump Cycle Tracker' && <DumpCycleTracker />}
        </div>
}
