import React from 'react';
import TrendArrow from './TrendArrow';

const Progress_bar = ({
  height,
  width,
  progress,
  unScheduled,
  isNotOperating,
  notCatching,
  fallingBehind,
  color
}) => {
  const Parentdiv = {
    width: width || '100%',
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    height: height,
    backgroundColor: 'transparent',
    zIndex: 10,
    borderWidth: 1,
    position: 'relative',
    borderColor: 'yellow',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };

  const Childdiv = {
    height: progress < 5 ? '50%' : progress < 10 ? '70%' : '100%',
    width: `${Math.min((progress / 125) * 100, 100)}%`,
    backgroundColor: color ? '#797FCC' : unScheduled
      ? '#707070'
      : progress < 100
        ? '#ED7C7F'
        : '#95C365',
    borderRadius: 50,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    left: 0,
  };

  return (
    <div style={Parentdiv} className="border">
      {/* {!props.unSheduled && ( */}
      <>
        <div style={Childdiv}></div>

        {!unScheduled && !isNotOperating && <TrendArrow backward={notCatching && fallingBehind} />}

        {!unScheduled && (
          <div
            style={{
              height: height - 2,
              backgroundColor: 'black',
              width: '3px',
              position: 'absolute',
              left: width - width / 5 - 5 >= 0 ? width - width / 5 - 3 : 0,
            }}
          ></div>
        )}
      </>
      {/* )} */}
    </div>
  );
};

export default Progress_bar;
