import React, { useEffect, useState } from 'react';

export default function CreateDumpPointModal(props) {
  const { created, dumpPoint, onCancel, onSubmit } = props;
  const [newDumpPoint, setNewDumpPoint] = useState(dumpPoint);

  useEffect(() => {
    setNewDumpPoint(dumpPoint);
  }, [dumpPoint]);

  if (!created && !dumpPoint.name) return <></>;
  return (
    <div
      style={{
        top: '50px',
        left: '20px',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '520px',
        height: '400px',
        padding: '28px 30px',
        background: ' #FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: ' 0px 3px 6px #00000029',
        borderRadius: '10px',
        zIndex: 2,
      }}
    >
      <div>
        <div
          style={{
            font: 'normal normal 600 14px/19px Open Sans',
            paddingBottom: '10px',
            borderBottom: '1px solid #DDE5F3',
            width: '100%',
          }}
        >
          {dumpPoint.name ? <span>EDIT</span> : <span>CREATE</span>} DUMP POINT
          GEOFENCE
        </div>
        <div style={{ marginTop: '25px' }}>
          <div className="d-flex justify-content-between my-5">
            <label
              style={{
                font: 'normal normal normal 14px/19px Open Sans',
                marginTop: '6px',
              }}
            >
              Name
            </label>
            <input
              style={{
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                border: '1px solid #E1E8F4',
                borderRadius: 4,
                height: 35,
                width: 340,
                font: 'normal normal normal 13px/20px Open Sans',
                padding: '12px',
              }}
              value={newDumpPoint.name}
              onChange={(e) =>
                setNewDumpPoint({ ...newDumpPoint, name: e.target.value })
              }
              type="text"
            />
          </div>
          <div className="d-flex justify-content-between my-5">
            <label
              style={{
                font: 'normal normal normal 14px/19px Open Sans',
                marginTop: '6px',
              }}
            >
              Description
            </label>
            <textarea
              style={{
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                border: '1px solid #E1E8F4',
                borderRadius: 4,
                height: 120,
                width: 340,
                resize: 'none',
                padding: '10px',
                font: 'normal normal normal 13px/20px Open Sans',
              }}
              value={newDumpPoint.description}
              onChange={(e) =>
                setNewDumpPoint({
                  ...newDumpPoint,
                  description: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ width: '100%' }}>
        <button
          style={{
            width: 80,
            height: 25,
            font: 'normal normal bold 12px/17px Open Sans',
            color: '#186FD9',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            border: ' 1px solid #186FD9',
            borderRadius: 4,
          }}
          className="btn discard"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="btn save ml-3"
          onClick={() => onSubmit(newDumpPoint)}
          style={{
            width: 80,
            height: 25,
            font: 'normal normal bold 12px/17px Open Sans',
            border: ' 1px solid #186FD9',
            background: '#186FD9 0% 0% no-repeat padding-box',
            borderRadius: 4,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
