import React, { useState, useRef, useEffect } from 'react';
import './style.css';

const ToggleArrow = ({ options, label, className, showDropdown, headerType, selectedSort }) => {
  const [dropdownWidth, setDropdownWidth] = useState(200);
  const menuRef = useRef(null);

  useEffect(() => {
    if (!options || options.length === 0) return;
    
    const tempElement = document.createElement('div');
    tempElement.style.visibility = 'hidden';
    tempElement.style.position = 'absolute';
    tempElement.style.whiteSpace = 'nowrap';
    document.body.appendChild(tempElement);

    const maxWidth = options.reduce((max, { name }) => {
      tempElement.textContent = name;
      return Math.max(max, tempElement.offsetWidth + 40);
    }, 200);

    document.body.removeChild(tempElement);
    setDropdownWidth(Math.min(maxWidth, 300));
  }, [options]);

  const getSelectedIcon = () => {
    const getSortIcon = (direction) => (
      <svg   style={{ transform: direction === "up"?"translateY(-1px)":"translateY(-2px)" }} width="20" height="20" viewBox="0 0 10 10" className={direction === "up" ? "" : "mb-1"}>
        <polygon points={direction === "up" ? "5,2 8,6 2,6" : "5,8 8,4 2,4"} fill={`${direction ? "#000000":"#666"}`} />
      </svg>
    );

    if (headerType === "User Name" && selectedSort?.sortName) 
      return getSortIcon(selectedSort.sortName === "1" ? "up" : (selectedSort.sortName === "-1"? "down":""));

    if (headerType === "Status") {
      const statusIcons = { A: "active", I: "inactive", P: "invited" };
      return <span className={`status-icon ${statusIcons[selectedSort.sortStatus] || "all"}`}></span>;
    }

    if (headerType === "Added By" && selectedSort?.sortAddedBy)
      return getSortIcon(selectedSort.sortAddedBy === "1" ? "up" : (selectedSort.sortAddedBy === "-1"? "down":""));

    return null;
  };

  return (
    <div ref={menuRef}>
      <div className={`${className} toggleArrowButton`}>
        <span>{label}</span>
        {getSelectedIcon() || (
          <svg width="20" height="20" viewBox="0 0 10 10" className={showDropdown ? "" : "downArrowSvg"}>
            <polygon points={showDropdown ? "5,2 8,6 2,6" : "5,8 8,4 2,4"} fill="#666" />
          </svg>
        )}
      </div>
      
      {showDropdown && (
        <div className="toggleArrowOptionsContainer" style={{ width: dropdownWidth }}>
          {options?.map(({ name, disabled, onClick, arrowIcon, icon }, index) => (
            <div key={index} className={`toggleArrowOption ${disabled ? "toggleArrowOptionDisabled" : ""}`} onClick={(e) => !disabled && onClick(e)}>
              {arrowIcon && <span className="optionArrowIcon">{arrowIcon}</span>}
              {icon && <span className="optionIcon">{icon}</span>}
              {name && <span className="optionLabel ml-2">{name}</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


export default ToggleArrow;
