import React, { useState } from "react";
import styles from "../EquipmentPlan/EquimentPlan.module.css";
import Kebab from "../../../../../assets/images/kebab.png";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { clipString, formateDataForView, getShiftStatus, highlightText } from "../../../utils/helper";
import ActionMenu from '../../../components/ActionMenu/ActionMenu';
import ConfirmAlert from "../../../components/ConfirmAlert/ConfirmAlert";
import { toLower } from "lodash";
import { AssetCategories, ShiftStatus } from "../../../utils/contants";

const DataCell = ({ content, isBold = false, customStyles = {}, onClick }) => (
  <div
    className={styles.dataCell}
    onClick={onClick}
    style={{ cursor: onClick ? "pointer" : "default", fontWeight: isBold ? "bold" : "normal", ...customStyles }}
  >
    {content == 0 ? 0 : (content || "-")}
  </div>
);


const RemovedAssetTableRow = ({
  data: ObjData,
  handleReassignRemovedAsset,
  onViewDetails,
  status,
  searchQuery,
  canEditShift,
  totalAssignedExcavators
}) => {
  const [showConfirmReassign, setShowConfirmReassign] = useState({
    show: false,
    title: "",
    message: "", // It can also accept react component instead of string
  });

  const getOptions = () => {
    const options = [];
    options.push({ name: 'View Details', onClick: onViewDetails })
    if (canEditShift) {

      options.push({
        name: 'Reassign to Shift', onClick: () => {
          if (totalAssignedExcavators == 1) {
            handleReassignRemovedAsset(ObjData, true);
          }
          else
            setShowConfirmReassign({ show: true, title: `${data.plantId} - Reassign to shift`, message: `Are you sure you want to reassign this ${toLower(data.category)} back to the ${toLower(getShiftStatus(status))} shift?` })
        }
      })
    }
    options.push({ name: 'Download PDF', onClick: () => { }, disabled: true })
    options.push({ name: 'Download CSV', onClick: () => { }, disabled: true })
    return options;
  }

  const data = formateDataForView(ObjData)
  return (
    <>
      {showConfirmReassign.show && canEditShift && (
        <ConfirmAlert
          title={showConfirmReassign.title}
          message={showConfirmReassign.message}
          onClose={() => {
            setShowConfirmReassign({
              show: false,
              title: "",
              message: "",
            });
          }}
          onConfirm={() => {
            handleReassignRemovedAsset(ObjData);
            setShowConfirmReassign({
              show: false,
              title: "",
              message: "",
            });
          }}
        />
      )}
      <div onClick={onViewDetails} tabIndex={0}
        style={{
          outline: "none", backgroundColor: "#efefefd2"
        }}
        className={`${styles.tableRow} ${styles.tableTruckRow}`}
      >
        <DataCell
          content={
            <div className="d-flex align-items-center">
              <div style={{ cursor: 'pointer', width: '100%' }}>{highlightText(data?.plantId, searchQuery)}</div>
            </div>
          }
          isBold
        />
        <DataCell
          customStyles={{ cursor: 'pointer' }}
          content={
            <Tooltip tooltipContent={highlightText(data.operator, searchQuery)} child={highlightText(clipString(data.operator, 15), searchQuery)} />
          }
        />

        <DataCell
          customStyles={{ cursor: 'pointer' }}

          content={
            <Tooltip tooltipContent={highlightText(data.material, searchQuery)} child={highlightText(clipString(data.material, 15), searchQuery)} />
          }
        />
        <DataCell
          customStyles={{ cursor: 'pointer' }}

          content={
            <Tooltip tooltipContent={highlightText(data.sourceArea, searchQuery)} child={highlightText(clipString(data.sourceArea, 15), searchQuery)} />
          }
        />
        <DataCell
          customStyles={{ cursor: 'pointer' }}

          content={
            <Tooltip tooltipContent={highlightText(data.dumpArea, searchQuery)} child={highlightText(clipString(data.dumpArea, 15), searchQuery)} />
          }
        />
        <DataCell
          customStyles={{ cursor: 'pointer' }}
          content={data[data.category == AssetCategories.EXCAVATORS ? 'targetBCMs' : "targetLoads"] + (data.category != AssetCategories.EXCAVATORS ? " Loads" : " BCMs")} />
        <DataCell
          customStyles={{ cursor: 'pointer' }}
          content={data.category == AssetCategories?.EXCAVATORS ? "Excavator" : "Haul Truck"} />
        <div className={styles.actions}>
          <ActionMenu
            options={getOptions()}
          />
        </div>
      </div>
    </>
  );
};

export default RemovedAssetTableRow;
