import axios from "axios";
import { AES } from "crypto-js";
import Cookies from "js-cookie";
import { useRef, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";

import { CallWithOutAuth } from "../../action/apiActions";
import {
  REACT_APP_API_ENDPOINT,
  SEND_OTP,
  VERIFY_OTP,
} from "../../action/apiPath";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../utils/axios";
import OtpInput from "./otp-input";

export default function OTP({
  KEY,
  email,
  password,
  rememberMe,
  login_time,
  onClickPrevious,
}) {
  const otpInputRef = useRef(null);
  const { setAuth, setMaintainanceOngoing } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const submitHandler = async (otp) => {
    setLoading(true);
    setError(undefined);

    CallWithOutAuth("POST", VERIFY_OTP, {
      email: email,
      otp: otp,
    })
      .then(async (response) => {
        if (response.res.data.status !== 200) {
          setLoading(false);
          setError(response.res.data.message || "Something went wrong");
          return;
        }

        toast.success("Login successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLoading(false);
        setError(undefined);

        login_time = new Date();
        // const { data: geoLocationData } = await axios.get(
        //   'https://geolocation-db.com/json/'
        // );
        const geoLocationData = await axios.get(REACT_APP_API_ENDPOINT + "api/myip");
        console.log("my ip", geoLocationData?.data);

        // TODO: Remove sockets
        let socket = {};
        // socket.current = openSocket(
        //   REACT_APP_API_ENDPOINT,
        //   { path: '' },
        //   { query: { data: 'web' } }
        // );
        // socket.current.on('connect', () => {
        //   console.debug('socket.current.connected--', socket.current.connected); // true
        //   if (socket.current.connected) {
        //     // alert(response.res.data.data.result.userId)
        //     socket.current.emit('useradd', {
        //       id: response.res.data.data.result.userId,
        //       from: 'web',
        //       ip: geoLocationData?.data,
        //       // ip: geoLocationData.IPv4,
        //     });
        //     socket.current.on('useradded', (data) => {
        //       // console.log(data);
        //     });
        //     // localStorage.setItem('socket', socket);
        //   }
        // });
        if (response.res.data?.data?.userdata?.maintainance)
          setMaintainanceOngoing(true)

        setAuth({
          isAuthenticated: true,
          isInitialized: false,
          userId: response.res.data.data.result.userId,
          userData: response.res.data.data.userdata,
          roles: response.res.data.data.result.userType,
          socketData: socket,
          ipAddress: geoLocationData.data,
          // ipAddress: geoLocationData.IPv4,
        });

        axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.res.data.data.tokenData.token}`;
        localStorage.setItem(
          "accessToken",
          response.res.data.data.tokenData.token
        );

        Cookies.set("login", email, {
          expires: rememberMe ? 30 : 0,
        });
        Cookies.set("password", AES.encrypt(password, KEY).toString(), {
          expires: rememberMe ? 30 : 0,
        });

        navigate("/landing-page", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        setError("Something went wrong");
        console.error(error);
      });
  };

  return (
    <>
      <BiArrowBack
        onClick={onClickPrevious}
        size={24}
        style={{ cursor: "pointer" }}
      />

      <main
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <hgroup className="before_login_heading">
          <h1>OTP Verification</h1>
          <p>
            Please enter the OTP sent to <strong>{email}</strong>
          </p>
        </hgroup>

        <form
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <OtpInput
            onChange={(event) => {
              if (event.target.dataset.otp) {
                submitHandler(event.target.dataset.otp);
              }
            }}
            ref={otpInputRef}
            style={{ fontSize: "30px", marginBottom: 10, width: "80%" }}
          />
          <p style={{ color: "red", height: 40 }}>{error}</p>

          <button
            disabled={loading}
            onClick={() => {
              setLoading(true);
              otpInputRef.current.triggerClear();

              CallWithOutAuth("POST", SEND_OTP, {
                email: email,
                password: AES.encrypt(password, KEY).toString(),
              })
                .then(async (response) => {
                  if (response.res.data.status !== 200) {
                    setLoading(false);
                    setError(
                      response.res.data.message || "Something wennt wrong"
                    );
                    return;
                  }

                  toast.success("OTP re-sent successfully.", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                  setLoading(false);
                  setError(undefined);
                })
                .catch((error) => {
                  setLoading(false);
                  setError("Something went wrong");
                  console.error(error);
                });
            }}
            style={{
              background: "none",
              border: "none",
              color: "#186fd9",
              fontWeight: "bold",
              marginBottom: 60,
            }}
            type="reset"
          >
            Resend OTP
          </button>
        </form>
      </main>
    </>
  );
}

