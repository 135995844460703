import React, { } from "react";
import styles from "../../../EquimentPlan.module.css";
import rightArrow from "../../../../../../../../assets/svgs/rightArrow.svg";
import downArrow from "../../../../../../../../assets/svgs/downArrow.svg";
import Tooltip from "../../../../../../components/Tooltip/Tooltip";
import { clipString, highlightText, searchExist } from "../../../../../../utils/helper";
import { AssetCategories, SelectAssetTypes } from "../../../../../../utils/contants";
import RowActions from "./RowActions";
import { useAddDailyPlan } from "../../../../../../Context/context";
import { PiWarningCircleFill } from "react-icons/pi";

const DataCell = ({ content, isBold = false, customStyles = {}, onClick }) => (
    <div
        className={styles.dataCell}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "default", fontWeight: isBold ? "bold" : "normal", ...customStyles }}
    >
        {content ?? "-"}
    </div>
);

const EquipmentRow = ({
    equipmentViewOnlyData,
    type,
    onExpand,
    index,
    handleRemoveAsset,
    setShowError,
    setShowConfirmRemove,
    setShowConfirmReassignTrucks,
    onTruckAddClick,
    searchQuery,
    expanded,
    rowIndex,
    isDisabled,
    handleReassignTruck,
    showOperatorWarning
}) => {
    const {
        assignedExcavatorDetailsFormData,
        openFlyer,
        overlapFailures
    } = useAddDailyPlan()

    const handleClickViewDetails = () => {
        if (type === SelectAssetTypes.EXCAVATORS) {
            openFlyer('excavator', index);
        } else {
            openFlyer("truck", { id: equipmentViewOnlyData.equipment, idx: rowIndex, exIdx: index });
        }
    };
    return (
        <div onClick={handleClickViewDetails} tabIndex={0}
            style={{
                outline: "none", ...(isDisabled ? { backgroundColor: "#efefefd2" } : {})
            }}
            className={`${styles.tableRow} ${type === SelectAssetTypes.EXCAVATORS ? "" : styles.tableTruckRow}`}
        >
            <DataCell
                content={
                    <div style={type === SelectAssetTypes.EXCAVATORS ? { marginLeft: -20 } : {}} className="d-flex align-items-center">
                        {type === SelectAssetTypes.EXCAVATORS && equipmentViewOnlyData?.excavatorHaulTrucks !== "" && <img src={expanded === equipmentViewOnlyData?.plantId ? downArrow : rightArrow} alt="" style={{ height: "20px", width: "20px", cursor: 'pointer' }} onClick={(e) => {
                            e.stopPropagation()
                            onExpand(e)
                        }} />}

                        <div className="d-flex align-items-center" style={{ cursor: 'pointer', width: '100%' }}>{highlightText(equipmentViewOnlyData?.plantId, searchQuery)} {(showOperatorWarning || overlapFailures?.includes(equipmentViewOnlyData.plantId)) && <PiWarningCircleFill className="ml-1" fontSize={19} color="#BA3938" />}</div>

                    </div>
                }
                isBold
            />
            <DataCell
                customStyles={{ cursor: 'pointer' }}

                content={
                    <Tooltip tooltipContent={highlightText(equipmentViewOnlyData.operator, searchQuery)}
                        child={
                            highlightText(clipString(equipmentViewOnlyData.operator, 15), searchExist(equipmentViewOnlyData.operator, searchQuery, 15) ? clipString(equipmentViewOnlyData.operator, 15) : searchQuery)
                        } />
                }
            />

            <DataCell
                customStyles={{ cursor: 'pointer' }}

                content={
                    <Tooltip tooltipContent={highlightText(equipmentViewOnlyData.material, searchQuery)}
                        child={
                            highlightText(clipString(equipmentViewOnlyData.material, 15), searchExist(equipmentViewOnlyData.material, searchQuery, 15) ? clipString(equipmentViewOnlyData.material, 15) : searchQuery)
                        } />
                }
            />
            <DataCell
                customStyles={{ cursor: 'pointer' }}

                content={
                    <Tooltip tooltipContent={highlightText(equipmentViewOnlyData.sourceArea, searchQuery)}
                        child={
                            highlightText(clipString(equipmentViewOnlyData.sourceArea, 15), searchExist(equipmentViewOnlyData.sourceArea, searchQuery, 15) ? clipString(equipmentViewOnlyData.sourceArea, 15) : searchQuery)
                        } />
                }
            />
            <DataCell
                customStyles={{ cursor: 'pointer' }}

                content={
                    <Tooltip
                        tooltipContent={highlightText(equipmentViewOnlyData.dumpArea, searchQuery)}
                        child={
                            highlightText(clipString(equipmentViewOnlyData.dumpArea, 15), searchExist(equipmentViewOnlyData.dumpArea, searchQuery, 15) ? clipString(equipmentViewOnlyData.dumpArea, 15) : searchQuery)
                        }
                    />
                }
            />
            <DataCell
                customStyles={{ cursor: 'pointer' }}

                content={equipmentViewOnlyData[equipmentViewOnlyData.category == AssetCategories.EXCAVATORS ? 'targetBCMs' : "targetLoads"] != undefined ? (equipmentViewOnlyData[equipmentViewOnlyData.category == AssetCategories.EXCAVATORS ? 'targetBCMs' : "targetLoads"] + (equipmentViewOnlyData.category != AssetCategories.EXCAVATORS ? " Loads" : " BCMs")) : "-"} />
            <DataCell
                customStyles={{ cursor: 'pointer' }}
                content={highlightText(equipmentViewOnlyData.category == AssetCategories.EXCAVATORS ? equipmentViewOnlyData?.category : "Haul Truck", searchQuery)} />
            <RowActions
                index={index}
                rowIndex={rowIndex}
                equipmentViewOnlyData={equipmentViewOnlyData}
                isExcavator={type === SelectAssetTypes.EXCAVATORS}
                assignedExcavatorDetailsFormData={assignedExcavatorDetailsFormData}
                handleRemoveAsset={handleRemoveAsset}
                setShowError={setShowError}
                setShowConfirmRemove={setShowConfirmRemove}
                setShowConfirmReassignTrucks={setShowConfirmReassignTrucks}
                onTruckAddClick={onTruckAddClick}
                handleReassignTruck={handleReassignTruck}
                handleClickViewDetails={handleClickViewDetails}
            />
        </div>
    )
};

export default EquipmentRow;
