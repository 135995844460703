import { useEffect, useState, useRef } from 'react';
import { Image } from 'react-bootstrap';
import { BsVectorPen } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { CallWithAuth } from '../../action/apiActions';
import { SAVE_SOURCE_DUMP } from '../../action/apiPath';
import AuthLayout from '../../components/AuthLayout';
import ConfirmationPopup from './ConfirmationPopup';
import CreateDumpPointModal from './CreateDumpPointModal';
import { DumpPointsContext } from './DumpPointsContext';
import { FetchDumpPointsData } from './DumpPointsFetching';
import DumpPointsModal from './DumpPointsModal/DumpPointsModal';
import { MapContainer } from './Map/Map';
import MessagePopup from './MessagePopup';
import VehiclesModal from './VehiclesModal/VehiclesModal';
import plusIcon from '../../assets/svgs/plus.svg'
import minusIcon from '../../assets/svgs/minus.svg'


const SOURCE_DUMP_TEMPLATE = {
  name: '',
  description: '',
  coordinates: [],
};

const DumpPoints = () => {
  const dumpPointsData = FetchDumpPointsData();
  const {
    setIsDetailPageOpen,
    triggerFetch,
    setSelectedDumpPoint,

    setSelectedVehicle,
  } = dumpPointsData;

  const [showCreateDumpPointModal, setShowCreateDumpPointModal] =
    useState(false);
  const [created, setCreated] = useState(false);
  const [dumpPoint, setDumpPoint] = useState(SOURCE_DUMP_TEMPLATE);

  useEffect(() => {
    if (!showCreateDumpPointModal) {
      setCreated(false);
      setDumpPoint(SOURCE_DUMP_TEMPLATE);
    }
  }, [showCreateDumpPointModal]);

  const [popupConfirmation, setPopupConfirmation] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [showDumpPointsModal, setShowDumpPointsModal] = useState(false);
  const mapRef = useRef(undefined)
  const [isDumpPoints, setIsDumpPoints] = useState(true);
  useEffect(() => {
    if (isDumpPoints) {
      setSelectedVehicle({});
    } else {
      setSelectedDumpPoint({});
    }
  }, [isDumpPoints]);

  const [showVehiclesModal, setShowVehiclesModal] = useState(false);

  const submitHandler = async (values) => {
    if (!values.name || !values.description) {
      setPopupMessage('Fill in all fields of the form.');
      return;
    }
    try {
      const response = await CallWithAuth('POST', SAVE_SOURCE_DUMP, values);
      if (response.res.status == 201) {
        triggerFetch();
        setShowCreateDumpPointModal(false);
        setPopupMessage('Dump Point created!');
      } else throw response.res;
    } catch (error) {
      // console.log(error.message);
      toast.error('Server Error', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const cancelHandler = () => {
    setPopupConfirmation({
      title: 'Fill out the form or your changes won’t be saved.',
      description: 'Are you sure you want to cancel?',
      cancelText: 'Discard',
      confirmText: 'Fill Form',
      onCancel: () => {
        setPopupConfirmation('');
        setShowCreateDumpPointModal(false);
      },
      onConfirm: () => {
        setPopupConfirmation('');
      },
    });
  };

  return (
    <AuthLayout pageName="Dump Points">
      <DumpPointsContext.Provider value={{ ...dumpPointsData }}>
        <div className="right_sidebar_body">
          <div
            style={{
              alignItems: 'center',
              backgroundColor: '#DDDDDD',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              overflowY: 'hidden',
              height: '100%',
              objectFit: 'cover',
              position: 'relative',
            }}
          >
            <MapContainer
              createMode={showCreateDumpPointModal}
              created={created}
              setCreated={setCreated}
              setDumpPoint={setDumpPoint}
              viewDumpPoints={isDumpPoints}
              mapRef={mapRef}
            />
            <div
              style={{
                alignSelf: 'flex-start',
                display: 'flex',
                padding: '10px 20px',
                font: 'normal normal 600 14px/19px Open Sans',
                color: '#000000',
                position: 'absolute',
                zIndex: 1,
              }}
            >
              {isDumpPoints ? (
                <>
                  <div
                    style={{
                      width: 'max-content',
                      padding: '8px 20px',
                      background: showDumpPointsModal
                        ? '#E3EEFF'
                        : '#FFFFFF' + ' 0% 0% no-repeat padding-box',
                      boxShadow: '0px 3px 4px #00000029',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setShowDumpPointsModal(true);
                      setShowCreateDumpPointModal(false);
                    }}
                  >
                    Show Dump Points
                  </div>
                  <div
                    style={{
                      width: 'max-content',
                      padding: '8px 20px',
                      background: showCreateDumpPointModal
                        ? '#E3EEFF'
                        : '#FFFFFF' + ' 0% 0% no-repeat padding-box',
                      boxShadow: '0px 3px 4px #00000029',
                      borderRadius: '8px',
                      color: '#000000',
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      setShowCreateDumpPointModal(true);
                      setShowDumpPointsModal(false);
                    }}
                  >
                    Create Dump Point
                    <BsVectorPen style={{ marginLeft: '0.5rem' }} />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    width: 'max-content',
                    padding: '8px 20px',
                    background: showVehiclesModal
                      ? '#E3EEFF'
                      : '#FFFFFF' + ' 0% 0% no-repeat padding-box',
                    // background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 3px 4px #00000029',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowVehiclesModal(true)}
                >
                  Show Vehicles
                </div>
              )}
            </div>

            <nav
              style={{
                bottom: 0,
                marginBottom: '2rem',
                position: 'absolute',
                zIndex: 1,
              }}
            >
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '2rem',
                  justifyContent: 'center',
                }}
              >
                <li
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '16.5rem',
                    padding: '8px 0px',

                    background: isDumpPoints
                      ? '#E3EEFF'
                      : '#FFFFFF' + ' 0% 0% no-repeat padding-box',
                    boxShadow: '0px 3px 4px #00000029',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    transition: 'font-weight .1s ease-in',
                    fontWeight: isDumpPoints ? '600' : '300'
                  }}
                  onClick={() => {
                    setIsDumpPoints(true);
                    setIsDetailPageOpen(false);
                    setShowVehiclesModal(false);
                  }}
                >
                  <Image
                    src={
                      isDumpPoints
                        ? require('../../assets/images/geofence.png')
                        : require('../../assets/images/geofence.png')
                      // This should have `geofence_light.png` but the image didn't exist when this was committed
                    }

                    height={35}
                    width={52}
                  />
                  Dump Points
                </li>
                <li
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '16.5rem',
                    padding: '8px 0px',

                    background: isDumpPoints
                      ? '#FFFFFF'
                      : '#E3EEFF' + ' 0% 0% no-repeat padding-box',
                    boxShadow: '0px 3px 4px #00000029',
                    transition: 'font-weight .1s ease-in',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontWeight: isDumpPoints ? '300' : '600'
                  }}
                  onClick={() => {
                    setIsDumpPoints(false);
                    setIsDetailPageOpen(false);
                    setShowDumpPointsModal(false);
                    setShowCreateDumpPointModal(false);
                  }}
                >
                  <Image
                    src={
                      isDumpPoints
                        ? require('../../assets/images/truck_light.png')
                        : require('../../assets/images/truck.png')
                    }

                    height={35}
                    width={52}
                  />
                  Vehicles
                </li>

              </ul>
            </nav>

            <div style={{

              bottom: -17.5,
              marginBottom: '2rem',
              position: 'absolute',
              zIndex: 1,
              right: 9.5,
              backgroundColor: 'white',

            }}>
              <div style={{ cursor: 'pointer', width: 47, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 45 }} onClick={(e) => {
                e.preventDefault();
                if (showCreateDumpPointModal)
                  return;
                let counter = 0;
                let x;

                x = setInterval(() => {
                  mapRef.current._impl.zoomLevel += 0.05;
                  counter += 0.05;
                  if (counter >= 1) {
                    clearInterval(x);
                  }
                }, 20);
              }}>
                <img src={plusIcon} style={{ width: 30, height: 30 }} />
              </div>
              <div style={{ borderTop: '1px solid black', cursor: 'pointer', width: 47, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 45 }} onClick={(e) => {
                e.preventDefault();
                if (showCreateDumpPointModal)
                  return;
                let counter = 0;
                let x;

                x = setInterval(() => {
                  mapRef.current._impl.zoomLevel -= 0.05;
                  counter += 0.05;
                  if (counter >= 1) {
                    clearInterval(x);
                  }
                }, 20);
              }}>
                <img src={minusIcon} style={{ width: 20, height: 25 }} />
              </div>
            </div>

            {showDumpPointsModal && (
              <DumpPointsModal
                setPopupMessage={setPopupMessage}
                setPopupConfirmation={setPopupConfirmation}
                close={() => setShowDumpPointsModal(false)}
              />
            )}
            {showCreateDumpPointModal && (
              <CreateDumpPointModal
                created={created}
                dumpPoint={dumpPoint}
                onCancel={cancelHandler}
                onSubmit={submitHandler}
              />
            )}
            {showVehiclesModal && (
              <VehiclesModal close={() => setShowVehiclesModal(false)} />
            )}
          </div>
          {popupConfirmation && <ConfirmationPopup {...popupConfirmation} />}
          {popupMessage && (
            <MessagePopup
              message={popupMessage}
              setPopupMessage={setPopupMessage}
            />
          )}
        </div>
      </DumpPointsContext.Provider>
    </AuthLayout>
  );
};

export default DumpPoints;
