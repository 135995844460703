import React from "react";
import styles from "./Button.module.css"

export default function Button({
    label,
    onClick,
    isDisabled,
    style,
    className,
}) {
    return (
        <div
            className={(!isDisabled ? styles.hoverButton : "") + " " + (className || "")}
            onClick={(e) => !isDisabled && onClick(e)}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: isDisabled ? "#E1E4EA" : "#186FD9",
                color: isDisabled ? "#8190AD" : "#FFFFFF",
                borderRadius: 20,
                fontSize: 12,
                cursor: isDisabled ? "not-allowed" : "pointer",
                userSelect: "none",
                fontWeight: "bold",
                padding: ".1rem 1.75rem",
                border: "1px solid " + (isDisabled ? "#E1E4EA" : "#186FD9"),
                ...style,
            }}
        >
            {label}
        </div >
    );
}
