import React, { forwardRef, useState } from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import DatePicker from "react-datepicker";
import calandarIcon from '../assets/images/icon-calendar.png'

const CustomInput = ({ value, onClick }) => (
    <div style={{ padding: '22px 5px' }} className="position-relative filter-box form-control d-flex align-items-center justify-content-between" onClick={onClick}  >
        {value}
        <div className="w-100 d-flex justify-content-end">
            <img style={{
                height: 20,
                width: 25,
            }} src={calandarIcon} alt="" />
        </div>
    </div>
);

const range = (start, end, step) => {
    const array = [];
    for (let i = start; i <= end; i += step) {
        array.push(i);
    }
    return array;
}
const years = range(1990, new Date().getFullYear(), 1);
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];



const FilterModalInputComponent = ({ options, filterVariable, handleSelect, type, label, searchDate, setSearchDate, ...props }) => {
    return <div className="col-md-12 form-group">
        <div className="row  d-flex align-items-center">
            <div className="col-md-3">
                <label
                    style={{ font: "normal normal normal 14px/19px Open Sans" }}
                >
                    {label}{" "}
                </label>
            </div>
            <div className="p-1 col-md-9 custom_select">
                {type == 'multi' && <ReactMultiSelectCheckboxes
                    maxHeight={50}
                    options={options}
                    onChange={(selected) => handleSelect(selected, "Truck")}
                    value={filterVariable}
                    styles={{
                        menuList: (provided) => ({
                            ...provided,
                            maxHeight: '128px',
                            overflowY: 'auto',
                        })
                    }}

                />}
                {type == 'date' &&
                    <DatePicker className="filter-box form-control p-4"
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div className="date-picker-calander-header"
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                <div style={{
                                    background: 'none',
                                    cursor: 'pointer'
                                }} className="filter-box form-control mr-1" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </div>
                                <select
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    value={new Date(date).getFullYear()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                    className="filter-box form-control col-4"
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    value={months[new Date(date).getMonth()]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                    className="filter-box form-control  ml-1 col-5"
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <div className="filter-box form-control ml-1" style={{
                                    background: 'none',
                                    cursor: 'pointer'
                                }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </div>
                            </div>
                        )}
                        selected={searchDate}
                        onChange={(date) => setSearchDate(date)}
                        customInput={<CustomInput />}
                    />

                }
                {type == 'single' &&
                    <select className="filter-box form-control p-3" style={{
                        height: 'auto'
                    }}
                        id="selectedUserType"
                        value={props?.value ? props?.value : ""}
                        onChange={props?.onChange}
                        name={props?.name}
                        placeholder={props?.optionLabel}
                    >

                        <option value="">{ }</option>
                        {props?.optionList?.map((option, index) => (
                            <option key={props?.keyField == 'index' ? index : option?.[props?.keyField]} value={option?.[props?.valueField]}>
                                {option?.[props?.valueField]}
                            </option>
                        ))}
                    </select>
                }
            </div>
        </div>
    </div>
};

export default FilterModalInputComponent;
