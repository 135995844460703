import React, { useEffect } from 'react'
import ProgressBar from '../ProgressBar'
import { Image } from 'react-bootstrap'
import { utcToNZTime } from "../../utils/helper";
import { getHourlyTarget } from "../../utils/helper";

export default function TruckRow({ truck, behindTargetTrucks, externalLoads, notOperatingTrucks, unScheduled, shiftStartingAndEndingTime }) {
    useEffect(() => {
        // console.log(unScheduled ? "#707070" : false
        //     ? "#ED7C7F"
        //     : 50 < 100
        //         ? "#F5BF6C"
        //         : "#95C365", unScheduled);
    }, [])
    const checkForCatching = (equipment) => {
        const curr = utcToNZTime()?.date;
        let ext;
        let finalTime;
        if (new Date(shiftStartingAndEndingTime.end)?.getTime() > curr.getTime()) {
            finalTime = new Date(curr?.getTime() - 3600000)?.toLocaleString('en-US', {
                hour: 'numeric',
                hour12: true
            })
            ext = new Date(curr?.getTime())?.toLocaleString('en-US', {
                hour: 'numeric',
                hour12: true
            })
        }
        else
            finalTime = new Date(new Date(shiftStartingAndEndingTime.end).getTime() - 10000).toLocaleString('en-US', {
                hour: 'numeric',
                hour12: true
            })
        const data = equipment?.hourlyData?.[finalTime];

        const target = Math.round(getHourlyTarget(
            equipment?.productive_hours,
            parseInt(equipment?.targetLoads, 10)
        ))


        return (!data || (data < target && (equipment?.hourlyData?.[ext] ? equipment?.hourlyData?.[ext] : 0) < target)) && equipment?.actualLoads < equipment?.targetLoads;
    }

    const isNotOperating = notOperatingTrucks?.includes(truck?.name?.Unit)
    return (
        <div style={{ opacity: isNotOperating ? 0.5 : 1, lineHeight: "20px", height: '70px', padding: '0 30px', background: unScheduled ? '#F7F7F7 0% 0% no-repeat padding-box' : '', display: 'flex', alignItems: 'center' }}>
            <div
                style={{ height: 'min-content' }}
                className="d-flex justify-content-between align-items-start"
            >
                <div className="d-flex" style={{ width: "90px" }}>
                    <div className="d-flex align-items-center flex-column">
                        <span
                            style={{
                                font: "normal normal 600 18px/24px Open Sans",
                                color: "black",
                            }}
                        >
                            {truck?.name?.Unit || truck?.Unit}
                        </span>

                        <span
                            style={{
                                fontWeight: "100",
                                fontSize: "12px",
                                color: "gray",
                            }}
                        >
                            {truck?.operator?.firstName ? `(${truck?.operator?.firstName})` : ""}
                        </span>
                    </div>
                    <div>
                        {isNotOperating ? (
                            <Image
                                src={require("../../assets/images/not-available.png")}
                                height={28}
                                width={28}
                            // style={{ marginLeft: 4 }}
                            />
                        ) : !unScheduled && behindTargetTrucks?.includes(truck?.name.Unit) ? (
                            <Image
                                src={require("../../assets/images/alert.png")}
                                height={14}
                                width={14}
                                style={{ marginLeft: 4 }}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div className="d-flex align-items-center flex-column">
                    <ProgressBar
                        progress={unScheduled ? 125 : (truck?.actualLoads / truck?.targetLoads) * 100}
                        height={20}
                        width={140}
                        notCatching={!unScheduled && checkForCatching(truck)}
                        unScheduled={unScheduled == true ? true : false}
                        fallingBehind={unScheduled ? false : behindTargetTrucks?.includes(truck?.name.Unit)}
                        isNotOperating={isNotOperating}
                    />
                </div>
                <div
                    style={{ width: "70px" }}
                    className="d-flex flex-column align-items-center"
                >
                    <span
                        className="d-flex justify-content-center"
                        style={{
                            color: "black",
                            font: "normal normal 600 18px/24px Open Sans",
                        }}
                    >
                        {unScheduled ? (externalLoads + "/" + externalLoads) : (truck?.actualLoads + '/')}
                        {!unScheduled && <span
                            style={{
                                font: "normal normal normal 18px/24px Open Sans",
                                color: "#707070",
                            }}
                        >
                            {truck?.targetLoads}
                        </span>}
                    </span>
                    <span
                        style={{
                            font: "normal normal normal 12px/17px Open Sans",
                            color: " #707070",
                        }}
                    >
                        Loads
                    </span>
                </div>
            </div>
        </div>
    )
}
