import React, { useEffect, useState } from "react";
import styles from "./History.module.css";
import { CallWithAuth } from "../../../../action/apiActions";
import { toLower } from "lodash";
import { highlightText } from "../../utils/helper";
import moment from "moment";
import NoDataMessage from "../../components/NoDataMessage/NoDataMessage";

const History = ({ search, setSearch, shiftId }) => {
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        fetchShiftHistory();
    }, [])

    const fetchShiftHistory = async () => {
        try {
            setLoading(true);
            const url = "api/shift-planning/history/" + shiftId;
            const response = await CallWithAuth("GET", url);
            if (response.res.data.status === 200) {
                let { data } = response.res.data;
                setHistory(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        if (search.query) {
            let count = 0;
            history.forEach((history) => {
                history.changes.forEach((change) => {
                    Object.values(change).forEach((value) => {
                        if (
                            typeof value === "string" &&
                            toLower(value).includes(toLower(search.query))
                        )
                            count++;
                    });
                });
            });
            setSearch({ ...search, count: count });
        } else {
            setSearch({ ...search, count: 0 });
        }
    }, [search.query]);

    return (
        <div className={styles.container}>
            {loading ? 'Loading...' : !history?.length ? <NoDataMessage /> : <div style={{ marginRight: -5, height: "100%", width: "100%" }}>
                {history.map((history, index) => {
                    return (
                        <div key={index} className={styles.card}>
                            <div className={styles.date}>
                                {moment(history.updatedAt).format('DD/MM/YYYY, hh:mm A')} - {history.updatedBy}
                            </div>
                            {history.changes.map((change, idx) => {
                                return (
                                    <div key={idx} className={styles.changesCard}>
                                        <div className={styles.key}>
                                            {highlightText(change.key, search.query)}:
                                        </div>
                                        <div>
                                            {highlightText(change.from, search.query)}{" "}
                                            {change.from && <span
                                                style={{ color: "#6A7C9A", fontStyle: "italic" }}
                                            >
                                                to
                                            </span>}{" "}
                                            {highlightText(change.to, search.query)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )
                })
                }
            </div>
            }
        </div>
    );
};

export default History;

