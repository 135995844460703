import { getDefaultNewShiftTimings } from "./helper";

export const ShiftStatus = {
  NEW: "N",
  DRAFT: "D",
  ONGOING: "O",
  PUSBLISHED: "P",
  ENDED: "E",
};

export const DateTimeFormats = {
  DATE_TIME_U: "DD/MM/YYYY, LT",
};

export const SelectAssetTypes = {
  EXCAVATORS: 1,
  TRUCKS: 2,
};

export const AssetCategories = {
  EXCAVATORS: "Excavator",
  TRUCKS: "Truck",
};

export const HistoryTypes = {
  UPDATED: "Edited",
  ADDED: "Added",
};

export const shifts = {
  DAY: "Day",
  NIGHT: "Night",
};
export const shiftTypes = {
  DAY: "Day",
  NIGHT: "Night",
};

export const shiftOptions = [
  { name: "Show All", shift: '' },
  { name: "Day", shift: shifts.DAY },
  { name: "Night", shift: shifts.NIGHT },
];

export const shiftStatusOptions = [
  { name: "Ongoing", status: ShiftStatus.ONGOING, color: "#26A071" },
  { name: "Published", status: ShiftStatus.PUSBLISHED, color: "#186FD9" },
  { name: "Draft", status: ShiftStatus.DRAFT, color: "#E58D00" },
  { name: "Ended", status: ShiftStatus.ENDED, color: "#6A7C9A" },
];

export const InitialFilters = {
  date: "",
  mine: "",
  shift: "",
  department: [],
  crew: [],
  status: [],
  query: "",
};
// initialStates.js

export const initialSearchState = {
  query: "",
  count: 0,
  count2: 0,
};

export const createInitialGeneralShiftPlanDetails = () => {
  const { isDayShift, startTime, endTime } = getDefaultNewShiftTimings()
  return {
    reportNo: 0,
    status: ShiftStatus.NEW,
    mine: null,
    shiftType: isDayShift ? shiftTypes.DAY : shiftTypes.NIGHT,
    department: null,
    crew: null,
    startTime,
    endTime,
    productiveHours: { label: 9, value: 9 },
    shiftSupervisor: null,
    leadingHand: null,
    annualLeave: [],
    sickLeave: [],
    absent: [],
    createdBy: "",
    generalShiftNotes: '',
  }
};

export const initialEditSession = {
  startedAt: null,
  willEndAt: null,
  by: null,
};

export const initialState = {
  shiftStatus: ShiftStatus.NEW,
  removedEquipments: [],
  isGeneralShiftFlyerOpen: false,
  originalShiftData: null,
  originalData: [],
  search: initialSearchState,
  minesList: [],
  departmentsList: [],
  crewTypesList: [],
  materialList: [],
  areaList: [],
  sourceDumpList: [],
  supervisorList: [],
  leadingHandList: [],
  activityDescriptions: [],
  loading: true,
  assignedExcavatorDetailsFormData: [],
  removedEquipmentFlyerOpen: -1,
  isTruckShiftDetailsFlyerOpen: null,
  isExcavatorShiftDetailsFlyerOpen: -1,
  editSession: initialEditSession,
  canEditShift: false,
  canEditShiftRef: true,
  stopFetching: false,
};


export const JumpToSection = {
  ExcavatorsAndTrucks: 'Excavators & Trucks',
  RemovedEquipments: 'Removed Equipment'
}

export const JumpToSectionOptions = [
  {
    value: JumpToSection.ExcavatorsAndTrucks,
    label: JumpToSection.ExcavatorsAndTrucks,
  },
  {
    value: JumpToSection.RemovedEquipments,
    label: JumpToSection.RemovedEquipments,
  },
]
