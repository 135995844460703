import { ShiftStatus } from "../contants";

export const getShiftUpdates = (oldData, newData, status) => {

    if (status == ShiftStatus.NEW) {
        return [{
            key: "New Plan Created",
            to: newData?.reportNo
        }]
    }
    const updates = [];

    // Helper function to compare two values and format changes
    const compareValues = (key, originalValue, newValue) => {
        if (originalValue != newValue) {
            updates.push({
                key,
                from: originalValue,
                to: newValue
            });
        }
    };

    compareValues('Mine', oldData?.mine?.label, newData?.mine?.label);
    compareValues('Shift', oldData?.shiftType, newData?.shiftType);
    compareValues('Department', oldData?.department?.label, newData?.department?.label);
    compareValues('Crew', oldData?.crew?.label, newData?.crew?.label);
    compareValues('Shift Start', oldData?.startTime, newData?.startTime);
    compareValues('Shift End', oldData?.endTime, newData?.endTime);
    compareValues('Productive Hours', oldData?.productiveHours?.value, newData?.productiveHours?.value);
    compareValues('Shift Supervisor', oldData?.shiftSupervisor?.label, newData?.shiftSupervisor?.label);
    compareValues('Leading Hand', oldData?.leadingHand?.label, newData?.leadingHand?.label);
    compareValues('General Notes', oldData?.generalShiftNotes, newData?.generalShiftNotes);

    const formatLeave = (leave) => leave?.map(u => u?.label).sort((a, b) => a.localeCompare(b))?.join(', ') || '';

    compareValues('Annual Leave', formatLeave(oldData?.annualLeave), formatLeave(newData?.annualLeave));
    compareValues('Sick Leave', formatLeave(oldData?.sickLeave), formatLeave(newData?.sickLeave));
    compareValues('Absent', formatLeave(oldData?.absent), formatLeave(newData?.absent));

    return updates;
};

export const getExShiftPlanUpdates = (oldData, newData) => {

    const updates = []
    // Helper function to compare two values and format changes
    const compareValues = (key, originalValue, newValue) => {
        if (originalValue !== newValue) {
            updates.push({
                key,
                from: originalValue,
                to: newValue
            });
        }
    };

    const formatLeave = (leave) => leave?.map(u => u?.label).sort((a, b) => a.localeCompare(b))?.join(', ') || '';

    compareValues('Operator', oldData?.operator?.label, newData?.operator?.label);
    compareValues('Assistant Operator', oldData?.assistantOperator?.label, newData?.assistantOperator?.label);
    compareValues('Trainee', oldData?.trainee?.label, newData?.trainee?.label);
    compareValues('Material', oldData?.material?.label, newData?.material?.label);
    compareValues('Source Area', oldData?.sourceArea?.label, newData?.sourceArea?.label);
    compareValues('Dump Area', formatLeave(oldData?.dumpArea), formatLeave(newData?.dumpArea));
    compareValues('Haul Trucks', oldData?.haulTrucks?.map(u => u?.plantId)?.sort((a, b) => a.localeCompare(b))?.join(' ,'), newData?.haulTrucks?.map(u => u?.plantId)?.sort((a, b) => a.localeCompare(b))?.join(' ,'));
    compareValues('Shift Start', oldData?.startTime, newData?.startTime);
    compareValues('Shift End', oldData?.endTime, newData?.endTime);
    compareValues('Productive Hours', oldData?.productiveHours?.value, newData?.productiveHours?.value);
    compareValues('Activity Description', oldData?.activityDescription?.label, newData?.activityDescription?.label);
    compareValues('Known Hazards', oldData?.knownHazards, newData?.knownHazards);
    compareValues('Start Of Shift Note To Operator', oldData?.startOfShiftNoteToOperator, newData?.startOfShiftNoteToOperator);

    return updates;
};

export const getTruckUpdates = (od, newData) => {

    const oldData = od?.find((ht => ht?.plantId == newData?.plantId))
    if (!oldData) {
        return [{
            key: "Asset Added",
            to: newData?.plantId
        }]
    }

    if (!oldData?.removedFromPlan && newData?.removedFromPlan) {
        return [{
            key: "Asset Removed",
            to: newData?.plantId
        }]
    }
    const updates = [];

    // Helper function to compare two values and format changes
    const compareValues = (key, originalValue, newValue) => {
        if (originalValue != newValue) {
            updates.push({
                key,
                from: originalValue,
                to: newValue
            });
        }
    };

    const formatLeave = (leave) => leave?.map(u => u?.label).sort((a, b) => a.localeCompare(b))?.join(', ') || '';
    compareValues('Operator', oldData?.operator?.label, newData?.operator?.label);
    compareValues('Assistant Operator', oldData?.assistantOperator?.label, newData?.assistantOperator?.label);
    compareValues('Trainee', oldData?.trainee?.label, newData?.trainee?.label);
    compareValues('Material', oldData?.material?.label, newData?.material?.label);
    compareValues('Source Area', oldData?.sourceArea?.label, newData?.sourceArea?.label);
    compareValues('Dump Area', formatLeave(oldData?.dumpArea), formatLeave(newData?.dumpArea));
    compareValues('Shift Start', oldData?.startTime, newData?.startTime);
    compareValues('Shift End', oldData?.endTime, newData?.endTime);
    compareValues('Productive Hours', oldData?.productiveHours?.value, newData?.productiveHours?.value);
    compareValues('Activity Description', oldData?.activityDescription?.label, newData?.activityDescription?.label);
    compareValues('Known Hazards', oldData?.knownHazards, newData?.knownHazards);
    compareValues('Start Of Shift Note To Operator', oldData?.startOfShiftNoteToOperator, newData?.startOfShiftNoteToOperator);

    return updates;
};