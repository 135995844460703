import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CallWithOutAuth } from '../../action/apiActions';
import { FORGOT_PASSWORD } from '../../action/apiPath';
import GuestLayout from '../../components/GuestLayout';
import validationUtility from '../../utils/validation';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    isError: false,
  });

  const handelOnchange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      isError: false,
    });
  };

  const handelValidateForm = () => {
    if (validationUtility.email(formData.email)) return Promise.resolve(true);
    return Promise.resolve(false);
  };

  const submitForgetPasswordHandler = async (e) => {
    e.preventDefault();
    console.log('--->' + formData.email);
    let validationStatus = await handelValidateForm();
    if (validationStatus) {
      const payload = {
        email: formData.email,
      };
      const response = await CallWithOutAuth('POST', FORGOT_PASSWORD, payload);
      // console.log(response);
      if (response?.res?.data?.status === 200) {
        toast.success(response.res.data.message, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate('/', { replace: true });
      } else {
        toast.error(response.res.data.message, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      setFormData({ ...formData, isError: true });
      // alert("email is not right");
    }
  };
  return (
    <GuestLayout>
      <section className="before_login_screen">
        <Helmet>
          <title>Forget Password | iMOS</title>
        </Helmet>
        <div className="before_main_login_box">
          <div className="before_logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="login_box" style={{ padding: "5rem 6rem", textAlign: "center" }}>
            <hgroup className="before_login_heading">
              <h1>Forgot your password?</h1>
              <p>
                Don't worry! Just type in your registered email adddress and a
                temporary password will be sent to you.
              </p>
            </hgroup>
            <p></p>
            <form onSubmit={submitForgetPasswordHandler}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input_field_wrap has-float-label pb-5">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handelOnchange}
                        // placeholder="Email"
                        style={{
                          border:
                            formData.isError &&
                            !validationUtility.email(formData.email)
                              ? '1px solid red'
                              : '',
                        }}
                      />
                      <label>Email*</label>
                    </div>
                    {formData.isError &&
                      !validationUtility.email(formData.email) && (
                        <span className="form-control-message">
                          Please enter registered email Address{' '}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      className="form-control login_button"
                      value="Send me a temporary password"
                      id="login-btn"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="before_login_footer_menu">
            <ul>
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Terms of services</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default ForgetPassword;
