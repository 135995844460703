import { DumpPointsContext } from '../DumpPointsContext';

import { LatLng, LatLngBounds } from 'leaflet';
import { useContext, useEffect, useState } from 'react';
import { DumpPointPopup } from './DumpPointPopup';
import { DumpEventPopup } from './DumpEventPopup';

let map;
let mapkit = undefined;

let markerAnnotation = undefined;
var points = [];
var polylineOverlays = [];
var polygonOverlay;

let dumpPointOverlays = [];
let dumpPointPopups = [];
let vehicleEventOverlays = [];
let vehicleEventPopups = [];

let globalCreateMode;
let globalCreated;
let globalViewDumpPoints;

let setGlobalCreated;
let setGlobalDumpPoint;

let polylineStyle;

const createDumpPointPolygon = (
  coordinates,
  name = undefined,
  selected = false,
  dumpEvent
) => {
  const initialStyle = new mapkit.Style({
    fillColor: '#4fa522',
    fillOpacity: globalViewDumpPoints ? 0.5 : 0.0,
    lineWidth: 2,
    lineJoin: 'round',
    strokeColor: '#4fa522',
  });

  const selectedStyle = new mapkit.Style({
    fillColor: '#6995bf',
    fillOpacity: globalViewDumpPoints ? 0.5 : 0.0,
    lineWidth: 2,
    lineJoin: 'round',
    strokeColor: '#6995bf',
  });

  const bounds = new LatLngBounds(
    coordinates.map(
      ({ latitude, longitude }) => new LatLng(latitude, longitude)
    )
  );
  const center = new mapkit.Coordinate(
    bounds.getCenter().lat,
    bounds.getCenter().lng
  );
  const northPoint = new mapkit.Coordinate(
    bounds.getNorth(),
    bounds.getCenter().lng
  );

  const options = {
    title: name,
    data: { dumpEvent: dumpEvent },
    visible: selected,
  };

  let annotation = new mapkit.Annotation(northPoint, DumpPointPopup, options);
  dumpPointPopups.push(map.addAnnotation(annotation));

  let polygon = new mapkit.PolygonOverlay(coordinates, {
    style: selected ? selectedStyle : initialStyle,
  });
  polygon.addEventListener('select', (event) => {
    if (!globalViewDumpPoints) return;

    event.target.style = selectedStyle;
    map.setCenterAnimated(center);
    annotation.visible = true;
  });
  polygon.addEventListener('deselect', (event) => {
    event.target.style = initialStyle;
    annotation.visible = false;
  });

  return polygon;
};

const createDumpEventMarker = (coordinates, name, dumpEvent, dumpPointName) => {
  const style = {
    fillColor: dumpPointName === 'Unknown' ? 'red' : 'green',
    fillOpacity: 1,
    strokeColor: 'white',
    lineWidth: 2,
    // visible: globalViewDumpPoints ? false : true,
    visible: true
  };

  const initialStyle = new mapkit.Style(style);
  const selectedStyle1 = new mapkit.Style({
    fillColor: 'transparent',
    fillOpacity: 1,
    strokeColor: 'transparent',
    lineWidth: 2,
    // visible: globalViewDumpPoints ? false : true,
    visible: true
  });
  const selectedStyle2 = new mapkit.Style({
    fillOpacity: 1,
    fillColor: 'transparent',
    strokeColor: 'white',
    lineWidth: 2,
    // visible: globalViewDumpPoints ? false : true,
    visible: true
  });

  let dumpEventMarker = new mapkit.CircleOverlay(coordinates, 4, {
    style: initialStyle,
  });
  let dumpEventMarker2 = new mapkit.CircleOverlay(coordinates, 6, {
    style: selectedStyle1,
  });
  const options = {
    title: name,
    data: { dumpEvent: dumpEvent, dumpPointName: dumpPointName },
    visible: false,
  };

  let annotation = new mapkit.Annotation(coordinates, DumpEventPopup, options);
  vehicleEventPopups.push(map.addAnnotation(annotation));


  dumpEventMarker2.addEventListener('select', (event) => {
    // if (globalViewDumpPoints)
    //   return;
    dumpEventMarker2.style = selectedStyle2;
    annotation.visible = true;
  });
  dumpEventMarker2.addEventListener('deselect', (event) => {
    dumpEventMarker2.style = selectedStyle1;
    annotation.visible = false;
  });

  return { dumpEventMarker, dumpEventMarker2 };
};

const resetPolygons = () => {
  if (!polygonOverlay) return;
  map.removeOverlay(polygonOverlay);
  polygonOverlay = undefined;
};

const resetPolylines = () => {
  markerAnnotation && map.removeAnnotation(markerAnnotation);
  if (!polylineOverlays) return;
  map.removeOverlays(polylineOverlays);
  points = [];
  polylineOverlays = [];
};

const setupMapKitJs = async () => {
  if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
    await new Promise((resolve) => {
      window.initMapKit = resolve;
    });

    delete window.initMapKit;
  }

  const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
  window.mapkit.init({
    authorizationCallback: (done) => {
      done(jwt);
    },
    language: 'en',
  });
};

const setupMap = (props) => {
  const mapCenter = new mapkit.Coordinate(-37.61037, 175.08057);
  const mapRegion = new mapkit.CoordinateRegion(
    mapCenter,
    new mapkit.CoordinateSpan(0.04, 0.04)
  );


  map = new mapkit.Map('map-container', {
    center: mapCenter,
    mapType: mapkit.Map.MapTypes.Satellite,
    region: mapRegion,
    showsCompass: mapkit.FeatureVisibility.Hidden,
  });

  props.mapRef.current = map;
  map.cameraZoomRange = new mapkit.CameraZoomRange(100, 8000);
  map.cameraBoundary = mapRegion.toMapRect();
  map.cameraDistance = 6000;

  map.addEventListener('single-tap', singleTapEvent);
};

const singleTapEvent = (event) => {
  if (!globalCreateMode || globalCreated) return;

  var coordinates = map.convertPointOnPageToCoordinate(event.pointOnPage);
  points.push(coordinates);

  if (points.length === 1) {
    var marker = new mapkit.MarkerAnnotation(
      map.convertPointOnPageToCoordinate(event.pointOnPage),
      { color: 'red' }
    );
    markerAnnotation = map.addAnnotation(marker);
    return;
  }

  map.removeAnnotation(markerAnnotation);
  var polyline = new mapkit.PolylineOverlay(
    [points[points.length - 2], points[points.length - 1]],
    { style: polylineStyle }
  );
  var overlay = map.addOverlay(polyline);
  polylineOverlays.push(overlay);

  if (points.length > 3) {
    const isIntersecting = points.some((point, i) => {
      if (i < points.length - 3) {
        const currentLine = [points[i], points[i + 1]];
        return lineIntersects(currentLine, [
          points[points.length - 2],
          points[points.length - 1],
        ]);
      }
      return false;
    });

    if (!isIntersecting) return;

    let polygon = createDumpPointPolygon(points.slice(0, points.length - 1));
    polygonOverlay = map.addOverlay(polygon);

    setGlobalCreated(true);
    setGlobalDumpPoint((prev) => {
      return {
        ...prev,
        coordinates: points.slice(0, points.length - 1).map((point) => {
          return { lat: point.latitude, lng: point.longitude };
        }),
      };
    });

    resetPolylines();
  }
};

const lineIntersects = (line1, line2) => {
  const { latitude: x1, longitude: y1 } = line1[0];
  const { latitude: x2, longitude: y2 } = line1[1];
  const { latitude: x3, longitude: y3 } = line2[0];
  const { latitude: x4, longitude: y4 } = line2[1];

  const det = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  if (det === 0) {
    return false; // Lines are parallel
  }

  const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / det;
  const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / det;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    return true; // Intersection detected
  }

  return false; // No intersection
};

export const MapContainer = (props) => {
  const {
    createMode,
    created,
    setCreated,
    setDumpPoint,
    viewDumpPoints,
  } = props;
  const [setupCompleted, setSetupCompleted] = useState(false);

  useEffect(async () => {
    await setupMapKitJs();
    mapkit = window.mapkit;
    setupMap(props);

    setGlobalCreated = setCreated;
    setGlobalDumpPoint = setDumpPoint;

    polylineStyle = new mapkit.Style({
      lineWidth: 2,
      lineJoin: 'round',
      lineDash: [8, 4],
      strokeColor: '#F0F',
    });

    setSetupCompleted(true);

    return () => {
      delete window.initMapKit;
    };
  }, []);

  useEffect(() => {

    if (!setupCompleted) return;

    if (!created) {
      resetPolygons();
    }

    if (!createMode) {
      resetPolylines();
      map.isZoomEnabled = true;
      map.isScrollEnabled = true;
    } else {
      map.isZoomEnabled = false;
      map.isScrollEnabled = false;
    }

    globalCreateMode = createMode;
    globalCreated = created;
    globalViewDumpPoints = viewDumpPoints;
  }, [createMode, created, viewDumpPoints, setupCompleted]);

  const [cursor, setCursor] = useState('');
  useEffect(() => {
    setCursor(createMode && !created ? 'crosshair' : '');
  }, [createMode, created]);

  const {
    dumpPoints,
    dumpEvents,
    vehicleEvents,
    isDetailPageOpen,
    selectedVehicle,
    selectedDumpPoint,
  } = useContext(DumpPointsContext);

  useEffect(() => {
    if (!viewDumpPoints) return;
    if (!selectedDumpPoint?.name) return;
    if (isDetailPageOpen) return;

    const dumpPoint = dumpPoints.find(
      (dumpPoint) => dumpPoint.name === selectedDumpPoint.name
    );
    if (!dumpPoint) return;

    const boundCenter = new LatLngBounds(
      dumpPoint.coordinates.map(({ lat, lng }) => new LatLng(lat, lng))
    ).getCenter();
    const center = new mapkit.Coordinate(boundCenter.lat, boundCenter.lng);

    map.setCenterAnimated(center);
  }, [selectedDumpPoint, viewDumpPoints]);

  useEffect(() => {
    if (!setupCompleted) return;

    dumpPointOverlays.forEach((overlay) => {
      overlay.style.fillOpacity = viewDumpPoints ? 0.5 : 0;
    });

    vehicleEventOverlays.forEach((overlay) => {
      overlay.visible = true;
    });
  }, [vehicleEventOverlays, viewDumpPoints, setupCompleted]);

  useEffect(() => {
    if (!setupCompleted) return;

    if (dumpPointOverlays) {
      map.removeAnnotations(dumpPointPopups);
      dumpPointPopups = [];
      map.removeOverlays(dumpPointOverlays);
      dumpPointOverlays = [];
    }

    dumpPoints
      .filter((dumpPoint) => dumpPoint.coordinates.length > 2)
      .forEach(({ name, coordinates }) => {
        let polygon = createDumpPointPolygon(
          coordinates.map((coordinate) => {
            return new mapkit.Coordinate(coordinate.lat, coordinate.lng);
          }),
          name,
          selectedDumpPoint.name === name,
          dumpEvents.find((dumpEvent) => dumpEvent.name === name)
        );
        dumpPointOverlays.push(map.addOverlay(polygon));
      });
  }, [dumpPoints, dumpEvents, selectedDumpPoint, setupCompleted]);

  useEffect(() => {
    if (!setupCompleted) return;

    if (vehicleEventOverlays) {
      map.removeAnnotations(vehicleEventPopups);
      vehicleEventPopups = [];
      map.removeOverlays(vehicleEventOverlays);
      vehicleEventOverlays = [];
    }

    vehicleEvents.forEach((truck) => {
      if (!selectedVehicle?._id || truck?._id == selectedVehicle._id)
        truck.allLoads.forEach((dumpPoint) => {
          dumpPoint.loads.forEach((dumpEvent) => {
            let { dumpEventMarker, dumpEventMarker2 } = createDumpEventMarker(
              new mapkit.Coordinate(dumpEvent.lat, dumpEvent.lng),
              truck.name,
              dumpEvent,
              dumpPoint.name
            );
            vehicleEventOverlays.push(map.addOverlay(dumpEventMarker));
            vehicleEventOverlays.push(map.addOverlay(dumpEventMarker2));
          });
        });
    });
  }, [vehicleEvents, setupCompleted, selectedVehicle]);

  return (
    <section
      onMouseOver={(event) => {
        event.target.style.cursor = cursor;
      }}
      id="map-container"
      style={{ height: '100%', width: '100%' }}
    />
  );
};
