import { getTargetLoads } from "../../../../../../utils/helper";
import { createHaulTruckDetailFromAssignment } from "../../../utils/helper";

export const clearPreviousOperator = (oldEx, i, overlappingDetails) => {
    const ex = JSON.parse(JSON.stringify(oldEx));

    const clearOperator = (operatorType, locations) => {
        locations.forEach(({ plan, truckPlantId, exPlantId, type }) => {
            const isMatchingPlan = plan === ex.plan;
            const isMatchingEx = ex.plantId === exPlantId;

            if (isMatchingEx) {

                if (isMatchingPlan) {
                    if (type === "excavator") {
                        ex[operatorType] = "";
                    } else {
                        const htIdx = ex?.haulTrucks?.findIndex(ht => ht.plantId === truckPlantId);
                        if (htIdx !== -1) {
                            ex.haulTrucks[htIdx][operatorType] = "";
                        }
                    }
                }

                const shiftPlanIdx = ex.shiftPlans?.findIndex(shiftPlan => shiftPlan.plan === plan);
                if (shiftPlanIdx !== -1) {
                    if (type === "excavator") {
                        ex.shiftPlans[shiftPlanIdx][operatorType] = "";
                        if (operatorType === "operator") {
                            ex.shiftPlans[shiftPlanIdx].isOperatorSwapped = true;
                        }
                    } else {
                        const htIdx = ex.shiftPlans[shiftPlanIdx]?.haulTrucks?.findIndex(ht => ht.plantId === truckPlantId);
                        if (htIdx !== -1) {
                            ex.shiftPlans[shiftPlanIdx].haulTrucks[htIdx][operatorType] = "";
                            if (operatorType === "operator") {
                                ex.shiftPlans[shiftPlanIdx].haulTrucks[htIdx].isOperatorSwapped = true;
                            }
                        }
                    }
                }
            }
        });
    };

    ["operator", "assistantOperator", "trainee"].forEach(opType => {
        const details = overlappingDetails?.[opType];
        if (details) clearOperator(opType, details.locations);
    });

    return ex;
};


export const updateHaulTrucks = (haulTrucks, data, excavatorHaulTrucks, removedHaulTrucks, truckId) => {

    const updateTruckDataOnBasisOfExcavator = (ht, haulTrucks) => ({
        ...ht,
        material: data?.material,
        sourceArea: data?.sourceArea,
        dumpArea: data?.dumpArea,
        startTime: data?.startTime,
        endTime: data?.endTime,
        productiveHours: data?.productiveHours,
        targetLoads: getTargetLoads(data, haulTrucks),
    });

    const createNewHaulTruckDetails = (haulTrucks, removedHaulTrucks, data, excavatorHaulTrucks) => {

        const existingHaulTrucks = haulTrucks || [];
        const savedRemovedHaulTrucks = removedHaulTrucks || [];

        const newHaulTrucks = data?.haulTrucks?.filter(
            v => !existingHaulTrucks.some(ht => ht.equipment === v.value) &&
                !savedRemovedHaulTrucks?.some(ht => ht.equipment === v.value)
        ) || [];

        const existingHaulTrucksFiltered = [
            ...existingHaulTrucks.filter(ht => data?.haulTrucks?.some(v => ht.equipment === v.value)),
            ...savedRemovedHaulTrucks
                .filter(ht => data?.haulTrucks.some(v => ht.equipment === v.value))
                .map(ht => ({ ...ht, operator: null, removedFromPlan: false })),
            ...newHaulTrucks.map(v => createHaulTruckDetailFromAssignment({
                equipment: v.value,
                plantId: v.label,
                bcmPerLoad: excavatorHaulTrucks?.find(exHt => v.value === exHt.value)?.bcmPerLoad
            }, data))
        ];

        const updatedRemovedEquipments = [
            ...savedRemovedHaulTrucks.filter(ht => !data?.haulTrucks.some(v => ht.equipment === v.value)),
            ...existingHaulTrucks
                .filter(ht => ht.assignmentId && !data?.haulTrucks.some(v => ht.equipment === v.value))
                .map(ht => ({ ...ht, removedFromPlan: true, targetLoads: ht?.actualLoads }))
        ];

        return { existingHaulTrucksFiltered, updatedRemovedEquipments };
    };

    if (truckId == null) {
        const { existingHaulTrucksFiltered, updatedRemovedEquipments } = createNewHaulTruckDetails(haulTrucks, removedHaulTrucks, data, excavatorHaulTrucks);
        haulTrucks = existingHaulTrucksFiltered;
        removedHaulTrucks = updatedRemovedEquipments;

        return {
            haulTrucks: haulTrucks?.map((ht) => (updateTruckDataOnBasisOfExcavator(ht, haulTrucks))),
            removedHaulTrucks
        }
    }

    return haulTrucks?.map((ht) => (
        ht.equipment == truckId
            ? { ...ht, ...data }
            : ht
    ));
};

export const updateShiftPlans = (shiftPlans, data, currPlanId, type, ex, truckId) => {
    if (type === "excavator") {
        if (shiftPlans.length === currPlanId) {
            const targetLoads = getTargetLoads(data, data?.haulTrucks?.map((ht) => ({ ...ht, bcmPerLoad: ex?.excavatorHaulTrucks?.find((exHt => ht.value == exHt.value))?.bcmPerLoad })))

            return [
                ...shiftPlans,
                {
                    ...data,
                    haulTrucks: data?.haulTrucks.map(ht => ({ ...createHaulTruckDetailFromAssignment({ plantId: ht.label, equipment: ht.value, bcmPerLoad: ex?.excavatorHaulTrucks?.find((exHt => ht.value == exHt.value))?.bcmPerLoad }, data), targetLoads })),
                },
            ];
        } else {
            return shiftPlans.map((shiftPlan, idx) => {
                if (idx === currPlanId) {
                    const temp = updateHaulTrucks(shiftPlan?.haulTrucks, { ...data, plantId: ex.plantId, equipment: ex.equipment }, ex?.excavatorHaulTrucks, ex?.removedHaulTrucks)

                    return {
                        ...shiftPlan,
                        ...data,
                        haulTrucks: temp?.haulTrucks,
                        removedHaulTrucks: temp?.removedHaulTrucks
                    }
                }
                return shiftPlan
            });
        }
    } else {
        return shiftPlans.map((shiftPlan, idx) =>
            idx === currPlanId
                ? {
                    ...shiftPlan,
                    haulTrucks: updateHaulTrucks(shiftPlan?.haulTrucks, data, ex?.excavatorHaulTrucks, ex?.removedHaulTrucks, truckId),
                }
                : shiftPlan
        );
    }
};

