import React from "react";

export default function ConfirmationPopup(props) {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background:
          "0% 0% no-repeat padding-box padding-box rgb(110 110 110 / 30%)",
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10000,
      }}
    >
      <div
        style={{
          background: " #FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 31,
          minWidth: "385px",
          minHeight: "214px",
          width: "max-content",
          height: "max-content",
          padding: "20px 30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginBottom: "60px",
        }}
      >
        <span
          style={{
            font: " normal normal bold 16px/18px Open Sans",
            color: "#1A1A1A",
          }}
        >
          {props.title}
        </span>
        <span
          style={{
            paddingBottom: "10px",
            font: " normal normal normal 16px/30px Open Sans",
            letterSpacing: "0.32px",
            color: "#1A1A1A",
          }}
        >
          {props.description}
        </span>
        <div
          className="d-flex justify-content-center"
          style={{ width: "100%" }}
        >
          <button
            style={{
              width: 80,
              height: 25,
              font: "normal normal bold 12px/17px Open Sans",
              color: "#186FD9",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              border: " 1px solid #186FD9",
              borderRadius: 4,
            }}
            className="btn discard"
            onClick={props.onCancel}
          >
            {props.cancelText}
          </button>
          <button
            className="btn save ml-3"
            onClick={props.onConfirm}
            style={{
              width: 80,
              height: 25,
              font: "normal normal bold 12px/17px Open Sans",
              border: " 1px solid #186FD9",
              background: "#186FD9 0% 0% no-repeat padding-box",
              borderRadius: 4,
              color: "white",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {props.confirmText}
          </button>
        </div>
      </div>
    </div>
  );
}
