import React from "react";
import { CallWithAuth } from "../action/apiActions";
import { UPDATE_USER } from "../action/apiPath";
import { toast } from "react-toastify";

const ConfirmStatusModal = ({
  openStatusModal,
  setStatusModal,
  selectedUser,
  userData,
  setUserData,
}) => {

  const submitStatusHandler = async() => {
    let toggleSelectedUserIndex = userData.findIndex(
      (data) => data._id === selectedUser?._id
    );
    let userStatus;
    if (selectedUser?.status === "A" || selectedUser?.status === "true") {
      userStatus = "I";
    } else {
      userStatus = "A";
    }
    userData[toggleSelectedUserIndex].status = userStatus;
    setUserData(userData);
    const payload = {
      status: userStatus,
    };
    const URL = UPDATE_USER(selectedUser?._id);
    let response = await CallWithAuth("PUT", URL, payload);
    if (response?.status) {
        if (response?.res?.data?.status === 400) {
          toast.error("Status is not Updated", {
            position:"bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.success("Status updated Successfully", {
            position:"bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setStatusModal(false);
        }
      }
  };

  return (
    <div
      className={
        openStatusModal ? "full_modal generate_new_pin_active" : "full_modal"
      }
      id="generate_new_pin"
    >
      <div className="generate_new_pin_box">
        <div className="close_icon"  onClick={() => setStatusModal(false)}>
          <img src="assets/images/close.png"/>
        </div>
        <h4>
          Are you sure you want to <b>{selectedUser?.status === "A" || selectedUser?.status === "true" ? "deactivate" : "activate"}</b> {selectedUser.firstName} {selectedUser.lastName} ?
        </h4>
        <div className="generate_new_pin_form">
          <div className="add_new_user_box_top_right">
            <button className="btn save" onClick={submitStatusHandler}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStatusModal;
