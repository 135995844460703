import React, { useContext, useEffect, useState } from "react";
import { CallWithAuth } from "../action/apiActions";
import { GET_PIN, UPDATE_USER } from "../action/apiPath";
import { toast } from "react-toastify";
import AuthContext from "../context/AuthProvider";


const GeneratePINModal = ({
  type,
  openPINModal,
  closeGeneratePIN,
  selectedUser,
  userData,
  setUserData,
}) => {
  const { setAuth } = useContext(AuthContext);
  const [isCustom, setIsCustom] = useState(true);
  const [formData, setFormData] = useState({ pin: "" });

  useEffect(() => {
      setFormData({ pin: selectedUser.pin });
  }, []);

  const getPinInfo = async () => {
    const response = await CallWithAuth("GET", GET_PIN);
    if (response?.res?.data?.status === 200) {
      setFormData({ pin: response.res.data.data });
    }
  };
 
  const generateNewPin = async () => {
    await getPinInfo();
  };

  const handelValidateForm = () => {
    if (formData?.pin?.length != 4) {
      toast.error("Please Enter a 4 digit pin", { position: "bottom-right" });
      return Promise.resolve(false);
    } else {
      return Promise.resolve(true);
    }
  };

  const togglePinType = () => {
    setIsCustom((prev) => !prev);
    setFormData(prev => ({ ...prev, pin: formData?.pin }))
};

  const SubmitPINHandler = async () => {
    let validationStatus = await handelValidateForm();
    if (validationStatus) {
      let filterIndex = userData.findIndex(
        (data) => data._id === selectedUser?._id
      );
      userData[filterIndex].pin = formData.pin;
      setUserData(userData);
      let payload= {
        pin: formData.pin,
      };
      let toggleURL = UPDATE_USER(selectedUser?._id);
      let method = "PUT";
      let response;
      response = await CallWithAuth(method, toggleURL, payload);
      if (response?.res?.data?.status === 200) {
        toast.success("PIN changed Successfully", {
          position:"bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error("PIN Has not Changed", {
          position:"bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      closeGeneratePIN(false);
    } else {
      setFormData({ ...formData, isError: true });
    }
  };

  console.log(selectedUser);
  return (
    <div
            className={
              openPINModal ? "full_modal generate_new_pin_active" : "full_modal"
            }
            id="change_password"
        >
            <div className="generate_new_pin_box my_account_box" style={{
                width: '640px'
            }}>
                <h4 style={{font:" normal normal bold 20px/27px Open Sans"}}>{type==="Edit"?"Edit your PIN Number":"Generate New PIN"}</h4>
                <div className="generate_new_pin_form popup_animated_form">
                    <form>
                        <div className="row">
                            <div className="d-flex row justify-content-between col-md-10 col-12 mb-3">
                                <div className="h4">PIN No:</div>
                                <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                        <input
                                            type="radio"
                                            checked={isCustom}
                                            onChange={togglePinType}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                    </div>
                                    <div className="h4">Custom</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                        <input
                                            type="radio"
                                            checked={!isCustom}
                                            onChange={togglePinType}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                    </div>
                                    <div className="h4">Autogenerate</div>
                                </div>

                            </div>
                            <div className="col-md-12 form-group">
                                <div className="row">
                                    <div className="input_field_wrap has-float-label" style={{ display: 'flex', flex: 1 }}>
                                        <input
                                            type="number"
                                            name="pin"
                                            id="fname"
                                            value={formData?.pin}
                                            maxLength="4"
                                            onChange={(e) => setFormData(prev => ({ ...prev, pin: e?.target?.value }))}
                                            required
                                            style={{
                                                height: '4rem',
                                                border:"1px solid #E1E8F4",
                                                borderRadius:"4px"
                                            }}
                                        />
                                    </div>
                                    {!isCustom && (
                                        <>

                                            <div
                                                className="col-md-4 generate_new_pin ml-4 d-flex justify-content-center align-items-center"
                                                onClick={generateNewPin}
                                            >
                                                <span  >Generate New PIN</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex col-sm-12 justify-content-end pr-0">
                                <button
                                    style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#186FD9",border:"1.4px solid #a3c6ff", width:"100px"}}
                                    className="btn"
                                    onClick={(e) => {
                                        e.preventDefault(); 
                                        e.stopPropagation(); 
                                        closeGeneratePIN();
                                        setFormData(prev => ({ ...prev, pin:null }))
                                        setIsCustom(true);
                                    }}
                                >
                                    Discard
                                </button>
                                <button
                                 style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#FFFFFF",backgroundColor:"#186FD9",border:"1.4px solid #a3c6ff", width:"140px"}} 
                                 className="btn ml-2"   onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); SubmitPINHandler();}}>
                                    {" "}
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
  );
};

export default GeneratePINModal;
