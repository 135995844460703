import React from 'react'
import { PiWarningCircleFill } from "react-icons/pi";
import { IoCloseSharp } from "react-icons/io5";

export default function ErrorMessage({ message, onClose, hideClose }) {
    return (
        <div className=' px-3 py-2 d-flex rounded mb-3' style={{ color: "white", backgroundColor: "#BA3938", fontSize: 14, width: "100%" }}>
            <div className='w-100 d-flex align-items-start'>
                <PiWarningCircleFill className='mt-1 mr-2' size={20} color='white' />
                <span style={{ lineHeight: 1.75 }} >{message}</span>
            </div>
            {!hideClose && <IoCloseSharp className='mt-1' onClick={onClose} style={{ cursor: "pointer" }} size={20} />}
        </div>
    )
}
