import "./index.css";
import "./css/allcss.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import React from "react";
import ReactDOM from "react-dom";
// import { debugContextDevtool } from 'react-context-devtool';
// const container = document.getElementById("root");
// const options = [{
//   debugReducer: true,
//   debugContext: true
// }]

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,

  document.getElementById("root")
);
// debugContextDevtool(container, options);
