import React from 'react';
import './style.css';
import AuthLayout from '../../components/AuthLayout';
import { DashboardContextProvider } from './Context/DashboardContext';
import DashboardComponent from './DashboardComponent';
export default function Dashboard() {
  return (
    <AuthLayout pageName="Dashboard">
      <DashboardContextProvider>
        <DashboardComponent />
      </DashboardContextProvider>
    </AuthLayout>
  );
}
