import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HourlyResult from './components/HourlyResult';

import ConfirmationPopup from '../Popup';
import Header from './components/Header';
import ShiftOverview from './components/ShiftOverview';
import ShiftTotalDetail from './components/ShiftTotalDetail';


const ShiftListing = (props) => {

    const [radioValue, setRadioValue] = useState(1);
    const [topSlider, setTopSlider] = useState(true);
    const [popup, setPopup] = useState('');

    return (

        <div style={{ height: 'calc(100% - 60px)', overflow: 'hidden' }}>

            <Header topSlider={topSlider} setPopup={setPopup} setSelectedOption={setRadioValue} selectedOption={radioValue} />

            <div style={{ overflow: 'hidden', height: 'calc(100vh - 150px)' }}>
                {radioValue == 1 && (
                    <>
                        <ShiftOverview
                            topSlider={topSlider}
                            setTopSlider={setTopSlider} />

                        <ShiftTotalDetail
                            topSlider={topSlider}
                        />
                    </>
                )}
                {radioValue == 2 && (
                    <HourlyResult />
                )}
            </div>
            {popup && <ConfirmationPopup {...popup} />}

        </div>

    );
};
export default ShiftListing;






























































































































































































// const filterData = (excavator) => {
     //     const excavatorMatched =
    //         filterVariableRef.current.Excavator.length === 0 ||
    //         filterVariableRef.current.Excavator.some(
    //             (filterExcavator) => filterExcavator.label === excavator.name.Unit
    //         );
    //     const statusMatched =
    //         filterVariableRef.current.Status.length === 0 ||
    //         filterVariableRef.current.Status.some((status) => {
    //             const isFallingBehind = behindTargetExcavators?.includes(
    //                 excavator.name.Unit
    //             );
    //             const isNotOperating = notOperatingExcavators?.includes(
    //                 excavator.name.Unit
    //             );
    //             const isBehindTarget =
    //                 excavator.actualLoads < excavator.targetLoads && !isNotOperating;
    //             const isTargetComplete =
    //                 excavator.actualLoads >= excavator.targetLoads && !isNotOperating;

    //             switch (status.value) {
    //                 case 0:
    //                     return isTargetComplete;
    //                 case 1:
    //                     return isBehindTarget;
    //                 case 2:
    //                     return isFallingBehind;
    //                 case 3:
    //                     return isNotOperating;
    //             }
    //         });
    //     const operatorMatched =
    //         filterVariableRef.current.Operator.length === 0 ||
    //         filterVariableRef.current.Operator.some(
    //             (operator) => operator.value === excavator.operator._id
    //         );
    //     const truckMatched =
    //         filterVariableRef.current.Truck.length === 0 ||
    //         filterVariableRef.current.Truck.some((truck) =>
    //             excavator.haulTrucks.some(
    //                 (haulTruck) => haulTruck.label === truck.label
    //             )
    //         );
    //     const truckOperatorMatched =
    //         filterVariableRef.current.Operator.length === 0 ||
    //         filterVariableRef.current.Operator.some((operator) => {
    //             return excavator.haulTrucks.some(
    //                 (haulTruck) =>
    //                     trucks.find((truck) => truck.equipment === haulTruck._id)?.operator
    //                         ._id === operator.value
    //             );
    //         });

    //     // Return true if all filter conditions are met, false otherwise
    //     return (
    //         excavatorMatched &&
    //         statusMatched &&
    //         (operatorMatched || truckOperatorMatched) &&
    //         truckMatched
    //     );
    // };
