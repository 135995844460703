import React, { useState, useRef, useEffect } from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import FilterModalInputComponent from './FilterModalInputComponent';
import crossImg from '../assets/images/cross.svg'
function FilterModal(props) {
    const {
        onFilterApply,
        onCancel,
        filter,
        className,
        headerClassName
    } = props;


    return (
        <div
            className={`dashboard_filter ${className}`}
            style={{
                borderRadius: "5px",
                height: "70%",
                width: "500px",
                right: "0px",
                backgroundColor: "white",
                position: "fixed",
                bottom: "0px",
                zIndex: 999,
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 10px 14px #0000001a",
            }}
        >
            <div>
                <div
                    className={headerClassName}
                    style={{
                        display: "flex",
                        border: "0px solid black",
                        justifyContent: "space-between",
                        margin: "0px 30px",
                        marginTop: "30px",
                    }}
                >
                    <span
                        style={{
                            font: "normal normal bold 15px/20px Open Sans",
                            color: " #1A1A1A",
                        }}
                    >
                        Filter By
                    </span>
                    <div className="d-flex" style={{ width: "max-content" }}>
                        <img src={crossImg} onClick={onCancel} style={{
                            cursor: 'pointer'
                        }} />
                    </div>
                </div>
                <div
                    className='px-3'
                    style={{
                        border: "0px solid black",
                        marginLeft: "20px",
                        marginRight: "20px",
                        marginTop: "30px",
                    }}
                >
                    {props.children}
                </div>

                <div className="d-flex justify-content-between px-4 dashboard_filter_heading" style={{
                    position: 'absolute',
                    bottom: 0,
                    paddingLeft: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%', height: '7%', background: '#FAFBFD 0% 0% no-repeat padding-box',
                    border: '1px solid #E1E8F4',
                }}>

                    <div onClick={props?.onClear} style={{ color: (filter?.isCleared || filter?.count == 0) ? (props?.clearFontColor ? props?.clearFontColor : "#ADD8E6") : "black", cursor: !filter?.isCleared ? 'pointer' : 'default', textDecoration: 'underline', font: 'normal normal 600 13px/17px Open Sans' }}>
                        Clear all ({filter?.count})
                    </div>
                    <button
                        className="btn save"
                        onClick={() => {
                            onFilterApply()
                        }}
                        style={{
                            width: 80,
                            height: 25,
                            font: "normal normal bold 12px/17px Open Sans",
                            border: " 1px solid #186FD9",
                            background: "#186FD9 0% 0% no-repeat padding-box",
                            borderRadius: 4,
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                    >
                        Apply
                    </button>
                </div>


            </div>
        </div>
    )
}
export default FilterModal
