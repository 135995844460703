import { isUndefined } from "lodash";
import React, { useState } from "react";
import search from "../../../../assets/images/search-input.svg";
import clear from "../../../../assets/images/clear.png";

const Search = ({
  query = "",
  onChange,
  containerStyle = {},
  inputStyle = {},
  count,
  showCount,
  onClear,
  className
}) => {
  const [searchStr, setSearchStr] = useState(query);

  const handleOnChange = (e) => {
    if (!isUndefined(e?.target?.value)) {
      setSearchStr(e.target.value);
      onChange(e.target.value);
    } else {
      setSearchStr("");
      onChange("");
    }
  };

  const handleOnClear = () => {
    setSearchStr("");
    onClear();
  };

  return (
    <div
      className={className}
      style={{
        border: "1px solid #E1E8F4",
        borderRadius: "15px",
        background: "white",
        fontSize: "12px",
        width: "160px",
        display: "flex",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <input
        type="text"
        placeholder="Search"
        value={searchStr}
        onChange={handleOnChange}
        style={{
          border: "none",
          outline: "none",
          background: "transparent",
          height: "30px",
          padding: "0px 10px",
          width: "120px",
          ...inputStyle,
        }}
      />
      {(showCount || onClear) && query !== "" ? (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {showCount && (
            <div
              style={{ borderRight: "1px solid #E1E8F4", paddingRight: "5px" }}
            >
              {count}
            </div>
          )}
          {onClear && (
            <img
              src={clear}
              alt="clear"
              style={{ cursor: "pointer" }}
              onClick={handleOnClear}
            />
          )}
        </div>
      ) : (
        <img className="ml-3" src={search} alt="search" />
      )}
    </div>
  );
};

export default Search;
