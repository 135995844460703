import React, { useState, useEffect } from "react";
import {
    VictoryBar,
    VictoryChart,
    VictoryLine,
    VictoryTheme,
    VictoryAxis,
} from "victory";
import ConfirmationPopup from "../../Popup";
import { Image } from "react-bootstrap";
import {
    formatData,
    getHourlyTarget,
    isCurrentBar,
    utcToNZTime,
} from "../../../../utils/helper";
import { toast } from 'react-toastify';
import { CallWithAuth } from "../../../../action/apiActions";
import { END_SHIFT } from "../../../../action/apiPath";



export default function TruckGraphRow(props) {
    const { isFallingBehind, shiftTiming, truck, getMaximumDomain, shiftInfo } = props;
    const [popup, setPopup] = useState();
    const [target, setTarget] = useState(0);

    const endShifts = async (closePopup, data) => {
        const response = await CallWithAuth('POST', END_SHIFT, {
            type: 'single',
            shiftId: shiftInfo?._id,
            ...data
        });
        if (response?.res?.data?.status === 200) {
            toast.success(`Shift ended successfully for truck ${data?.name}.`, {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            closePopup();
        } else {
            toast.error('An error occured when ending the shift.', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    useEffect(() => {

        setTarget(Math.round(getHourlyTarget(
            truck?.productive_hours,
            parseInt(truck?.targetLoads, 10)
        )))
    }, [truck])


    return (
        <div style={{ opacity: !props.unScheduled && truck.breakdown ? .5 : 1 }} className="d-flex justify-content-between align-items-center">
            <div
                className="truck_cover"
                style={{
                    width: "260px",
                    height: "270px",
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    padding: 20,
                    borderRadius: " 8px",
                    // borderLeft:
                    //     "20px solid " +
                    //     (props.unScheduled ? "#707070" :
                    //         truck.breakdown
                    //             ? "#ED7C7F"
                    //             : isFallingBehind
                    //                 ? "#95C365"
                    //                 : "#F5BF6C"),
                    borderLeft: props.unScheduled == true ? "20px solid #707070" : "none",
                    border: "1px solid #E1E8F4",

                }}
            >
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ paddingBottom: "25px" }}
                >
                    <span
                        style={{
                            font: "normal normal bold 20px/27px Open Sans",
                        }}
                    >
                        {truck?.truckName}
                    </span>
                    {!props.unScheduled && truck.breakdown ? (
                        <Image
                            src={require("../../../../assets/images/not-available.png")}
                            height={30}
                            width={30}
                        // style={{ marginTop: 5 }}
                        />
                    ) : !props.unScheduled && isFallingBehind ? (
                        <Image
                            src={require("../../../../assets/images/alert.png")}
                            height={18}
                            width={18}
                            style={{ marginTop: 5 }}
                        />
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className="d-flex flex-column justify-content-between"
                    style={{ height: "130px" }}
                >
                    <span
                        style={{
                            font: "normal normal normal 14px/22px Open Sans",
                            color: "#707070",
                        }}
                    >
                        Total Loads:{" "}
                        <span
                            style={{
                                font: "normal normal 600 16px/22px Open Sans",
                                color: "#1A1A1A",
                            }}
                        >
                            {!props.unScheduled ? `${truck?.actualLoads} / ${truck?.targetLoads}` : truck?.actualLoads}

                        </span>
                    </span>
                    <span
                        style={{
                            font: "normal normal normal 14px/22px Open Sans",
                            color: "#707070",
                        }}
                    >
                        Hourly Target:{" "}
                        <span
                            style={{
                                font: "normal normal 600 16px/22px Open Sans",
                                color: "#1A1A1A",
                            }}
                        >
                            {" "}
                            {!props.unScheduled ? target : '-'}
                        </span>
                    </span>
                    <span
                        style={{
                            font: "normal normal normal 14px/22px Open Sans",
                            color: "#707070",
                        }}
                    >
                        First Load:{" "}
                        <span
                            style={{
                                font: "normal normal 600 16px/22px Open Sans",
                                color: "#1A1A1A",
                            }}
                        >
                            {" "}
                            {utcToNZTime(truck?.firstBucket)?.formattedTime ||
                                "-"}
                        </span>
                    </span>
                    <span
                        style={{
                            font: "normal normal normal 14px/22px Open Sans",
                            color: "#707070",
                        }}
                    >
                        Operator:{" "}
                        <span
                            style={{
                                font: "normal normal 600 16px/22px Open Sans",
                                color: "#1A1A1A",
                            }}
                        >
                            {" "}
                            {truck?.operator?.firstName || "-"}
                        </span>
                    </span>
                </div>
                {shiftInfo?.status == 'O' && !props.unScheduled && <button
                    className="btn btn-outline-info"
                    disabled={true}
                    onClick={() => {
                        return
                        setPopup({
                            title: `You're about to end the shift for TRUCK ${truck?.truckName}`,
                            description: 'Would you like to continue?',
                            confirmText: 'Confirm',
                            onCancel: () => {
                                setPopup(undefined);
                            },
                            onConfirm: () => {
                                endShifts(() => setPopup(undefined), {
                                    name: truck.truckId,
                                    _id: truck.truckName
                                });
                            },
                        });
                    }}
                    style={{ marginTop: 10, width: '40%' }}
                >
                    End Shift
                </button>}
            </div>

            <div
                style={{
                    display: "flex",
                    width: "calc(100% - 350px)",
                    height: "330px",
                    marginLeft: "40px",
                    background:
                        "0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)",

                    borderRadius: "8px",
                    alignItems: "center",
                }}
            >
                <div className="d-flex justify-content-end align-items-center">
                    <span
                        style={{
                            transform: "rotate(270deg)",
                            height: "max-content",
                            fontWeight: "700",
                        }}
                    >
                        LOADS
                    </span>
                    <div>
                        <VictoryChart
                            width={1180}
                            height={300}
                            domainPadding={{ x: 25 }}

                        >
                            <VictoryBar
                                alignment="middle"
                                barWidth={10}
                                // animate={true}
                                events={[{
                                    target: "data",
                                    eventHandlers: {

                                        onMouseOver: () => {

                                            return [{
                                                target: 'labels',
                                                mutation: (props) => {
                                                    // if (props.datum)
                                                    //     console.log(props);
                                                    if (props.datum.earnings >= target)
                                                        return {
                                                            style: Object.assign({}, props.style, { fill: "black" })
                                                        };
                                                }
                                            }];
                                        },
                                        onMouseOut: () => {
                                            return [{
                                                target: 'labels',
                                                mutation: (innerProps) => {
                                                    if (innerProps.datum.earnings >= target)
                                                        return {
                                                            style: Object.assign({}, innerProps.style, { fill: ({ datum }) => isCurrentBar(datum.quarter, shiftTiming?.end) ? "black" : datum.earnings >= target ? "black" : "black" })
                                                        };
                                                }
                                            }];
                                        }
                                    }
                                }]}
                                data={formatData(
                                    props.unScheduled ? props?.loads : truck.hourlyData,
                                    shiftInfo?.type,
                                    truck,
                                    props.breakdown
                                )}
                                // labels={""}
                                style={{
                                    background: { fill: (re) => "yellow" },
                                    data: {
                                        fill: ({ datum }) =>
                                            isCurrentBar(datum.quarter, shiftTiming?.end)
                                                ? '#80B4FD'
                                                : props.unScheduled
                                                    ? '#707070'
                                                    : datum.earnings >= target
                                                        ? "#95C365"
                                                        : "#FE747C",
                                        cursor: ({ datum }) => datum.earnings >= target ? "pointer" : "default",
                                    },

                                    labels: {
                                        fontSize: 15,
                                        marginLeft: 50,

                                        fill: ({ datum }) =>
                                            isCurrentBar(datum.quarter, shiftTiming?.end) ? "black" : datum.earnings >= target ? "black" : "black",
                                    },
                                }}
                                theme={VictoryTheme.grayscale}
                                x="quarter"
                                y="earnings"
                                cornerRadius={5}
                            />
                            <VictoryAxis
                                dependentAxis
                                tickFormat={(tick) => {
                                    if (tick == Math.round(tick))
                                        return tick;
                                    return ''
                                }}
                                domain={[
                                    0,
                                    getMaximumDomain(
                                        Math.max(
                                            ...formatData(
                                                props.unScheduled ? props?.loads : truck.hourlyData,
                                                shiftInfo?.type,
                                                truck
                                            ).map((v) => v.earnings)
                                        ),
                                        target
                                    ),
                                ]}

                                style={{
                                    axis: { stroke: "black" },
                                    ticks: { stroke: "black" },
                                    tickLabels: { fill: "black" },
                                }}
                            />
                            <VictoryAxis
                                style={{
                                    axis: { stroke: "black" },
                                    ticks: { stroke: "black" },
                                    tickLabels: {
                                        fill: "black",
                                        fontWeight: ({ text }) =>
                                            isCurrentBar(text, shiftTiming?.end) ? "700" : "100",

                                        fontSize: ({ text }) =>
                                            isCurrentBar(text, shiftTiming?.end) ? "18px" : "17px"
                                    }
                                }}
                            />
                            <VictoryLine
                                y={() =>
                                    target
                                }
                                style={{
                                    data: { stroke: "black" },
                                }}
                            />
                        </VictoryChart>
                    </div>
                </div>
            </div>
            {popup && <ConfirmationPopup {...popup} />}
        </div>

    )
}
