import { useState } from 'react';
import { Link } from 'react-router-dom';

import GuestLayout from '../../components/GuestLayout';
import usePasswordToggle from '../../hooks/usePasswordToggle';

const LoginWithTemporarayPassword = () => {
  const [passwordInputType, PasswordToggleIcon] = usePasswordToggle();

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    isError: false,
  });

  const handelOnchange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      isError: false,
    });
  };

  const handelValidateForm = () => {
    if (formData.isError && formData.email !== '' && formData.password !== '')
      return Promise.resolve(true);
    return Promise.resolve(false);
  };

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();
    // console.log(formData.email, formData.password);
    let response = await handelValidateForm();
    if (response) {
      // axios
    } else {
      setFormData({ ...formData, isError: true });
    }
  };

  return (
    <GuestLayout>
      <section className="before_login_screen">
        <div className="before_main_login_box">
          <div className="before_logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="login_box">
            <hgroup className="before_login_heading">
              <h1>Log With Temporary Password</h1>
              <p>
                A temporary password has been sent to you. Please login again
                using the temporary password.
              </p>
            </hgroup>
            <form onSubmit={forgotPasswordHandler}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div
                      className="input_field_wrap"
                      style={{
                        border:
                          formData.isError && formData.email === ''
                            ? '1px solid red'
                            : '',
                      }}
                    >
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handelOnchange}
                      />
                      <label>Email*</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div
                      className="input_field_wrap"
                      style={{
                        border:
                          formData.isError && formData.password === ''
                            ? '1px solid red'
                            : '',
                      }}
                    >
                      <input
                        type={passwordInputType}
                        className="form-control"
                        id="pwd"
                        name="password"
                        value={formData.password}
                        onChange={handelOnchange}
                      />
                      <label>Password*</label>
                      <div className="onpwd">{PasswordToggleIcon}</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      className="form-control login_button"
                      value="Log In"
                      id="login-btn"
                    />
                  </div>
                </div>
                <div className="col-md-12 forgot_password">
                  <div className="form-group">
                    <p>
                      Didn't receive the temporary password?{' '}
                      <Link to="/">Resend</Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="before_login_footer_menu">
            <ul>
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Terms of services</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default LoginWithTemporarayPassword;
