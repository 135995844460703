import React from "react";
import styles from "./EquimentPlan.module.css";

const EquipmentPlanHeaders = () => {
  const headers = [
    { label: "Plant ID", width: "10%" },
    { label: "Operator", width: "10%" },
    { label: "Material", width: "10%" },
    { label: "Source Area", width: "10%" },
    { label: "Dump Area", width: "10%" },
    { label: "Target", width: "10%" },
    { label: "Category", width: "10%" },
  ];
  return (
    <div className={styles.headersContainer}>
      {headers.map((e) => {
        return <div className={styles.header} style={{ width: e.width }}>{e.label}</div>;
      })}
    </div>
  );
};

export default EquipmentPlanHeaders;
