import { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { CallWithAuth } from '../../../action/apiActions';

const useSession = () => {
    const [editSession, setEditSession] = useState({ startedAt: null, endedAt: null, by: null });
    const [seconds, setSeconds] = useState(0);
    const [mySession, setMySession] = useState(false)
    const stopFetchingRef = useRef(false);
    const editSessionRef = useRef({ startedAt: null, endedAt: null, by: null });
    useEffect(() => {
        let interval;
        editSessionRef.current = editSession;

        if (!editSession?.by) {
            const sec = Math.max(
                Math.floor((new Date(editSession.willEndAt).getTime() - Date.now()) / 1000),
                0
            );

            const initialSeconds = sec;
            setSeconds(initialSeconds);

            if (initialSeconds > 0) {
                interval = setInterval(() => {
                    setSeconds((prevSeconds) => {
                        const newSeconds = prevSeconds - 1;

                        if (newSeconds <= 0) {
                            setEditSession({ startedAt: null, endedAt: null, by: null });
                            stopFetchingRef.current = false;
                            setMySession(false);
                            clearInterval(interval); // Stop the timer when time is up
                        }

                        return newSeconds;
                    });
                }, 1000);
            }
        }

        return () => clearInterval(interval); // Cleanup interval on unmount or when dependencies change

    }, [editSession]);

    const startSession = async (newShiftId, status) => {
        try {
            if (editSession.willEndAt)
                return
            const now = new Date();
            const payload = {
                newShiftId: newShiftId,
                startedAt: now.toISOString(),
                status: status,
                willEndAt: new Date(now.getTime() + 600000).toISOString(),
            }
            const response = await CallWithAuth("POST", "api/shift-planning/session", payload);
            if (response?.res?.data?.status === 200) {
                stopFetchingRef.current = true
                setEditSession({ ...payload, by: null })
                setMySession(true)
                toast.success(
                    "Session Started Successfully.",
                    {
                        position: "bottom-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            } else {
                toast.error(
                    response?.res?.data?.errors
                        ? response?.res?.data?.errors
                        : "Error occured while creating Session.",
                    {
                        position: "bottom-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
        } catch (error) {
            toast.error(
                "Error occured while starting session.",
                {
                    position: "bottom-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
            throw new Error("Error occured while starting session");
        }
    }

    const endSession = async (newShiftId, status) => {
        try {
            const payload = {
                newShiftId: newShiftId,
                willEndAt: new Date().toISOString(),
                status: status,
            }
            // if (shiftStatus && shiftStatus != ShiftStatus.NEW)
            // await Promise.all([fetchData(), fetchShiftDetails(queryParams.get("id"))]);
            const response = await CallWithAuth("POST", "api/shift-planning/session", payload);
            if (response?.res?.data?.status === 200) {

                setEditSession({ startedAt: null, endedAt: null, by: null })
                stopFetchingRef.current = false
                setMySession(false)
                toast.success(
                    "Session Ended Successfully.",
                    {
                        position: "bottom-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            } else {
                toast.error(
                    response?.res?.data?.errors
                        ? response?.res?.data?.errors
                        : "Error occured while Ending Session.",
                    {
                        position: "bottom-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                return;
            }
        } catch (error) {
            toast.error(
                "Error occured while ending session.",
                {
                    position: "bottom-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
            return;

        }
    }

    return {
        seconds,
        startSession,
        editSession,
        setEditSession,
        endSession,
        setMySession,
        mySession,
        stopFetchingRef,
        editSessionRef
    };
};

export default useSession;
