import React, { useState, useRef, useEffect } from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
function FilterModal(props) {
    const {
        filterVariableAfterApply,
        onFilterApply,
        onCancel,
        optionsForTruck,
        optionsForExacavator,
        optionsForOperator,
        optionsForStatus,
    } = props;

    const [filterVariable, setFilterVariable] = useState(filterVariableAfterApply ? filterVariableAfterApply : {
        Excavator: [],
        Status: [],
        Operator: [],
        Truck: [],
    });
    const [isFilterSelected, setIsFilterSelected] = useState(0)
    useEffect(() => {
        // console.log("hello", filterVariableAfterApply)
        let x = 0;
        if (filterVariable?.Excavator?.length > 0)
            x++;
        if (filterVariable?.Truck?.length > 0)
            x++;
        if (filterVariable?.Operator?.length > 0)
            x++;
        if (filterVariable?.Status?.length > 0)
            x++;
        setIsFilterSelected(x)
    }, [filterVariable])

    //   const excavatorFreshData = useRef([])
    const handleSelect = (selected, arrayName) => {
        setFilterVariable((prevSelectedOptions) => {
            const newArray = [...prevSelectedOptions[arrayName]];
            const selectedValues = selected?.map((option) => option?.value);
            newArray?.push(
                ...selected?.filter(
                    (option) => !newArray?.some((o) => o?.value === option?.value)
                )
            );
            const deselectedValues = newArray
                ?.filter((option) => !selectedValues?.includes(option?.value))
                ?.map((option) => option?.value);
            const newFilteredArray = newArray?.filter(
                (option) => !deselectedValues?.includes(option?.value)
            );
            return { ...prevSelectedOptions, [arrayName]: newFilteredArray };
        });
    };

    return (
        <div
            className="dashboard_filter"
            style={{
                // border: "2px solid grey",
                borderRadius: "5px",
                height: "70%",
                // width: "40rem",
                // height: "650px",
                width: "500px",
                right: "20px",
                backgroundColor: "white",
                position: "absolute",
                bottom: "12px",
                // justifyContent: 'space-between',
                zIndex: 999,
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 10px 14px #0000001a",
            }}
        >
            <div
                className="dashboard_filter_heading"
                style={{
                    display: "flex",
                    border: "0px solid black",
                    justifyContent: "space-between",
                    margin: "0px 30px",
                    marginTop: "30px",
                }}
            >
                <span
                    style={{
                        font: "normal normal bold 15px/20px Open Sans",
                        color: " #1A1A1A",
                    }}
                >
                    Filter By
                </span>
                <div className="d-flex" style={{ width: "max-content" }}>
                    <button
                        style={{
                            width: 80,
                            height: 25,
                            font: "normal normal bold 12px/17px Open Sans",
                            color: "#186FD9",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                            border: " 1px solid #186FD9",
                            borderRadius: 4,
                        }}
                        className="btn discard"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn save ml-3"
                        onClick={() => {
                            onFilterApply(filterVariable)
                        }}
                        style={{
                            width: 80,
                            height: 25,
                            font: "normal normal bold 12px/17px Open Sans",
                            border: " 1px solid #186FD9",
                            background: "#186FD9 0% 0% no-repeat padding-box",
                            borderRadius: 4,
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                    >
                        Apply
                    </button>
                </div>
            </div>
            <div
                style={{
                    border: "0px solid black",
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "120px",
                }}
            >
                <div className="col-md-12 form-group">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-3">
                            <label
                                style={{ font: "normal normal normal 14px/19px Open Sans" }}
                            >
                                Excavator
                            </label>
                        </div>
                        <div className="col-md-9 custom_select">
                            {/* {console?.log(optionsForExacavator)} */}
                            <ReactMultiSelectCheckboxes
                                onChange={(selected) => handleSelect(selected, "Excavator")}
                                options={optionsForExacavator}
                                value={filterVariable?.Excavator}
                                styles={(provided, state) => ({
                                    ...provided,
                                    height: 10,
                                })
                                }


                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 form-group">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-3">
                            <label
                                style={{ font: "normal normal normal 14px/19px Open Sans" }}
                            >
                                Status
                            </label>
                        </div>
                        <div className="col-md-9 custom_select">
                            {/* {console?.log(optionsForStatus)} */}
                            <ReactMultiSelectCheckboxes
                                onChange={(selected) => handleSelect(selected, "Status")}
                                maxHeight={50}
                                options={optionsForStatus}
                                value={filterVariable?.Status}
                            // selected={filterVariable?.Status}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 form-group">
                    <div className="row d-flex align-items-center" style={{ height: "50px" }}>
                        <div className="col-md-3">
                            <label
                                style={{ font: "normal normal normal 14px/19px Open Sans" }}
                            >
                                Operator
                            </label>
                        </div>
                        <div className="col-md-9 custom_select" style={{ height: "px" }} >
                            <ReactMultiSelectCheckboxes
                                maxHeight={50}
                                onChange={(selected) => handleSelect(selected, "Operator")}
                                options={optionsForOperator}
                                value={filterVariable?.Operator}
                                styles={{
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: '170px', // set the maximum height of the menu list
                                        overflowY: 'auto', // enable vertical scrolling if the content overflows
                                    }),
                                }}

                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 form-group">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-3">
                            <label
                                style={{ font: "normal normal normal 14px/19px Open Sans" }}
                            >
                                Truck{" "}
                            </label>
                        </div>
                        <div className="col-md-9 custom_select">
                            {/* {console?.log("jhhh",optionsForExacavator)} */}
                            <ReactMultiSelectCheckboxes
                                maxHeight={50}
                                options={optionsForTruck}
                                onChange={(selected) => handleSelect(selected, "Truck")}
                                value={filterVariable?.Truck}
                                styles={{
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: '128px',
                                        overflowY: 'auto',
                                    })
                                }}

                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                // justifySelf: 'flex-end',
                position: 'absolute',
                bottom: 0,
                paddingLeft: '14px',
                display: 'flex',
                alignItems: 'center',
                width: '100%', height: '7%', background: '#FAFBFD 0% 0% no-repeat padding-box',
                border: '1px solid #E1E8F4'
            }}>
                <span onClick={() => {
                    if (isFilterSelected)
                        setFilterVariable({
                            Excavator: [],
                            Status: [],
                            Operator: [],
                            Truck: [],
                        })
                }} style={{ color: !isFilterSelected ? "#ADD8E6" : "black", cursor: isFilterSelected ? 'pointer' : 'default', textDecoration: 'underline', font: 'normal normal 600 13px/17px Open Sans' }}>
                    Clear all ({isFilterSelected})
                </span>
            </div>
        </div>
    )
}
export default FilterModal
