import React from 'react';
import { FaPlus } from 'react-icons/fa';
import styles from './styles.module.css'

export default function AddEquipmentButton({ onClick, label, isDisabled }) {
    return (
        <div
            className={styles.button}
            onClick={(e) => {
                e.stopPropagation()
                if (!isDisabled)
                    onClick(e)
            }}
        >
            <FaPlus size={11} />
            <span>&nbsp;{label}</span>
        </div>
    );
}
