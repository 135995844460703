import { IoMdClose } from 'react-icons/io';

export default function ConfirmationPopup(props) {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        background:
          '0% 0% no-repeat padding-box padding-box rgb(110 110 110 / 30%)',
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10000,
      }}
    >
      <div
        style={{
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          boxShadow: '0px 3px 6px #00000029',
          borderRadius: 10,
          padding: '15px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          width: 400,
      }}
      >
        <IoMdClose
          onClick={props.onCancel}
          size={16}
          style={{ cursor: 'pointer', marginBottom: 20, marginLeft: 'auto' }}
        />
        <span
          style={{
            font: ' normal normal bold 16px/18px Open Sans',
            color: '#1A1A1A',
            marginBottom: 20,
            maxWidth: 275,
          }}
        >
          {props.title}
        </span>
        <span
          style={{
            paddingBottom: '10px',
            font: ' normal normal normal 16px/30px Open Sans',
            letterSpacing: '0.32px',
            color: '#1A1A1A',
          }}
        >
          {props.description}
        </span>
        <div
          className="d-flex justify-content-center"
          style={{ width: '100%' }}
        >
          <button className="btn btn-primary btn-lg" onClick={props.onConfirm}>
            {props.confirmText}
          </button>
        </div>
      </div>
    </div>
  );
}
