import { REACT_APP_API_ENDPOINT } from "../action/apiPath";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  // timeout: 15000,
  headers: { "content-type": "application/json" },
});

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("interceptor request sent config: \n", config)
    return config;
  },
  function (error) {
    console.log("error in request interceptor:--\n", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // console.log("interceptor response get config: \n", response)
    return response;
  },
  function (error) {
    console.log("error in response interceptor:--\n", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;

// delete axios.defaults.headers.common.Authorization;
// axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
