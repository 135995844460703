import React, { useState } from "react";
import Flyer from "../../components/Flyers/Flyer";
import ShiftDetails from "./Sections/ShiftDetails";
import { DateTimeFormats, ShiftStatus } from "../../utils/contants";
import moment from "moment";
import History from "../HistoryTab/History";
import ConfirmAlert from "../../components/ConfirmAlert/ConfirmAlert";
import { useAddDailyPlan } from "../../Context/context";
import { getTargetLoads } from "../../utils/helper";
import { getShiftUpdates } from "../../utils/helpers/FormUpdates";
import { updateExcavatorDetails, validate } from "./utils/helper";

export default function GeneralShiftDetails({
  setShiftDataSuccessfullySaved
}) {
  const {
    canEditShift,
    shiftStatus,
    originalShiftData,
    isGeneralShiftFlyerOpen,
    assignedExcavatorDetailsFormData,
    generalShiftPlanDetailsFormData,
    //Functions
    closeFlyer,
    startSession,
    setAssignedExcavatorDetailsFormData,
    setGeneralShiftPlanDetailsFormData,
    //Fetched Data
    minesList,
    departmentsList,
    crewTypesList
  } = useAddDailyPlan();

  const [formData, setFormData] = useState({
    reportNo: generalShiftPlanDetailsFormData?.reportNo,
    mine: generalShiftPlanDetailsFormData?.mine || { label: "Open-Pit", value: minesList?.find((mine) => mine.Mine == "Open-Pit")._id },
    shiftType: generalShiftPlanDetailsFormData?.shiftType,
    department: generalShiftPlanDetailsFormData?.department || { label: "Production", value: departmentsList?.find((department) => department.DepartmentType == "Production")._id },
    crew: generalShiftPlanDetailsFormData?.crew || {
      label: "A", value: crewTypesList?.find((crew) => crew.CrewType == "A")._id
    },
    startTime: generalShiftPlanDetailsFormData?.startTime,
    endTime: generalShiftPlanDetailsFormData?.endTime,
    productiveHours: generalShiftPlanDetailsFormData?.productiveHours,
    shiftSupervisor: generalShiftPlanDetailsFormData?.shiftSupervisor,
    leadingHand: generalShiftPlanDetailsFormData?.leadingHand,
    annualLeave: generalShiftPlanDetailsFormData?.annualLeave ?? [],
    sickLeave: generalShiftPlanDetailsFormData?.sickLeave ?? [],
    absent: generalShiftPlanDetailsFormData?.absent ?? [],
    generalShiftNotes: generalShiftPlanDetailsFormData?.generalShiftNotes,
  });
  const [showError, setShowError] = useState(false);
  const [viewOnly, setViewOnly] = useState(shiftStatus != ShiftStatus.NEW);
  const [showDateTimeChangeWarning, setShowDateTimeChangeWarning] = useState({
    show: false,
    title: "Changing productive hours, start time, or end time will update conflicting values in the excavator and truck plans.",
    message: "Please make sure to set these values correctly. Are you sure you want to proceed with this update?"
  });

  //?custom Hook can be created
  const [search, setSearch] = useState({
    query: "",
    count: 0,
  });
  const handleOnClear = () => {
    setSearch({
      query: "",
      count: 0,
    });
  };

  const shiftGeneralDetailsSaveHandler = async (data) => {
    if (shiftStatus !== ShiftStatus.NEW && originalShiftData) {
      const shiftUpdates = getShiftUpdates(originalShiftData, data);
      if (shiftUpdates.length > 0 && shiftStatus !== ShiftStatus.NEW) {
        try {
          await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus);
        } catch (error) {
          console.log(error);
          return;
        }
      }
    }

    setGeneralShiftPlanDetailsFormData(prev => ({
      ...prev,
      ...data,
    }));

    setAssignedExcavatorDetailsFormData(prev => updateExcavatorDetails(prev, data, shiftStatus, getTargetLoads));

    setShiftDataSuccessfullySaved(true);
    closeFlyer("general");
  };

  return (
    <>
      {showDateTimeChangeWarning.show && (
        <ConfirmAlert
          title={showDateTimeChangeWarning.title}
          message={showDateTimeChangeWarning.message}
          onClose={() => {
            setShowDateTimeChangeWarning((prev) => ({
              ...prev,
              show: false,
            }));
          }}
          onConfirm={() => {
            shiftGeneralDetailsSaveHandler(formData);
            setShowDateTimeChangeWarning((prev) => ({
              ...prev,
              show: false,
            }));
          }}
        />
      )}
      <Flyer
        isOpen={isGeneralShiftFlyerOpen}
        onClose={() => {
          closeFlyer("general")
        }}
        title={`Shift ${generalShiftPlanDetailsFormData?.reportNo} - Shift Details`}
        tabs={[
          {
            title: "Shift Details",
            leftActionButtons: viewOnly && shiftStatus != ShiftStatus.ENDED && canEditShift ? [
              {
                label: "Edit",
                style: { border: "1px solid  #186FD9", },
                onClick: () => {
                  setViewOnly(false);
                },
              }
            ] : [],
            rightActionButtons: !viewOnly && shiftStatus != ShiftStatus.ENDED && canEditShift ? [
              {
                label: "Save",
                onClick: () => {
                  if (validate(formData)) {

                    if ((formData.startTime != generalShiftPlanDetailsFormData?.startTime || formData.endTime != generalShiftPlanDetailsFormData?.endTime || formData.productiveHours?.value != generalShiftPlanDetailsFormData?.productiveHours?.value) && assignedExcavatorDetailsFormData?.length) {
                      setShowDateTimeChangeWarning(prev => ({ ...prev, show: true }))
                    } else
                      shiftGeneralDetailsSaveHandler(formData);
                  } else {
                    setShowError(true)
                  }
                },
              }
            ] : [],
            component: (
              <ShiftDetails
                setFormData={setFormData}
                formData={formData}
                showError={showError}
                viewOnly={viewOnly || !canEditShift}
              />
            ),
          },
          {
            title: "History",
            component: <History search={search} setSearch={setSearch} shiftId={generalShiftPlanDetailsFormData.newShiftId} />,
          },
        ]}
        search={true}
        searchString={search.query}
        searchCount={search.count}
        onSearchInput={(val) => {
          setSearch({ ...search, query: val });
        }}
        handleOnClear={handleOnClear}
      />
    </>
  );
}
