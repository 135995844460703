import React, { useState } from "react";
import styles from "./ReassignTrucks.module.css";

const ReassignTrucks = ({
  truck,
  removed,
  onClose,
  trucks,
  excavators,
  handleReassignTruck,
  handleReassignTrucks,
}) => {
  const [selectedExcavatorPlantId, setSelectedExcavatorPlantId] = useState(excavators?.length == 1 ? excavators[0]?.plantId : null);
  const [selectedTrucks, setSelectedTrucks] = useState([]);

  const handleClose = () => {
    document.body.style.height = "100%";
    document.body.style.overflow = "auto";
    onClose();
  };

  const handleExcavatorChange = (plantId) => {
    setSelectedExcavatorPlantId(plantId);
  }

  const handleCheckboxChange = (item) => {
    if (selectedTrucks.find((e) => item.label === e.label)) {
      setSelectedTrucks(
        selectedTrucks.filter((selectedItem) => selectedItem.label !== item.label)
      );
    } else {
      setSelectedTrucks([...selectedTrucks, item]);
    }
  };

  const handleAllCheckboxChange = () => {
    if (selectedTrucks.length === trucks.length) {
      setSelectedTrucks([]);
    } else {
      setSelectedTrucks(trucks);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.top}>
          <div>
            {truck ? (
              removed ? (
                <span>Reassign to Shift</span>
              ) : (
                <span>Reassign Truck</span>
              )
            ) : (
              <span>Reassign Trucks</span>
            )}
            <img src="assets/images/close.png" onClick={handleClose} />
          </div>
          <div style={{ fontSize: "15px", lineHeight: 1.5, fontWeight: "normal" }}>
            {truck ? (
              <span>
                Please select the excavator you would like to reassign truck{" "}
                <span style={{ fontWeight: "bold", fontWeight: "bolder" }}>
                  {truck.plantId || truck.label}
                </span>{" "}
                to.
              </span>
            ) : (
              <span>
                Please select the trucks you wish to reassign and the excavator
                you would like to reassign them to.
              </span>
            )}
          </div>
        </div>
        <div className={styles.mid}>
          {!truck && (
            <div className={styles.trucks}>
              <div className={styles.header}>
                Select Trucks <span>(to be reassigned)</span>
              </div>
              <div className={styles.options}>
                <div
                  className={styles.check}
                  onClick={handleAllCheckboxChange}
                  style={{
                    position: "sticky",
                    top: "0px",
                    background: "white",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={
                      trucks.length !== 0 &&
                      selectedTrucks.length === trucks.length
                    }
                    style={{ cursor: "pointer" }}
                    onChange={handleAllCheckboxChange}
                  />
                  <div>Select All</div>
                </div>
                {trucks?.map((trk, idx) => {
                  return (
                    <div
                      onClick={() => handleCheckboxChange(trk)}
                      className={styles.check}
                      key={idx}
                    >
                      <input
                        type="checkbox"
                        checked={selectedTrucks.find((e) => trk.label === e.label)}
                        onChange={() => handleCheckboxChange(trk)}
                      />
                      {trk.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className={styles.excavators}>
            <div className={styles.header}>
              Select Excavator {!truck && <span>(reassign trucks to)</span>}
            </div>
            <div className={styles.options}>
              {excavators?.map((exc, idx) => {
                return (
                  <label className={styles.check} key={idx}>
                    <input
                      type="radio"
                      value={exc?.plantId}
                      checked={selectedExcavatorPlantId === exc.plantId}
                      name="reassignTo"
                      onChange={(e) => handleExcavatorChange(e.target.value)}
                    />
                    <span>{exc?.plantId}</span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          {truck ? (
            <div
              className={styles.btn}
              onClick={() => {
                if (selectedExcavatorPlantId) {
                  handleReassignTruck(selectedExcavatorPlantId, truck);
                }
              }}
              style={
                !selectedExcavatorPlantId
                  ? {
                    backgroundColor: "#E1E8F4",
                    color: "#8190AD",
                  }
                  : {}
              }
            >
              Reassgin Truck
            </div>
          ) : (
            <div
              className={styles.btn}
              onClick={() => {
                if (selectedExcavatorPlantId && selectedTrucks.length > 0) {
                  handleReassignTrucks(selectedExcavatorPlantId, selectedTrucks);
                }
              }}
              style={
                !(selectedExcavatorPlantId && selectedTrucks.length > 0)
                  ? {
                    backgroundColor: "#E1E8F4",
                    color: "#8190AD",
                  }
                  : {}
              }
            >
              Reassgin Trucks
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReassignTrucks;
