import React, { useState } from "react";
import AuthLayout from "../components/AuthLayout";

const Test = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    city: [],
    crew: "",
    checked: "",
    isError: false,
  });
  const [modalState, setModalState] = useState(false);


  const handelOnchange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      isError: false,
    });
  };

  const handelValidateForm = () => {
    if (
      formData.firstName !== "" &&
      formData.city !== [] &&
      formData.crew !== ""
    )
      return Promise.resolve(true);
    return Promise.resolve(false);
  };

  const onSubmitHandler = async () => {
    let validationStatus = await handelValidateForm();
    if (validationStatus) {
      // console.log(formData);
    } else {
      setFormData({ ...formData, isError: true });
    }
  };

  let modalStyle;
  if (modalState) {
    modalStyle = "add_new_user_box active_user";
  } else {
    modalStyle = "add_new_user_box";
  }

  // console.log(formData);

  return (
    <AuthLayout pageName="Test">
      <div className="right_sidebar_body">
        <div className="top_middle">
          <div className="top_middle_left">
            <a href="#" onClick={() => setModalState(true)}>
              Add Users
            </a>
          </div>
        </div>

        {/* --------------MODAL------------ */}

        <div className={modalStyle} id="add_new_user">
          <div className="add_new_user_box_top">
            <div className="add_new_user_box_top_heading">
              <h3>{"Add"} User</h3>
            </div>
            <div className="add_new_user_box_top_right">
              <button
                className="btn discard"
                onClick={() => setModalState(false)}
              >
                Discard
              </button>
              <button className="btn save" onClick={onSubmitHandler}>
                save
              </button>
            </div>
          </div>
          <div className="add_new_user_box_middle">
            <div className="row">
              <div className="col-md-12 form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="firstName"
                      id="fname"
                      onChange={handelOnchange}
                      value={formData.firstName}
                      style={{
                        border:
                          formData.isError && formData.firstName === ""
                            ? "1px solid red"
                            : "",
                      }}
                    />
                    <span className="form-control-message">
                      {formData.isError &&
                        formData.firstName === "" &&
                        "Please enter First Name..."}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-12 form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      City <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  {/* <div className="col-md-8">
                    <Multiselect
                      isObject={false}
                      options={multiSelectOptions}
                      onSelect={(e) => onaddMultiSelect(e)} // Function will trigger on select event
                      onRemove={(e) => onaddMultiSelect(e)}
                      //   showCheckbox
                    />
                    <span className="form-control-message">
                      {formData.isError &&
                        formData.city.length === 0 &&
                        "Select a City..."}
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="col-md-12 form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Crew <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <select
                      name="Crew"
                      onChange={handelOnchange}
                      value={formData.crew}
                    >
                      <option value="">Select...</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                    </select>
                    <span className="form-control-message">
                      {formData.isError &&
                        formData.crew === "" &&
                        "Select a Crew..."}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="form-check">
                <input
                  name="checked"
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  checked={formData.checked === "on" ? "on" : ""}
                  onChange={handelOnchange}
                />
                <label className="form-check-label" for="exampleCheck1">
                  Check me
                </label>
                <span className="form-control-message">
                  {formData.isError &&
                    formData.checked === "" &&
                    "Please check..."}
                </span>
              </div>
              <br /> */}

              <div className="col-md-12 form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Check me <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="checked"
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      //   checked={formData.checked === "on" ? "on" : ""}
                      onChange={handelOnchange}
                    />
                    <span className="form-control-message">
                      {formData.isError &&
                        formData.checked === "" &&
                        "Please check..."}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-12 form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      options <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-md-8">
                    {/* <div className="form-check"> */}
                      <label className="form-check-label">
                        <input
                          type="radio"
                          // className="form-check-input"
                          name="optradio"
                        />
                        Option 1
                      </label>
                    {/* </div> */}
                    {/* <div className="form-check"> */}
                      <label className="form-check-label">
                        <input
                          type="radio"
                          // className="form-check-input"
                          name="optradio"
                        />
                        Option 2
                      </label>
                    {/* </div> */}
                    {/* <span className="form-control-message">
                      {formData.isError &&
                        formData.checked === "" &&
                        "Please check..."}
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End of modal */}
      </div>
    </AuthLayout>
  );
};

export default Test;
