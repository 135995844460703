import React, { useState } from 'react';
import { whichUserType, userTypeName } from "../../helper";
import "./style.css"
import moment from 'moment';
import ActionMenu from '../../ShiftPlanning/components/ActionMenu/ActionMenu';

const UserRow = ({
  user,
  index,
  users,
  userType,
  handleUserSelection,
  setUsers,
  setModals,
  getUserDetails,
  checkedList
}) => {

  const equipmentLicenseList = [
    { label: "Excavator", value: "Excavator" },
    { label: "Truck", value: "Truck" }
  ]

  const [pinVisibility, setPinVisibility] = useState({});

  const handleClick = (e, userValue) => {
    if (e.type === "click") {
      getUserDetails(userValue);
    }
    setModals((prev) => ({ ...prev, type: "Edit" ,openUserModal:true}));
    setUsers((prev) => ({ ...prev, selectedUser: user }));
    if (users?.showEllipsisPopUp > -1) {
      setUsers((prev) => ({ ...prev, showEllipsisPopUp: -1 }));
    }
  };

  const changeViewPinStatus = (e, index, type = "TemporaryPassword") => {
    e.stopPropagation();
    setUsers((prev) => {
      const newUsers = [...prev.userData];
      newUsers[index][type === "TemporaryPassword" ? "tempPasswordView" : "pinView"] = !newUsers[index][type === "TemporaryPassword" ? "tempPasswordView" : "pinView"];
      return { ...prev, userData: newUsers };
    });
  };

  const toggleEllipsis = (e, rowIndex) => {
    e.stopPropagation();
    setUsers((prev) => ({ ...prev, showEllipsisPopUp: prev.showEllipsisPopUp === rowIndex ? -1 : rowIndex }));
  };

  const rowClickEventView = (userValue) => {
    setUsers((prev) => ({ ...prev, selectedUser: user }));
    getUserDetails(userValue);
    setModals((prev) => ({ ...prev, type: "View" ,openUserModal:true}));
  };

  const handleUserAction = (user, type) => {
    setUsers((prev) => ({ ...prev, selectedUser: user }));
    setModals((prev) => ({ ...prev, deleteModalOpen: { visible: true, type } }));
  };

  const getOptions = (user) => {
    const userType = Array.isArray(user?.userType) ? user?.userType : [];

    const isOperator = (userType?.length ===1  && userType[0]===200 );
    const isMechanic = userType.includes(700);
    const isTraineeOperator = userType.includes(100);
    const baseOptions = [];  

    if (user.status !== "P") {
      baseOptions.push({
        name: user.status === "A" ? "Deactivate" : "Activate",
        onClick: () => handleUserAction(user, user.status === "A" ? "deactivate" : "activate"),
      });
    }

    baseOptions.push(
      {
        name: "Edit",
        onClick: (e) => handleClick(e, user, index),
      },
      {
        name: "Delete",
        onClick: () => handleUserAction(user, "delete"),
      }
    );

    if (isOperator || isMechanic ) {
      baseOptions.push({
        name: "Generate New Pin",
        onClick: () => {
          setModals(prev => ({ ...prev, GeneratePINState: true }));
          setUsers(prev => ({ ...prev, selectedUser: user }));
        }
      });
    }

    return baseOptions;
  };

  const togglePinVisibility = (e, userId) => {
    e.stopPropagation();
    setPinVisibility(prev => ({
      ...prev,
      [userId]: !prev[userId]
    }));
  };

  const allLicensesPresent = equipmentLicenseList
  .map(option => option.value)
  .every(license => user?.equipmentLicense?.includes(license));

  return (
        <tr 
        className={users?.selectedUser?._id === user?._id || users?.checkedUserList?.includes(user?._id) ? "user-table-row-active" : "user-table-row"}
        onClick={() => rowClickEventView(user)}
        key={index}>
          <td align="left" valign="top">
        <div className='user-checkbox-container'>
          <input
            checked={checkedList?.includes(user?._id)}
            className='user-checkbox'
            type="checkbox"
            name="required"
            onClick={(e) => {handleUserSelection(e, user?._id, ""); e.stopPropagation(); }}
          />
        </div>
      </td>
      <td align="left" valign="top">{`${user.firstName} ${user.lastName}`}</td>
      <td align="left" valign="top">
        <span className={`status ${user.status === "A" || user.status === "true" ? "active" : user.status === "I" || user.status === "D" ? "inactive" : "invited"}`}></span>
        {user.status === "P" ? "Invited" : user.status === "A" || user.status === "true" ? "Active" : user.status === "I" ? "Inactive" : "Deleted"}
      </td>
      <td align="left" valign="top" className='d-flex align-items-center justify-content-between'>
        {user.userType?.length ?
          user.userType.length > 1 ? `${userTypeName(user.userType, userType)} + ${user.userType.length - 1}` :
            userTypeName(user.userType, userType) :
          "N/A"}
      </td>
      <td align="left" valign="top">
        {(whichUserType(user.userType)[0] || whichUserType(user.userType)[1]) ? user.email : "N/A"}
      </td>
      <td align="left" valign="top">{user.department ? user.department : "N/A"}</td>
      <td align="left" valign="top">{user.crewType || "N/A"}</td>
      <td align="left" valign="top">
        {allLicensesPresent
          ? "All"
          : user?.equipmentLicense?.filter(license => license !== "")?.length
            ? user.equipmentLicense.filter(license => license !== "").join(", ")
            : "N/A"}
      </td>
      <td align="left" valign="top">
        {!(user.userType?.length === 1 && (user.userType[0] === 200 || user.userType[0] === 100)) ? (
          <span className="unset">Private</span>
        ) : user?.pin ? (
          <span className="user-pin" onClick={(e) => togglePinVisibility(e, user._id)}>
            <a>
              {pinVisibility[user._id] ? user.pin : "View PIN"}
            </a>
          </span>
        ) : (
          <span className="unset">N/A</span>
        )}
      </td>
      <td align="left" valign="top">{user?.addedBy ? user?.addedBy : "N/A"} , {user?.createdAt ? moment(user?.createdAt).format('DD/MM/YYYY') : "N/A"}</td>
      <td align="center" valign="top action-popup-container" >
        <ActionMenu
          iconContainerStyle={{height:"25px"}}
          optionsContainerClass="mr-3 mt-1"
          optionClass="px-4"
          isOpen={users?.showEllipsisPopUp === index}
          onClick={(e) => toggleEllipsis(e, index)}
          options={getOptions(user)}
        />
      </td>
        </tr>
  );
};

export default UserRow;
