import { Helmet } from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { useEffect } from "react";

export default function AuthLayout({ children, pageName, noSideBar }) {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  //For Registration it will
  // if(auth.isAuthenticated && !auth?.userData?.isDelete){
  //   return <Navigate to="/changepassword" state={{ from: location }} replace />
  // }else{
  //   // return <Navigate to="/manageuser" state={{ from: location }} replace />
  // }

  return (
    <section className="manage_dashboard">
      {!noSideBar ? (
        <>
          <Helmet>
            <title>{`${pageName} | iMOS`}</title>
          </Helmet>

          <Sidebar />

          <div className="right_sidebar" id="RightSidebar">
            <div
              className="top_header"
            >
              <Header pageName={pageName} />
            </div>
            {children}
          </div>
        </>
      ) : (
        <div className="  " id="RightSidebar">
          <div className="top_header_blank" style={{ opacity: 1 }}>
            <Header pageName={""} />
          </div>
          {children}
        </div>
      )}
    </section>
  );
}

