import './style.css';

const ActionButton = ({
  variant,
  children,
  onClick,
  size,
  name,
  disabled,
  id,
  style,
  className = "",
  actionType
}) => {
  return (
    <div className={`${className}`} style={{height:"3.875rem",...style}}>
    <button
      style={{ ...style, cursor: "pointer" , backgroundColor: ( actionType === "discard" || actionType === "generatePIN" ) ? "#FFFFFF" : "#186FD9",borderColor: "#186FD9", color: (actionType ==="discard" || actionType === "generatePIN")?"#186FD9":"#FFFFFF",height:"100%",minHeight:"1.875rem" }}
      disabled={disabled}
      name={name}
      size={size}
      onClick={onClick}
      className={`action-button`}
      variant={variant}
      id={id}
    >
        {children}
    </button>
    </div>
  );
};

export default ActionButton;
