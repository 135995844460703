import React, { useContext, useEffect, useState } from "react";
import { CallWithAuth } from "../action/apiActions";
import { GET_PIN, EDIT_PIN } from "../action/apiPath";
import usePasswordToggle from "../hooks/usePasswordToggle";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import AuthContext from "../context/AuthProvider";

const ChangePinModal = ({ openChangeModal, userPin,openViewPinModal, closeModal }) => {
    const { logout } = useAuth();
    const { setAuth } = useContext(AuthContext);
    const [isCustom, setIsCustom] = useState(true);
    const [pin, setPin] = useState(null);

    useEffect(() => {
        setPin(userPin);
    }, [userPin]);

    const handleChange = ({ target: { name, value, maxLength } }) => {
        setPin(value.slice(0, maxLength));
    };

    const togglePinType = () => {
        setIsCustom((prev) => !prev);
        setPin(pin);
    };

    const getPinInfo = async () => {
        const response = await CallWithAuth("GET", GET_PIN);
        if (response?.res?.data?.status === 200) {
            setPin(response.res.data.data);
        }
    };

    const generateNewPin = async () => {
        await getPinInfo();
    };

    const submitEditPinForm = async () => {
        if(pin?.length!=4){
            toast.error("Please Enter a 4 digit pin", { position: "bottom-right" });
        }else{
            const response = await CallWithAuth("POST", EDIT_PIN, { pin:pin });
            if ([200, 201].includes(response?.res?.data?.status)) {
                setAuth((prev) => ({
                    ...prev,
                    userData: response?.res?.data?.data,
                }));
                toast.success("Pin Changed Successfully.", { position: "bottom-right" });
                closeModal((prev)=>!prev);
                openViewPinModal((prev)=>!prev);
            } else {
                toast.error("Pin not changed.", { position: "bottom-right" });
            }
        }
    };





    return (
        <div
            className={
                openChangeModal ? "full_modal" : "full_modal generate_new_pin_active"
            }
            id="change_password"
        >
            <div className="generate_new_pin_box my_account_box" style={{
                width: "640px"
            }}>
                <h4 style={{font:" normal normal bold 20px/27px Open Sans"}}>Edit your PIN Number</h4>
                <div className="generate_new_pin_form popup_animated_form">
                    <form>
                        <div className="row">
                            <div className="d-flex row justify-content-between col-md-10 col-12 mb-3">
                                <div className="h4">PIN No:</div>
                                <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                        <input
                                            type="radio"
                                            checked={isCustom}
                                            onChange={togglePinType}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                    </div>
                                    <div className="h4">Custom</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                        <input
                                            type="radio"
                                            checked={!isCustom}
                                            onChange={togglePinType}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                    </div>
                                    <div className="h4">Autogenerate</div>
                                </div>

                            </div>
                            <div className="col-md-12 form-group">
                                <div className="row">
                                    <div className="input_field_wrap has-float-label" style={{ display: 'flex', flex: 1 }}>
                                        <input
                                            type="number"
                                            name="pin"
                                            id="fname"
                                            value={pin}
                                            maxLength="4"
                                            onChange={handleChange}
                                            required
                                            style={{
                                                height: '4rem',
                                                border:"1px solid #E1E8F4",
                                                borderRadius:"4px"
                                            }}
                                        />
                                    </div>
                                    {!isCustom && (
                                        <>

                                            <div
                                                className="col-md-4 generate_new_pin ml-4 d-flex justify-content-center align-items-center"
                                                onClick={generateNewPin}
                                            >
                                                <span  >Generate New PIN</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex col-sm-12 justify-content-end pr-0">
                                <button
                                    style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#186FD9",border:"1.4px solid #a3c6ff", width:"100px"}}
                                    className="btn"
                                    onClick={(e) => {
                                        e.preventDefault(); 
                                        e.stopPropagation(); 
                                        closeModal((prevState) => !prevState);
                                        setPin(userPin);
                                        setIsCustom(true);
                                    }}
                                >
                                    Discard
                                </button>
                                <button
                                 style={{height:"44px", font:"normal normal bold 14px/19px Open Sans", color:"#FFFFFF",backgroundColor:"#186FD9",border:"1.4px solid #a3c6ff", width:"140px"}} 
                                 className="btn ml-2"   onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); submitEditPinForm();}}>
                                    {" "}
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default ChangePinModal;
