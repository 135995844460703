const validationUtility = {
  text(s, l = 0) {
    if (s !== null && s !== '' && s !== undefined && s.trim() !== '' && s.length > l) {
      return true;
    }
    return false;
  },
    email(data) {
        var flag = false;
        if (data.length > 0) {
          if (
            data.match(
              /^[a-z0-9._%+-]{1,64}@(?:[a-z0-9-]{1,63}\.){1,4}[a-z]{2,10}$/
            )
          ) {
            flag = true;
          }
        }
        return flag;
      },
}

export default validationUtility;