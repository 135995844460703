import { formatEpochTimeToNZ } from '../../../utils/helper';

import ReactDOM from 'react-dom';

export const DumpEventPopup = (coordinate, { title, data }) => {
  const div = document.createElement('div');
  const dumpEvent = data.dumpEvent;

  const popup = (
    <>
      <table
        className="dumpEventPopup"
        style={{
          display: 'flex',
          // width: '100%';
          alignItems: 'center',
          flexDirection: 'column',
          lineHeight: '25px'

        }}
        key={`popup-${coordinate.lat}-${coordinate.lng}`}
      >
        <thead>
          <tr>
            <th
              style={{
                paddingTop: '1rem',
                textAlign: 'center',
              }}
            >
              <span style={{ fontSize: '15px' }}>{title}</span>
            </th>
          </tr>
        </thead>
        <hr style={{ width: 'calc(100% - 15px)' }} />
        <tbody>
          <tr>
            <td
              style={{
                padding: '0rem 1rem',
              }}
            >
              Operator: <strong>{dumpEvent.operator}</strong>
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: '0rem 1rem',
              }}
            >
              Dump Point: <strong>{data.dumpPointName}</strong>
            </td>
          </tr>
          {data.dumpPointName === 'Unknown' && (
            <>
              <tr>
                <td
                  style={{
                    padding: '0rem 1rem',
                  }}
                >
                  Location:
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '0rem 1rem',
                  }}
                >
                  <strong>
                    <span style={{ textDecoration: 'underline' }}>Long:</span>{' '}
                    {dumpEvent.lng}
                  </strong>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '0rem 1rem',
                  }}
                >
                  <strong>
                    <span style={{ textDecoration: 'underline' }}>Lat:</span>{' '}
                    {dumpEvent.lat}
                  </strong>
                </td>
              </tr>
            </>
          )}
          {/* <tr>
          <td
            style={{
              padding: '0rem 1rem',
            }}
          >
            Material: <strong>{dumpEvent.material}</strong>
          </td>
        </tr> */}
          <tr>
            <td
              style={{
                padding: '0rem 1rem',
              }}
            >
              <strong>{formatEpochTimeToNZ(dumpEvent.timestamp, false)}</strong>
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: '1rem' }} />
          </tr>
        </tbody>
      </table>
      <div className="popup-tip-container"></div>
    </>
  );

  ReactDOM.render(popup, div);
  return div;
};
