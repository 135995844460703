import { createContext } from 'react';

// This sample structure will never be used, in practice, by the code.
// It remains here to display the current structure of what attributes the context has.

// PS: Update this structure if you ever add more attributes in DumpPointsFetching.js
const sampleStructure = {
  // mapKit: {},

  isDetailPageOpen: false,
  setIsDetailPageOpen: () => { },

  setFetchNow: () => { },

  selectedDumpPoint: {},
  setSelectedDumpPoint: () => { },
  selectedVehicle: {},
  setSelectedVehicle: () => { },

  shiftChoice: '0',
  setShiftChoice: () => { },

  dumpEvents: [],
  ongoingDumpEvents: [],
  previousDumpEvents: [],

  vehicleEvents: [],
  ongoingVehicleEvents: [],
  previousVehicleEvents: [],

  dumpPoints: [],
  optionsForDumpPoints: { label: '', value: '' },

  materials: [],
  optionsForMaterials: { label: '', value: '' },

  operators: [],
  optionsForOperators: { label: '', value: '' },

  trucks: [],
  optionsForTrucks: { label: '', value: '' },
};

export const DumpPointsContext = createContext(sampleStructure);
