import clsx from 'clsx';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AuthContext from '../context/AuthProvider';
// Import icons statically
import dashboardIcon from '../assets/svgs/sidebar/dashboard.svg';
import shiftIcon from '../assets/svgs/sidebar/shift.svg';
import dumpIcon from '../assets/svgs/sidebar/dump.svg';
import kpiIcon from '../assets/svgs/sidebar/kpi.svg';
import maintainanceIcon from '../assets/svgs/sidebar/maintainance.svg';
import preStartChecksIcon from '../assets/svgs/sidebar/preStartChecks.svg';
import assetMonitoringIcon from '../assets/svgs/sidebar/monitoring.svg';
import historyIcon from '../assets/svgs/sidebar/history.svg';
import userIcon from '../assets/svgs/sidebar/user.svg';
import notificationsIcon from '../assets/svgs/sidebar/notifications.svg';
import settingsIcon from '../assets/svgs/sidebar/settings.svg';

// Colored icons
import dashboardIconColored from '../assets/svgs/sidebar/dashboard_colored.png';
import shiftIconColored from '../assets/svgs/sidebar/shift_colored.png';
import dumpIconColored from '../assets/svgs/sidebar/dump_colored.png';
import kpiIconColored from '../assets/svgs/sidebar/kpi_colored.png';
import maintainanceIconColored from '../assets/svgs/sidebar/maintainance_colored.png';
import preStartChecksIconColored from '../assets/svgs/sidebar/preStartChecks_colored.png';
import assetMonitoringIconColored from '../assets/svgs/sidebar/monitoring_colored.png';
import historyIconColored from '../assets/svgs/sidebar/history_colored.png';
import userIconColored from '../assets/svgs/sidebar/user_colored.png';
import notificationsIconColored from '../assets/svgs/sidebar/notifications_colored.png';
import settingsIconColored from '../assets/svgs/sidebar/settings_colored.png';

// Import icons dynamically
const icons = {
  dashboard: dashboardIcon,
  shift: shiftIcon,
  dump: dumpIcon,
  kpi: kpiIcon,
  maintainance: maintainanceIcon,
  preStartChecks: preStartChecksIcon,
  assetMonitoring: assetMonitoringIcon,
  history: historyIcon,
  user: userIcon,
  notifications: notificationsIcon,
  settings: settingsIcon,
};
const coloredIcons = {
  dashboard: dashboardIconColored,
  shift: shiftIconColored,
  dump: dumpIconColored,
  kpi: kpiIconColored,
  maintainance: maintainanceIconColored,
  preStartChecks: preStartChecksIconColored,
  assetMonitoring: assetMonitoringIconColored,
  history: historyIconColored,
  user: userIconColored,
  notifications: notificationsIconColored,
  settings: settingsIconColored,
};

const Sidebar = () => {
  const { auth } = useAuth();
  const { myRoles } = useContext(AuthContext);

  // Base routes available to all or specific user types
  const getBaseRoutes = (isPrivileged) => [
    { displayName: 'Dashboard', pathName: '/dashboard', userType: [300, 400], name: 'dashboard' },
    { displayName: 'Shift Planning', pathName: '/shift-list', userType: [300, 400], name: 'shift' },
    isPrivileged ? { displayName: 'Maintenance', pathName: '/maintainance', name: 'maintainance' } : null,
    { displayName: 'Dump Management', pathName: '/dump-points', name: 'dump' },
    { displayName: 'History', pathName: '/history', name: 'history' },
  ].filter(Boolean); // Removes null or undefined elements

  // List of users who have access to additional routes
  const privilegedUserIds = [
    '6340ba6a85fb2e16f77d5f55',
    '6342d9e56363e50bf1dcbd2f',
    '63436d336363e50bf1dcc50a',
    '646164f1b1a1a1216c7b7ff4',
    '64ab461b2ea82c770718b84e',
    '64c7132f2dec53fe3bc2aaf0',
  ];

  // Extra routes common to all users
  const extraRoutes = [
    undefined,
    { displayName: 'User Management', pathName: '/manageuser', userType: [300, 400], name: 'user' },
    { displayName: 'Notifications', pathName: '#', name: 'notifications' },
    { displayName: 'Settings', pathName: '#', name: 'settings' },
  ];

  // Helper function to check if user is privileged
  const isPrivilegedUser = (userId) => privilegedUserIds.includes(userId?.toString());

  // Merge all routes based on user privileges
  const getRoutes = (userId) => {
    const isPrivileged = isPrivilegedUser(userId);
    const baseRoutes = getBaseRoutes(isPrivileged);
    return [...baseRoutes, ...extraRoutes];
  };

  // Example usage
  const routes = getRoutes(auth?.userData?._id);

  const location = useLocation();
  function HideNav() {
    var leftsidebar = document.getElementById('LeftSidebar');
    var rightbar = document.getElementById('RightSidebar');
    leftsidebar.classList.toggle('hide_sidebar');
    rightbar.classList.toggle('full_dashboard');
  }

  const isAllowed = (myRoles, userType) => {
    return myRoles?.some(role => userType?.includes(role));
  }

  return (
    <aside className="left_sidebar" id="LeftSidebar">
      <div className="left_sidebar_top">
        <div className="dashboard_logo">
          <Link to="/">
            <img src="assets/images/logo-white.png" alt="logo" />
          </Link>
        </div>
        <div className="toggle_design" onClick={HideNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <hr
        style={{
          backgroundColor: "#E1E8F466",
          borderWidth: "0.1rem 0",
        }}
      />
      <nav className="sidebar_menu">
        <ul>
          {routes.map((route, index) => {
            // return (route &&  (route?.userType === undefined || isAllowed(myRoles, route?.userType))) ? (
            return (route) ? (
              <li
                className='my-1'
                key={index}
              >
                <div style={location?.pathname.startsWith(route?.pathName) ? { display: 'flex', padding: '0.6rem .8rem', alignItems: 'center', backgroundColor: 'white', color: 'black', borderRadius: 12.5 } : { display: 'flex', padding: '0.6rem .8rem', alignItems: 'center', }}>
                  <div style={{ width: "2.5rem", display: 'flex', alignItems: 'center', justifyContent: 'start' }} >
                    <img src={location?.pathname.startsWith(route?.pathName) ? coloredIcons[route.name] : icons[route.name]} alt="React Logo" style={location?.pathname.startsWith(route?.pathName) ? { width: "1.8rem", height: "1.8rem", objectFit: 'contain', display: 'block' } : { width: "1.6rem", height: "1.6rem", objectFit: 'contain', display: 'block' }} />
                  </div>
                  <Link style={location?.pathname.startsWith(route?.pathName) ? { color: 'black', fontWeight: 600 } : {}} to={route.pathName}>{route.displayName}</Link>
                </div>
              </li>
            ) : (
              <hr
                key={index}
                style={{
                  backgroundColor: "#E1E8F466",
                  borderWidth: "0.1rem 0",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              />
            );
          })}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
