import { AES } from 'crypto-js';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CallWithOutAuth } from '../../action/apiActions';
import { SEND_OTP, LOGIN_API_URL } from '../../action/apiPath';
import usePasswordToggle from '../../hooks/usePasswordToggle';
import validationUtility from '../../utils/validation';
import axios from 'axios';
import Cookies from 'js-cookie';
import openSocket from 'socket.io-client';
import {
  REACT_APP_API_ENDPOINT,
} from '../../action/apiPath';
import axiosInstance from '../../utils/axios';

export default function LoginForm({ KEY, email, password, rememberMe, setEmail, setPassword, setRememberMe, onClickContinue, login_time }) {
  const [passwordInputType, ToggleIcon] = usePasswordToggle();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState();
  const [tncError, setTncError] = useState();
  const [privacyPolicyStatus, setPrivacyPolicyStatus] = useState(false)

  const validateEmail = () => {
    const emailValidityState = emailInputRef.current.validity;

    if (emailValidityState.valueMissing) {
      emailInputRef.current.setCustomValidity('This field is required');
    } else if (!validationUtility.email(emailInputRef.current.value)) {
      emailInputRef.current.setCustomValidity(
        'Please enter your registered email address'
      );
    } else {
      emailInputRef.current.style.border = '1px solid #ced4da';
      emailInputRef.current.setCustomValidity('');
    }
    emailInputRef.current.reportValidity();
  };

  const validatePassword = () => {
    const passwordValidityState = passwordInputRef.current.validity;

    if (passwordValidityState.valueMissing) {
      passwordInputRef.current.setCustomValidity('This field is required');
    } else if (!passwordInputRef.current.value) {
      passwordInputRef.current.setCustomValidity(
        'The password you entered is incorrect'
      );
    } else {
      passwordInputRef.current.style.border = '1px solid #ced4da';
      passwordInputRef.current.setCustomValidity('');
    }
    passwordInputRef.current.reportValidity();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!privacyPolicyStatus) {
      setTncError('Terms and Conditions are not accepted');
      // setApiError('Terms and Conditions are not accepted');
      return;
    }
    setLoading(true);
    setApiError(undefined);
    setTncError(undefined);

    CallWithOutAuth('POST', email == 'admin@imos.co.nz' ? LOGIN_API_URL : SEND_OTP, {
      email: email,
      password: AES.encrypt(password, KEY).toString(),
    })
      .then(async (response) => {
        if (response.res.data.status === 401) {
          setLoading(false);
          setApiError(
            response.res.data.message = 'user is not authorized'
          );
          return;
        }
        if (response.res.data.status !== 200) {
          setLoading(false);
          setApiError(
            response.res.data.message === 'IncorrectPassword'
              ? 'The password you entered is incorrect'
              : 'Something went wrong'
          );
          return;
        }

        setLoading(false);

        if (email == 'admin@imos.co.nz') {
          toast.success('Login successfully.', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setLoading(false);
          setError(undefined);

          login_time = new Date();
          // const { data: geoLocationData } = await axios.get(
          //   'https://geolocation-db.com/json/'
          // );
          const geoLocationData = await axios.get(REACT_APP_API_ENDPOINT + "api/myip");
          console.log("my ip", geoLocationData?.data);

          // TODO: Remove sockets
          let socket = {};
          // socket.current = openSocket(
          //   REACT_APP_API_ENDPOINT,
          //   { path: '' },
          //   { query: { data: 'web' } }
          // );
          // socket.current.on('connect', () => {
          //   console.debug('socket.current.connected--', socket.current.connected); // true
          //   if (socket.current.connected) {
          //     // alert(response.res.data.data.result.userId)
          //     socket.current.emit('useradd', {
          //       id: response.res.data.data.result.userId,
          //       from: 'web',
          //       ip: geoLocationData?.data,
          //       // ip: geoLocationData.IPv4,
          //     });
          //     socket.current.on('useradded', (data) => {
          //       // console.log(data);
          //     });
          //     // localStorage.setItem('socket', socket);
          //   }
          // });

          setAuth({
            isAuthenticated: true,
            isInitialized: false,
            userId: response.res.data.data.result.userId,
            userData: response.res.data.data.userdata,
            roles: response.res.data.data.userdata.userType,
            socketData: socket,
            ipAddress: geoLocationData?.data,
            // ipAddress: geoLocationData.IPv4,
          });
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.res.data.data.tokenData.token}`;
          localStorage.setItem(
            'accessToken',
            response.res.data.data.tokenData.token
          );

          Cookies.set('login', email, {
            expires: rememberMe ? 30 : 0,
          });
          Cookies.set('password', AES.encrypt(password, KEY).toString(), {
            expires: rememberMe ? 30 : 0,
          });

          navigate('/landing-page', { replace: true });
        }
        else {
          toast.success('OTP sent successfully.', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          onClickContinue();
        }
        setApiError(undefined);
      })
      .catch((error) => {
        setLoading(false);
        setApiError('Something went wrong');
        console.error(error);
      });
  };

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '3rem 4rem',
        textAlign: 'center',
      }}
    >
      <hgroup className="before_login_heading">
        <h1>Log in to Your Account</h1>
      </hgroup>

      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="input_field_wrap has-float-label pb-5">
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={(event) => {
                    validateEmail();
                    setEmail(event.target.value.trim());
                  }}
                  onInvalid={(e) => {
                    e.target.style.border = '1px solid red';
                  }}
                  placeholder=""
                  ref={emailInputRef}
                  required
                  type="text"
                  value={email}
                />
                <label>Email *</label>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="input_field_wrap has-float-label pb-5">
                <input
                  className="form-control"
                  id="pwd"
                  name="password"
                  onChange={(event) => {
                    validatePassword();
                    setPassword(event.target.value.trim());
                  }}
                  onInvalid={(e) => {
                    e.target.style.border = '1px solid red';
                  }}
                  placeholder=""
                  ref={passwordInputRef}
                  required
                  type={passwordInputType}
                  value={password}
                />
                <label>Password *</label>
                <div className="onpwd">{ToggleIcon}</div>
              </div>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />{' '}
              Remember me
            </div>
          </div>
          <div className="col-md-12">

            {tncError && (
              <div className="col-md-12 forgot_password">
                <div className="form-group">
                  <span
                    style={{
                      color: 'red',
                      fontSize: 'initial',
                    }}
                  >
                    {tncError}
                  </span>
                </div>
              </div>
            )}

            <div className="form-group">
              <input
                type="checkbox"
                checked={privacyPolicyStatus}
                onChange={(e) => setPrivacyPolicyStatus(e.target.checked)}
              />{' '}
              <span className='my-2'>Accept Terms and Conditions</span>
              <br />
              <input
                className="form-control login_button"
                disabled={loading}
                id="login-btn"
                type="submit"
                value={loading ? 'Loading...' : 'Continue'}
              />
            </div>
          </div>
          <div className="col-md-12 forgot_password">
            <div className="form-group">
              <Link to="/forgetpassword">Forgot password?</Link>
            </div>
          </div>
          {apiError && (
            <div className="col-md-12 forgot_password">
              <div className="form-group">
                <span
                  style={{
                    color: 'red',
                    fontSize: 'initial',
                  }}
                >
                  {apiError}
                </span>
              </div>
            </div>
          )}
        </div>
      </form>
    </main>
  );
}
