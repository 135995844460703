import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    return (
        auth.isAuthenticated &&  auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : Object.entries(auth.userData).length > 0
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    );
    // return (
    //     auth.isAuthenticated
    //         ? <Outlet />
    //         : Object.entries(auth.userData).length > 0
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/" state={{ from: location }} replace />
    // );
}

export default RequireAuth;