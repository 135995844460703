import { Image } from 'react-bootstrap';
import { FiWind } from 'react-icons/fi';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { ImEnlarge2 } from 'react-icons/im';
import React, { useState } from 'react';
import { IoIosRainy } from 'react-icons/io';
import ProgressBar from '../../../../components/ProgressBar';
import { WiDust } from 'react-icons/wi';

import WeatherReport from '../../../../components/WeatherReport/WeatherReport';
import { useContext,useEffect } from 'react';
import DashboardContext from '../../Context/DashboardContext';


export default function ShiftOverview({ topSlider, setTopSlider }) {
    const [modalShow, setModalShow] = useState(false);
    const { weatherData, breakdownEquipments, fallingBehind, shiftPerformance, } = useContext(DashboardContext)
    useEffect(() => {
        console.log(shiftPerformance, fallingBehind);
    }, [shiftPerformance])

    return (
        <>
            {modalShow && (
                <WeatherReport
                    setModalShow={setModalShow}
                    forecast={weatherData?.forecast}
                    webhookData={weatherData?.webhookData}
                    todayData={weatherData?.todayData}
                    tomorrowData={weatherData?.tomorrowData}
                />
            )}
            <div
                id="truck_excavator_summary"
                style={{
                    width: '100%',
                    background:
                        '0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)',
                    boxShadow: 'rgb(0 0 0 / 6%) 0px 8px 6px',
                }}
            >
                <div
                    style={{
                        transition:
                            'height .25s ease-in,opacity .25s ease-in,padding .25s ease-in',
                    }}
                    className={
                        (topSlider
                            ? 'dashboard__top__slider__show'
                            : 'dashboard__top__slider__hide') +
                        ' d-flex justify-content-between px-4'
                    }
                >
                    <div
                        className="px-3"
                        style={{
                            overflow: 'hidden',
                            width: '35%',
                            background: '#eaf7fd 0% 0% no-repeat padding-box',
                            borderRadius: '8px',
                            height: 210,
                        }}
                    >
                        <div
                            className="py-2"
                            style={{ borderBottom: '1px solid #c0e7e9' }}
                        >
                            <Image
                                src={require('../../../../assets/images/PERFORMANCE.png')}
                                height={35}
                                width={35}
                            />
                            <span
                                style={{
                                    paddingLeft: '15px',
                                    font: 'normal normal 600 16px/22px Open Sans',
                                }}
                            >
                                Performance
                            </span>
                        </div>
                        <div
                            style={{ width: '100%', height: 'calc(100% - 43px)' }}
                            className="d-flex align-items-start"
                        >
                            <div
                                style={{
                                    width: '60%',
                                    padding: ' 10px 0',
                                    height: '100%',
                                }}
                            >
                                <span
                                    style={{
                                        paddingLeft: '5px',
                                        color: '#707070',
                                        font: 'normal normal 600 12px/17px Open Sans',
                                    }}
                                >
                                    Totals:
                                </span>
                                <div
                                    style={{
                                        margin: '10px  0',
                                        font: 'normal normal bold 20px/30px Open Sans',
                                    }}
                                >
                                    {shiftPerformance?.fallingBehind && (
                                        <Image
                                            src={require('../../../../assets/images/alert.png')}
                                            height={20}
                                            width={20}
                                            style={{ marginTop: '-5px' }}
                                        />
                                    )}{' '}
                                    {shiftPerformance?.currentBCM?.actualLoads}{' '}
                                    <span
                                        style={{
                                            color: '#707070',
                                            font: 'normal normal normal 20px/30px Open Sans',
                                        }}
                                    >
                                        / {shiftPerformance?.currentBCM?.targetLoads} BCM
                                    </span>
                                    <div style={{ margin: '10px 2px' }}>
                                        {' '}
                                        <ProgressBar
                                            progress={
                                                (shiftPerformance?.currentBCM?.actualLoads /
                                                    shiftPerformance?.currentBCM?.targetLoads) *
                                                100
                                            }
                                            notCatching={shiftPerformance?.fallingBehind}
                                            fallingBehind={shiftPerformance?.fallingBehind}
                                            height={19}
                                            width={220}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    height: '100%',
                                    width: '40%',
                                    padding: '15px 0',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <div className="d-flex flex-column">
                                    <span
                                        style={{
                                            color: '#707070',
                                            marginBottom: '7px',
                                            font: 'normal normal 600 12px/17px Open Sans',
                                        }}
                                    >
                                        To target:
                                    </span>
                                    <span
                                        style={{
                                            marginBottom: '7px',
                                            font: ' normal normal 600 15px/21px Open Sans',
                                        }}
                                    >
                                        {parseInt(shiftPerformance?.currentBCM?.targetLoads) -
                                            parseInt(shiftPerformance?.currentBCM?.actualLoads) >
                                            0
                                            ? parseInt(
                                                shiftPerformance?.currentBCM?.targetLoads
                                            ) -
                                            parseInt(shiftPerformance?.currentBCM?.actualLoads)
                                            : 0}{' '}
                                        BCM
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span
                                        style={{
                                            marginBottom: '7px',
                                            color: '#707070',
                                            font: 'normal normal 600 12px/17px Open Sans',
                                        }}
                                    >
                                        Previous shift total:
                                    </span>
                                    <span
                                        style={{
                                            color: '#707070',
                                            marginBottom: '1px',
                                            font: 'normal normal 600 15px/21px Open Sans',
                                        }}
                                    >
                                        {shiftPerformance?.previousBCM?.actualLoads}
                                        <span
                                            style={{
                                                font: 'normal normal normal 15px/21px Open Sans',
                                            }}
                                        >
                                            / {shiftPerformance?.previousBCM?.targetLoads} BCM
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="equipment_falling_behind_reference_container"
                        className="remove_scroll_container"
                        style={{
                            overflow: 'hidden',
                            padding: '5px 10px',
                            width: '22.5%',
                            background:
                                '0% 0% no-repeat padding-box padding-box rgb(252, 240, 218)',
                            borderRadius: '8px',
                            minHeight: 210,
                            height: 210,
                            color: 'rgb(112, 112, 112)',
                            font: '12px/17px "Open Sans"',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '10px',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <Image
                                src={require('../../../../assets/images/alert_circle.png')}
                                height={35}
                                width={35}
                            />{' '}
                            <span
                                style={{
                                    font: 'normal normal 600 14px/20px Open Sans',
                                    color: 'black',
                                    paddingLeft: '10px',
                                }}
                            >
                                Equipment falling behind
                            </span>
                        </div>
                        <div
                            className="d-flex px-2"
                            style={{ width: '100%', paddingTop: 20 }}
                        >
                            <div
                                style={{ width: '50%' }}
                                className="d-flex flex-column align-items-center"
                            >
                                <span
                                    style={{
                                        paddingBottom: '4px',
                                        borderBottom: '1px solid rgb(234, 222, 91)',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    Excavators
                                </span>
                                {/* {hasOneHourElapsed && ( */}
                                <div
                                    id="equipment_falling_behind_excavator"
                                    className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                                    style={{
                                        width: '100%',
                                        minHeight: 120,
                                        height: 120,
                                        overflowX: 'auto',
                                        borderRight: '.5px solid #EADE5B',
                                    }}
                                >
                                    {fallingBehind.excavators?.map((name) => (
                                        <span
                                            style={{
                                                font: 'normal normal normal 12px/16px Open Sans',
                                                fontWeight: '500',
                                                height: 'min-content',
                                                padding: '10px 10px 10px 0px',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {name}
                                        </span>
                                    ))}
                                </div>
                                {/* )} */}
                            </div>
                            <div
                                style={{ width: '50%' }}
                                className="d-flex flex-column align-items-center"
                            >
                                <span
                                    style={{
                                        paddingBottom: '4px',
                                        borderBottom: '1px solid #EADE5B',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    Trucks
                                </span>
                                {/* {hasOneHourElapsed && ( */}
                                <div
                                    id="equipment_falling_behind_truck"
                                    className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                                    style={{
                                        overflowX: 'auto',
                                        width: '100%',
                                        minHeight: 120,
                                        height: 120,
                                        borderLeft: '.5px solid #EADE5B',
                                    }}
                                >
                                    {fallingBehind.trucks?.map((name) => (
                                        <span
                                            style={{
                                                font: 'normal normal normal 12px/16px Open Sans',
                                                fontWeight: '500',
                                                height: 'min-content',
                                                padding: '10px 0px 10px 10px',
                                                paddingLeft: '10px',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {name}
                                        </span>
                                    ))}
                                </div>
                                {/* )} */}
                            </div>
                        </div>
                    </div>

                    <div
                        id="equipment_not_operating_reference_container"
                        className="remove_scroll_container"
                        style={{
                            overflow: 'hidden',
                            padding: '5px 10px',
                            width: '22.5%',
                            background: ' #FBEEE6 0% 0% no-repeat padding-box',
                            borderRadius: '8px',
                            minHeight: 210,
                            height: 210,
                            color: 'rgb(112, 112, 112)',
                            font: '12px/17px "Open Sans"',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '10px',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <Image
                                src={require('../../../../assets/images/not_available_circle.png')}
                                height={35}
                                width={35}
                            />{' '}
                            <span
                                style={{
                                    font: 'normal normal 600 14px/20px Open Sans',
                                    color: 'black',
                                    paddingLeft: '10px',
                                }}
                            >
                                Equipment not operating
                            </span>
                        </div>
                        <div
                            className="d-flex px-2"
                            style={{ width: '100%', paddingTop: 20 }}
                        >
                            <div
                                style={{ width: '50%' }}
                                className="d-flex flex-column align-items-center"
                            >
                                <span
                                    style={{
                                        paddingBottom: '4px',
                                        borderBottom: '1px solid #ED7C7F',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    Excavators
                                </span>
                                <div
                                    id="equipment_not_operating_excavator"
                                    className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                                    style={{
                                        width: '100%',
                                        minHeight: 120,
                                        height: 120,
                                        overflowX: 'auto',
                                        borderRight: '.5px solid #ED7C81',
                                    }}
                                >
                                    {breakdownEquipments.excavators?.map((excavator) => (
                                        <span
                                            style={{
                                                font: 'normal normal normal 12px/16px Open Sans',
                                                fontWeight: '500',
                                                height: 'min-content',
                                                padding: '10px 10px 10px 0px',
                                                textDecoration: 'underline',
                                                paddingLeft: '10px',
                                            }}
                                        >
                                            {excavator}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div
                                style={{ width: '50%' }}
                                className="d-flex flex-column align-items-center"
                            >
                                <span
                                    style={{
                                        paddingBottom: '4px',
                                        borderBottom: '1px solid #ED7C7F',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    Trucks
                                </span>
                                <div
                                    id="equipment_not_operating_truck"
                                    className="d-flex flex-wrap my-2 justify-content-between align-content-start remove_scroll"
                                    style={{
                                        overflowX: 'auto',
                                        width: '100%',
                                        minHeight: 120,
                                        height: 120,
                                        borderLeft: '.5px solid #ED7C81',
                                    }}
                                >
                                    {breakdownEquipments.trucks?.map((trucks) => (
                                        <span
                                            style={{
                                                font: 'normal normal normal 12px/16px Open Sans',
                                                fontWeight: '500',
                                                height: 'min-content',
                                                padding: '10px 0px 10px 10px',
                                                paddingLeft: '10px',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {trucks}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            overflow: 'hidden',
                            width: '15%',
                            paddingLeft: '10px',
                            marginLeft: '30px',
                            background:
                                '0% 0% no-repeat padding-box padding-box rgb(222, 235, 255)',
                            boxShadow: 'rgba(82, 77, 77, 0.17) 1px 6px 5px 0px',
                            borderRadius: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => setModalShow(true)}
                        id="weather"
                        className="d-flex flex-column align-items-center"
                    >
                        <div
                            style={{ margin: '10px 0' }}
                            className="d-flex flex-column align-items-center"
                        >
                            <span
                                style={{
                                    font: 'normal normal 600 15px/20px Open Sans',
                                    color: '#1A1A1A',
                                }}
                            >
                                Cloudy
                            </span>
                            <span
                                style={{
                                    font: ' normal normal bold 30px/41px Open Sans',
                                }}
                            >
                                {weatherData?.webhookData.temp}
                            </span>
                            <span
                                style={{
                                    font: 'normal normal normal 12px/17px Open Sans',
                                }}
                            >
                                Feels like{' '}
                                {(weatherData?.todayData.feels_like - 273.15).toFixed(1)}°C
                            </span>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                height: '100%',
                                padding: '0 10px',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <span>
                                    <IoIosRainy color="gray" size={20} />
                                </span>
                                <span
                                    style={{
                                        width: 30,
                                        font: 'normal normal normal 12px/17px Open Sans',
                                    }}
                                >
                                    Rainfall
                                </span>
                                <span
                                    style={{
                                        width: 110,
                                        font: 'normal normal 600 12px/17px Open Sans',
                                    }}
                                >
                                    {weatherData?.webhookData?.rain}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span>
                                    <FiWind color="gray" size={20} />
                                </span>
                                <span
                                    style={{
                                        width: 30,
                                        font: 'normal normal normal 12px/17px Open Sans',
                                    }}
                                >
                                    Wind
                                </span>
                                <span
                                    style={{
                                        width: 110,
                                        font: 'normal normal 600 12px/17px Open Sans',
                                    }}
                                >
                                    {weatherData?.webhookData.wind} {weatherData?.webhookData.windDirection}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span>
                                    <WiDust color="gray" size={20} />
                                </span>
                                <span
                                    style={{
                                        width: 30,
                                        font: 'normal normal normal 12px/17px Open Sans',
                                    }}
                                >
                                    Dust
                                </span>
                                <span
                                    style={{
                                        width: 110,
                                        font: 'normal normal 600 12px/17px Open Sans',
                                    }}
                                >
                                    {weatherData?.webhookData?.dust} &mu;g/m<sup>3</sup>
                                </span>
                            </div>
                        </div>
                        <ImEnlarge2
                            style={{
                                fontSize: '12px',
                                position: 'absolute',
                                right: '13px',
                                top: '15px',
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        height: 'max-content',
                        padding: '5px 25px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingBottom: '20px',
                        cursor: 'pointer',
                    }}
                >
                    <span
                        style={{
                            backgroundColor: topSlider ? '#F2F7FF' : '#011358',
                            height: 16,
                            width: 23,
                            border: '1px solid #E1E8F4',
                        }}
                        className="d-flex justify-content-center"
                        onClick={() => setTopSlider(!topSlider)}
                    >
                        {topSlider ? (
                            <TiArrowSortedUp />
                        ) : (
                            <TiArrowSortedDown color="white" />
                        )}
                    </span>
                </div>
            </div>
        </>
    )
}
