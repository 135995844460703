import React, { useState } from 'react';
import RadioButton from '../RadioButton/index.js';
import InputField from '../InputField/index.js';
import ActionButton from '../Button';
import TextInputField from '../../pages/ShiftPlanning/components/GeneralFields/TextInputField.jsx';


const PinInput = ({
  pin, 
  onPinChange, 
  onGeneratePin, 
  viewOnly,
  labelStyle,
  label,
  required,
  style,
  containerClass,
  inputClass
}) => {
  const [isCustom, setIsCustom] = useState(true);
  return (
    viewOnly ? <div className='row mb-3'>
        <span className={`col-md-${label ? 4 : 12}`} style={{margin: 0, color: "#4b515a",...labelStyle }}>{label}</span>
        <span className={`col-md-${label ? 8 : 12}`} style={{ font: "normal normal bold 1.3rem/19px Open Sans"}}>{pin?pin:"--"}</span>
    </div> : (
    <div className={`mb-3 ${containerClass}`}>
      <RadioButton
      labelStyle={labelStyle}
        required 
        className="mb-2"
        label={label}
        options={[
          { label: 'Custom', value: 'custom', className: `${label ? "col-sm-4" : "col-sm-4 ml-5"}` },
          { label: 'Autogenerate', value: 'auto', className: `${label ? "col-sm-8" : "col-sm-4 mr-5"}` },
        ]}
        selectedValue={isCustom ? 'custom' : 'auto'}
        onChange={() => setIsCustom(!isCustom)}
      />
      <div className={`row d-flex ${label?"justify-content-end":"justify-content-center flex-1 mx-0 px-0"}`}>
        <div className="d-flex col-sm-8 pr-0 justify-content-between">
          <div className={`${isCustom?"col-12":"col-sm-7"} mx-0 pl-0`}>
          <TextInputField
            inputClass={inputClass}
            style={{height:"40px",...style, borderRadius:"4px"}}
            required 
            type="number"
            value={pin}
            maxLength="4"
            onChange={(v) => onPinChange(v)}
            viewOnly={viewOnly}
          />
          </div>
          {!isCustom && (
            <ActionButton
              actionType="generatePIN"
              className="col-sm-5 mr-0 pr-0"
              onClick={onGeneratePin}
              style={{
                font: "normal normal bold 12px/17px Open Sans", 
                height: "40px",
              }}
            >
              <span>Generate New PIN</span>
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  )
  );
};
  
export default PinInput;