import React from 'react';


export default function RadioField({ required, viewOnly, label, options, value, isDisabled, id, style = {}, error, errorMessage, className, onChange }) {
    return (
        <div style={{ minHeight: '3.5rem' }} className="row form-group">
            <div className="col-md-4">
                <label style={{
                    fontSize: "1.25rem",
                    fontWeight: '500',
                    color: "#4B515A",
                }}>
                    {label} {required && <span style={{ color: "#6A7C9A" }}>*</span>}
                </label>
            </div>
            <div className={"col-md-8 d-flex align-items-center justify-content-start flex-wrap " + className}>
                {viewOnly ? <span style={{ fontWeight: '600', fontSize: 16, color: "#1A1A1A" }}>{value}</span> : options?.map((option, index) => (
                    <label className="custom_radio" key={index}>
                        {option.label}
                        <input
                       
                            type="radio"
                            value={option.value}
                            checked={value === option.value}
                            name={id}
                            disabled={isDisabled}
                            onChange={() => onChange(option.value)}
                        />
                        <span className="checkmark"></span>
                    </label>
                ))}
                <div
                    className="d-flex align-items-center pt-1"
                    style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
                >
                    {error ? errorMessage : ""}
                </div>
            </div>
        </div>
    );
}
