import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const GuestLayout = ({ children }) => {
  const { auth } = useAuth();
  const location = useLocation();

  if (auth.isAuthenticated) {
    if (auth?.userData?.crewType == 'Command Office') {
      return <Navigate to="/tvdashboard" state={{ from: location }} replace />
    } else {
      return <Navigate to="/landing-page" state={{ from: location }} replace />
    }
  }
  //For Registration it will be open later- Prasanta and Off upper code
  // if(auth.isAuthenticated){
  //   if(auth?.userData?.tempPasswordStatus){
  //     return <Navigate to="/changepassword" state={{ from: location }} replace />
  //   }else{
  //     return <Navigate to="/manageuser" state={{ from: location }} replace />
  //   }
  // }

  return (
    <>{children}</>
  );
};

export default GuestLayout;
