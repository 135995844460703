import { createContext, useEffect, useState, useRef } from 'react';

import { useIntervalFetch } from '../hooks/useIntervalFetch';
import { CallWithAuth, CallWithAuthFormData } from '../action/apiActions';
import { GET_SHIFT_HISTORY } from '../action/apiPath';
import useAuth from '../hooks/useAuth';
import moment from 'moment';

const initialState = {
  shift: [],
  dayShift: [],
  nightShift: [],
};
export const ShiftHistoryContext = createContext(initialState);

export const FetchShiftHistoryData = () => {
  const { auth } = useAuth();
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const selectedDateRef = useRef('');
  const [selectedShift, setSelectedShift] = useState({
    day: undefined,
    night: undefined
  })
  const [shiftsOptions, setShiftsOptions] = useState([])
  const selectedShiftRef = useRef({
    day: undefined,
    night: undefined
  });

  const fetch = (di) => {
    const fetchShiftHistory = async () => {
      const response = await CallWithAuth('POST', GET_SHIFT_HISTORY, {
        date: selectedDateRef.current,
        dayShiftId: selectedShiftRef.current.day?.value,
        nightShiftId: selectedShiftRef.current.night?.value,
      });
      if (response.status) {
        di({
          type: 'dayShift',
          payload: response.res.data[0],
        });
        di({
          type: 'nightShift',
          payload: response.res.data[1],
        });
      } else throw response.res;
    };

    fetchShiftHistory();
  };

  const [state, dispatch] = useIntervalFetch('History', fetch, initialState);

  // 0 = Ongoing Shift
  // 1 = Previous Shift
  const [shiftChoice, setShiftChoice] = useState('0');
  useEffect(() => {
    switch (shiftChoice) {
      case '0':
        dispatch({ type: 'shift', payload: state.dayShift });
        break;

      case '1':
        dispatch({ type: 'shift', payload: state.nightShift });
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dayShift, state.nightShift, shiftChoice]);


  useEffect(() => {
    let timeout;
    selectedDateRef.current = selectedDate;
    const fetchData = async () => {
      const { userData } = auth || {};
      const { current: selectedDate } = selectedDateRef;

      // Fetch shifts data
      const shiftResponse = await CallWithAuthFormData("POST", "/api/getShiftsForDashboard", {
        userId: userData?._id,
        date: selectedDate,
      });

      const shiftsData = shiftResponse?.res?.data?.data || {};
      setShiftsOptions({
        day: shiftsData.day,
        night: shiftsData.night
      });

      // Set default shifts if not already selected

      const { day: selectedDay, night: selectedNight } = selectedShiftRef.current;
      const dayShiftExists = !shiftsData.day?.length || shiftsData.day?.some(sd => sd.value === selectedDay?.value);
      const nightShiftExists = !shiftsData.night?.length || shiftsData.night?.some(sn => sn.value === selectedNight?.value);

      if (!dayShiftExists || !nightShiftExists) {
        const updatedShift = {
          day: dayShiftExists ? selectedDay : shiftsData.day?.[0],
          night: nightShiftExists ? selectedNight : shiftsData.night?.[0]
        }
        setSelectedShift(updatedShift);
        selectedShiftRef.current = updatedShift;
      }

      timeout = setTimeout(() => {
        fetchData()
      }, 60000);
    }
    if (selectedDate)
      fetchData()
    return () => {
      if (timeout)
        clearTimeout(timeout);
    }
  }, [selectedDate])


  useEffect(() => {
    const fetchData = async () => {
      console.log(selectedShiftRef.current, selectedShift);

      const response = await CallWithAuth('POST', GET_SHIFT_HISTORY, {
        date: selectedDate,
        dayShiftId: selectedShiftRef.current.day?.value,
        nightShiftId: selectedShiftRef.current.night?.value,
      });
      if (response.status) {
        dispatch({
          type: 'dayShift',
          payload: response.res.data[0],
        });
        dispatch({
          type: 'nightShift',
          payload: response.res.data[1],
        });
      } else throw response.res;
    }

    fetchData();

  }, [selectedShift, selectedShiftRef])



  return {
    shiftChoice,
    setShiftChoice,
    selectedDate,
    setSelectedDate,
    selectedShift,
    setSelectedShift,
    shiftsOptions,
    setShiftsOptions,
    selectedShiftRef,
    ...state,
  };
};


