import { Image } from 'react-bootstrap';
import { MdOutlineFilterList } from 'react-icons/md';
import React, { useState } from 'react';
import ExcavatorsCard from './ExcavatorsCard';
import { useContext, useEffect } from 'react';
import DashboardContext from '../../Context/DashboardContext';
import FilterModal from '../../FilterModal'

export default function ShiftTotalDetail({
    topSlider,
}) {
    const [searchName, setSearchName] = useState('');
    const { shiftDetail, equipments, fallingBehind } = useContext(DashboardContext);
    const [isFilterModalOpen, setisFilterModalOpen] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [filterVariableAfterApply, setFilterVariableAfterApply] = useState({
        Excavator: [],
        Status: [],
        Operator: [],
        Truck: [],
    })

    const filterData = (excavator) => {
        const foundExcavator = filterVariableAfterApply.Excavator.findIndex((ex) => ex.value == excavator.excavatorName) != - 1
        if (foundExcavator)
            return true;

        const foundTruck = filterVariableAfterApply.Truck.findIndex((t) => excavator.trucks.findIndex(tt => tt.truckName == t.value) != -1) != - 1
        if (foundTruck)
            return true;
        const foundOperator = filterVariableAfterApply.Operator.some((operator) => {
            const excavatorFullName = excavator.operator.firstName + ' ' + excavator.operator.lastName;
            const truckOperators = excavator.trucks.map((truck) => truck.operator.firstName + ' ' + truck.operator.lastName);
            return excavatorFullName === operator.value || truckOperators?.includes(operator.value);
        });

        if (foundOperator) {
            return true;
        }

        let statusFound = false;
        filterVariableAfterApply.Status.forEach((s) => {
            if (statusFound)
                return;
            switch (s.label) {
                case 'Reached target':
                    statusFound = excavator?.actualLoads >= excavator?.targetLoads
                    break;
                case 'Below target':
                    statusFound = excavator?.actualLoads < excavator?.targetLoads
                    break;
                case 'Falling behind':
                    statusFound = fallingBehind.excavators?.includes(excavator?.excavatorName)
                    break;
                case 'Not operating':
                    statusFound = excavator.breakdown
                    break;
            }

        })
        if (statusFound)
            return true;

    }

    useEffect(() => {

        const filterCount = Object.values(filterVariableAfterApply).reduce(
            (accumulator, currentValue) =>
                currentValue.length !== 0 ? accumulator + 1 : accumulator,
            0
        );

        if (filterCount > 0) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
        }

    }, [filterVariableAfterApply]);

    const statusOptions = [
        {
            label: 'Reached target',
            value: 0,
        },
        {
            label: 'Below target',
            value: 1,
        },
        {
            label: 'Falling behind',
            value: 2,
        },
        {
            label: 'Not operating',
            value: 3,
        },
    ]

    return (
        <>
            {isFilterModalOpen && (
                <FilterModal
                    filterVariableAfterApply={filterVariableAfterApply}
                    onFilterApply={(v) => {
                        console.log(v);
                        setFilterVariableAfterApply(v);
                        setisFilterModalOpen(false);
                    }}
                    onCancel={() => setisFilterModalOpen(false)}
                    optionsForExacavator={equipments.excavators?.map((excavator) => ({ value: excavator, label: excavator }))}
                    optionsForOperator={shiftDetail?.excavators?.reduce((result, excavator) => {
                        result.push({ value: excavator.operator.firstName + ' ' + excavator.operator.lastName, label: excavator.operator.firstName + ' ' + excavator.operator.lastName })
                        excavator?.trucks.forEach((truck) => {
                            result.push({ value: truck.operator.firstName + ' ' + truck.operator.lastName, label: truck.operator.firstName + ' ' + truck.operator.lastName })
                        })
                        return result;
                    }, [])}
                    optionsForStatus={statusOptions}
                    optionsForTruck={equipments.trucks?.map((truck) => ({ value: truck, label: truck }))}
                />
            )}
            <div
                id="y_scroll"
                style={{
                    padding: '20px',
                    overflowY: 'scroll',
                    height:
                        'calc(100vh - ' + (topSlider ? '460px)' : '200px)'),
                }}
            >
                <div
                    id="excavator_heading"
                    style={{ margin: '15px 20px' }}
                    className="d-flex align-items-center justify-content-between"
                >
                    <div className="d-flex align-items-center">
                        <Image
                            src={require('../../../../assets/images/jcb.png')}
                            height={30}
                            width={40}
                        />
                        <span
                            className="ml-2"
                            style={{ fontWeight: '600', fontSize: '23px' }}
                        >
                            Excavators{' '}
                            <span style={{ fontWeight: 400 }}>(BCM)</span>
                        </span>
                    </div>
                    <div
                        style={{ border: '0px solid black', display: 'flex', alignItems: 'center' }}
                    >
                        <div
                            style={{
                                marginRight: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: '1px solid #dde5f3',
                                padding: '6px 12px',
                                borderRadius: '15px',
                                width: '250px',
                                backgroundColor: 'white',
                            }}
                        >
                            <input
                                type="search"
                                id="searchuser"
                                className="shadow-none"
                                name="searchuser"
                                value={searchName}
                                placeholder="Search for an Excavator"
                                style={{
                                    border: 'none',
                                    font: 'normal normal normal 14px Open Sans',
                                    width: '200px',
                                }}
                                onChange={(v) => setSearchName(v.target.value)}
                            />

                            <i
                                style={{ fontSize: '13px' }}
                                className="fa-regular fa-magnifying-glass"
                            ></i>
                        </div>
                        <div
                            style={{
                                width: '30px',
                                height: '30px',
                                backgroundColor: 'rgb(240, 244, 251)',
                                borderRadius: '50%',
                                objectFit: 'contain',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1.5px solid rgb(225,232,244)',
                                cursor: 'pointer',
                                // border:"2px solid black"
                            }}
                        >
                            <MdOutlineFilterList
                                color={isFilterApplied ? 'red' : 'black'}
                                fontSize={'25px'}
                                onClick={() => {
                                    setisFilterModalOpen((prev) => !prev);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    id="excavatorCard_container"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {shiftDetail?.excavators
                        ?.filter(
                            (excavator) =>
                                excavator?.excavatorName.toLowerCase()?.includes(
                                    searchName?.toLowerCase()
                                )
                                && (!isFilterApplied || filterData(excavator))
                        )
                        ?.map((excavator, i) => (
                            <ExcavatorsCard
                                key={i}
                                excavator={excavator}

                            />
                        ))}
                </div>
            </div></>
    )
}
