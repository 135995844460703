import React, { useEffect, useState } from "react";
import styles from "./WaterfallModal.module.css";
import { FaChevronRight } from "react-icons/fa";
import ActionMenu from "../ActionMenu/ActionMenu";

const WaterfallModal = ({ isOpen = false, triggerContent, content, actionMenuOptions, actionIcon, isDisabled }) => {
  const [showContent, setShowContent] = useState(isOpen);

  useEffect(() => {
    setShowContent(isOpen);
  }, [isOpen]);

  const toggleContent = () => {
    setShowContent(prev => !prev);
  };

  return (
    <div className={`mb-3 pr-2 ${styles.waterfallModal}`}>
      <div style={isDisabled ? { backgroundColor: "#bcbcbc" } : {}} className={`d-flex align-items-center mb-3 ${styles.trigger} ${showContent ? styles.showContent : ""}`}>
        <div style={{ userSelect: 'none' }} onClick={toggleContent} className="d-flex align-items-center w-100">
          <span className="d-flex align-items-center justify-content-center ml-2 mr-2">
            <FaChevronRight size={14} style={{ transition: "transform 0.5s ease", transform: `rotateZ(${showContent ? 90 : 0}deg)` }} />
          </span>
          {triggerContent}
        </div>
        {actionMenuOptions && (
          <div className="mr-2">
            <ActionMenu iconContainerStyle={{ filter: showContent ? "invert(1)" : "" }} options={actionMenuOptions} icon={actionIcon} />
          </div>
        )}
      </div>
      <div className={`${!showContent ? "d-none" : "px-3"}`}>{content}</div>
    </div>
  );
};

export default WaterfallModal;
