import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { fetchCrewTypes, fetchOperators } from './apiService';
import { GoPlus } from "react-icons/go";
import SelectField from '../GeneralFields/SelectField';
import TextInputField from '../GeneralFields/TextInputField';
import styles from './OperatorSelect.module.css';
import Button from '../Button/Button';

export default function OperatorSelect({ placeholder, userTypes, equipmentType, isMulti, viewOnly, required, label, value, isDisabled, clearSelection = false, style = {}, error, errorMessage, className, onChange, initialCrew }) {
    const [crewTypesList, setCrewTypesList] = useState([]);
    const [selectedCrewType, setSelectedCrewType] = useState(initialCrew ?? null);
    const [operatorList, setOperatorList] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedCrewTypes = await fetchCrewTypes()
                setCrewTypesList(fetchedCrewTypes)
                setSelectedCrewType(initialCrew ?? fetchedCrewTypes[0].CrewType)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedCrewType != null) {
                    const fetchedOperators = await fetchOperators(selectedCrewType, equipmentType, userTypes)
                    setOperatorList(fetchedOperators)
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()
    }, [selectedCrewType]);


    const Control = () => {
        return <div></div>
    }

    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div className={styles.optionContainer}>
                    <input
                        type={isMulti ? "checkbox" : "radio"}
                        style={{ borderRadius: 0 }}
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <span className={styles.optionLabel}>{props.label}</span>
                </div>
            </components.Option>
        );
    };

    useEffect(() => {
        // Function to close dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div style={{ minHeight: '3.25rem', height: "max-content", }} className="row mb-3">
            <div className="col-md-4 d-flex align-items-center">
                <label style={{
                    // fontSize: " 1.3rem",
                    fontSize: "1.25rem",
                    fontWeight: '500',
                    margin: 0,
                    color: "#4B515A"
                }}>
                    {label}{required && <span style={{ color: "#6A7C9A" }}>*</span>}
                </label>
            </div>
            <div className={"col-md-8 " + className}>
                <div ref={dropdownRef} style={!viewOnly ? { cursor: 'pointer' } : {}} className={styles.dropdownContainer}>
                    {/* Field */}
                    <div
                        style={{
                            border:
                                isDisabled ? "none" : ("1px solid " +
                                    (error ? "#BA3938" : isOpen ? "#186FD9" : "#E1E8F4")),
                            ...(viewOnly ? { color: "#1A1A1A", } : {}),
                            minHeight: "3.25rem",
                            fontWeight: "600",
                            fontSize: "1.3rem",
                            height: "max-content",
                            color: isDisabled ? "#B3BBC4" : "#1A1A1A",
                            backgroundColor: !viewOnly && isDisabled ? "rgb(240,241,244)" : "transparent",
                        }}
                        className={'d-flex justify-content-between align-items-center py-1 ' + (viewOnly ? "border-0 pl-1" : "rounded px-3")}
                        onClick={() => !viewOnly && setIsOpen(!isOpen)}
                    >
                        {isMulti ? (value?.length ?
                            <div style={{ display: "flex", flexWrap: 'wrap' }}>
                                {value?.map((operator, index) =>
                                    <span className='pr-1' key={index}>
                                        {operator.label}
                                        {index !== value?.length - 1 && ","}
                                    </span>
                                )}
                            </div> :
                            (placeholder || "Select Operator ")
                        ) : (viewOnly ? (value?.label || "-") : (value?.label || (placeholder || "Select Operator ")))}
                        <div style={{ minWidth: 20 }}>
                            {!viewOnly && <GoPlus size={20} />}
                        </div>
                    </div>
                    {/* CustomMenu */}
                    {isOpen &&
                        <div className={styles.menu}  >
                            <div className='form-group py-1 pt-3 d-flex flex-column align-items-center mb-0' style={{
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4, width: '100%', backgroundColor: "#EFF3FA",
                                cursor: 'auto'
                            }}>
                                <input
                                    placeholder="Search"
                                    className='mb-3'
                                    style={{
                                        width: 320, borderRadius: 15, height: 30, fontSize: 12.5, userSelect: "none",
                                    }}
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value)
                                    }}
                                    disabled={isDisabled}
                                />

                                <div style={{ width: 320 }} className='d-flex justify-content-between'>
                                    <SelectField
                                        placeholder={"All"}
                                        style={{ backgroundColor: 'white', height: 30, borderRadius: 15, width: clearSelection ? 240 : 320, fontSize: 12.5 }}
                                        value={selectedCrewType}
                                        options={crewTypesList
                                            .map((d) => ({ label: d.CrewType, value: d.CrewType }))}
                                        onChange={(v) => setSelectedCrewType(v)} />

                                    {clearSelection && < Button isDisabled={isMulti ? value?.length == 0 : !value} onClick={() => {
                                        if (isMulti) {
                                            onChange([])
                                        }
                                        else {

                                            onChange(null)
                                            setIsOpen(false);
                                        }
                                    }} style={{ height: "30px" }} label={"Clear"} className={"py-1"} />}
                                </div>
                            </div>
                            <Select
                                menuIsOpen
                                isMulti={isMulti}
                                isClearable={false}
                                styles={{
                                    menu: (baseStyles) => ({
                                        ...baseStyles,
                                        marginTop: 0,
                                        borderRadius: 0,
                                        borderBottomLeftRadius: 4,
                                        borderBottomRightRadius: 4,
                                        border: '1px solid #E1E8F4',
                                        borderTop: 0,
                                        width: "calc(100% + 2px)",
                                        boxShadow: "1px 10px 8px #80808029",
                                        left: -1,
                                    }),
                                    container: (baseStyles) => ({
                                        ...baseStyles,
                                        width: "100%",


                                    }),

                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        padding: "3px 30px ",
                                        backgroundColor: (state.isFocused ? "#E1E8F4" : "white"),
                                        color: '#1A1A1A'
                                    }),
                                }}
                                controlShouldRenderValue={false}
                                hideSelectedOptions={false}
                                tabSelectsValue={false}
                                closeMenuOnSelect={false}
                                value={value}
                                isSearchable={true}
                                onKeyDown={(e) => e.stopPropagation()}
                                onChange={(v) => {
                                    if (isMulti) {
                                        onChange(v)
                                    }
                                    else {

                                        onChange(v)
                                        setIsOpen(false);
                                    }
                                }}
                                components={{ Control: Control, IndicatorSeparator: null, DropdownIndicator: GoPlus, Option: Option }}
                                options={operatorList
                                    .filter((operator) =>
                                        operator.firstName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                                        operator.lastName?.toLowerCase().includes(searchQuery?.toLowerCase())
                                    ).map((d) => ({ label: `${d.firstName} ${d.lastName}`, value: d._id }))}
                            />
                        </div>}
                </div>
                <div className='d-flex align-items-center pt-1' style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}>
                    {error ? errorMessage : ""}
                </div>
            </div>
        </div >
    )
}


