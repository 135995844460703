const Tabs = ({ activeTab, setActiveTab, tabs }) => {
    return (
        <div className="shift_tab_list mb-0">
            <ul>
                {tabs.map((tab, index) => (
                    <li className={activeTab === index ? "active" : ""} onClick={() => setActiveTab(index)}>
                        <a href="#">  {tab.title}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Tabs;
