import { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
} from 'victory';

import { CallWithAuth } from '../../../../action/apiActions';
import { END_SHIFT } from '../../../../action/apiPath';
import Button from '../../../../components/Buttons';
import TruckGraphRow from './TruckGraphRow';
import {
  formatData,
  getHourlyTarget,
  isCurrentBar,
  utcToNZTime,
} from '../../../../utils/helper';
import ConfirmationPopup from '../../Popup';
import { useContext } from 'react';
import DashboardContext from '../../Context/DashboardContext';

const getMaximumDomain = (v, ex) => {
  return Math.max(v, ex) + Math.max(v, ex) / 2;
};

const HourlyResult = (props) => {

  const { fallingBehind, shiftDetail, shiftTiming, shiftInfo } = useContext(DashboardContext)

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [activeExcavator, setActiveExcavator] = useState(0);
  const scrollableWindow = useRef();
  const excavatorCardRef = useRef();
  const [searchName, setsearchName] = useState("");
  const [target, setTarget] = useState(0)

  useEffect(() => {
    setWidth(excavatorCardRef.current?.getBoundingClientRect().width);
    setHeight(excavatorCardRef.current?.getBoundingClientRect().height);
    const handleResize = () => {
      setWidth(excavatorCardRef.current?.getBoundingClientRect().width);
      setHeight(excavatorCardRef.current?.getBoundingClientRect().height);
    };
    excavatorCardRef.current.addEventListener("resize", handleResize);
    return () => {
      excavatorCardRef.current.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setsearchName("");
    setTarget(Math.round(getHourlyTarget(
      shiftDetail?.excavators?.[activeExcavator]?.productive_hours,
      parseInt(shiftDetail?.excavators?.[activeExcavator]?.targetLoads, 10)
    )))
  }, [activeExcavator]);

  const [popup, setPopup] = useState();

  const endShifts = async (closePopup, data) => {
    const response = await CallWithAuth('POST', END_SHIFT, {
      type: 'single',
      shiftId: shiftInfo?._id,
      ...data
    });
    if (response?.res?.data?.status === 200) {
      toast.success(`Shift ended successfully for excavator ${data?.name}.`, {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      closePopup();
    } else {
      toast.error('An error occured when ending the shift.', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className="d-flex justify-content-center px-3 mb-3"
        id="button_header"
        style={{
          width: "100%",
          paddingTop: "20px",
          paddingBottom: "13px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: " 0px 3px 6px #00000029",
          height: "max-content",
          overflowX: "auto",
        }}
      >
        {shiftDetail?.excavators?.map((excavator, id) => {
          return (
            <Button
              label={excavator?.excavatorName}
              alert={fallingBehind?.excavators?.includes(excavator.excavatorName)}
              hold={excavator.breakdown}
              onClick={() => {
                setActiveExcavator(id);
              }}
              selected={id == activeExcavator}
            />
          );
        })}
      </div>

      {shiftDetail.excavators[activeExcavator]?.hourlyData && (
        <div
          ref={scrollableWindow}
          style={{
            width: "100%",
            padding: "10px 25px",
            height: "calc(100vh - 205px)",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              marginRight: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                style={{
                  font: "normal normal 700 18px/25px Open Sans",
                }}
              >
                Hourly Results
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4" id="excavator_part">
            <div
              style={{
                width: "330px",
                height: "330px",
                background: " #FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid " +
                  (shiftDetail.excavators[activeExcavator].breakdown
                    ? "#ED7C7F"
                    : fallingBehind?.excavators?.includes(shiftDetail.excavators[activeExcavator].excavatorName)
                      ? "#EADE5B"
                      : "#DEE5F2"),
                borderRadius: "8px",
                padding: 20,
              }}
            >
              <div className="d-flex" style={{ gap: 10, marginBottom: 20 }}>
                <Image
                  src={require('../../../../assets/images/jcb.png')}
                  height={30}
                  width={40}
                />
                <h2
                  style={{
                    font: 'normal normal bold 20px/27px Open Sans',
                    flexGrow: 1,
                  }}
                >
                  EXCAVATOR
                </h2>
                {
                  shiftDetail.excavators[activeExcavator]?.breakdown
                    ? (
                      <Image
                        src={require('../../../../assets/images/not-available.png')}
                        height={32}
                        width={32}
                      />
                    ) : fallingBehind?.excavators?.includes(
                      shiftDetail.excavators[activeExcavator].excavatorName
                    ) ? (
                      <Image
                        src={require('../../../../assets/images/alert.png')}
                        height={18}
                        width={18}
                        style={{ marginTop: 5 }}
                      />
                    ) : (
                      <div
                        style={{
                          height: 18,
                          width: 18,
                        }}
                      ></div>
                    )}{' '}
              </div>

              <table className="hourly-result-excavator-card">
                <thead>
                  <tr>
                    <th
                      style={{
                        font: 'normal normal bold 20px/27px Open Sans',
                        color: 'black',
                      }}
                    >
                      {shiftDetail.excavators[activeExcavator]?.excavatorName}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>BCM:</th>
                    <td>
                      {shiftDetail.excavators[activeExcavator]?.actualLoads}/
                      {shiftDetail.excavators[activeExcavator]?.targetLoads}
                    </td>
                  </tr>
                  <tr>
                    <th>Hourly Target:</th>
                    <td>{`${target} BCM`}</td>
                  </tr>
                  <tr>
                    <th>First Load:</th>
                    <td>
                      {utcToNZTime(
                        shiftDetail.excavators[activeExcavator]?.firstBucket
                      )?.formattedTime || '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>Operator:</th>
                    <td>
                      {shiftDetail.excavators[activeExcavator]?.operator
                        ?.firstName || '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
              {shiftInfo?.status == 'O' && <button
                className="btn btn-outline-info"
                disabled={true}
                onClick={() => {
                  return
                  setPopup({
                    title: `You're about to end the shift for EXCAVATOR ${shiftDetail.excavators[activeExcavator].excavatorName}`,
                    description: 'Would you like to continue?',
                    confirmText: 'Confirm',
                    onCancel: () => {
                      setPopup(undefined);
                    },
                    onConfirm: () => {
                      endShifts(() => setPopup(undefined), {
                        name: shiftDetail.excavators[activeExcavator].excavatorName,
                        _id: shiftDetail.excavators[activeExcavator].excavatorId
                      });
                    },
                  });
                }}
                style={{ marginTop: 10, width: '40%' }}
              >
                End Shift
              </button>}
            </div>

            <div
              style={{
                display: "flex",
                width: "calc(100% - 400px)",
                height: "330px",
                marginLeft: "40px",
                background:
                  "0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)",
                border: "1px solid rgb(225, 232, 244)",
                borderRadius: "8px",
                alignItems: "center",
              }}
              ref={excavatorCardRef}
            >
              <div className="d-flex justify-content-end align-items-center">
                <span
                  style={{
                    transform: "rotate(270deg)",
                    height: "max-content",
                    fontWeight: "700",
                  }}
                >
                  BCM
                </span>
                <div style={{ width: "calc(100% - 60px)", height: "100%" }}>
                  <VictoryChart
                    width={width}
                    height={height}
                    domainPadding={{ x: 25 }}
                  >
                    <VictoryBar
                      alignment="middle"
                      barWidth={12}
                      data={formatData(
                        shiftDetail.excavators[activeExcavator]?.hourlyData,
                        shiftInfo?.type,
                        shiftDetail.excavators[activeExcavator]
                      )}
                      events={[{
                        target: "data",
                        eventHandlers: {

                          onMouseOver: () => {

                            return [{
                              target: 'labels',
                              mutation: (props) => {
                                // if (props.datum)
                                //     console.log(props);
                                if (props.datum.earnings >= target)
                                  return {
                                    style: Object.assign({}, props.style, { fill: "black" })
                                  };
                              }
                            }];
                          },
                          onMouseOut: () => {
                            return [{
                              target: 'labels',
                              mutation: (innerProps) => {
                                if (innerProps.datum.earnings >= target)
                                  return {
                                    style: Object.assign({}, innerProps.style, { fill: ({ datum }) => isCurrentBar(datum.quarter, shiftTiming?.end) ? "black" : datum.earnings >= target ? "black" : "black" })
                                  };
                              }
                            }];
                          }
                        }
                      }]}
                      style={{
                        background: { fill: (re) => "yellow" },
                        data: {
                          fill: ({ datum }) =>
                            isCurrentBar(datum.quarter, shiftTiming?.end)
                              ? '#80B4FD'
                              : props.unScheduled
                                ? '#707070'
                                : datum.earnings >= target
                                  ? "#95C365"
                                  : "#FE747C",
                          cursor: ({ datum }) => datum.earnings >= target ? "pointer" : "default",
                        },

                        labels: {
                          fontSize: 15,
                          marginLeft: 50,

                          fill: ({ datum }) =>
                            isCurrentBar(datum.quarter, shiftTiming?.end) ? "black" : datum.earnings >= target ? "black" : "black",
                        },
                      }}
                      theme={VictoryTheme.grayscale}
                      x="quarter"
                      y="earnings"
                      cornerRadius={5}
                    />
                    <VictoryAxis
                      dependentAxis
                      minDomain={0}
                      domain={[
                        0,
                        getMaximumDomain(
                          Math.max(
                            ...formatData(
                              shiftDetail?.excavators?.[activeExcavator]?.hourlyData,
                              shiftInfo?.type,
                              shiftDetail.excavators[activeExcavator]
                            )?.map((v) => v.earnings)
                          ),
                          target
                        ),
                      ]}
                      tickFormat={(y) => y}
                      style={{
                        axis: { stroke: "black" },
                        ticks: { stroke: "black" },
                        tickLabels: { fill: "black" },
                      }}
                    />
                    <VictoryAxis
                      style={{
                        axis: { stroke: "black" },
                        ticks: { stroke: "black" },
                        tickLabels: {
                          fill: "black",
                          fontWeight: ({ text }) =>
                            isCurrentBar(text, shiftTiming?.end) ? "700" : "normal",

                          fontSize: ({ text }) =>
                            isCurrentBar(text, shiftTiming?.end) ? "18px" : "17px"
                        },
                      }}
                    />
                    <VictoryLine
                      y={() =>
                        target
                      }
                      style={{
                        data: { stroke: "black" },
                      }}
                    />
                    {/* <VictoryAxis
                  // style={{ marginBottom: '150px' }}
                  label="time"
                // dependentAxis
                /> */}
                  </VictoryChart>
                </div>
              </div>
            </div>
          </div>

          <div
            id="truck_part"
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "1px solid #E1E8F4",
              borderRadius: "8px",
              marginTop: "25px",
              marginBottom: "60px",
              padding: "20px",
              width: "100%",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-start">
                <Image
                  src={require("../../../../assets/images/truck.png")}
                  height={21}
                  width={28}
                />
                <span
                  style={{
                    marginLeft: 10,
                    font: "normal normal 700 18px/25px Open Sans",
                  }}
                >
                  Trucks(
                  {(shiftDetail.excavators[activeExcavator]?.haulTrucks?.length ? shiftDetail.excavators[activeExcavator]?.haulTrucks?.length : 0) + (shiftDetail.excavators[activeExcavator]?.allLoadsHourlyData?.length ? shiftDetail.excavators[activeExcavator]?.allLoadsHourlyData?.length : 0)})
                </span>
              </div>
              <div>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #dde5f3",
                    justifyContent: "space-between",
                    padding: "6px 12px",
                    borderRadius: "15px",
                    width: "250px",
                    backgroundColor: "white",
                  }}
                >
                  <input
                    type="search"
                    id="searchuser"
                    name="searchuser"
                    className="shadow-none"
                    value={searchName}
                    placeholder="Search for an Truck"
                    style={{
                      border: "none",
                      font: "normal normal normal 14px Open Sans",
                      width: "200px",
                    }}
                    onChange={(v) => setsearchName(v.target.value)}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
            {shiftDetail?.excavators[activeExcavator].trucks.filter((truck) => truck.truckName.toLowerCase()?.includes(searchName.toLowerCase()) || searchName.length < 1)
              .sort((truckL, truckR) => {
                if (
                  parseInt(truckR.actualLoads) >=
                  parseInt(truckR.targetLoads) &&
                  truckL.breakdown
                )
                  return true;
                else if (
                  parseInt(truckR.actualLoads) >=
                  parseInt(truckR.targetLoads) &&
                  parseInt(truckL.actualLoads) < parseInt(truckL.targetLoads)
                )
                  return true;
                else if (
                  parseInt(truckL.actualLoads) < parseInt(truckL.targetLoads) &&
                  truckR.breakdown
                )
                  return true;
                return false;
              })
              .map((truck, id) => {
                return (
                  <TruckGraphRow
                    // {...props}
                    isFallingBehind={fallingBehind?.excavators?.includes(truck.truckName)}
                    truck={truck}
                    shiftTiming={shiftTiming}
                    getMaximumDomain={getMaximumDomain}

                  />
                );
              })}
            {/* {shiftDetail.excavators[activeExcavator]?.allLoadsHourlyData?.map(
              (ht, id) => {
                let truck = props?.trucks?.filter(function (truck) {
                  return truck?.name?.Unit === ht?.name;
                })[0];
                return (
                  <TruckGraphRow
                    {...props}
                    notOperatingTrucks={notOperatingTrucks}
                    truck={{ ...truck, actualLoads: ht.totalLoads }}
                    loads={ht.loads}
                    unScheduled={true}

                    getMaximumDomain={getMaximumDomain}
                    behindTargetTrucks={behindTargetTrucks}
                  />
                );
              }
            )} */}
            {popup && <ConfirmationPopup {...popup} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default HourlyResult;
