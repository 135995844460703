import { ButtonGroup, Dropdown, Form, Image } from 'react-bootstrap';
import { CallWithAuth } from '../../../../action/apiActions';
import { FiDownload } from 'react-icons/fi';
import { toast } from 'react-toastify';
import {
    END_SHIFT,
} from '../../../../action/apiPath';
import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import TrendArrow from '../../../../components/TrendArrow';
import { useContext } from 'react';
import DashboardContext from '../../Context/DashboardContext';

export default function Header({ selectedOption, setSelectedOption, setPopup, topSlider }) {
    const [type, setType] = useState('Overburden');
    const { shiftInfo } = useContext(DashboardContext)

    const options = [
        { name: 'Shift totals', value: 1 },
        { name: 'Hourly results', value: 2 },
    ];

    const generateHourlyResultPDF = () => {
        let dashboard_header = document.getElementById('dashboard_header');
        let height1 = dashboard_header.getBoundingClientRect().height;

        let button_header = document.getElementById('button_header');
        button_header.style.removeProperty('box-shadow');
        let height2 = button_header.getBoundingClientRect().height;

        let excavator_part = document.getElementById('excavator_part');
        excavator_part.style.removeProperty('box-shadow');
        let height3 = excavator_part.getBoundingClientRect().height;

        let truck_part = document.getElementById('truck_part');
        let height4 = truck_part.getBoundingClientRect().height;

        let truck_cover = document.getElementsByClassName('truck_cover');
        for (var i = 0; i < truck_cover.length; i++) {
            truck_cover[i].style.removeProperty('border-right');
        }

        html2canvas(dashboard_header).then((canvas1) => {
            const imgData1 = canvas1.toDataURL(' image/jpeg');
            html2canvas(button_header).then((canvas2) => {
                const imgData2 = canvas2.toDataURL(' image/jpeg');
                html2canvas(excavator_part).then((canvas3) => {
                    const imgData3 = canvas3.toDataURL(' image/jpeg');
                    html2canvas(truck_part)
                        .then((canvas4) => {
                            const imgData4 = canvas4.toDataURL(' image/jpeg');
                            let pdf = new jsPDF('1', 'px', [
                                1480,
                                height1 + height2 + height3 + height4 + 200,
                            ]);

                            pdf.addImage(imgData1, 'JPEG', 0, 40, 1450, height1);
                            pdf.addImage(imgData2, 'JPEG', 0, height1 + 50, 1480, height2);
                            pdf.addImage(imgData3, 'JPEG', 10, height2 + 140, 1470, height3);
                            pdf.addImage(imgData4, 'JPEG', 10, height3 + 250, 1480, height4);

                            pdf.save('Hourly Report');
                        })
                        .then(() => {
                            button_header.style.boxShadow = 'rgba(0, 0, 0, 0.16) 0px 3px 6px';
                            for (var i = 0; i < truck_cover.length; i++) {
                                truck_cover[i].style.borderRight = '1px solid #E1E8F4';
                            }
                        });
                });
            });

            // document.getElementById("main").style.paddingTop = "0px";
        });
        // excavator_part.style.boxShadow="0px 0px 4px 2px #8989895e"
    };

    const generateShiftTotalPDF = () => {
        let dashboard_header = document.getElementById('dashboard_header');
        let height1 = dashboard_header.getBoundingClientRect().height;

        let truck_excavator_summary = document.getElementById(
            'truck_excavator_summary'
        );
        truck_excavator_summary.style.removeProperty('box-shadow');

        // button_header.style.removeProperty("box-shadow");
        let height2 = truck_excavator_summary.getBoundingClientRect().height;

        let excavator_heading = document.getElementById('excavator_heading');
        // y_scroll
        let y_scroll = document.getElementById('y_scroll');
        y_scroll.style.overflowY = 'visible';
        let height3 = excavator_heading.getBoundingClientRect().height;

        let excavatorCard_container = document.getElementById(
            'excavatorCard_container'
        );
        let height4 = excavatorCard_container.getBoundingClientRect().height;

        let excavator_card_container = document.getElementsByClassName(
            'excavator_card_container'
        );
        for (var i = 0; i < excavator_card_container.length; i++) {
            excavator_card_container[i].style.removeProperty('box-shadow');
        }

        let remove_scroll = document.getElementsByClassName('remove_scroll');
        //  if(remove_scroll.scrollHeight > remove_scroll.clientHeight)
        // console.log(r)
        {
            for (var i = 0; i < remove_scroll.length; i++) {
                console.log('leri');
                remove_scroll[i].style.height = 'max-content';
            }
        }

        let remove_scroll_container = document.getElementsByClassName(
            'remove_scroll_container'
        );
        // if(remove_scroll_container.scrollHeight > remove_scroll_container.clientHeight )
        {
            for (var i = 0; i < remove_scroll_container.length; i++) {
                console.log('pheri');
                remove_scroll_container[i].style.height = 'max-content';
            }
        }

        html2canvas(dashboard_header).then((canvas1) => {
            const imgData1 = canvas1.toDataURL(' image/jpeg');
            html2canvas(truck_excavator_summary).then((canvas2) => {
                const imgData2 = canvas2.toDataURL(' image/jpeg');
                html2canvas(excavator_heading).then((canvas3) => {
                    const imgData3 = canvas3.toDataURL(' image/jpeg');
                    html2canvas(excavatorCard_container)
                        .then((canvas4) => {
                            const imgData4 = canvas4.toDataURL(' image/jpeg');
                            let pdf = new jsPDF('1', 'px', [
                                1480,
                                height1 + height2 + height3 + height4 + 200,
                            ]);

                            pdf.addImage(imgData1, 'JPEG', 0, 40, 1450, height1);
                            pdf.addImage(imgData2, 'JPEG', 0, height1 + 50, 1480, height2);
                            pdf.addImage(imgData3, 'JPEG', 20, height2 + 140, 1480, height3);
                            topSlider
                                ? pdf.addImage(
                                    imgData4,
                                    'JPEG',
                                    0,
                                    height3 + 500,
                                    1480,
                                    height4
                                )
                                : pdf.addImage(
                                    imgData4,
                                    'JPEG',
                                    0,
                                    height3 + 200,
                                    1480,
                                    height4
                                );

                            pdf.save('Dashboard Report');
                        })
                        .then(() => {
                            truck_excavator_summary.style.boxShadow =
                                'rgba(0, 0, 0, 0.06) 0px 8px 6px;';
                            for (var i = 0; i < excavator_card_container.length; i++) {
                                excavator_card_container[i].style.boxShadow =
                                    'rgb(0 0 0 / 16%) 0px 3px 6px';
                            }
                            for (var i = 0; i < remove_scroll.length; i++) {
                                console.log('remove_scroll');
                                remove_scroll[i].style.height = '120px';
                            }

                            for (var i = 0; i < remove_scroll_container.length; i++) {
                                console.log('remove_scroll_container');
                                remove_scroll_container[i].style.height = '230px';
                            }
                            y_scroll.style.overflowY = 'scroll';
                        });
                });
            });

            // document.getElementById("main").style.paddingTop = "0px";
        });
    };

    const endShifts = async (closePopup) => {
        const response = await CallWithAuth('POST', END_SHIFT, {
            type: 'all',
            shiftId: shiftInfo?.shiftPlanId
        });
        if (response?.res?.data?.status === 200) {
            toast.success('Shifts ended successfully.', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            closePopup();
        } else {
            toast.error('An error occured when ending the shifts.', {
                position: 'bottom-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const renderShiftStatus = () => {
        let statusText = '';
        if (shiftInfo?.status === 'O') {
            statusText = 'Ongoing';
        } else if (shiftInfo?.status === 'P') {
            statusText = 'Published';
        } else {
            statusText = 'Ended';
        }
        return (
            <p style={{ color: '#707070', fontWeight: 'bold', marginBottom: 0 }}>{`(${statusText})`}</p>
        );
    };

    const renderShiftButton = () => {
        if (shiftInfo?.status === 'O') {
            return (
                <button
                    className="btn btn-outline-info"
                    onClick={() => {
                        setPopup({
                            title: 'You’re about to end the shift for ALL EQUIPMENT',
                            description: 'Would you like to continue?',
                            confirmText: 'Confirm',
                            onCancel: () => {
                                setPopup(undefined);
                            },
                            onConfirm: () => {
                                endShifts(() => setPopup(undefined));
                            },
                        });
                    }}
                    style={{ marginLeft: 20 }}
                >
                    End shift for all equipment
                </button>
            );
        }
        return null;
    };

    const renderDropdownItems = () => {
        const dropdownItems = [
            { label: 'Target', color: '#000000', bar: true },
            { label: 'Target Achieved', color: '#95C365' },
            { label: 'Below Target', color: '#ED7C7F' },
            { label: 'Unscheduled', color: '#707070' },
            { label: 'Not Operating', imageSrc: require('../../../../assets/images/not-available.png') },
            { label: 'Falling Behind', imageSrc: require('../../../../assets/images/alert.png') },
            { label: 'Backward Trend', trend: true, backward: true },
            { label: 'Upward Trend', trend: true, backward: false },
            { label: 'Ongoing Cycle (on hourly results)', color: '#BFD4F9' },
        ];

        return dropdownItems.map((item, index) => (
            <Dropdown.Item
                key={index}
                className="d-flex align-items-center justify-content-start"
                disabled
            >
                {item.bar && (
                    <div
                        className="d-flex justify-content-center"
                        style={{ width: '18px', marginRight: 7 }}
                    >
                        <div
                            style={{
                                width: '0px',
                                height: '15px',
                                border: ' 1.6px solid #000000',
                                borderRadius: '7px',
                            }}
                        />
                    </div>
                )}
                {item.color && !item.bar && (
                    <div
                        style={{
                            background: item.color + ' 0% 0% no-repeat padding-box',
                        }}
                        className="dashboard__header__bar"
                    />
                )}
                {item.imageSrc && (
                    <Image
                        src={item.imageSrc}
                        height={item.label === 'Not Operating' ? 18 : 16}
                        width={18}
                        style={{ marginRight: 7 }}
                    />
                )}
                {item.trend && (
                    <div
                        className="d-flex justify-content-center"
                        style={{ width: '18px', marginRight: 7 }}
                    >
                        {item.backward ? <TrendArrow backward={true} /> : <TrendArrow backward={false} />}
                    </div>
                )}
                <div className="dashboard__header__bar__label">{item.label}</div>
            </Dropdown.Item>
        ));
    };

    return (
      <div
        className="d-flex align-items-center justify-content-between pb-3"
        style={{
          width: "100%",
          paddingInline: 20,
          paddingTop: "2rem",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
        }}
        id="dashboard_header"
      >
        <section className="d-flex">
          <hgroup className="d-flex" style={{ gap: 5 }}>
            <h1
              style={{
                fontSize: "1.7rem",
                lineHeight: "2.5rem",
                marginBottom: 0,
              }}
            >{`#${shiftInfo?.reportNo} ${shiftInfo?.type} Shift`}</h1>
            {renderShiftStatus()}
          </hgroup>
          {renderShiftButton()}
        </section>

        <div className="d-flex align-items-center">
          <Dropdown className="d-inline">
            <Dropdown.Toggle
              style={{ height: "2.5rem", width: "6rem" }}
              bsPrefix="custom-toggle"
              id=""
            >
              Legend
            </Dropdown.Toggle>

            <Dropdown.Menu bsPrefix="dropdown-menu custom-menu">
              {renderDropdownItems()}
            </Dropdown.Menu>
          </Dropdown>
          <div className="d-flex mx-3">
            <Form.Group controlId="formBasicSelect">
              <Form.Control
                as="select"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                style={{
                  fontSize: "1rem",
                  borderRadius: "18px",
                  color: "black",
                  padding: "1px 7px",
                  width: "10.5rem",
                }}
              >
                <option value="Overburden">Overburden</option>
                <option value="Coal">Coal</option>
              </Form.Control>
            </Form.Group>
          </div>
          <FiDownload
            onClick={
              selectedOption === 2
                ? generateHourlyResultPDF
                : generateShiftTotalPDF
            }
            style={{
              color: "black",
              cursor: "pointer",
              borderRadius: "50%",
              padding: ".4rem",
              width: "2.4rem",
              height: "2.4rem",
              border: "1.5px solid rgb(225,232,244)",
            }}
          />

          <ButtonGroup
            className="ml-3"
            style={{ backgroundColor: "rgb(240,244,251)" }}
          >
            {options.map((option, idx) => (
              <div
                key={idx}
                style={{
                  backgroundColor:
                    selectedOption === idx + 1 ? "black" : "rgb(240,244,251)",
                  color: selectedOption === idx + 1 ? "white" : "#96a4bb",
                  borderRadius: "5px",
                  borderWidth: "0px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  padding: "0px 1rem",
                }}
                name="radio"
                value={option.value}
                onClick={(e) => setSelectedOption(idx + 1)}
              >
                {option.name}
              </div>
            ))}
          </ButtonGroup>
        </div>
      </div>
    );
};
