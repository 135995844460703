import React, { useEffect, useState } from 'react';
import WaterfallModal from '../../../components/WaterfallModal/WaterfallModal';
import styles from "./History.module.css";
import TruckPlanFields from './TruckPlanFields';
import { GoKebabHorizontal } from 'react-icons/go';
import { DateTimeFormats, ShiftStatus } from '../../../utils/contants';
import moment from 'moment';
import NoDataMessage from '../../../components/NoDataMessage/NoDataMessage';

export default function TruckPlan({ multi, showError, viewOnly, formData, setFormData, expandAll, handleEditMultiPlanTruck, excavatorAssignedPlan, canEditShift }) {

    const [isMandateErrorOpen, setIsMandateErrorOpen] = useState(showError);
    const handleFieldChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    useEffect(() => {
        setIsMandateErrorOpen(showError)
    }, [showError])

    if (!formData?.length && !formData?.plantId)
        return <NoDataMessage />

    if (multi) {

        return <div className={styles.container}>
            {formData?.map((shiftPlan, index) => {
                return (
                    <WaterfallModal
                        key={index}
                        isOpen={expandAll === index || expandAll}
                        triggerContent={`Plan ${shiftPlan?.plan}_${shiftPlan?.planStartTime?.slice(12) || shiftPlan?.startTime?.slice(12)} - ${shiftPlan?.planEndTime?.slice(12) || shiftPlan?.endTime?.slice(12)}`}
                        actionMenuOptions={(() => {
                            return [
                                (!shiftPlan?.removedFromPlan &&
                                    (shiftPlan?.status !== ShiftStatus?.ENDED &&
                                        canEditShift &&
                                        (!shiftPlan.status ||
                                            moment(shiftPlan.planEndTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') >= 10))) &&
                                {
                                    name: 'Edit',
                                    onClick: () => {
                                        console.log("Editing MultiPlan Truck:", shiftPlan?.plan);
                                        handleEditMultiPlanTruck(shiftPlan?.plan);
                                    }
                                },
                                { name: 'Download PDF', onClick: () => { console.log("Downloading PDF"); } },
                                { name: 'Download CSV', onClick: () => { console.log("Downloading CSV"); } },
                            ].filter(Boolean)
                        })()}
                        isDisabled={shiftPlan?.removedFromPlan}
                        actionIcon={<GoKebabHorizontal color='black' size={20} />}
                        content={
                            <TruckPlanFields
                                viewOnly={true}
                                formData={shiftPlan}
                                showError={showError}
                                excavatorAssignedPlan={excavatorAssignedPlan}
                                isMandateErrorOpen={isMandateErrorOpen}
                                handleFieldChange={handleFieldChange}
                                setIsMandateErrorOpen={setIsMandateErrorOpen}
                            />}
                    />
                );
            })}
        </div>
    }
    return (
        <TruckPlanFields
            excavatorAssignedPlan={excavatorAssignedPlan}
            isMandateErrorOpen={isMandateErrorOpen}
            setIsMandateErrorOpen={setIsMandateErrorOpen}
            viewOnly={viewOnly}
            formData={formData}
            showError={showError}
            handleFieldChange={handleFieldChange}
        />
    );
}