import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { createInitialGeneralShiftPlanDetails, initialState, ShiftStatus } from '../utils/contants';
import { fetchActivityDescriptions, fetchAreas, fetchCheckIsGettingEdited, fetchCrewTypes, fetchDepartments, fetchMaterials, fetchMines, fetchSourceDumps, fetchUserList } from '../utils/apiService';
import { toast } from 'react-toastify';
import useSession from '../hooks/useSession';
import { CallWithAuth } from '../../../action/apiActions';
import useAuth from '../../../hooks/useAuth';
import { GET_REPORT_CODE } from '../../../action/apiPath';
const AddDailyPlanContext = createContext();

export const useAddDailyPlan = () => {
    return useContext(AddDailyPlanContext);
};

export const AddDailyPlanProvider = ({ children }) => {
    //need module access
    const { auth } = useAuth();
    const queryParams = new URLSearchParams(window.location.search);
    const [search, setSearch] = useState(initialState.search);
    const [loading, setLoading] = useState(initialState.loading);
    const [shiftStatus, setShiftStatus] = useState(initialState.shiftStatus);
    const [overlapFailures, setOverlapFailures] = useState([])
    //Required Data to Create or Edit Shift
    const [minesList, setMinesList] = useState(initialState.minesList);
    const [departmentsList, setDepartmentsList] = useState(initialState.departmentsList);
    const [crewTypesList, setCrewTypesList] = useState(initialState.crewTypesList);
    const [materialList, setMaterialList] = useState(initialState.materialList);
    const [areaList, setAreaList] = useState(initialState.areaList);
    const [sourceDumpList, setSourceDumpList] = useState(initialState.sourceDumpList);
    const [supervisorList, setSupervisorList] = useState(initialState.supervisorList);
    const [leadingHandList, setLeadingHandList] = useState(initialState.leadingHandList);
    const [activityDescriptions, setActivityDescriptions] = useState(initialState.activityDescriptions);
    //Original Data
    const [originalShiftData, setOriginalShiftData] = useState(initialState.originalShiftData);
    const [originalData, setOriginalData] = useState(initialState.originalData);
    //Data Forms
    const [generalShiftPlanDetailsFormData, setGeneralShiftPlanDetailsFormData] = useState(createInitialGeneralShiftPlanDetails());
    const [assignedExcavatorDetailsFormData, setAssignedExcavatorDetailsFormData] = useState(initialState.assignedExcavatorDetailsFormData);
    const [removedEquipments, setRemovedEquipments] = useState(initialState.removedEquipments);
    //Flyers State
    const [isGeneralShiftFlyerOpen, setIsGeneralShiftFlyerOpen] = useState(initialState.isGeneralShiftFlyerOpen);
    const [isExcavatorShiftDetailsFlyerOpen, setIsExcavatorShiftDetailsFlyerOpen] = useState(initialState.isExcavatorShiftDetailsFlyerOpen);
    const [isTruckShiftDetailsFlyerOpen, setIsTruckShiftDetailsFlyerOpen] = useState(initialState.isTruckShiftDetailsFlyerOpen);
    const [removedEquipmentFlyerOpen, setRemovedEquipmentFlyerOpen] = useState(initialState.removedEquipmentFlyerOpen);
    //Session
    const { seconds, startSession, setEditSession, endSession, editSession, mySession, setMySession, editSessionRef, stopFetchingRef } = useSession()
    const [canEditShift, setCanEditShift] = useState(initialState.canEditShift);
    const canEditShiftRef = useRef(initialState.canEditShiftRef);
    const newShiftIdRef = useRef(null);
    const shiftStatusRef = useRef(null);

    const openFlyer = (openedFlyer, value) => {
        if (openedFlyer === 'truck') {
            setRemovedEquipmentFlyerOpen(-1);
            setIsExcavatorShiftDetailsFlyerOpen(-1);
            setIsGeneralShiftFlyerOpen(false);
            setIsTruckShiftDetailsFlyerOpen(value)
        } else if (openedFlyer === 'excavator') {
            setIsTruckShiftDetailsFlyerOpen(null);
            setRemovedEquipmentFlyerOpen(-1);
            setIsGeneralShiftFlyerOpen(false);
            setIsExcavatorShiftDetailsFlyerOpen(value)
        } else if (openedFlyer === 'removedEquipment') {
            setIsTruckShiftDetailsFlyerOpen(null);
            setIsExcavatorShiftDetailsFlyerOpen(-1);
            setIsGeneralShiftFlyerOpen(false);
            setRemovedEquipmentFlyerOpen(value)
        } else if (openedFlyer === 'general') {
            setIsTruckShiftDetailsFlyerOpen(null);
            setIsExcavatorShiftDetailsFlyerOpen(-1);
            setRemovedEquipmentFlyerOpen(-1);
            setIsGeneralShiftFlyerOpen(value)
        }
    };

    const closeFlyer = (openedFlyer) => {
        if (openedFlyer === 'truck')
            setIsTruckShiftDetailsFlyerOpen(null);
        else if (openedFlyer === 'excavator')
            setIsExcavatorShiftDetailsFlyerOpen(-1);
        else if (openedFlyer === 'removedEquipment')
            setRemovedEquipmentFlyerOpen(-1);
        else if (openedFlyer === 'general')
            setIsGeneralShiftFlyerOpen(false);

    }

    const getReportNo = async () => {
        const response = await CallWithAuth("GET", GET_REPORT_CODE);
        if (response.res.data.status === 200) {
            setGeneralShiftPlanDetailsFormData((prev) => ({
                ...prev,
                reportNo: response.res.data.data,
                shiftSupervisor: { value: auth.userData._id, label: auth.userData.firstName.concat(" ", auth.userData.lastName) },
                createdBy: { value: auth.userData._id, label: auth.userData.firstName.concat(" ", auth.userData.lastName) },
            }));
        }
    };

    const fetchData = async () => {
        try {
            const [
                fetchedCrewTypes,
                fetchedDepartments,
                fetchedMines,
                fetchedMaterials,
                fetchedAreas,
                fetchedSourceDumps,
                fetchedSupervisors,
                fetchedLeadingHands,
                fetchedActivityDescriptions,
            ] = await Promise.all([
                fetchCrewTypes(),
                fetchDepartments(),
                fetchMines(),
                fetchMaterials(),
                fetchAreas(),
                fetchSourceDumps(),
                fetchUserList(300),//supervisor
                fetchUserList(400),//Leading Hand
                fetchActivityDescriptions()
            ]);

            setCrewTypesList(fetchedCrewTypes);
            setDepartmentsList(fetchedDepartments);
            setMinesList(fetchedMines);
            setMaterialList(fetchedMaterials);
            setAreaList(fetchedAreas);
            setSourceDumpList(fetchedSourceDumps);
            setSupervisorList(fetchedSupervisors);
            setLeadingHandList(fetchedLeadingHands);
            setActivityDescriptions(fetchedActivityDescriptions)
        } catch (error) {
            console.error(error);
        }
    };

    const fetchShiftDetails = async (sid) => {

        const response = await CallWithAuth("GET", "api/shift-planning/shift-details/" + sid);
        if (response.res.data.status === 200) {
            let { data } = response.res.data;
            const { removedEquipments, assignedExcavators, ...shiftDetails } = data;

            setRemovedEquipments([...(removedEquipments ?? [])]);
            newShiftIdRef.current = data?.newShiftId
            console.log(data?.status);
            shiftStatusRef.current = data?.status
            setAssignedExcavatorDetailsFormData(JSON.parse(JSON.stringify(assignedExcavators)));
            setOriginalData(JSON.parse(JSON.stringify(assignedExcavators)));
            setOriginalShiftData({ ...data });
            setShiftStatus(shiftDetails.status)
            setGeneralShiftPlanDetailsFormData((prev) => ({
                ...prev,
                ...shiftDetails
            }));
        }
        setLoading(false);
    };

    useEffect(() => {
        let timeoutForFetchDetails;
        let isMounted = true;

        const fetchDataAndDetails = async () => {
            try {
                setLoading(true);
                const sid = queryParams.get("id");

                if (sid) {
                    // Initial fetch
                    await Promise.all([fetchData(), fetchShiftDetails(sid)]);
                    if (isMounted) setLoading(false);

                    // Function to fetch data with delay
                    const fetchDataWithDelay = async () => {
                        if (isMounted && !stopFetchingRef.current) {
                            try {
                                await fetchData();
                                await fetchShiftDetails(sid);
                            } catch (err) {
                                console.error("Error fetching data with delay:", err.message);
                            }
                            if (isMounted) {
                                timeoutForFetchDetails = setTimeout(fetchDataWithDelay, 120000); // 120 seconds delay
                            }
                        }
                    };

                    // Start the interval for periodic fetch
                    if (isMounted && shiftStatusRef.current !== ShiftStatus.ENDED) {
                        timeoutForFetchDetails = setTimeout(fetchDataWithDelay, 120000);
                    }
                } else {
                    await Promise.all([fetchData(), getReportNo()]);
                    if (isMounted) setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data or details:", error.message);
                if (isMounted) setLoading(false);

                if (isMounted && shiftStatusRef.current !== ShiftStatus.ENDED) {
                    timeoutForFetchDetails = setTimeout(fetchDataAndDetails, 120000);
                }
            }
        };

        fetchDataAndDetails();

        return () => {
            isMounted = false;
            if (timeoutForFetchDetails) {
                clearTimeout(timeoutForFetchDetails);
            }
        };
    }, []); // This only runs once on mount,

    useEffect(() => {
        let timeoutForCheckIsGettingEditted;
        let isMounted = true;

        const checkIsGettingEdited = async () => {
            if (shiftStatusRef.current === ShiftStatus.ENDED) return;

            try {
                if (isMounted && !stopFetchingRef.current) {
                    const sid = queryParams.get("id");
                    const data = await fetchCheckIsGettingEdited(sid);

                    if (data?.willEndAt) {
                        const isCurrentUser = localStorage.getItem('accessToken') === data.sessionJWT;
                        if (isMounted) {
                            setEditSession(prev => ({
                                ...data,
                                by: isCurrentUser ? null : `${data?.user?.firstName} ${data?.user?.lastName ?? ""}`
                            }));
                            stopFetchingRef.current = isCurrentUser;
                            setMySession(isCurrentUser);
                        }
                    } else {
                        if (isMounted && !stopFetchingRef.current && editSessionRef.current?.by) {
                            setEditSession({ startedAt: null, willEndAt: null, by: null });
                            stopFetchingRef.current = false;
                        }
                    }
                }

                if (isMounted && shiftStatusRef.current !== ShiftStatus.ENDED) {
                    timeoutForCheckIsGettingEditted = setTimeout(checkIsGettingEdited, 3000); // 3 seconds delay
                }
            } catch (error) {
                console.error("Error fetching edit status:", error);

                if (isMounted && shiftStatusRef.current !== ShiftStatus.ENDED) {
                    timeoutForCheckIsGettingEditted = setTimeout(checkIsGettingEdited, 5000); // 5 seconds delay
                }
            }
        };

        const sid = queryParams.get("id");
        if (sid) checkIsGettingEdited();

        return () => {
            isMounted = false;
            if (timeoutForCheckIsGettingEditted) {
                clearTimeout(timeoutForCheckIsGettingEditted);
            }
            if (stopFetchingRef.current && shiftStatusRef.current) {
                endSession(newShiftIdRef.current, shiftStatusRef.current);
            }
        };
    }, [shiftStatus]); // This runs when shiftStatus changes


    useEffect(() => {

        canEditShiftRef.current = !(editSession.by || shiftStatus == ShiftStatus.ENDED || loading)

        setCanEditShift(!(editSession.by || shiftStatus == ShiftStatus.ENDED || loading))
    }, [loading, shiftStatus, editSession])


    const dailyPlanData = {
        shiftStatus,
        removedEquipments,
        setRemovedEquipments,
        isGeneralShiftFlyerOpen,
        setIsGeneralShiftFlyerOpen,
        originalShiftData,
        setOriginalShiftData,
        originalData,
        setOriginalData,
        search,
        setSearch,
        minesList,
        setMinesList,
        departmentsList,
        setDepartmentsList,
        crewTypesList,
        setCrewTypesList,
        materialList,
        setMaterialList,
        areaList,
        setAreaList,
        sourceDumpList,
        setSourceDumpList,
        supervisorList,
        setSupervisorList,
        leadingHandList,
        setLeadingHandList,
        activityDescriptions,
        setActivityDescriptions,
        loading,
        setLoading,
        assignedExcavatorDetailsFormData,
        setAssignedExcavatorDetailsFormData,
        removedEquipmentFlyerOpen,
        setRemovedEquipmentFlyerOpen,
        isTruckShiftDetailsFlyerOpen,
        setIsTruckShiftDetailsFlyerOpen,
        isExcavatorShiftDetailsFlyerOpen,
        setIsExcavatorShiftDetailsFlyerOpen,
        generalShiftPlanDetailsFormData,
        setGeneralShiftPlanDetailsFormData,
        editSession,
        setEditSession,
        stopFetchingRef,
        canEditShiftRef,
        canEditShift,
        setCanEditShift,
        newShiftIdRef,
        shiftStatusRef,
        startSession,
        endSession,
        openFlyer,
        closeFlyer,
        mySession,
        seconds,
        overlapFailures,
        setOverlapFailures
    };

    return (
        <AddDailyPlanContext.Provider value={dailyPlanData}>
            {children}
        </AddDailyPlanContext.Provider>
    );
};
