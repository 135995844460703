export default function TrendArrow({ backward }) {
  return (
    <svg
      style={{
        height: 12,
        position: 'relative',
        fontWeight: '900',
        transform: backward ? 'rotateZ(180deg)' : 'initial',
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="22.207"
      height="20.414"
      viewBox="0 0 22.207 20.414"
    >
      <g
        id="trend_arrow"
        data-name="trend arrow"
        transform="translate(1 19.207) rotate(-90)"
      >
        <path
          id="Icon_ionic-md-arrow-dropdown"
          data-name="Icon ionic-md-arrow-dropdown"
          d="M9,22.5l9-9,9,9Z"
          transform="translate(27 34) rotate(180)"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Line_2266"
          data-name="Line 2266"
          x2="15"
          transform="translate(9) rotate(90)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
