import React, { useEffect, useState } from "react";
import styles from "./History.module.css";
import { CallWithAuth } from "../../../../action/apiActions";
import { toLower } from "lodash";
import WaterfallModal from "../../components/WaterfallModal/WaterfallModal";
import { highlightText } from "../../utils/helper";
import moment from "moment";
import NoDataMessage from "../../components/NoDataMessage/NoDataMessage";

const HistoryWithPlans = ({ search, setSearch, shiftId, equipId, equipType, exId }) => {
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        fetchShiftHistory();
    }, [equipId])

    const fetchShiftHistory = async () => {
        try {
            setLoading(true);
            const url = "api/shift-planning/history/" + shiftId + `?equipment=${equipId}&category=${equipType}`;
            const response = await CallWithAuth("GET", url);
            if (response.res.data.status === 200) {
                let { data } = response.res.data;
                setHistory(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        if (search.query) {
            let count = 0;
            history.forEach((Plan) => {
                Plan.history.forEach((history) => {
                    history.changes.forEach((change) => {
                        Object.values(change).forEach((value) => {
                            if (
                                typeof value === "string" &&
                                toLower(value).includes(toLower(search.query))
                            )
                                count++;
                        });
                    });
                });
            });
            setSearch({ ...search, count: count });
        } else {
            setSearch({ ...search, count: 0 });
        }
    }, [search.query]);

    const checkQuery = (PlanHistory) => {
        if (search.query) {
            let count = 0;
            PlanHistory.forEach((history) => {
                history.changes.forEach((change) => {
                    Object.values(change).forEach((value) => {
                        if (
                            typeof value === "string" &&
                            toLower(value).includes(toLower(search.query))
                        )
                            count++;
                    });
                });
            });
            if (count > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <div className={styles.container}>
            {loading ? 'Loading...' : !history?.length ? <NoDataMessage /> : <div style={{ marginRight: -5, height: "100%", width: "100%" }}>
                {history.length === 1 ? history.map((history, index) => {
                    return (
                        history.history.map((history, index) => {
                            return (
                                <div key={index} className={styles.card}>
                                    <div className={styles.date}>
                                        {moment(history.updatedAt).format('DD/MM/YYYY, hh:mm A')} - {history.updatedBy}
                                    </div>
                                    {history.changes.map((change, idx) => {
                                        return (
                                            <div key={idx} className={styles.changesCard}>
                                                <div className={styles.key}>
                                                    {highlightText(change.key, search.query)}:
                                                </div>
                                                <div>
                                                    {highlightText(change.from, search.query)}{" "}
                                                    {change.from && <span
                                                        style={{ color: "#6A7C9A", fontStyle: "italic" }}
                                                    >
                                                        to
                                                    </span>}{" "}
                                                    {highlightText(change.to, search.query)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    );
                }) : history.map((shiftPlan, index) => {
                    return (
                        <WaterfallModal
                            key={index}
                            isOpen={checkQuery(shiftPlan.shiftPlan)}
                            triggerContent={`${shiftPlan?.excavator?.label} Plan ${shiftPlan.plan + "_" + moment(shiftPlan.startTime).format('hh:mm A') + " - " + moment(shiftPlan.endTime).format('hh:mm A')
                                }`}
                            isDisabled={shiftPlan?.excavator?.value != exId || shiftPlan?.isRemoved}
                            content={shiftPlan.history.map((history, index) => {
                                return (
                                    <div key={index} className={styles.card}>
                                        <div className={styles.date}>
                                            {moment(history.updatedAt).format('DD/MM/YYYY, hh:mm A')} - {history.updatedBy}
                                        </div>
                                        {history.changes.map((change, idx) => {
                                            return (
                                                <div key={idx} className={styles.changesCard}>
                                                    <div className={styles.key}>
                                                        {highlightText(change.key, search.query)}:
                                                    </div>
                                                    <div>
                                                        {highlightText(change.from, search.query)}{" "}
                                                        {change.from && <span
                                                            style={{ color: "#6A7C9A", fontStyle: "italic" }}
                                                        >
                                                            to
                                                        </span>}{" "}
                                                        {highlightText(change.to, search.query)}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        />
                    );
                })}
            </div>}
        </div>
    );
};

export default HistoryWithPlans;

