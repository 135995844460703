import { CallWithAuth } from "../../../../action/apiActions";
import { GET_OPERATOR_SHIFT_ASSIGNMENTS } from "../../../../action/apiPath";
import { fetchEquipmentList } from "../apiService";
import { detectOverlaps } from "../../SubComponents/ExcavatorShiftDetails/utils/helper";


function isOverlapping(shift1, shift2) {
    // //Pass partial
    // return (
    //     new Date(shift1.startTime) >= new Date(shift2.startTime) &&
    //     new Date(shift1.endTime) <= new Date(shift2.endTime)
    // );

    return (
        new Date(shift1.startTime) < new Date(shift2.endTime) &&
        new Date(shift1.endTime) > new Date(shift2.startTime)
    );
}

const fetchOperatorShiftAssignments = async (formattedData, plan) => {
    try {
        const response = await CallWithAuth(
            "GET",
            `${GET_OPERATOR_SHIFT_ASSIGNMENTS}/${plan?.operator}?startTime=${plan?.startTime}&endTime=${plan?.endTime}`
        );
        const { status, data } = response.res.data;

        if (status !== 200) {
            throw new Error(`Error fetching operator's other shift details: ${data?.message || 'Unknown error'}`);
        }

        if (data?.length && data.some(d => d?.shift !== formattedData.newShiftId)) {
            return true;
        }
        return false;
    } catch (error) {
        throw new Error(`Error fetching operator's other shift details: ${error.message}`);
    }
};

export const checkForShiftPlanOverlaps = async (formattedShiftData) => {
    let allOverlaps = [];

    formattedShiftData?.assignedExcavators?.forEach((ex) => {
        const hasOverlap = detectOverlaps(ex?.shiftPlans);
        if (hasOverlap) {
            allOverlaps.push(ex?.plantId);
        }
    });

    return {
        hasOverlap: allOverlaps.length > 0,
        allOverlaps,
    };
};

export const checkForEquipmentOverlaps = async (formattedData) => {
    const { excavators, trucks } = await fetchEquipmentList();

    let allOverlaps = [];
    console.log(excavators, trucks);

    formattedData?.assignedExcavators?.forEach((ex) => {
        const otheshiftDetailsAssigned = excavators?.find(e => e.Unit === ex?.plantId)?.assignedInShifts;
        let excavatorOverlapFound = false;

        ex?.shiftPlans?.forEach((shiftPlan) => {
            // Check for excavator overlap
            otheshiftDetailsAssigned?.forEach((oshift) => {
                if (!excavatorOverlapFound && oshift?.shift._id !== formattedData.newShiftId && isOverlapping(shiftPlan, oshift)) {
                    allOverlaps.push(
                        ex?.plantId
                    );
                    excavatorOverlapFound = true; // Mark that an excavator overlap was found
                }
            });

            //  check for haul truck overlap
            shiftPlan?.haulTrucks?.forEach((ht) => {
                const otheshiftDetailsAssignedForTruck = trucks?.find(t => t.Unit === ht?.plantId)?.assignedInShifts;
                otheshiftDetailsAssignedForTruck?.forEach((oshift) => {
                    if (oshift?.shift._id !== formattedData.newShiftId && isOverlapping(ht, oshift)) {
                        allOverlaps.push(
                            ht?.plantId,
                        );
                    }
                });
            });
        });
    });

    return { hasOverlap: allOverlaps.length > 0, allOverlaps };
};

export const checkForOperatorOverlaps = async (formattedData) => {
    if (!formattedData?.assignedExcavators?.length) return { hasOverlap: false, allOverlaps: [] };

    const allOverlaps = [];

    // Function to handle fetching and processing of assignments
    const processAssignments = async (ex, shiftPlan, type) => {
        try {
            const result = await fetchOperatorShiftAssignments(formattedData, shiftPlan);
            if (result) {
                allOverlaps.push(type == "excavator" ? ex?.plantId : shiftPlan?.plantId);
            }
        } catch (error) {
            console.error(`Error fetching operator shift assignment for ${type}:`, shiftPlan, error);
        }
    };

    const promises = formattedData.assignedExcavators.flatMap(ex =>
        ex?.shiftPlans?.flatMap(shiftPlan => [
            processAssignments(ex, shiftPlan, 'excavator'),
            ...shiftPlan?.haulTrucks?.map(ht => processAssignments(ex, ht, 'haulTruck'))
        ])
    );

    try {
        await Promise.all(promises);
        return { hasOverlap: allOverlaps.length > 0, allOverlaps };
    } catch (error) {
        console.error('Error processing promises:', error);
        return { hasOverlap: false, allOverlaps: [] };
    }
};