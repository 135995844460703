import { GET_CREW, GET_OPERATORS } from '../../../../action/apiPath';
import { CallWithAuth } from '../../../../action/apiActions';

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000;

const getFromCache = (key) => {
    const cachedData = cache[key];
    if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
        return cachedData.data;
    }
    return null;
};

const setCache = (key, data) => {
    cache[key] = {
        data,
        timestamp: Date.now(),
    };
};

export const fetchCrewTypes = async () => {
    const cacheKey = 'crewTypes';
    const cachedData = getFromCache(cacheKey);

    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", GET_CREW);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching crew: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching crew: ${error.message}`);
    }
};

export const fetchOperators = async (selectedCrewType, equipmentType, userTypes) => {
    let query = '';

    if (selectedCrewType) query += `crewType=${selectedCrewType}`;
    if (equipmentType) query += `&equipmentType=${equipmentType}`;
    if (userTypes) query += `&userTypes=${userTypes?.join(',')}`

    const cacheKey = `operators_${selectedCrewType || 'all'}_${equipmentType || 'all'}_${userTypes ? userTypes?.sort(((a, b) => a - b))?.join(',') : 'all'}`;
    console.log(cacheKey);
    const cachedData = getFromCache(cacheKey);

    if (cachedData) {
        return cachedData;
    }

    try {
        const response = await CallWithAuth("GET", `${GET_OPERATORS}?${query}`);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data);
            return data;
        } else {
            throw new Error(`Error fetching operators: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching operators: ${error.message}`);
    }
};
