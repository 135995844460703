import React, { useState, useEffect } from "react";
import ProgressBar from "../../../../components/ProgressBar";
import { Image } from "react-bootstrap";
import TruckRow from "./TruckRow";
import { utcToNZTime } from "../../../../utils/helper";
import { getHourlyTarget } from "../../../../utils/helper";
import { useContext } from "react";
import DashboardContext from "../../Context/DashboardContext";

export default function ExcavatorsCard({ excavator }) {

  const { fallingBehind, shiftTiming } = useContext(DashboardContext)
  const [isFallingBehind, setIsFallingBehind] = useState(false);

  useEffect(() => {
    setIsFallingBehind(fallingBehind.excavators?.includes(excavator.excavatorName))
  }, [fallingBehind])


  const checkForCatching = (equipment) => {
    const curr = utcToNZTime()?.date;
    let ext;
    let finalTime;
    if (new Date(shiftTiming.end)?.getTime() > curr.getTime()) {
      finalTime = new Date(curr?.getTime() - 3600000)?.toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true
      })
      ext = new Date(curr?.getTime())?.toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true
      })
    }
    else
      finalTime = new Date(new Date(shiftTiming.end).getTime() - 10000).toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true
      })
    const data = equipment?.hourlyData[finalTime];

    const target = Math.round(getHourlyTarget(
      equipment?.productive_hours,
      parseInt(equipment?.targetLoads, 10)
    ))


    return (!data || (data < target && (equipment?.hourlyData[ext] ? equipment?.hourlyData[ext] : 0) < target)) && equipment.actualLoads < equipment.targetLoads;
  }

  return (
    <div
      className="excavator_card_container"
      style={{
        // height: 'max-content',
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border:
          "1px solid " +
          (excavator.breakdown
            ? "#ED7C7F"
            : isFallingBehind
              ? "#EADE5B"
              : "#DEE5F2"),
        borderRadius: "7px",
        width: 370,
        margin: "15px 10px",
      }}
    >
      {/* {console.log(trucks)} */}
      <div
        style={{
          borderBottom: "1px solid #E1E8F4",
          display: "flex",
          alignItems: "center",
          padding: "30px 30px 20px 30px",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex" style={{ alignItems: "flex-start" }}>
          {excavator.breakdown ? (
            <Image
              src={require("../../../../assets/images/not-available.png")}
              height={18}
              width={18}
              style={{ marginTop: 5 }}
            />
          ) : isFallingBehind ? (
            <Image
              src={require("../../../../assets/images/alert.png")}
              height={18}
              width={18}
              style={{ marginTop: 5 }}
            />
          ) : (
            <div
              style={{
                height: 18,
                width: 18,
              }}
            ></div>
          )}
          <div
            style={{ marginLeft: "3px" }}
            className="d-flex flex-column align-items-center"
          >
            <span
              style={{
                font: " normal normal 600 20px/27px Open Sans",
                marginBottom: "5px",
              }}
            >
              {excavator?.excavatorName}
            </span>
            <span
              style={{
                font: "normal normal normal 12px/17px Open Sans",
                color: "#606060",
              }}
            >
              ({excavator?.operator?.firstName})
            </span>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <span
            style={{
              font: "normal normal 600 20px/27px Open Sans",
              marginBottom: "2px",
            }}
          >
            {" "}
            {excavator?.actualLoads}
            <span
              style={{
                font: "normal normal normal 20px/27px Open Sans",
                color: "#707070",
              }}
            >
              {" "}
              / {excavator?.targetLoads}
            </span>
          </span>
          <div>
            <ProgressBar
              progress={(excavator?.actualLoads / excavator?.targetLoads) * 100}
              height={20}
              width={180}
              notCatching={checkForCatching(excavator)}
              fallingBehind={isFallingBehind}
              isNotOperating={excavator.breakdown}
            />
          </div>
        </div>
      </div>

      <div style={{ padding: "15px 0" }}>
        <div style={{ marginTop: "20px", marginBottom: "35px", padding: "0 30px" }}>
          <Image
            src={require("../../../../assets/images/truck_light.png")}
            height={30}
            width={40}
          />{" "}
          <span
            style={{
              font: "normal normal normal 20px/27px Open Sans",
              color: "#707070",
              marginLeft: "10px",
              fontSize: "1.3rem",
            }}
          >
            TRUCKS (
            {excavator?.trucks?.length ? excavator?.trucks?.length : 0})
          </span>
        </div>

        <div style={{ width: "100%" }}>
          {excavator?.trucks?.map((truck) => {
            return <TruckRow truck={truck} isFallingBehind={fallingBehind.trucks?.includes(truck.truckName)} shiftTiming={shiftTiming} />

          })}

          {/* {excavator?.allLoads?.filter(tt =>
            !excavator?.haulTrucks?.some((ht) => ht.id == tt.id)
          )?.map((tt) => {
            const truck = trucks?.filter(function (t) {
              return t?.name?._id == tt.id;
            })[0];
            return <TruckRow shiftTiming={shiftTiming} externalLoads={tt?.loads} unScheduled={true} truck={truck} behindTargetTrucks={behindTargetTrucks} notOperatingTrucks={notOperatingTrucks} />
          })} */}
        </div>
      </div>
    </div>
  );
}
