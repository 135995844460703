import { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
} from 'victory';

import { CallWithAuth } from '../action/apiActions';
import { END_SHIFT } from '../action/apiPath';
import Button from '../components/Buttons';
import TruckGraphRow from '../components/Dashboard/TruckGraphRow';
import {
  formatData,
  getHourlyTarget,
  isCurrentBar,
  utcToNZTime,
} from '../utils/helper';
import ConfirmationPopup from './Dashboard/Popup';

const getMaximumDomain = (v, ex) => {
  return Math.max(v, ex) + Math.max(v, ex) / 2;
};

const HourlyResult = (props) => {
  const {
    notOperatingExcavators,
    breakdownExcavatorsOldData,
    notOperatingTrucks,
    behindTargetExcavators,
    behindTargetTrucks,

  } = props;

  const getAverageBCM = (excavator) => {
    const curr = utcToNZTime()?.date;
    const endTime = new Date(props.shiftStartingAndEndingTime?.end);
    const startTime = new Date(props.shiftStartingAndEndingTime?.start);

    if (endTime.getTime() < curr.getTime()) {
      const totalHours = excavator?.productive_hours;
      const totalBCM = Object.values(excavator?.hourlyData)?.reduce((prev, curr) => prev + curr, 0);
      return Math.floor(totalBCM / totalHours);
    } else {
      const elapsedTime = curr.getTime() - startTime.getTime();
      const totalHours = elapsedTime / 3600000;
      const totalBCM = Object.values(excavator?.hourlyData)?.reduce((prev, curr) => prev + curr, 0);
      return Math.floor(totalBCM / totalHours);
    }
  };

  const intervalId = useRef(null);


  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      setActiveExcavator((prevActiveExcavator) => {
        const nextExcavator = (prevActiveExcavator + 1) % (props?.excavators?.length || 1);
        return nextExcavator;
      });
    }, 20000);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [props?.excavators]);

  const [width, setWidth] = useState(0);
  // const [excavatorHourlyTarget, setExcavatorHourlyTarget] = useState(0)
  const [height, setHeight] = useState(0);
  const [activeExcavator, setActiveExcavator] = useState(0);
  const scrollableWindow = useRef();
  const excavatorCardRef = useRef();
  const [searchName, setsearchName] = useState("");
  const [target, setTarget] = useState(0);
  const [popup, setPopup] = useState();

  useEffect(() => {
    setWidth(excavatorCardRef.current?.getBoundingClientRect().width);
    setHeight(excavatorCardRef.current?.getBoundingClientRect().height);
    const handleResize = () => {
      setWidth(excavatorCardRef.current?.getBoundingClientRect().width);
      setHeight(excavatorCardRef.current?.getBoundingClientRect().height);
    };
    excavatorCardRef.current?.addEventListener("resize", handleResize);
    return () => {
      excavatorCardRef.current?.removeEventListener("resize", handleResize);
    };
  }, []);
  const [particularExcavatorHaulTrucks, setparticularExcavatorHaulTrucks] =
    useState(props?.excavators?.[activeExcavator]?.haulTrucks);
  const [particularExcavatorLoadTrucks, setparticularExcavatorLoadTrucks] =
    useState(props?.excavators?.[activeExcavator]?.allLoadsHourlyData);


  useEffect(() => {
    console.log(props?.excavators?.[activeExcavator]?.allLoadsHourlyData);
    setparticularExcavatorLoadTrucks(props?.excavators?.[activeExcavator]?.allLoadsHourlyData)
    setparticularExcavatorHaulTrucks(
      props?.excavators?.[activeExcavator]?.haulTrucks
    );
    setparticularExcavatorLoadTrucks(
      props?.excavators?.[activeExcavator]?.loads
    )
    setsearchName("");

    setTarget(Math.round(getHourlyTarget(
      props?.excavators?.[activeExcavator]?.productive_hours,
      parseInt(props?.excavators?.[activeExcavator]?.targetLoads, 10)
    )))
  }, [activeExcavator]);

  const endShifts = async (closePopup, data) => {
    const response = await CallWithAuth('POST', END_SHIFT, {
      type: 'single',
      shiftId: props?.shiftData?._id,
      ...data
    });
    if (response?.res?.data?.status === 200) {
      toast.success(`Shift ended successfully for excavator ${data?.name}.`, {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      closePopup();
    } else {
      toast.error('An error occured when ending the shift.', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className="d-flex justify-content-center px-3 mb-3"
        id="button_header"
        style={{
          width: "100%",
          paddingTop: "20px",
          paddingBottom: "13px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: " 0px 3px 6px #00000029",
          height: "max-content",
          overflowX: "auto",
        }}
      >
        {props?.excavators?.map((excavator, id) => {
          return (
            <Button
              key={id}
              label={excavator.name?.Unit}
              alert={behindTargetExcavators?.includes(excavator.name.Unit)}
              hold={notOperatingExcavators?.includes(excavator.name.Unit)}
              onClick={() => {
                setActiveExcavator(id);
              }}
              selected={id == activeExcavator}
            />
          );
        })}
      </div>

      {props?.excavators?.[activeExcavator]?.hourlyData && (
        <div
          ref={scrollableWindow}
          style={{

            width: "100%",
            padding: "10px 25px",
            height: "calc(100vh - 205px)",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              marginRight: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                style={{
                  font: "normal normal 700 18px/25px Open Sans",
                }}
              >
                Hourly Results
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4" id="excavator_part">
            <div
              style={{
                opacity: notOperatingExcavators?.includes(
                  props?.excavators?.[activeExcavator]?.name?.Unit
                ) ? 0.5 : 1,
                width: "330px",
                height: "330px",
                background: " #FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid " +
                  (notOperatingExcavators?.includes(props?.excavators?.[activeExcavator].name.Unit)
                    ? "#ED7C7F"
                    : behindTargetExcavators?.includes(props?.excavators?.[activeExcavator].name.Unit)
                      ? "#EADE5B"
                      : "#DEE5F2"),
                borderRadius: "8px",
                padding: 20,
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ font: "normal normal bold 20px/27px Open Sans" }}
                >
                  <Image
                    src={require("../assets/images/jcb.png")}
                    height={30}
                    width={40}
                  />
                  Excavators
                </span>
                {notOperatingExcavators?.includes(
                  props?.excavators?.[activeExcavator]?.name?.Unit
                ) ? (
                  <Image
                    src={require("../assets/images/not-available.png")}
                    height={32}
                    width={32}
                  // style={{ marginTop: 5 }}
                  />
                ) : behindTargetExcavators?.includes(props?.excavators?.[activeExcavator].name.Unit) ? (
                  <Image
                    src={require("../assets/images/alert.png")}
                    height={18}
                    width={18}
                    style={{ marginTop: 5 }}
                  />
                ) : (
                  <div
                    style={{
                      height: 18,
                      width: 18,
                    }}
                  ></div>
                )}{" "}
              </div>
              <div
                style={{
                  paddingTop: "25px",
                  paddingBottom: "25px",
                  font: "normal normal bold 20px/27px Open Sans",
                  color: "black",
                }}
              >
                {" "}
                {props?.excavators?.[activeExcavator]?.name?.Unit}
              </div>

              <div
                className="d-flex flex-column justify-content-between"
                style={{ height: "150px" }}
              >
                <span
                  style={{
                    font: "normal normal normal 14px/22px Open Sans",
                    color: "#707070",
                  }}
                >
                  BCM:{" "}
                  <span
                    style={{
                      font: "normal normal 600 16px/22px Open Sans",
                      color: "#1A1A1A",
                    }}
                  >
                    {props?.excavators?.[activeExcavator]?.actualLoads}/
                    {props?.excavators?.[activeExcavator]?.targetLoads}
                  </span>
                </span>
                <span
                  style={{
                    font: "normal normal normal 14px/22px Open Sans",
                    color: "#707070",
                  }}
                >
                  Average/hr:{" "}
                  <span
                    style={{
                      font: "normal normal 600 16px/22px Open Sans",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    {getAverageBCM(props?.excavators?.[activeExcavator])}{" "}
                    BCM
                  </span>
                </span>
                <span
                  style={{
                    font: "normal normal normal 14px/22px Open Sans",
                    color: "#707070",
                  }}
                >
                  Hourly Target:{" "}
                  <span
                    style={{
                      font: "normal normal 600 16px/22px Open Sans",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    {target}{" "}
                    BCM
                  </span>
                </span>
                <span
                  style={{
                    font: "normal normal normal 14px/22px Open Sans",
                    color: "#707070",
                  }}
                >
                  First Load:{" "}
                  <span
                    style={{
                      font: "normal normal 600 16px/22px Open Sans",
                      color: "#1A1A1A",
                    }}
                  >
                    {utcToNZTime(props.excavators?.[activeExcavator]?.firstBucket)
                      ?.formattedTime || "-"}
                  </span>
                </span>
                <span
                  style={{
                    font: "normal normal normal 14px/22px Open Sans",
                    color: "#707070",
                  }}
                >
                  Operator:{" "}
                  <span
                    style={{
                      font: "normal normal 600 16px/22px Open Sans",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    {props?.excavators?.[activeExcavator]?.operator?.firstName ||
                      "-"}
                  </span>
                </span>
              </div>

              {props?.shiftData?.status == 'O' && <button
                className="btn btn-outline-info"
                disabled={true}
                onClick={() => {
                  return
                  setPopup({
                    title: `You're about to end the shift for EXCAVATOR ${props?.shiftData?.excavators[activeExcavator].name?.Unit}`,
                    description: 'Would you like to continue?',
                    confirmText: 'Confirm',
                    onCancel: () => {
                      setPopup(undefined);
                    },
                    onConfirm: () => {
                      endShifts(() => setPopup(undefined), {
                        name: props?.excavators[activeExcavator].name?.Unit,
                        _id: props?.excavators[activeExcavator].equipment
                      });
                    },
                  });
                }}
                style={{ marginTop: 10, width: '40%' }}
              >
                End Shift
              </button>}
            </div>

            <div
              style={{

                display: "flex",
                width: "calc(100% - 400px)",
                height: "330px",
                marginLeft: "40px",
                background:
                  "0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)",
                border: "1px solid rgb(225, 232, 244)",
                borderRadius: "8px",
                alignItems: "center",
              }}
              ref={excavatorCardRef}
            >
              <div className="d-flex justify-content-end align-items-center">
                <span
                  style={{
                    transform: "rotate(270deg)",
                    height: "max-content",
                    fontWeight: "700",
                  }}
                >
                  BCM
                </span>
                <div style={{ width: "calc(100% - 60px)", height: "100%" }}>
                  <VictoryChart
                    width={width}
                    height={height}
                    domainPadding={{ x: 25 }}
                  >
                    <VictoryBar
                      alignment="middle"
                      barWidth={12}
                      data={formatData(
                        props?.excavators?.[activeExcavator]?.hourlyData,
                        props.shiftData?.shiftType,
                        props?.excavators?.[activeExcavator]
                      )}
                      events={[{
                        target: "data",
                        eventHandlers: {

                          onMouseOver: () => {

                            return [{
                              target: 'labels',
                              mutation: (props) => {
                                // if (props.datum)
                                //     console.log(props);
                                if (props.datum.earnings >= target)
                                  return {
                                    style: Object.assign({}, props.style, { fill: "black" })
                                  };
                              }
                            }];
                          },
                          onMouseOut: () => {
                            return [{
                              target: 'labels',
                              mutation: (innerProps) => {
                                if (innerProps.datum.earnings >= target)
                                  return {
                                    style: Object.assign({}, innerProps.style, { fill: ({ datum }) => isCurrentBar(datum.quarter, props?.shiftStartingAndEndingTime?.end) ? "black" : datum.earnings >= target ? "black" : "black" })
                                  };
                              }
                            }];
                          }
                        }
                      }]}
                      style={{
                        background: { fill: (re) => "yellow" },
                        data: {
                          fill: ({ datum }) =>
                            isCurrentBar(datum.quarter, props?.shiftStartingAndEndingTime?.end)
                              ? '#80B4FD'
                              : props.unScheduled
                                ? '#707070'
                                : datum.earnings >= target
                                  ? "#95C365"
                                  : "#FE747C",
                          cursor: ({ datum }) => datum.earnings >= target ? "pointer" : "default",
                        },

                        labels: {
                          fontSize: 15,
                          marginLeft: 50,

                          fill: ({ datum }) =>
                            isCurrentBar(datum.quarter, props?.shiftStartingAndEndingTime?.end) ? "black" : datum.earnings >= target ? "black" : "black",
                        },
                      }}
                      theme={VictoryTheme.grayscale}
                      x="quarter"
                      y="earnings"
                      cornerRadius={5}
                    />
                    <VictoryAxis
                      dependentAxis
                      minDomain={0}
                      domain={[
                        0,
                        getMaximumDomain(
                          Math.max(
                            ...formatData(
                              props?.excavators?.[activeExcavator]?.hourlyData,
                              props.shiftData?.shiftType,
                              props?.excavators?.[activeExcavator]
                            ).map((v) => v.earnings)
                          ),
                          target
                        ),
                      ]}
                      tickFormat={(y) => y}
                      style={{
                        axis: { stroke: "black" },
                        ticks: { stroke: "black" },
                        tickLabels: { fill: "black" },
                      }}
                    />
                    <VictoryAxis
                      style={{
                        axis: { stroke: "black" },
                        ticks: { stroke: "black" },
                        tickLabels: {
                          fill: "black",
                          fontWeight: ({ text }) =>
                            isCurrentBar(text, props?.shiftStartingAndEndingTime?.end) ? "700" : "normal",

                          fontSize: ({ text }) =>
                            isCurrentBar(text, props?.shiftStartingAndEndingTime?.end) ? "18px" : "17px"
                        },
                      }}
                    />
                    <VictoryLine
                      y={() =>
                        target
                      }
                      style={{
                        data: { stroke: "black" },
                      }}
                    />
                    {/* <VictoryAxis
                  // style={{ marginBottom: '150px' }}
                  label="time"
                // dependentAxis
                /> */}
                  </VictoryChart>
                </div>
              </div>
            </div>
          </div>

          <div
            id="truck_part"
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "1px solid #E1E8F4",
              borderRadius: "8px",
              marginTop: "25px",
              marginBottom: "60px",
              padding: "20px",
              width: "100%",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-start">
                <Image
                  src={require("../assets/images/truck.png")}
                  height={21}
                  width={28}
                />
                <span
                  style={{
                    marginLeft: 10,
                    font: "normal normal 700 18px/25px Open Sans",
                  }}
                >
                  Trucks(
                  {props?.excavators?.[activeExcavator]?.haulTrucks?.length ?? 0})
                </span>
              </div>
              <div>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #dde5f3",
                    justifyContent: "space-between",
                    padding: "6px 12px",
                    borderRadius: "15px",
                    width: "250px",
                    backgroundColor: "white",
                  }}
                >
                  <input
                    type="search"
                    id="searchuser"
                    name="searchuser"
                    className="shadow-none"
                    value={searchName}
                    placeholder="Search for an Truck"
                    style={{
                      border: "none",
                      font: "normal normal normal 14px Open Sans",
                      width: "200px",
                    }}
                    onChange={(v) => {
                      setsearchName(v.target.value);
                      setparticularExcavatorHaulTrucks(
                        props?.excavators?.[activeExcavator]?.haulTrucks.filter(
                          (obj) =>
                            obj?.Unit?.toLowerCase().includes(
                              v.target.value.toLowerCase()
                            )
                        )
                      );
                      // console.log("Search")
                    }}
                  />
                  <i className="fa-regular fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
            {!notOperatingExcavators.includes(
              props?.excavators[activeExcavator]?.name?.Unit
            ) && particularExcavatorHaulTrucks.map((ht) => {
              return props?.trucks?.filter(function (truck) {
                return truck?.name?._id == ht?._id;
              })[0];
            }).sort((truckL, truckR) => {
              if (parseInt(truckR.actualLoads) >= parseInt(truckR.targetLoads) && truckL.breakdown)
                return true;
              else if (parseInt(truckR.actualLoads) >= parseInt(truckR.targetLoads) && parseInt(truckL.actualLoads) < parseInt(truckL.targetLoads))
                return true;
              else if (parseInt(truckL.actualLoads) < parseInt(truckL.targetLoads) && truckR.breakdown)
                return true;
              return false;
            }).map((truck, id) => {
              return (
                <TruckGraphRow key={id} {...props} notOperatingTrucks={notOperatingTrucks} truck={truck} getMaximumDomain={getMaximumDomain} behindTargetTrucks={behindTargetTrucks} />
              );
            })}
            {notOperatingExcavators.includes(
              props?.excavators[activeExcavator]?.name?.Unit
            ) && breakdownExcavatorsOldData.find(ex => ex._id.toString() == props?.excavators[activeExcavator].equipment.toString())?.trucks?.sort((truckL, truckR) => {
              if (parseInt(truckR.actualLoads) >= parseInt(truckR.targetLoads) && truckL.breakdown)
                return true;
              else if (parseInt(truckR.actualLoads) >= parseInt(truckR.targetLoads) && parseInt(truckL.actualLoads) < parseInt(truckL.targetLoads))
                return true;
              else if (parseInt(truckL.actualLoads) < parseInt(truckL.targetLoads) && truckR.breakdown)
                return true;
              return false;
            }).map((truck, id) => {
              return (
                <TruckGraphRow {...props} notOperatingTrucks={notOperatingTrucks} truck={truck} getMaximumDomain={getMaximumDomain} behindTargetTrucks={[]} />
              );
            })}
            {/* {props?.excavators[activeExcavator]?.allLoadsHourlyData?.map((ht, id) => {
              let truck = props?.trucks?.filter(function (truck) {
                return truck?.name?.Unit == ht?.name;
              })[0];
              return (
                <TruckGraphRow key={id} {...props} notOperatingTrucks={notOperatingTrucks} truck={{ ...truck, actualLoads: ht.totalLoads }} loads={ht.loads}
                  unScheduled={true}
                  getMaximumDomain={getMaximumDomain} behindTargetTrucks={behindTargetTrucks} />
              );
            })} */}

            {popup && <ConfirmationPopup {...popup} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default HourlyResult;
