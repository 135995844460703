import { RiRadioButtonLine } from 'react-icons/ri';
// import { formatDate, getTimeDiff } from './helper';
import { formatDate } from '../helper';

const DOT_HEIGHT = 20;
const MIN_LINE_HEIGHT = 52;

export const Dot = ({ Dotstyles, status, active }) => {
  const getColor = () => {
    if (!status && active)
      return '#1b499f';
    if (status)
      return '#2A7B39';
    return "#939292"
  }
  const getBgColor = () => {
    if (!status && active)
      return '#72a2fd';
    if (status)
      return '#63E07A';
    return "rgb(226, 226, 226)"
  }
  return (
    <RiRadioButtonLine
      color={getColor()}
      min={status ? DOT_HEIGHT + 5 : DOT_HEIGHT}
      size={status ? DOT_HEIGHT + 5 : DOT_HEIGHT}
      style={{
        backgroundColor: getBgColor(),
        borderRadius: '50%',
        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        // padding: '0',
        margin: '0 auto',
        padding: "0px",
        ...(Dotstyles ? Dotstyles : {})
      }}
    />
  );
};

export const DotDescription = ({ label, detail }) => (
  <hgroup style={{ fontSize: '12px', height: DOT_HEIGHT }}>
    <div className='mt-2' style={{ fontSize: '1rem', fontWeight: '600', lineHeight: 0.1, margin: 0 }}>
      {label}
    </div>
    <p
      style={{
        color: 'gray',
        fontSize: '0.9rem',
        fontWeight: 600,
        height: 'max-content',
        marginBottom: 0,
      }}
    >
      <em>{detail}</em>
    </p>
  </hgroup>
);

const Line = ({ active = false, height }) => {
  const color = active ? '#1b499f' : '#939292';
  const width = active ? '5px' : '3px';
  return (
    <div
      style={{
        backgroundColor: color,
        height: height,
        minHeight: height,
        margin: '0 auto',
        width: width,
      }}
    />
  );
};

export const ShiftHistoryTimeline = ({ selectedUnit }) => {

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        // height: 'calc(100% - 40px)',
        paddingBottom: 20,
        minHeight: 'max-content',
        marginLeft: "6.3rem",
        marginTop: 40,

      }}
    >

      < section style={{ marginLeft: 10, width: 30, marginRight: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <Dot active={selectedUnit.sourceArivalTime} status={selectedUnit.status == 1} />

        <Line active={selectedUnit.excavatorConnectedTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        <Dot active={selectedUnit.excavatorConnectedTime} status={selectedUnit.status == 2} />

        <Line active={selectedUnit.loadFilledTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        <Dot active={selectedUnit.loadFilledTime} status={selectedUnit.status == 3} />

        <Line active={selectedUnit.sourceExitTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        <Dot active={selectedUnit.sourceExitTime} status={selectedUnit.status == 4} />

        <Line active={selectedUnit.dumpPointEntryTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        <Dot active={selectedUnit.dumpPointEntryTime} status={selectedUnit.status == 5} />

        <Line active={selectedUnit.dumpingStartingTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        <Dot active={selectedUnit.dumpingStartingTime} status={selectedUnit.status == 6} />

        <Line active={selectedUnit.dumpingEndingTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        <Dot active={selectedUnit.dumpingEndingTime} status={selectedUnit.status == 7} />

        <Line active={selectedUnit.dumpPointExitTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        {/* <Dot active={selectedUnit.dumpPointExitTime} status={selectedUnit.status == 0} /> */}

        {/* <Line active={selectedUnit.dumpPointExitTime} height={Math.max(MIN_LINE_HEIGHT, 65)} /> */}
        <Dot active={selectedUnit.dumpPointExitTime} status={selectedUnit.status == 8} />

        <Line active={selectedUnit.sourceReturningTime} height={Math.max(MIN_LINE_HEIGHT, 65)} />
        <Dot active={selectedUnit.sourceReturningTime} status={selectedUnit.status == 9} />


      </section >

      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: Math.max(MIN_LINE_HEIGHT, 65),
          width: 'fit-content',
        }}
      >
        <DotDescription label={"Arrived at Area " + selectedUnit.sourceAtStart} detail={formatDate(selectedUnit.sourceArivalTime, '00:00 AM')} status={selectedUnit.status == 1} />
        <DotDescription label={"Connected to " + selectedUnit.contactTitle} detail={formatDate(selectedUnit.excavatorConnectedTime, '00:00 AM')} status={selectedUnit.status == 2} />
        <DotDescription label={"Loaded - " + selectedUnit.material + " (" + (selectedUnit.dumpPoint ? selectedUnit.dumpPoint : "Dump Point") + ")"} detail={formatDate(selectedUnit.loadFilledTime, '00:00 AM')} status={selectedUnit.status == 3} />
        <DotDescription label={"Travelling to " + (selectedUnit.dumpPoint ? selectedUnit.dumpPoint : "Dump Point")} detail={formatDate(selectedUnit.sourceExitTime, '00:00 AM')} status={selectedUnit.status == 4} />
        <DotDescription label={"Arrived at " + (selectedUnit.dumpPoint ? selectedUnit.dumpPoint : "Dump Point")} detail={formatDate(selectedUnit.dumpPointEntryTime, '00:00 AM')} status={selectedUnit.status == 5} />
        <DotDescription label={"Dumping at " + (selectedUnit.dumpPoint ? selectedUnit.dumpPoint : "Dump Point")} detail={formatDate(selectedUnit.dumpingStartingTime, '00:00 AM')} status={selectedUnit.status == 6} />
        <DotDescription label={selectedUnit.material + " Dumped Successfuly"} detail={formatDate(selectedUnit.dumpingEndingTime, '00:00 AM')} status={selectedUnit.status == 7} />
        {/* <DotDescription label={"Exited " + selectedUnit.dumpPoint} detail={formatDate(selectedUnit.dumpPointExitTime, '00:00 AM')} status={selectedUnit.status == 0} /> */}
        <DotDescription label={"Trammimg Empty (Exited " + selectedUnit.dumpPoint + ")"} detail={formatDate(selectedUnit.dumpPointExitTime, '00:00 AM')} status={selectedUnit.status == 8} />
        <DotDescription label={"Returned to Area " + selectedUnit.sourceAtEnd} detail={formatDate(selectedUnit.sourceReturningTime, '00:00 AM')} status={selectedUnit.status == 9} />
      </section>
    </section >
  );
};
