import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CallWithAuth } from '../../action/apiActions';
import { CHANGE_PASSWORD_ADMIN } from '../../action/apiPath';
import GuestLayout from '../../components/GuestLayout';
import usePasswordToggle from '../../hooks/usePasswordToggle';

const ChangePassword = () => {
  const navigate = useNavigate();

  const [updateId, setUpdateId] = useState('');

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const [passwordInputType, PasswordToggleIcon] = usePasswordToggle();
  const [ConfirmpasswordInputType, ConfirmPasswordToggleIcon] =
    usePasswordToggle();

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    isError: false,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sid = queryParams.get('id');
    // console.log(sid);
    if (sid) {
      sid && setUpdateId(sid);
    } else {
    }
  }, []);

  const handelOnchange = (e) => {
    setErr(false);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      isError: false,
    });
  };

  const handelValidateForm = () => {
    if (formData.password !== '' && formData.confirmPassword !== '')
      return Promise.resolve(true);
    return Promise.resolve(false);
  };

  // const [newPassword, setNewpassword] = useState("");
  // const [confirmPassword, setConfirmpassword] = useState("");

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    let Validations = await handelValidateForm();
    if (Validations) {
      if (formData.password === formData.confirmPassword) {
        const payload = {
          id: updateId ? updateId : undefined,
          password: formData.password,
        };
        setLoading(true);
        let response = await CallWithAuth(
          'POST',
          CHANGE_PASSWORD_ADMIN,
          payload
        );
        if (response?.res?.data?.status === 200) {
          setLoading(false);
          toast.success('Password Changed Successfully', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          navigate('/', { replace: true });
        } else {
          setLoading(false);
        }
      } else {
        setErr(true);
      }
    } else {
      setFormData({ ...formData, isError: true });
    }
    // console.log(formData.password, formData.confirmPassword);
  };

  return (
    <GuestLayout>
      <section className="before_login_screen">
        <div className="before_main_login_box">
          <div className="before_logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="login_box">
            <hgroup className="before_login_heading">
              <h1>Change Password</h1>
            </hgroup>
            <form onSubmit={changePasswordHandler}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input_field_wrap has-float-label pb-5">
                      <input
                        type={passwordInputType}
                        className="form-control"
                        id="pwd"
                        name="password"
                        value={formData.password}
                        onChange={handelOnchange}
                        // placeholder="New Password"
                        style={{
                          border:
                            formData.isError && formData.password === ''
                              ? '1px solid red'
                              : '',
                        }}
                      />
                      <label>New Password*</label>
                      <div className="onpwd">{PasswordToggleIcon}</div>
                    </div>
                    <span className="form-control-message">
                      {formData.isError &&
                        !formData.password &&
                        'Please enter password'}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input_field_wrap has-float-label pb-5">
                      <input
                        type={ConfirmpasswordInputType}
                        className="form-control"
                        id="pwd"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handelOnchange}
                        // placeholder="New Password"
                        style={{
                          border:
                            formData.isError && formData.confirmPassword === ''
                              ? '1px solid red'
                              : '',
                        }}
                      />
                      <label>Confirm New Password*</label>
                      <div className="onpwd">{ConfirmPasswordToggleIcon}</div>
                    </div>
                    <span className="form-control-message">
                      {formData.isError &&
                        !formData.confirmPassword &&
                        'Please enter Confirm New Password'}
                      {err &&
                        'Password Confirm New Password both need need to be same'}
                    </span>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      className="form-control login_button"
                      value="Change Password"
                      id="login-btn"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </GuestLayout>
  );
};

export default ChangePassword;
