import React, { useState, useEffect } from 'react';
import InputField from '../InputField';
import './style.css';
import CountryCode from '../Country Code/CountryCode';
import TextInputField from '../../pages/ShiftPlanning/components/GeneralFields/TextInputField';

const PhoneNum = ({ label, value, required, showCountryCode, onChange,labelStyle, labelClassName, error, errorMessage, conatinerClassName, modalType}) => {
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if (value) {
            const [code, ...numberParts] = value.split(' ');
            setCountryCode(code.replace('+', ''));
            setPhoneNumber(numberParts.join(' '));
        }
    }, [value]);

    useEffect(() => {
        if (countryCode && phoneNumber) {
            onChange(`+${countryCode} ${phoneNumber}`);
        }
    }, [countryCode, phoneNumber]);

    function formatPhoneNumber(phoneNumber) {
        const formattedNo = phoneNumber?.replace(/^(\+\d{1,3})\s(\d+)$/, "($1) $2");
        return formattedNo;
    }
    
      

    return (
        <div className={`user-phone-input-container mb-3 pr-0 ${conatinerClassName}`}>
            <span className={`col-sm-4 ${labelClassName}`}>{label && <label style={{...labelStyle}}>{label}{required && <span style={{ color: "#6A7C9A" }}>*</span>}</label>}</span>
            <div className={`d-flex ${label ? 'col-sm-8 pr-3' : 'col-sm-12'} justify-content-between pl-0 ${modalType==="Edit" && "pr-3"}`}>
                { (modalType==="View" || modalType ==="Edit" ) ?
               <TextInputField
                        type="text"
                        value={formatPhoneNumber(value)}
                        placeholder=""
                        onChange={(v) => onChange(v)}
                        wrapperClassName="col-sm-12 pt-3 px-0 mx-0"
                        viewOnly={modalType==="View" }
                        className="px-0 mxx-0"
                    /> :
                    (
                        <>
                            <CountryCode
                                className="col-sm-4 px-0 pt-3"
                                value={countryCode}
                                onChange={(v) => setCountryCode(v)}
                                error={error}
                                errorMessage={errorMessage}
                            />
                          <TextInputField
                                type="number"
                                value={phoneNumber}
                                onChange={(v) => setPhoneNumber(v)}
                                placeholder=""
                                wrapperClassName="col-sm-8 pr-0 pl-1 pt-3"
                                labelStyle={labelStyle}
                            />
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default PhoneNum;