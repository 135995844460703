import { useEffect } from "react";

export function useDefaultBlocker(message, when) {

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (when) {
                event.preventDefault();
                event.returnValue = message;
            }
        };
        if (when)
            window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [message]);
}