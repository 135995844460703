import React from "react";
import styles from "./Tooltip.module.css";

const Tooltip = ({ tooltipContent, child, dontShowToolTip = false, alwaysShowOnHover = false, toolTipStyle = {} }) => {

  return (
    <div className={styles.tooltip}>
      {child || "-"}
      {dontShowToolTip ? <></> : typeof tooltipContent === typeof "" && !alwaysShowOnHover ? (
        tooltipContent.length > 0 && tooltipContent.length > child.length ? (
          <span style={{ ...toolTipStyle }} className={styles.tooltiptext}>{tooltipContent}</span>
        ) : (
          <></>
        )
      ) : (
          <span style={{ ...toolTipStyle }} className={styles.tooltiptext}>{tooltipContent}</span>
      )}
    </div>
  );
};

export default Tooltip;
