import React from 'react'

const RadioButton = ({ label, options, className, style, onChange, labelStyle,selectedValue, required }) => {
  return (
    <div className={`row ${className}`} style={style}>
      {label && (
        <label className="col-md-4" style={{ fontSize: '1.3rem', fontWeight: 500, margin: 0, color: "rgb(9, 35, 68)",...labelStyle }}>
          {label}
          {required && <span style={{ color: "#6A7C9A" }}>*</span>}
        </label>
      )}
      <div className={`d-flex flex-wrap ${label? "col-sm-8" : "col-sm-12 justify-content-center"}`}>
        {options.map((option, index) => (
          <div className={`d-flex ${option.className || 'col-md-4'}`} key={index}>
            <input
              type="radio"
              checked={selectedValue === option.value}
              onChange={() => onChange(option.value)}
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
              }}
            />
            <div className="h4 ml-2">{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RadioButton