import React, { useEffect } from "react";

export default function MessagePopup(props) {
  useEffect(() => {
    let x = setTimeout(() => {
      props.setPopupMessage("");
    }, 2500);
    return () => {
      clearTimeout(x);
    };
  }, [props.popupMessage]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background:
          "0% 0% no-repeat padding-box padding-box rgb(110 110 110 / 30%)",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10000,
      }}
    >
      <div
        style={{
          background: " #FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 2px 10px #00000010",
          borderRadius: 31,
          font: " normal normal bold 16px/18px Open Sans",
          color: "#1A1A1A",
          width: 360,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
          marginBottom: "60px",
        }}
      >
        {props.message}
      </div>
    </div>
  );
}
