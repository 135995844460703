import React from 'react';

const LoadingScreen = () => {
    return (
        <>
            <div className="loadingScreen">
                {/* <img src="/assets/images/loading-gif.gif" alt="loading" /> */}
                <svg
          xmlns="http://www.w3.org/2000/svg"
        //   xmlns:xlink="http://www.w3.org/1999/xlink"
          style={{margin:"auto",background:"transparent",display:"block"}}
          width="197px"
          height="197px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(25 50)">
            <circle cx="0" cy="0" r="10" fill="#0967d2">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.5128205128205128s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1.5384615384615383s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(50 50)">
            <circle cx="0" cy="0" r="10" fill="#0967d2">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.2564102564102564s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1.5384615384615383s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(75 50)">
            <circle cx="0" cy="0" r="10" fill="#0967d2">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="0s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1.5384615384615383s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
        </svg>
            </div>
        </>
    )
}

export default LoadingScreen;