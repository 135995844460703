import React, { useContext, useEffect, useState } from "react";
// import { CallWithAuth } from "../action/apiActions";
// import { GET_USER_ACCOUNT_DETAILS } from "../action/apiPath";
import AuthLayout from "../components/AuthLayout";
import moment from "moment";
import UserEditModal from "../components/UserEditModal";
import ChangePasswordModal from "../components/ChangePasswordModal";
import ChangePinModal from "../components/ChangePinModal";
import useAuth from "../hooks/useAuth";


import { whichUserType, userTypeName } from "./helper";
import AuthContext from "../context/AuthProvider";
import ViewPinModal from "../components/ViewPinModal";

const MyAccount = () => {
  const { auth } = useAuth();

  const {userType} = useContext(AuthContext)

  const [openEditModal, setOpenEditModal] = useState(true);
  const [openChangeModal, setOpenChangeModal] = useState(true);
  const [openChangePinModal, setOpenChangePinModal] = useState(true);
  const [openViewPinModal, setOpenViewPinModal] = useState(false);
  const [userData, setUserData] = useState('')

  useEffect(() => {
    auth?.userData && auth.userData?._id &&
      setUserData(auth?.userData)
    // CallWithAuth("GET", GET_USER_ACCOUNT_DETAILS).then((response) => {
    // 	console.log(response);
    // 	if (response.res.data.status === 200) {
    // 	  setUserData(response.res.data.data);
    // 	}
    //   });

  }, [auth.userData]);
  // console.log(userData);
  const changeViewPinStatus = (userData, type = "TemporaryPassword") => {
    console.log(userData, type);

    if (type === "TemporaryPassword") {
      setUserData({ ...userData, tempPasswordView: !userData.tempPasswordView });

    } else {
      setUserData({ ...userData, pinView: !userData.pinView });
    }

  };





  return (
    <AuthLayout pageName="My Account"  className="d-none">
      <div className="right_sidebar_body">
        <div className="my_account_page">
          <div className="generate_new_pin_box my_account_box" style={{width:"560px"}}>
            <div className="col-md-12 form-group">
              <div className="row">
                <div className="col-md-3">
                  <span>Name:</span>
                </div>
                <div className="col-md-9">
                  <label>
                    <b>{userData?.firstName}{" "}{userData?.lastName}</b>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <div className="row">
                <div className="col-md-3">
                  <span>Email:</span>
                </div>
                <div className="col-md-9">
                  <label>
                    <b>
                      {(whichUserType(userData?.userType)[0] || whichUserType(userData?.userType)[1]) ? userData.email : "N/A"}
                    </b>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <div className="row">
                <div className="col-md-3">
                  <span>Phone No.:</span>
                </div>
                <div className="col-md-9">
                  <label>
                    <b>
                      {userData?.phoneNo}
                    </b>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <div className="row">
                <div className="col-md-3">
                  <span>User Type:</span>
                </div>
                <div className="col-md-9">
                  <label>
                    <b>{userData?.userType?.length > 0 &&
                      (userData?.userType.length > 1
                        ? `${userTypeName(userData?.userType, userType)} + ${userData?.userType.length - 1}`
                        : userTypeName(userData?.userType, userType))
                    }</b>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <div className="row">
                <div className="col-md-3">
                  <span>PIN No.:</span>
                </div>
                <div className="col-md-9">
                  <span
                  style={{color:"#186FD9",cursor:"pointer",font:"normal normal bold 14px/19px Open Sans"}}
                  onClick={() => setOpenViewPinModal((prevstate) => !prevstate)}
                  >
                    View PIN
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <div className="row">
                <div className="col-md-3">
                  <span>Added By:</span>
                </div>
                <div className="col-md-9">
                  <label>
                    <b>{userData?.addedBy ? userData?.addedBy : "N/A"},{" "}{userData?.createdAt ? moment(userData.createdAt).format("DD/MM/YYYY") : "N/A"}</b>
                  </label>
                </div>
              </div>
            </div>
            <div className="generate_new_pin_form">
              <div className="add_new_user_box_top_right">
                <button
                  style={{height:"40px", font:"normal normal bold 14px/19px Open Sans", color:"#186FD9",border:"1.4px solid #a3c6ff", width:"80px"}}
                  className="btn"
                  onClick={() => setOpenEditModal((prevstate) => !prevstate)}
                >
                  Edit
                </button>
                <button
                  style={{height:"40px", font:"normal normal bold 14px/19px Open Sans", color:"#186FD9",border:"1.4px solid #a3c6ff",width:"160px"}}
                  className="btn px-2 mx-2"
                  onClick={() => setOpenChangeModal((prevstate) => !prevstate)}
                >
                  Change Password
                </button>
                <button
                  style={{height:"40px", font:"normal normal bold 14px/19px Open Sans", color:"#186FD9",border:"1.4px solid #a3c6ff",width:"160px"}}
                  className="btn"
                  onClick={() => setOpenChangePinModal((prevstate) => !prevstate)}
                >
                  Edit Pin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UserEditModal openEditModal={openEditModal} closeModal={setOpenEditModal} userData={userData} />

      {/* change password modal starts------------> */}

      <ChangePasswordModal openChangeModal={openChangeModal} closeModal={setOpenChangeModal} />
      <ChangePinModal openChangeModal={openChangePinModal} openViewPinModal={setOpenViewPinModal} closeModal={setOpenChangePinModal} userPin={userData?.pin}/>
     {openViewPinModal && <ViewPinModal pin={userData?.pin} openChangePinModal={setOpenChangePinModal} closeModal={setOpenViewPinModal} />}
      {/* change update modal ends */}
    </AuthLayout>
  );
};

export default MyAccount;
