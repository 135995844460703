import React from "react";

const getStatusColor = (status) => {
  return status === "P"
    ? "#186FD9"
    : status === "D"
    ? "#E58D00"
    : status === "O"
    ? "#26A071"
    : status === "E"
    ? "#6A7C9A"
    : "Red";
};

const GetStatusCell = ({ status }) => {
  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      <div
        style={{
          background: getStatusColor(status),
          height: "9px",
          width: "9px",
          borderRadius: "100%",
        }}
      />
      <div>
        {status === "P"
          ? "Published"
          : status === "D"
          ? "Draft"
          : status === "O"
          ? "Ongoing"
          : status === "E"
          ? "Ended"
          : "Not Available"}
      </div>
    </div>
  );
};

export default GetStatusCell;
